
const leaflets = {
    "dok": {
        "config": {
            "primary-color": "#e48a00",
            "secondary-color": "#dc2311",
            "tertiary-color": "#ffdd00",
            "hover-color": "rgba(220, 35, 17, 0.2)",
            "clientIcon": require("assets/images/dok_clientLogo.png"),
            "serverPath": "dok.volantinointerattivo.net/",
            "pdfPath": "/media/interactive_flyer_3/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino Dok!",
            "shareFlyerURL": "https://dok.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Dok!",
            "ogTitleMeta": "Volantino Interattivo Dok",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://dok.volantinointerattivo.net/media/interactive_flyer_3/images/fine.png",
            "filterBrand": 1,
            "filterUnderprice": 1,
            "brandImage": "/media/interactive_flyer_3/images/selex.png",
            "underPriceImage": "/media/interactive_flyer_3/images/carta.jpg"
        },
        "leaflet": {
            "name": "Progetto Dok",
            "code": "1234dok",
            "id_campaign": "1234",
            "index": {
                "image_file": "/media/interactive_flyer_3/pages/indice.png",
                "links": [
                    {
                        "page": 0,
                        "blueprint": {
                            "top": 15,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7
                        }
                    },
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 22.5,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7
                        }
                    },
                    {
                        "page": 4,
                        "blueprint": {
                            "top": 30,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7
                        }
                    },
                    {
                        "page": 6,
                        "blueprint": {
                            "top": 37.5,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7
                        }
                    },
                    {
                        "page": 8,
                        "blueprint": {
                            "top": 45,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7
                        }
                    },
                    {
                        "page": 10,
                        "blueprint": {
                            "top": 52.5,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7
                        }
                    },
                    {
                        "page": 12,
                        "blueprint": {
                            "top": 60,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7
                        }
                    },
                    {
                        "page": 14,
                        "blueprint": {
                            "top": 67.5,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7.5
                        }
                    },
                    {
                        "page": 16,
                        "blueprint": {
                            "top": 75,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7.5
                        }
                    },
                    {
                        "page": 18,
                        "blueprint": {
                            "top": 82.5,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7.5
                        }
                    },
                    {
                        "page": 20,
                        "blueprint": {
                            "top": 90,
                            "left": 2.5128,
                            "width": 95.5,
                            "height": 7.5
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Alimentari",
                    "subcategories": ["Pasta", "Olio", "Rossi", "Dispensa", "Colazione", "Snack Salati", "Snack Dolci"]
                },

                {
                    "category_name": "Bevande",
                    "subcategories": ["Acqua", "Bibite", "Succhi", "Birre", "Vini e Spumanti", "Liquori"]
                },

                {
                    "category_name": "Gastronomia",
                    "subcategories": ["Salumi", "Formaggi"]
                },

                {
                    "category_name": "Igiene Persona",
                    "subcategories": ["Capelli", "Corpo", "Intima", "Orale"]
                },

                {
                    "category_name": "Igiene Casa",
                    "subcategories": ["Pavimenti", "Superfici", "Bucato", "Extra"]
                },

                {
                    "category_name": "Macelleria",
                    "subcategories": ["Avicoli", "Suino", "Bovino"]
                },

                {
                    "category_name": "No Food",
                    "subcategories": ["Home", "Esterno"]
                },

                {
                    "category_name": "Ortofrutta",
                    "subcategories": ["Frutta", "Verdura"]
                },

                {
                    "category_name": "Pet",
                    "subcategories": ["Food"]
                },

                {
                    "category_name": "Self Service",
                    "subcategories": ["Pasta", "Salumi", "Formaggi", "Ittico", "Yogurt", "Snack", "Latte", "Vegetali"]
                },

                {
                    "category_name": "Surgelati",
                    "subcategories": ["Ittico", "Verdure", "Gelati", "Preparati"]
                }

            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "/media/interactive_flyer_3/pages/page_1.jpg",
                    "products": [
                        {
                            "item_id": 634298,
                            "field1": "5 Cornetti",
                            "field2": "ALGIDA",
                            "field3": "g. 375",
                            "field4": "",
                            "description": "5 Cornetti ALGIDA g. 375",
                            "grammage": 375,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 42.91717417783192,
                                "left": 1.3824604141291137,
                                "width": 33.288672350791714,
                                "height": 27.07673568818514
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/41355651_ALGIDA_CORNETTO_POTI_X5_LIMITED_EDITION_1553508382_DiGDsKM.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Algida_Cornetto_Classico_60anni_x5_1555695444_1558619326_1558623371.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Torta Gelato Cornetto Algida",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_3/images/torta-cornetto.jpg"
                                        ],
                                        "ingredients": "200g di biscotti, 100g di burro, 350 ml di panna fresca da montare non zuccherata, 170g di latte condensato, 1 cucchiaino di estratto di vaniglia 50 g di cioccolato fondente al 50%, 25g di burro, 50g di nocciole tostate",
                                        "recipe": "Sciogliete il burro. Tritate nel mixer i biscotti, aggiungete il burro e mescolate bene. Foderate uno stampo con carta forno, versate il composto di biscotti e pressatelo sul fondo e sui lati aiutandovi con il fondo di un bicchiere. Fate riposare 30 minuti in frigo. "
                                    }
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Torta gelato Cornetto Algida - Videoricetta",
                                    "data": "/media/interactive_flyer_3/video/Torta-Cornetto.mp4"
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA' - Curiosità su Cornetto Algida",
                                    "data": {

                                        "titolo": "Cornetto Algida Compie 60 anni",
                                        "testo": "E' nato a Napoli 60 anni fa come gelato artigianale il cornetto Algida. E dal 1959, quella cialda croccante ripiena di gelato alla crema di latte, coperto da una granella di nocciole e cacao, è diventata l'icona del gelato industriale e il marchio principe del gelato da passeggio, in Italia e nei quattro angoli del pianeta. Un successo globale legato alla qualità delle materie prime certificate di alta qualità, a partire dal latte fresco 100% italiano e dalle nocciole, anch'esse tutte italiane. E da un equilibrio tra i diversi ingredienti, riconosciuto dai consumatori di ogni età e latitudine.",
                                        "img": [
                                            "/media/interactive_flyer_3/images/cornetto-algida.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.sharehappy.it/brands/cornetto.html"
                                }
                            ]
                        },


                        {
                            "item_id": 634197,
                            "field1": "Tonno all’Olio  ",
                            "field2": "di Oliva",
                            "field3": "SELEX",
                            "field4": "/G. 80 x 6//PINNE GIALLE/",
                            "description": "Tonno all’Olio   di Oliva SELEX /G. 80 x 6//PINNE GIALLE/",
                            "grammage": 480,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 43.16077953714982,
                                "left": 34.51278928136419,
                                "width": 32.19244823386114,
                                "height": 27.07673568818514
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/482765__tonno_in_olio_di_oliva_selex_80x6_1504858287_1504863029.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_aMghubi_HhOFRm3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 637825,
                            "field1": "Hamburger",
                            "field2": "AMADORI g. 204",
                            "field3": "",
                            "field4": "",
                            "description": "Hamburger AMADORI g. 204",
                            "grammage": 204,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "hamburger",
                                "polpettine"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 54.85383678440926,
                                "left": 68.00852618757614,
                                "width": 27.56394640682095,
                                "height": 22.93544457978075
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/amadpori_hamburger_1555512790.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_6lDKJjG_OIQHCuW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }

                            ]
                        },
                        {
                            "item_id": 634268,
                            "field1": "STRACCHINO",
                            "field2": "NONNO NANNI",
                            "field3": "",
                            "field4": "",
                            "description": "STRACCHINO NONNO NANNI",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 69.83556638246041,
                                "left": 2.1132764920828286,
                                "width": 32.67965895249695,
                                "height": 30.0
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Stracchino_Nonno_Nanni_DOK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Stracchino_2_1525360280_1535707298.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_CnYsKa7_1A5WRUH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634254,
                            "field1": "PARMIGIANO REGGIANO",
                            "field2": "D.O.P.",
                            "field3": "STAGIONATO 24 MESI",
                            "field4": "",
                            "description": "PARMIGIANO REGGIANO D.O.P. STAGIONATO 24 MESI",
                            "grammage": 0,
                            "price": "1.59",
                            "price_label": "€ 1.59/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 69.67113276492083,
                                "left": 34.39783800243605,
                                "width": 32.39951278928137,
                                "height": 27.161997563946407
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/445060_Parmigiano_ReggianoDOK_1488532569.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/parmigiano_1551717878.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_8YPBjTD_a0uTKcq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Tagliatelle con proscuitto di Parma, salsa al melone e Parmigiano Reggiano",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_3/images/ricetta-parmigiano.png"
                                        ],
                                        "ingredients": "Tagliatelle 350gr, Melone mondato 200gr, Prosciutto di Parma Dop 150gr, Parmigiano Reggiano Dop 100gr, Burro 30gr, Cipolla 20gr, Crema di latte 1 dl, 8 foglie di basilico, Sale",
                                        "recipe": "Tagliate  il melone a cubetti. A parte preparate un fondo con  metà del burro e la cipolla tritata, fate appassire e aggiungete il melone. Cuocete per cinque minuti, quindi unite la crema di latte e fate cuocere altri cinque minuti, salate e passate il tutto al frullatore. In una padella fate fondere il rimanente burro con il prosciutto  tagliato a quadratini. Lasciate cuocere a bassa temperatura fin quando il prosciutto non diviene  leggermente biondo. Nel frattempo avrete messo in cottura le tagliatelle, scolatele nella salsa  di melone, ripassatele sul fuoco per farle insaporire. Fuori dal fuoco aggiungete il Parmigiano Reggiano e servite con sopra il Prosciutto di Parma croccante e il basilico."
                                    }
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Caseifici aperti",
                                    "data": "https://www.parmigianoreggiano.it/ext/CaseificiAperti/default.aspx"
                                }
                            ],
                            "related": [634266, 634263, 634269]
                        },
                        {
                            "item_id": 637824,
                            "field1": "Salsiccia",
                            "field2": "AMADORI g. 430",
                            "field3": "",
                            "field4": "",
                            "description": "Salsiccia AMADORI g. 430",
                            "grammage": 430,
                            "price": "2.49",
                            "price_label": "€ 2.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 76.65651644336175,
                                "left": 67.88672350791718,
                                "width": 28.172959805115713,
                                "height": 19.28136419001218
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/logo_amadori_1537950326.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/8006473018902_amadori_salsiccia_430_gr_1552562672.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Nh9KUnt_qpS7Vpx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "/media/interactive_flyer_3/pages/page_2.jpg",
                    "products": [
                        {
                            "item_id": 638873,
                            "field1": "Ciuffetti di Spinaci Primavera ",
                            "field2": "FINDUS",
                            "field3": "g. 450",
                            "field4": "",
                            "description": "Ciuffetti di Spinaci Primavera  FINDUS g. 450",
                            "grammage": 450,
                            "price": "1.29",
                            "price_label": "€ 1.29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Verdure",
                            "blueprint": {
                                "top": 2.965895249695496,
                                "left": 73.61144945188795,
                                "width": 23.666260657734473,
                                "height": 18.672350791717417
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/3d_ciuffetti_spinaci_primavera_450g_2018_hr_1539337663_1558949803.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_iyj9Oq4_qoK7q6u.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638874,
                            "field1": "ÈSPRESSO",
                            "field2": "VERGNANO",
                            "field3": "10 Capsule",
                            "field4": "",
                            "description": "ÈSPRESSO VERGNANO 10 Capsule",
                            "grammage": null,
                            "price": "1.59",
                            "price_label": "€ 1.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "arabica",
                                "cremoso",
                                "intenso",
                                "napoli"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": true,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 22.33252131546894,
                                "left": 2.965895249695496,
                                "width": 23.057247259439706,
                                "height": 36.94275274056029
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Pack__Arabica_ITA_2017_Slim_1542133646.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Pack_Cremoso_ITA_2017_Slim_1542133647.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Pack_Intenso_ITA_2017_Slim_1542133648.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Pack_Napoli_ITA_2017_Slim_1542133649.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Caffe-Vergnano_Capsula_1549040588.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_OWGXbEs_tG2SBF1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638887,
                            "field1": "Lasagne all’Uovo ",
                            "field2": "DIVELLA ",
                            "field3": "g. 500",
                            "field4": "",
                            "description": "Lasagne all’Uovo  DIVELLA  g. 500",
                            "grammage": 500,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 21.967113276492086,
                                "left": 26.47381242387333,
                                "width": 23.666260657734473,
                                "height": 18.672350791717417
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/divella_lasagne_uovo_500_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_PA0UEfB_wsHh4mH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Lasagna con speck, crema di castagne e noci",
                                    "data": {

                                        "ingredients": "250 gr. Lasagna sottili all’uovo Divella, Grana padano grattugiato 50 g, speck 300 g Castagne 1 kg, Latte intero 500 ml, noce moscata q.b., sale q.b., pepe q.b., noci 10 sgusciate e tritate",
                                        "recipe": "Cominciare con la cottura delle castagne: sciacquale e ponile in un tegame, ricoprilo con l’acqua e falle bollire per circa 40 minuti. Nel frattempo taglia a sottili listarelle lo speck, sbuccia lo scalogno e tritalo finemente. In una padella lascia appassire lo scalogno tritato con un filo di olio di oliva per 10 minuti, poi unisci pepe, sale. A cottura ultimata, spegni il fuoco e lasciai intiepidire, intanto le castagne saranno pronte: scolale, sbucciale con uno spelucchino e raccogli la polpa in una ciotola, poi passala attraverso il passaverdure.  In un tegame poni il latte, aromatizzalo con la noce moscata; portatelo a bollore, poi unisci la purea di castagne e prosegui la cottura per 6-7 minuti continuando a mescolare finché la crema si sarà addensata. Tutto è pronto per comporre le lasagne: prendi una pirofila 20x30 cm, spalma sul fondo uno strato di crema di castagne, prendi le sfoglie e posizionane tre sul fondo, quindi prosegui con un altro strato di crema, poi condisci con il radicchio e lo speck. A questo punto adagia sopra 4 sfoglie e versa sopra la crema di castagne, lo speck, le noci tritate e un po’ di grana. Termina con un ultimo strato di lasagne e condisci come in precedenza, aggiungendo giusto una spolverata di grana e le noci tritate. Cuoci le lasagne a forno statico preriscaldato a 220° per 20 minuti e, a cottura ultimata, lascia intiepidire.",
                                        "img": [
                                            "/media/interactive_flyer_3/images/ricetta-lasagne.jpeg"
                                        ]
                                    }
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web Divella",
                                    "data": "https://www.divella.it/it/"
                                }
                            ]
                        },
                        {
                            "item_id": 638884,
                            "field1": "Crackers MISURA",
                            "field2": "- Iposale g. 350",
                            "field3": "- Integrali g. 385",
                            "field4": "- Soia g. 400",
                            "description": "Crackers MISURA - Iposale g. 350 - Integrali g. 385 - Soia g. 400",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "iposale",
                                "integrali",
                                "soia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 21.72350791717418,
                                "left": 49.98172959805117,
                                "width": 23.422655298416565,
                                "height": 18.91595615103532
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/misura_Crackers_Fibrextra_385g_-_no_olio_palma_1437556688.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Misura_Crackers_Soia_400_g_no_olio_di_palma_1439387328.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_UPT7U3C_8S847bG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638875,
                            "field1": "Patatine Classiche ",
                            "field2": "SAN CARLO",
                            "field3": "g. 300",
                            "field4": "",
                            "description": "Patatine Classiche  SAN CARLO g. 300",
                            "grammage": 300,
                            "price": "1.98",
                            "price_label": "€ 1.98",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 21.845310596833134,
                                "left": 73.77588306942754,
                                "width": 23.544457978075517,
                                "height": 18.79415347137637
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/59053_san_carlo_classica_1515161126_1520247375.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_63pWeVf_DlBoEC2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638886,
                            "field1": "Acetelli ",
                            "field2": "SACLÀ g. 560",
                            "field3": "- Giardiniera - Insalatina ",
                            "field4": "",
                            "description": "Acetelli  SACLÀ g. 560 - Giardiniera - Insalatina ",
                            "grammage": 560,
                            "price": "1.19",
                            "price_label": "€ 1.19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "giardiniera",
                                "insalatina"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 40.84652862362972,
                                "left": 26.71741778319123,
                                "width": 23.422655298416565,
                                "height": 18.79415347137637
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Sacla_acetelli_giardiniera.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Sacla_acetelli_insalatina.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_c01ei1N_7UK4n0r.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638883,
                            "field1": "Aceto di Mele BIO",
                            "field2": "DE NIGRIS",
                            "field3": "ml. 500",
                            "field4": "",
                            "description": "Aceto di Mele BIO DE NIGRIS ml. 500",
                            "grammage": 500,
                            "price": "1.49",
                            "price_label": "€ 1.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 40.724725943970775,
                                "left": 49.98172959805117,
                                "width": 23.666260657734473,
                                "height": 18.79415347137637
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/de_nigris_ACETO_DI_MELE_BIO_500_ML_1510335537.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_HIYsO54_sKHAviT.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638885,
                            "field1": "Latte UHT Crescita 3",
                            "field2": "GRANAROLO",
                            "field3": "ml. 500 x 2",
                            "field4": "",
                            "description": "Latte UHT Crescita 3 GRANAROLO ml. 500 x 2",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€ 1.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 40.602923264311826,
                                "left": 74.09866017052376,
                                "width": 23.300852618757613,
                                "height": 19.037758830694273
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/20010_-_Latte_Baby_UHT_199_1497463232.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_RJsNgdS_ejJGTE2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638877,
                            "field1": "TAVERNELLO",
                            "field2": "1 LITRO",
                            "field3": "",
                            "field4": "",
                            "description": "TAVERNELLO 1 LITRO",
                            "grammage": null,
                            "price": "1.29",
                            "price_label": "€ 1.29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "rosso",
                                "bianco",
                                "rosato"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 59.847746650426316,
                                "left": 2.844092570036544,
                                "width": 11.729598051157122,
                                "height": 37.55176613885505
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Tavernello_Rosso_Brick_Lt_1.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Tavernello_Bianco_Brick_Lt_1_1467907643_1506066717.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/tavernello_vino_rosato_brik_da_1_litro_1488269678_148829225_2utqQIB.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_3UZkOs6_DHu3j1T.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638876,
                            "field1": "100% Frullato Veggie",
                            "field2": "VALFRUTTA",
                            "field3": "lt. 1",
                            "field4": "",
                            "description": "100% Frullato Veggie VALFRUTTA lt. 1",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "pera,finocchio,zenzero",
                                "mango,mela,zucca,carota",
                                "frutti di bosco,barbabietola,carota nera"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Succhi",
                            "blueprint": {
                                "top": 59.72594397076737,
                                "left": 14.537149817295985,
                                "width": 11.729598051157122,
                                "height": 37.795371498172955
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Valfrutta-Veggie_1497366933.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_8fceje0_ov3971T.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638881,
                            "field1": "Pizza Tradizionale ",
                            "field2": "Margherita  ",
                            "field3": "CAMEO g. 345",
                            "field4": "",
                            "description": "Pizza Tradizionale  Margherita   CAMEO g. 345",
                            "grammage": 345,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Preparati",
                            "blueprint": {
                                "top": 59.604141291108405,
                                "left": 26.47381242387333,
                                "width": 23.422655298416565,
                                "height": 18.79415347137637
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Cameo_pizza_tradizionale_margherita_345gr_1474966166_150331_lwHxTgX.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_NokJRHx_BHUNNAr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638882,
                            "field1": "Assorbenti Interni",
                            "field2": "TAMPAX",
                            "field3": "",
                            "field4": "",
                            "description": "Assorbenti Interni TAMPAX",
                            "grammage": 0,
                            "price": "2.99",
                            "price_label": "€ 2.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 78.52618757612669,
                                "left": 26.595615103532282,
                                "width": 23.422655298416565,
                                "height": 18.79415347137637
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/tampax_x16_1539797369.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/tampax_x20_1539797389.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_AbUT9Sk_89HQutm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638878,
                            "field1": "PIATTO PIZZA",
                            "field2": "cm. 32",
                            "field3": "",
                            "field4": "",
                            "description": "PIATTO PIZZA cm. 32",
                            "grammage": null,
                            "price": "1.50",
                            "price_label": "€ 1.50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 59.604141291108405,
                                "left": 50.10353227771011,
                                "width": 23.666260657734473,
                                "height": 18.79415347137637
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/piatto_pizza_cm32_1560265506_1560951404.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_6H9c1k1_OiN89hy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638879,
                            "field1": "Spazzolino AQUAFRESH",
                            "field2": "Flex Dinamic",
                            "field3": "- Medio - Duro",
                            "field4": "",
                            "description": "Spazzolino AQUAFRESH Flex Dinamic - Medio - Duro",
                            "grammage": 0,
                            "price": "0.89",
                            "price_label": "€ 0.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "medio",
                                "duro"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 78.40438489646773,
                                "left": 50.10353227771011,
                                "width": 23.422655298416565,
                                "height": 18.79415347137637
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/AQ_FLEX_DINAMIC_DURO_1444643883_1485191805.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/AQ_FLEX_DINAMIC_medium_1444643891_1485191807.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_JQGqpg1_7uxOJjY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638880,
                            "field1": "Brut",
                            "field2": "BERLUCCHI",
                            "field3": "cl. 75",
                            "field4": "",
                            "description": "Brut BERLUCCHI cl. 75",
                            "grammage": 750,
                            "price": "9.90",
                            "price_label": "€ 9.90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": true,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 59.96954933008527,
                                "left": 74.22046285018271,
                                "width": 23.057247259439706,
                                "height": 37.1863580998782
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/55351_berlucchi_cuvee_imperiale_brut.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_dlHXN1i_0KRnGZZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.berlucchi.it/i-franciacorta/cuvee-imperiale/cuvee-imperiale-brut/"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "/media/interactive_flyer_3/pages/page_3.jpg",
                    "products": [
                        {
                            "item_id": 639239,
                            "field1": "LEMONSODA",
                            "field2": "lt. 1",
                            "field3": "",
                            "field4": "",
                            "description": "LEMONSODA lt. 1",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 21.845310596833134,
                                "left": 2.844092570036544,
                                "width": 23.422655298416565,
                                "height": 25.006090133982948
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/LEMONSODA_100cl_1535633575.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_cwk6Cny_qGkIo5x.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "http://lemonsoda.it/it/about/"

                                }
                            ]
                        },
                        {
                            "item_id": 639240,
                            "field1": "Santhé",
                            "field2": "SANT'ANNA",
                            "field3": "lt. 1,5",
                            "field4": "",
                            "description": "Santhé SANT'ANNA lt. 1,5",
                            "grammage": 1500,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "pesca",
                                "limone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 21.72350791717418,
                                "left": 26.230207064555422,
                                "width": 23.78806333739342,
                                "height": 25.127892813641896
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Santanna_santhe_pesca_15_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Sant_anna_santhe_limone_15_lt_1492105005.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_bpkznbd_bzs6sTp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639241,
                            "field1": "Idrosalino Energetico",
                            "field2": "MG.K VIS",
                            "field3": "cl. 50",
                            "field4": "- Arancia - Limone",
                            "description": "Idrosalino Energetico MG.K VIS cl. 50 - Arancia - Limone",
                            "grammage": 500,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "arancia",
                                "limone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 21.845310596833134,
                                "left": 50.225334957369064,
                                "width": 23.544457978075517,
                                "height": 25.006090133982948
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/MGKvis_1463490100.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_7CzB0KX_1vHLkJV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639242,
                            "field1": "ACCADÌ UHT",
                            "field2": "Parzialmente Scremato",
                            "field3": "ml. 500",
                            "field4": "",
                            "description": "ACCADÌ UHT Parzialmente Scremato ml. 500",
                            "grammage": 500,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 21.845310596833134,
                                "left": 73.85505481120586,
                                "width": 23.544457978075517,
                                "height": 25.127892813641896
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/accadi_parz_scre_500_ml_1496221644.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_v3LuhqE_kSjJZZL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA'",
                                    "data": {
                                        "titolo": "L'intolleranza al lattosio",
                                        "testo": "L’intolleranza agli zuccheri (lattosio, sorbitolo) è dovuta a un deficit dell’enzima che normalmente permette la digestione dello zucchero, che essendo composto dall’unione di 2 zuccheri semplici viene scisso e quindi più facilmente digerito. Se, invece, l’enzima manca o è scarso, lo zucchero non viene scisso nei due costituenti più semplici e, non venendo assimilato, richiama liquidi per effetto osmotico. Viene fermentato dalla flora batterica intestinale con una produzione di gas che causa tutta una serie di disturbi gastro-intestinali: diarrea, meteorismo, flatulenza, ecc.. Queste intolleranze alimentari sono piuttosto diffuse e sono spesso la causa di problemi intestinali cronici come la sindrome del colon irritabile.",
                                        "img": [
                                            "/media/interactive_flyer_3/images/curiosita-accadi.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito latte accadì",
                                    "data": "https://www.granarolo.it/marchi/accadi-senza-lattosio/latte#header-navbar-anchor"
                                }
                            ]
                        },
                        {
                            "item_id": 639248,
                            "field1": "Pollo",
                            "field2": "YAKISOBA",
                            "field3": "g. 93",
                            "field4": "",
                            "description": "Pollo YAKISOBA g. 93",
                            "grammage": 93,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 47.0584652862363,
                                "left": 2.722289890377591,
                                "width": 23.179049939098658,
                                "height": 25.006090133982948
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_cf7r5d9_VOMyyx6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639243,
                            "field1": "Olive verdi intere",
                            "field2": "SELEX",
                            "field3": "g. 310",
                            "field4": "",
                            "description": "Olive verdi intere SELEX g. 310",
                            "grammage": 310,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 46.93666260657735,
                                "left": 26.230207064555422,
                                "width": 23.666260657734473,
                                "height": 25.127892813641896
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/principale_80585305_1517854327.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Rikoyvh_HQ5LGbX.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639250,
                            "field1": "Buoncereale",
                            "field2": "SELEX",
                            "field3": "- Croccante g. 230",
                            "field4": "- Frutta g. 250",
                            "description": "Buoncereale SELEX - Croccante g. 230 - Frutta g. 250",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "croccante",
                                "frutta"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 47.0584652862363,
                                "left": 50.10353227771011,
                                "width": 23.78806333739342,
                                "height": 25.127892813641896
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_ab4rYRe_739xWDW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639245,
                            "field1": "Dolcificante",
                            "field2": "SELEX",
                            "field3": "300 compresse",
                            "field4": "",
                            "description": "Dolcificante SELEX 300 compresse",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 47.0584652862363,
                                "left": 73.9768574908648,
                                "width": 23.422655298416565,
                                "height": 25.249695493300855
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/dolcificante_selex_300_compresse_1501497217_1562775447.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_s0fkFFk_TEi3m4z.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 642975,
                            "field1": "40 Tovaglioli FIOR DI CARTA",
                            "field2": "2 Veli - cm. 33 x 33",
                            "field3": "",
                            "field4": "",
                            "description": "40 Tovaglioli FIOR DI CARTA 2 Veli - cm. 33 x 33",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 72.27161997563947,
                                "left": 2.6004872107186388,
                                "width": 23.422655298416565,
                                "height": 25.006090133982948
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/fior_di_carta_tovaglioli_2_veli_pezzi_40_1515163768.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_7TLaL2j_x3Yfndi.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639246,
                            "field1": "Accendifuoco Ecologico",
                            "field2": "ARROW",
                            "field3": "32 pezzi",
                            "field4": "",
                            "description": "Accendifuoco Ecologico ARROW 32 pezzi",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 72.27161997563947,
                                "left": 26.230207064555422,
                                "width": 23.666260657734473,
                                "height": 25.006090133982948
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_MHHVuto_V1Wbm9Z.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639249,
                            "field1": "- Shampoo ml. 250",
                            "field2": "- Balsamo ml. 200",
                            "field3": "SELEX",
                            "field4": "Ego",
                            "description": "- Shampoo ml. 250 - Balsamo ml. 200 SELEX Ego",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "shampoo",
                                "balsamo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 72.39342265529841,
                                "left": 50.10353227771011,
                                "width": 23.666260657734473,
                                "height": 24.884287454323996
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_h136aQk_N8zWO2n.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639247,
                            "field1": "Lavatrice",
                            "field2": "SELEX",
                            "field3": "7 Misurini",
                            "field4": "",
                            "description": "Lavatrice SELEX 7 Misurini",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 72.27161997563947,
                                "left": 73.9768574908648,
                                "width": 23.300852618757613,
                                "height": 24.884287454323996
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_KgLlCur_ABO4Gcx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "/media/interactive_flyer_3/pages/page_4.jpg",
                    "products": []
                },
                {
                    "number": 5,
                    "image_file": "/media/interactive_flyer_3/pages/page_5.jpg",
                    "products": []
                },
                {
                    "number": 6,
                    "image_file": "/media/interactive_flyer_3/pages/page_6.jpg",
                    "products": [
                        {
                            "item_id": 638221,
                            "field1": "COPPA DI PARMA I.G.P. ",
                            "field2": "AURORA",
                            "field3": "SENZA GLUTINE",
                            "field4": "",
                            "description": "COPPA DI PARMA I.G.P.  AURORA SENZA GLUTINE",
                            "grammage": 0,
                            "price": "1.63",
                            "price_label": "€ 1.63/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 26.96102314250914,
                                "left": 4.062119366626068,
                                "width": 31.339829476248468,
                                "height": 23.179049939098658
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Coppa_di_Parma_Dok_1427120128_1461861485_1484235312.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/coppa_di_Parma_IGP_aurora_amb_1513101437.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Coppa_di_parma_1523261996.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/aurora_Coppa_piatto_rettangolare_frontale_cmyk_hi_key_1538666553.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_DN9TE16_AYLj8c8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634255,
                            "field1": "SALAME ",
                            "field2": "UNGHERESE",
                            "field3": "LEVONI",
                            "field4": "/solo carni italiane/",
                            "description": "SALAME  UNGHERESE LEVONI /solo carni italiane/",
                            "grammage": null,
                            "price": "1.59",
                            "price_label": "€ 1.59/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 50.10353227771011,
                                "left": 4.062119366626068,
                                "width": 31.339829476248468,
                                "height": 22.935444579780757
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/salame_ungherese_levoni_DOK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Salame_Ungherese_1520347181.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Levoni_1520928931.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_CI4fZqn_S3cBDMK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634260,
                            "field1": "NORCINETTO ",
                            "field2": "FIORUCCI",
                            "field3": "",
                            "field4": "",
                            "description": "NORCINETTO  FIORUCCI",
                            "grammage": null,
                            "price": "1.15",
                            "price_label": "€ 1.15/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 73.16686967113277,
                                "left": 4.305724725943973,
                                "width": 31.096224116930568,
                                "height": 22.691839220462846
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Salsiccia_Napoli_1519374416.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/norcinetto_1542983392.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Fiorucci_Norcinetto_DOK_1432227191_1545922870.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_BkGLSwT_yrN3Vl1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634262,
                            "field1": "CARPACCIO ",
                            "field2": "DI MANZO",
                            "field3": "",
                            "field4": "",
                            "description": "CARPACCIO  DI MANZO",
                            "grammage": null,
                            "price": "2.19",
                            "price_label": "€ 2.19/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 64.71985383678441,
                                "left": 35.60901339829477,
                                "width": 31.096224116930568,
                                "height": 31.461632155907427
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/carpaccio_1551370820.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/F.lli_Riva_carpaccio_di_manzo_DOK_1430233373_1468330905_155_mguWqGj.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_ykDYNyJ_XgkFZgY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634261,
                            "field1": "SPECK DELL'ALTO ADIGE I.G.P.",
                            "field2": "SAPER DI SAPORI",
                            "field3": "",
                            "field4": "",
                            "description": "SPECK DELL'ALTO ADIGE I.G.P. SAPER DI SAPORI",
                            "grammage": 0,
                            "price": "1.35",
                            "price_label": "€ 1.35/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 64.59805115712547,
                                "left": 66.71132764920829,
                                "width": 33.288672350791714,
                                "height": 31.339829476248468
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/speck_igp_saper_di_sapori_1529397060.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/speck_1530805015_1544171140.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_fskSTnh_Sj0CB06.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634259,
                            "field1": "COTTO PRAGA",
                            "field2": "RECLA",
                            "field3": "ALTA QUALITÀ",
                            "field4": "",
                            "description": "COTTO PRAGA RECLA ALTA QUALITÀ",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0.99/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 3.453105968331306,
                                "width": 31.94884287454324,
                                "height": 22.935444579780757
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/445199_cotto_praga_DOK_1472714832_bllP9g6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cotto_praga_1538666472_DAun0EE.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/prosciutto_grigliato_1555512826_fZlPzQH.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_ZrDWHrZ_NOpdv9b.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634256,
                            "field1": "PROSCIUTTO COTTO",
                            "field2": "ALTA QUALITÀ",
                            "field3": "NATURA SELEX",
                            "field4": "",
                            "description": "PROSCIUTTO COTTO ALTA QUALITÀ NATURA SELEX",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€ 0.99/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 21.479902557856274,
                                "left": 35.73081607795372,
                                "width": 27.929354445797806,
                                "height": 22.204628501827038
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/piatto_1_sds_cotto_1545410175.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/445200_selex_cotto_natura_DOK_1436282789_1558682657.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_o49Dxzg_9jVdrGs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634290,
                            "field1": "- Tacchino arrosto - Gran fesa di petto intero",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Tacchino arrosto",
                            "grammage": "",
                            "price": "1.19",
                            "price_label": "€ 1.19/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 42.91717417783192,
                                "left": 35.97442143727162,
                                "width": 36.09013398294762,
                                "height": 19.037758830694273
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/450368_tacchino_forno_vbselex_120g_1503670833.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/450367_petto_pollo_vbselex_120g_1505896631_1556292035.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_wUQd3i5_uuoXowW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "/media/interactive_flyer_3/pages/page_7.jpg",
                    "products": [
                        {
                            "item_id": 634266,
                            "field1": "PECORINO",
                            "field2": "DI NORCIA",
                            "field3": "",
                            "field4": "",
                            "description": "PECORINO DI NORCIA",
                            "grammage": null,
                            "price": "1.43",
                            "price_label": "€ 1.43/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 3.818514007308164,
                                "left": 64.51887941534714,
                                "width": 31.827040194884287,
                                "height": 23.300852618757613
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Pecorino_di_Norcia_DOK_1444921252_1556816783.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Cfn8qER_nsRurBV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634263,
                            "field1": "CACIOTTA DI NORCIA",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "CACIOTTA DI NORCIA",
                            "grammage": null,
                            "price": "1.18",
                            "price_label": "€ 1.18/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 26.96102314250914,
                                "left": 64.71985383678441,
                                "width": 31.461632155907427,
                                "height": 23.057247259439706
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/447368_caciotta_di_norcia_DOK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/caciotta-di-norcia_1518774450.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/caciotta_di_norcia_1528800544.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_vFe8qJY_NUdpm6J.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634269,
                            "field1": "PECORINO DA TAVOLA",
                            "field2": "PASTORE SARDO",
                            "field3": "",
                            "field4": "",
                            "description": "PECORINO DA TAVOLA PASTORE SARDO",
                            "grammage": null,
                            "price": "1.19",
                            "price_label": "€ 1.19/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 50.225334957369064,
                                "left": 64.84165651644336,
                                "width": 31.21802679658952,
                                "height": 22.813641900121798
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/445095_pastore_del_sardo_DOK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/445095_pastore_del_sardo_DOK_MOD_1514569754.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_3MHPvBN_ssHCbUE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634267,
                            "field1": "PROVOLONE PICCANTE",
                            "field2": "AURICCHIO",
                            "field3": "",
                            "field4": "",
                            "description": "PROVOLONE PICCANTE AURICCHIO",
                            "grammage": 0,
                            "price": "1.19",
                            "price_label": "€ 1.19/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 73.16686967113277,
                                "left": 65.20706455542023,
                                "width": 30.730816077953712,
                                "height": 23.179049939098658
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/445077_auricchio_provolone_semipiccante_DOK_1516870152.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Img3914_1516958148_1535707250.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_q2BX3pF_CzKmIMr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634257,
                            "field1": "BUFALONA ",
                            "field2": "DEL VULTURE ",
                            "field3": "1 CHILO",
                            "field4": "",
                            "description": "BUFALONA  DEL VULTURE  1 CHILO",
                            "grammage": 0,
                            "price": "9.90",
                            "price_label": "€ 9.90/kg",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "kg.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 11.004872107186362,
                                "left": 1.8696711327649238,
                                "width": 60.81607795371499,
                                "height": 20.74299634591961
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/zizzona_1457370984.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Logo_Sabino_1481616875.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/render-bufalona_1512810785_1525861547.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/come-riconoscere-mozarella-bufala_1533309929.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_kUtymKy_4jE1jaq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634270,
                            "field1": "RICOTTA DURA DI PECORA",
                            "field2": "LAVORAZIONE ARTIGIANALE",
                            "field3": "",
                            "field4": "",
                            "description": "RICOTTA DURA DI PECORA LAVORAZIONE ARTIGIANALE",
                            "grammage": null,
                            "price": "0.69",
                            "price_label": "€ 0.69/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 27.326431181485997,
                                "left": 2.2350791717417815,
                                "width": 60.45066991473812,
                                "height": 18.306942752740557
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Ricotta_salata_di_pecora_DOK_1517411227.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Ricotta_dura_da_grattugia_1521456315.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_fBc9PlC.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634264,
                            "field1": "CANESTRATO",
                            "field2": "DEL VULTURE",
                            "field3": "",
                            "field4": "",
                            "description": "CANESTRATO DEL VULTURE",
                            "grammage": null,
                            "price": "1.48",
                            "price_label": "€ 1.48/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 44.37880633373935,
                                "left": 1.504263093788066,
                                "width": 61.181485992691826,
                                "height": 17.697929354445797
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Canestrato_del_Vulture_DOK_1444745503.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Canestrato_1529414749.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_OCV2s7u_wvZtcWv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634258,
                            "field1": "MORTADELLA",
                            "field2": "SAPER DI SAPORI",
                            "field3": "- CLASSICA - CON PISTACCHIO",
                            "field4": "",
                            "description": "MORTADELLA SAPER DI SAPORI - CLASSICA - CON PISTACCHIO",
                            "grammage": 0,
                            "price": "0.89",
                            "price_label": "€ 0.89/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 64.35444579780756,
                                "left": 0.5298416565164461,
                                "width": 31.827040194884287,
                                "height": 31.58343483556638
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/saper_di_sapori_mortadella_con_pistacchi_1531823216.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/mortadella_pistacchio_amb_1531848873.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/logo_saper_di_sapori_1532943447.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/piatto_1544171097.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/saper_di_sapori_mortadella_con_pistacchi_1531823216_1544171105.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_r1WkpKM_3mI9kiV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634265,
                            "field1": "TALEGGIO D.O.P.",
                            "field2": "SAPER DI SAPORI",
                            "field3": "",
                            "field4": "",
                            "description": "TALEGGIO D.O.P. SAPER DI SAPORI",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0.99/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 64.71985383678441,
                                "left": 32.076735688185146,
                                "width": 32.67965895249695,
                                "height": 31.339829476248468
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/saper_di_sapori_taleggio_1556533956.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_O7tes9R_nidPDa0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 8,
                    "image_file": "/media/interactive_flyer_3/pages/page_8.jpg",
                    "products": [
                        {
                            "item_id": 637829,
                            "field1": "HAMBURGER DI BOVINO",
                            "field2": "con bacon",
                            "field3": "",
                            "field4": "",
                            "description": "HAMBURGER DI BOVINO con bacon",
                            "grammage": 0,
                            "price": "7.90",
                            "price_label": "€ 7.90/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Bovino",
                            "blueprint": {
                                "top": 4.141291108404385,
                                "left": 4.190773447015834,
                                "width": 30.937880633373933,
                                "height": 21.802679658952496
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/20333_bovino_adulto_hamburger_con_bacon_1486549099_1506437536.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_yLFFyEm_WJt9NMS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 637830,
                            "field1": "BOMBETTE DI SUINO",
                            "field2": "piccanti",
                            "field3": "",
                            "field4": "",
                            "description": "BOMBETTE DI SUINO piccanti",
                            "grammage": 0,
                            "price": "10.90",
                            "price_label": "€ 10.90/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Suino",
                            "blueprint": {
                                "top": 26.230207064555422,
                                "left": 4.183922046285021,
                                "width": 31.096224116930568,
                                "height": 17.088915956151034
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/20518_Bombette_piccanti_1452598391.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_lOYKwCW_uncfxA8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 637835,
                            "field1": "SALSICCIA MISTA DI SUINO E BOVINO",
                            "field2": "farcita con scamorza",
                            "field3": "",
                            "field4": "",
                            "description": "SALSICCIA MISTA DI SUINO E BOVINO farcita con scamorza",
                            "grammage": 0,
                            "price": "6.90",
                            "price_label": "€ 6.90/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Suino",
                            "blueprint": {
                                "top": 61.18757612667479,
                                "left": 4.062119366626068,
                                "width": 30.974421437271616,
                                "height": 17.332521315468938
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Salsiccia_mista_scamorza_1469715134.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Czoilyy_KWjozMs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 637828,
                            "field1": "ALETTE DI POLLO ",
                            "field2": "condite",
                            "field3": "",
                            "field4": "",
                            "description": "ALETTE DI POLLO  condite",
                            "grammage": null,
                            "price": "3.99",
                            "price_label": "€ 3.99/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 78.72716199756395,
                                "left": 4.062119366626068,
                                "width": 31.096224116930568,
                                "height": 17.332521315468938
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/alette_di_pollo_piccanti_DOK_1460445488_1460624282.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_GpHXd2U_QKkDMu2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 637831,
                            "field1": "COSTATE DI SUINO",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "COSTATE DI SUINO",
                            "grammage": null,
                            "price": "4.99",
                            "price_label": "€ 4.99/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Suino",
                            "blueprint": {
                                "top": 40.115712545676004,
                                "left": 35.36540803897686,
                                "width": 31.461632155907427,
                                "height": 26.954933008526186
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/costate_1538761287.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_6RxyBIM_Li7sI9u.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 637827,
                            "field1": "MACINATO ",
                            "field2": "DI BOVINO",
                            "field3": "",
                            "field4": "",
                            "description": "MACINATO  DI BOVINO",
                            "grammage": 0,
                            "price": "5.90",
                            "price_label": "€ 5.90/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Bovino",
                            "blueprint": {
                                "top": 39.993909866017056,
                                "left": 66.91230207064557,
                                "width": 31.21802679658952,
                                "height": 27.07673568818514
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/macinato_bovino_1542877093.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_b1Kjjrg_PBbtoSi.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 637837,
                            "field1": "Valdostana di filetti di Pollo ",
                            "field2": "con Scamorza g. 200",
                            "field3": "",
                            "field4": "",
                            "description": "Valdostana di filetti di Pollo  con Scamorza g. 200",
                            "grammage": 200,
                            "price": "1.80",
                            "price_label": "€ 1.80",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 67.03410475030451,
                                "left": 35.24360535931791,
                                "width": 31.58343483556638,
                                "height": 26.954933008526186
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/aia_valdostana_1465460062.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_rEiAuAi_NA36nHV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 637838,
                            "field1": "Deliziose di Pollo",
                            "field2": "FILENI",
                            "field3": "",
                            "field4": "",
                            "description": "Deliziose di Pollo FILENI",
                            "grammage": 0,
                            "price": "3.99",
                            "price_label": "€ 3.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 66.91230207064557,
                                "left": 66.79049939098661,
                                "width": 30.730816077953712,
                                "height": 27.07673568818514
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Deliziose_di_pollo_fileni_1562836462.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_QaC0fw6_jPWXgd7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 637834,
                            "field1": "PETTO DI POLLO",
                            "field2": "a fette",
                            "field3": "",
                            "field4": "",
                            "description": "PETTO DI POLLO a fette",
                            "grammage": null,
                            "price": "6.49",
                            "price_label": "€ 6.49/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 4.019488428745433,
                                "left": 67.80755176613887,
                                "width": 30.0,
                                "height": 24.64068209500609
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/22076_pollo_petto_fette_DOK_1428426832_1542876670.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_AsjCLc5_KHoBwgK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 637832,
                            "field1": "FUSI DI POLLO",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "FUSI DI POLLO",
                            "grammage": null,
                            "price": "3.99",
                            "price_label": "€ 3.99/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 28.909866017052376,
                                "left": 63.50182704019489,
                                "width": 34.628501827040196,
                                "height": 11.120584652862359
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/22069_pollo_fusi_DOK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pollo_fusi_e_sovracosce.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_a5Ps3Aw_XtQ43rr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 9,
                    "image_file": "/media/interactive_flyer_3/pages/page_9.jpg",
                    "products": [
                        {
                            "item_id": 639446,
                            "field1": "NETTARINE AA",
                            "field2": "A PASTA GIALLA",
                            "field3": "",
                            "field4": "",
                            "description": "NETTARINE AA A PASTA GIALLA",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1.49/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 2.1132764920828286,
                                "width": 22.32643118148599,
                                "height": 29.390986601705237
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pesche_nettarine_pasta_gialla_1433772097.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_DWYgaSb_iNJuJs6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639445,
                            "field1": "CAROSELLI",
                            "field2": "NERI",
                            "field3": "",
                            "field4": "",
                            "description": "CAROSELLI NERI",
                            "grammage": 0,
                            "price": "0.95",
                            "price_label": "€ 0.95/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 24.03775883069428,
                                "width": 19.28136419001218,
                                "height": 29.390986601705237
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Caroselli_bianchi_Dok_1435584074.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/caroselli_1560267413_1562918840.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_5Wnku85_sUl5P5S.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639453,
                            "field1": "Pomodoro Regina",
                            "field2": "di Fasano",
                            "field3": "g. 500",
                            "field4": "",
                            "description": "Pomodoro Regina di Fasano g. 500",
                            "grammage": 500,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 43.28258221680878,
                                "width": 21.961023142509134,
                                "height": 29.269183922046288
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pomodoro_regina_fasano_1563011113.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/top_quality_1563197046.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_rsbkIL4_0cuFPGj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639443,
                            "field1": "PEPERONI",
                            "field2": "- GIALLI - ROSSI",
                            "field3": "",
                            "field4": "",
                            "description": "PEPERONI - GIALLI - ROSSI",
                            "grammage": 0,
                            "price": "1.60",
                            "price_label": "€ 1.60/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 33.660170523751525,
                                "left": 1.6260657734470187,
                                "width": 32.43605359317904,
                                "height": 18.79415347137637
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Peperoni_gialli_e_rossi_shutter.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/peperoni_gialli_e_rossi_254501692_1491405645.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_wJMeXj9_UGrAOh2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639444,
                            "field1": "Patate per Tutti gli Usi",
                            "field2": "SELEX",
                            "field3": "conf. kg. 2",
                            "field4": "",
                            "description": "Patate per Tutti gli Usi SELEX conf. kg. 2",
                            "grammage": 2000,
                            "price": "1.79",
                            "price_label": "€ 1.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 33.660170523751525,
                                "left": 34.269183922046295,
                                "width": 30.974421437271616,
                                "height": 18.79415347137637
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Selex_patate_per_tutti_gli_usi_2kg_1432546976.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_WQtzZGU_DCxfkSz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639440,
                            "field1": "MELONE",
                            "field2": "CANTALUPE",
                            "field3": "",
                            "field4": "",
                            "description": "MELONE CANTALUPE",
                            "grammage": null,
                            "price": "0.89",
                            "price_label": "€ 0.89/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 34.756394640682096,
                                "left": 67.88672350791718,
                                "width": 26.58952496954933,
                                "height": 7.71010962241169
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Melone_Cantalupo_DOK_1428489952.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_inRs0uV_ZQrxNNx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639442,
                            "field1": "PRUGNE ROSSE",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "PRUGNE ROSSE",
                            "grammage": 0,
                            "price": "1.20",
                            "price_label": "€ 1.20/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 43.647990255785636,
                                "left": 68.25213154689403,
                                "width": 26.345919610231423,
                                "height": 7.588306942752739
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Prugne_rosse_1433941510.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_qHvS2LQ_XpK0b7G.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639454,
                            "field1": "Kiwi",
                            "field2": "ZESPRI Green",
                            "field3": "g. 750",
                            "field4": "",
                            "description": "Kiwi ZESPRI Green g. 750",
                            "grammage": 750,
                            "price": "2.99",
                            "price_label": "€ 2.99/kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 51.6869671132765,
                                "left": 68.25213154689403,
                                "width": 26.345919610231423,
                                "height": 7.588306942752739
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Zespri_Kiwi_green_1430816523_1559143768_1562834650.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/sungold_zespri__1556807200_1559143590_1562834702.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_K4C15ZX_00ArNfg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639448,
                            "field1": "CIPOLLE ROSSE",
                            "field2": "DI TROPEA I.G.P.",
                            "field3": "g. 500",
                            "field4": "",
                            "description": "CIPOLLE ROSSE DI TROPEA I.G.P. g. 500",
                            "grammage": 500,
                            "price": "0.89",
                            "price_label": "€ 0.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 52.66138855054812,
                                "left": 2.1132764920828286,
                                "width": 64.1047503045067,
                                "height": 13.191230207064555
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cipolla_rossa_tropea_sacco_da_500_g_1435584207.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cipolla_rossa_1435584271.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cipolle_rosse_tropea_1463072944.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_P8nPs08_vzKZrRc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639449,
                            "field1": "Insalata Fantasiosa",
                            "field2": "SELEX",
                            "field3": "g. 200",
                            "field4": "",
                            "description": "Insalata Fantasiosa SELEX g. 200",
                            "grammage": 200,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 69.18392204628502,
                                "left": 2.2419305724725946,
                                "width": 21.071863580998784,
                                "height": 24.72594397076736
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/insalata_fantasiosa_selex_g._200_1555491878.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_1dMs8re_6MBlzs2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639441,
                            "field1": "Insalata Pocker",
                            "field2": "NATURA CHIAMA SELEX",
                            "field3": "g. 200",
                            "field4": "",
                            "description": "Insalata Pocker NATURA CHIAMA SELEX g. 200",
                            "grammage": 200,
                            "price": "1.19",
                            "price_label": "€ 1.19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 69.83556638246041,
                                "left": 23.306942752740564,
                                "width": 21.839220462850182,
                                "height": 24.031668696711325
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Selex_insalata_poker_gr_200_1434533315.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_XVY28Md_X5XkOXn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639447,
                            "field1": "POMODORO CILIEGINO ",
                            "field2": "SELEX",
                            "field3": "g. 500",
                            "field4": "",
                            "description": "POMODORO CILIEGINO  SELEX g. 500",
                            "grammage": 500,
                            "price": "1.50",
                            "price_label": "€ 1.50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 66.18148599269185,
                                "left": 38.41047503045067,
                                "width": 18.672350791717417,
                                "height": 27.685749086479902
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/selex_natura_chiama_pomodoro_ciliegino_500_g_1429202435.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pomodoro_ciliegino_DOK_1466588020.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_x1sAykk_hQy08bq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639450,
                            "field1": "Frutta a cubetti",
                            "field2": "F.LLI ORSERO",
                            "field3": "- Cocco g. 100 - Mango g. 150 - Ananas e Mirtilli g. 150",
                            "field4": "",
                            "description": "Frutta a cubetti F.LLI ORSERO - Cocco g. 100 - Mango g. 150 - Ananas e Mirtilli g. 150",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "mango",
                                "cocco",
                                "ananas e mirtilli"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 63.9890377588307,
                                "left": 57.77710109622412,
                                "width": 38.160779537149814,
                                "height": 30.0
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/MangoCubetti_orsero_1550050057.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Cocco2_orsero_1550050058.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/orsero_ananas_mirtilli_1548783167_1562835165.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_1sICRCp_QRaBrli.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 10,
                    "image_file": "/media/interactive_flyer_3/pages/page_10.jpg",
                    "products": [
                        {
                            "item_id": 634274,
                            "field1": "Pasta FRESCA Ripiena ",
                            "field2": "Rustici RANA  ",
                            "field3": "g. 250",
                            "field4": "",
                            "description": "Pasta FRESCA Ripiena  Rustici RANA   g. 250",
                            "grammage": 250,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 1.8696711327649238,
                                "left": 4.062119366626068,
                                "width": 31.461632155907427,
                                "height": 21.839220462850182
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Rana_rustici_ricotta_e_spinaci_innamorati_1527328887.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_ZPqMV7T_qPvKKhK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634276,
                            "field1": "GRAN BIRAGHI",
                            "field2": "Grattugiato",
                            "field3": "g. 100",
                            "field4": "",
                            "description": "GRAN BIRAGHI Grattugiato g. 100",
                            "grammage": 100,
                            "price": "1.15",
                            "price_label": "€ 1.15",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 3.331303288672354,
                                "left": 35.487210718635815,
                                "width": 31.21802679658952,
                                "height": 20.62119366626066
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/biraghi_100_1525859145.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_KVqW5w7_gTQlFkO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634277,
                            "field1": "5 Snacks",
                            "field2": "PARMAREGGIO",
                            "field3": "g. 100",
                            "field4": "",
                            "description": "5 Snacks PARMAREGGIO g. 100",
                            "grammage": 100,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 4.019488428745433,
                                "left": 67.03410475030451,
                                "width": 31.339829476248468,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Parmareggio_5_Snacks_100_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_xW2WmrQ_iDdPekN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634281,
                            "field1": "Pancetta a Cubetti ",
                            "field2": "SELEX g. 80 x 2 ",
                            "field3": "",
                            "field4": "",
                            "description": "Pancetta a Cubetti  SELEX g. 80 x 2 ",
                            "grammage": 160,
                            "price": "1.39",
                            "price_label": "€ 1.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 32.1985383678441,
                                "left": 4.062119366626068,
                                "width": 26.71132764920828,
                                "height": 21.230207064555422
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/SELEX_Pancetta_affumicata_80x2_1535386656.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/SELEX_Pancetta_dolce_80x2_1535386670_1551256851.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_KuTjQCA_qsflbny.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634272,
                            "field1": "4 Wurstel di Puro Suino ",
                            "field2": "SELEX",
                            "field3": "g. 100",
                            "field4": "",
                            "description": "4 Wurstel di Puro Suino  SELEX g. 100",
                            "grammage": 100,
                            "price": "0.45",
                            "price_label": "€ 0.45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 23.794153471376376,
                                "left": 26.108404384896474,
                                "width": 23.300852618757613,
                                "height": 30.0
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/wurstel_di_suino_selex_100g_1541514827_1547140212.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_3D12Cvn_fGUoOML.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638224,
                            "field1": "Salmone Norvegese",
                            "field2": "SELEX",
                            "field3": "g. 50",
                            "field4": "",
                            "description": "Salmone Norvegese SELEX g. 50",
                            "grammage": 50,
                            "price": "1.79",
                            "price_label": "€ 1.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 23.915956151035328,
                                "left": 49.49451887941535,
                                "width": 27.198538367844087,
                                "height": 29.63459196102314
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/450388__salmone_norvegese_affumicato_selex_50g_1495123517.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_dhoSqkC_jNB0RUX.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634253,
                            "field1": "Pesto FRESCO",
                            "field2": "SELEX g. 130",
                            "field3": "",
                            "field4": "",
                            "description": "Pesto FRESCO SELEX g. 130",
                            "grammage": 130,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Vegetali",
                            "blueprint": {
                                "top": 23.794153471376376,
                                "left": 76.16930572472594,
                                "width": 21.839220462850182,
                                "height": 29.63459196102314
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pesto_senza_aglio_selex_130_gr_1440574639_1467903476_1504860243.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/selex_pesto_genovese_1452005183_1548948459.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_jxbxUAe_jnF83s4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634271,
                            "field1": "GALBANINO",
                            "field2": "g. 270",
                            "field3": "",
                            "field4": "",
                            "description": "GALBANINO g. 270",
                            "grammage": 270,
                            "price": "1.97",
                            "price_label": "€ 1.97",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 56.19366626065774,
                                "left": 3.940316686967116,
                                "width": 31.21802679658952,
                                "height": 20.133982947624848
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Galbani_Galbanino_930g_1543428042_1543428587.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/galbanino_sutagliere_1463072551_1543428646.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_vgcyivX_xSEBobD.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Torta salata con zucchine, pesto e Galbanino",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_3/images/ricetta-galbanino.jpg"
                                        ],
                                        "ingredients": "1 rotolo di pasta brisée, 4 zucchine, 1 spicchio d'aglio , 2 uova, 200 ml di panna, 270 g di formaggio Galbanino Galbani, pesto pronto q.b., 40 g di parmigiano grattugiato, sale e pepe",
                                        "recipe": "Per realizzare la torta salata con zucchine dovete prima di tutto tagliare 50 g di Galbanino. Dopodiché, mettetelo in freezer. Nel frattempo, lavate le zucchine, eliminatene le estremità e tagliatele a rondelle. Infine, spadellatele con uno spicchio di aglio, un pizzico di sale, uno di pepe e qualche cucchiaio di olio extravergine. Dunque, lasciatele cuocere e insaporire per 15 minuti. Trascorso il tempo indicato, le zucchine dovranno perdere la loro acqua. Stendete il rotolo di pasta brisée e con questa rivestite una teglia da forno precedentemente imburrata con Burro Santa Lucia e poi infarinata (potete sostituire la farina con uno o due cucchiai di pane grattugiato) oppure rivestita di carta da forno. A questo punto, bucherellatene il fondo con l'ausilio dei rebbi di una forchetta e pareggiatene i bordi. A operazione conclusa, preparate la farcia: in una terrina sgusciate le due uova, aggiungete le zucchine, il parmigiano, il sale, il pepe e la panna. Quindi mescolate per bene il tutto. Dopodiché, tagliate a cubetti il Galbanino rimasto. Se lo gradite potete mescolare il formaggio con un paio di cucchiai d'olio aromatizzato. Quando avrete terminato di tagliare il formaggio, mettetelo sul fondo della tortiera. Poi versatevi sopra il composto ottenuto in precedenza. Rifinite la vostra quiche alle zucchine, aggiungendo qualche cucchiaiata di pesto, distribuita “a macchie”. Unito quindi il pesto, grattugiate su tutta la superficie della torta i 50 g di Galbanino tolti dal freezer, con l'aiuto di una grattugia a fori larghi. Concludete il vostro rustico cuocendolo in forno caldo a 180° per 40 minuti circa (infornate il tutto nella parte media del forno). A cottura raggiunta, lasciate intiepidire la torta salata con zucchine prima di consumarla."
                                    }
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO",
                                    "data": "/media/interactive_flyer_3/video/video-galbanino.mp4"
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web Galbanino",
                                    "data": "https://www.galbani.it/prodotti/galbanino/galbanino-l-originale"
                                }
                            ]
                        },
                        {
                            "item_id": 634169,
                            "field1": "Salse DEVELEY",
                            "field2": "Minions ml. 250",
                            "field3": "- Maionese - Ketchup",
                            "field4": "",
                            "description": "Salse DEVELEY Minions ml. 250 - Maionese - Ketchup",
                            "grammage": 250,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "maionese",
                                "ketchup"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 35.487210718635815,
                                "width": 30.974421437271616,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/ketchuo_develey_1531910150.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/maionese_devely_1531910150.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Sok1DLz_ay274RH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634279,
                            "field1": "Metà Metà ",
                            "field2": "PREALPI g. 250",
                            "field3": "",
                            "field4": "",
                            "description": "Metà Metà  PREALPI g. 250",
                            "grammage": 250,
                            "price": "1.39",
                            "price_label": "€ 1.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Latte",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 66.83313032886724,
                                "width": 31.096224116930568,
                                "height": 20.133982947624848
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/principale_8000415000741_1516960694.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_NfHopd4_wd6PrXz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634280,
                            "field1": "Formaggio Cremoso Classico",
                            "field2": "EXQUISA",
                            "field3": "g. 175",
                            "field4": "",
                            "description": "Formaggio Cremoso Classico EXQUISA g. 175",
                            "grammage": 175,
                            "price": "0.89",
                            "price_label": "€ 0.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 76.2911084043849,
                                "left": 4.062119366626068,
                                "width": 31.58343483556638,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/15549_EXQ_Classico_FrescoCremoso_175g_1562919256.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_VrZ6EW2_VsHEF1P.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634275,
                            "field1": "Fiocchi di Latte",
                            "field2": "JOCCA",
                            "field3": "g. 175",
                            "field4": "",
                            "description": "Fiocchi di Latte JOCCA g. 175",
                            "grammage": 175,
                            "price": "1.29",
                            "price_label": "€ 1.29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 79.70158343483557,
                                "left": 48.27649208282583,
                                "width": 24.15347137637028,
                                "height": 16.35809987819732
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Jocca_gr_175_1455893594_1482256239.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_dVyM9U4_R6wmoj8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634273,
                            "field1": "Bastoncini di mare",
                            "field2": "CORAYA ",
                            "field3": "g. 180",
                            "field4": "",
                            "description": "Bastoncini di mare CORAYA  g. 180",
                            "grammage": 180,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 79.82338611449453,
                                "left": 69.22655298416566,
                                "width": 28.78197320341047,
                                "height": 16.236297198538367
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/coraya_bastoncini_di_mare_1562774699.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_1hBMOfl_7D5lb7w.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 11,
                    "image_file": "/media/interactive_flyer_3/pages/page_11.jpg",
                    "products": [
                        {
                            "item_id": 634282,
                            "field1": "3 Piadillas FRESCHE ",
                            "field2": "g. 270",
                            "field3": "- Tris Farine - Multicereali",
                            "field4": "",
                            "description": "3 Piadillas FRESCHE  g. 270 - Tris Farine - Multicereali",
                            "grammage": 270,
                            "price": "1.15",
                            "price_label": "€ 1.15",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "tris farine",
                                "multicereali"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 2.0706455542021924,
                                "width": 31.21802679658952,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/PIADILLAS_1496943932.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_a9QfsjB_brWDuIt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634278,
                            "field1": "Fettine al Reggiano",
                            "field2": "PARMAREGGIO  ",
                            "field3": "g. 150",
                            "field4": "",
                            "description": "Fettine al Reggiano PARMAREGGIO   g. 150",
                            "grammage": 150,
                            "price": "1.19",
                            "price_label": "€ 1.19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 3.940316686967116,
                                "left": 33.41656516443362,
                                "width": 31.461632155907427,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/parmareggio_fettine_di_parmigiano_reggiano.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_gxxLLn3_04UUVIb.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634168,
                            "field1": "Pan Carrè Integrale",
                            "field2": "MULINO BIANCO",
                            "field3": "16 Fette  ",
                            "field4": "g. 315",
                            "description": "Pan Carrè Integrale MULINO BIANCO 16 Fette   g. 315",
                            "grammage": 315,
                            "price": "0.79",
                            "price_label": "€ 0.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 23.794153471376376,
                                "left": 1.504263093788066,
                                "width": 21.230207064555422,
                                "height": 30.609013398294763
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Pancarre_integrale_16_fette_sx_1558104633_1561047148_1562571716.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_ssYySVZ_HmBrPXq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634290,
                            "field1": "- Tacchino al Forno - Petto di Pollo Arrosto",
                            "field2": "VIVI BENE SELEX",
                            "field3": "g. 120",
                            "field4": "",
                            "description": "- Tacchino al Forno - Petto di Pollo Arrosto VIVI BENE SELEX g. 120",
                            "grammage": 120,
                            "price": "1.39",
                            "price_label": "€ 1.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "tacchino al forno",
                                "petto di pollo arrosto"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 24.03775883069428,
                                "left": 21.845310596833134,
                                "width": 23.909866017052373,
                                "height": 30.0
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/450368_tacchino_forno_vbselex_120g_1503670833_TTb429w.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/450367_petto_pollo_vbselex_120g_1505896631_1556292035_lwLYEoi.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_sKw4e0R_Mf4MTSh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634288,
                            "field1": "Tzatziki",
                            "field2": "ZORBAS",
                            "field3": "g. 200",
                            "field4": "",
                            "description": "Tzatziki ZORBAS g. 200",
                            "grammage": 200,
                            "price": "1.39",
                            "price_label": "€ 1.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 23.794153471376376,
                                "left": 38.532277710109625,
                                "width": 26.345919610231423,
                                "height": 30.36540803897686
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/081_Tzatziki_Zorbas_200g_1499845090.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_DbK1eyj_eo9bkoa.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634283,
                            "field1": "MULLER MIX",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "MULLER MIX",
                            "grammage": 0,
                            "price": "0.59",
                            "price_label": "€ 0.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "anelli al cioccolato",
                                "wafer al cioccolato"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 56.43727161997563,
                                "left": 1.9914738124238762,
                                "width": 31.461632155907427,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/muller_mix_anelli_e_cioccolato_1562059243.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/muller_mix_wafer_e_cioccolato_1562059244.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_efDJQET_FRiGr83.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA'",
                                    "data": {
                                        "titolo": "L'azienda Muller",
                                        "testo": "L'Unternehmensgruppe Theo Müller S.e.c.s., più comunemente conosciuta come Müller, è una multinazionale lussemburghese specializzata nel settore lattiero-casearie e in particolar modo produce yogurt, burro, latte UHT e latte in polvere. L'azienda che ha un fatturato di 5,7 miliardi di euro e circa 24.000 dipendenti[2], venne fondata a Fischach, in Baviera, Germania, località dove fino al 2011 aveva sede la società, prima del trasferimento in Lussemburgo.",
                                        "img": [
                                            "/media/interactive_flyer_3/images/curiosita-muller.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web Muller",
                                    "data": "https://www.muller.it/mix/5"
                                }
                            ]
                        },
                        {
                            "item_id": 634289,
                            "field1": "LC1",
                            "field2": "8 bottigliette da g. 90",
                            "field3": "",
                            "field4": "",
                            "description": "LC1 8 bottigliette da g. 90",
                            "grammage": 720,
                            "price": "2.15",
                            "price_label": "€ 2.15",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "frutti rossi",
                                "multifrutti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 56.43727161997563,
                                "left": 33.41656516443362,
                                "width": 31.21802679658952,
                                "height": 19.768574908647988
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Nestle_LC1_gr90X8_3D_frutti_rossi_1505116534_1516187835.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Nestle_LC1_gr90X8_3D_multifrutti_1505116535_1516187835.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Tmn7u1a_TiEkjaS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634285,
                            "field1": "Yogurt Intero",
                            "field2": "GRANAROLO",
                            "field3": "g. 125 x 2",
                            "field4": "/Alta qualità/",
                            "description": "Yogurt Intero GRANAROLO g. 125 x 2 /Alta qualità/",
                            "grammage": 250,
                            "price": "0.63",
                            "price_label": "€ 0.63",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "5 cereali",
                                "banana"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 76.2911084043849,
                                "left": 1.9914738124238762,
                                "width": 31.461632155907427,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/AQ_125gx2_RESTY_2018_5cereali_1542725577.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/AQ_125gx2_RESTY_2018_banana_1542725578.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_7uXwPy0_unQqfCH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634287,
                            "field1": "Yogurt Greco Magro",
                            "field2": "FAGE Fruyo",
                            "field3": "g. 170",
                            "field4": "",
                            "description": "Yogurt Greco Magro FAGE Fruyo g. 170",
                            "grammage": 170,
                            "price": "0.89",
                            "price_label": "€ 0.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "cocco",
                                "fragola"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 76.2911084043849,
                                "left": 33.538367844092576,
                                "width": 31.096224116930568,
                                "height": 19.646772228989036
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Fruyo_Classic_Cocco_170gr_1437148688.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Fage_Fruyo_Magro_Fragola_1460965484_1464960717.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_dXX5Wmn_vTONRmF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634286,
                            "field1": "Squeeze & Go",
                            "field2": "GRANAROLO g. 85",
                            "field3": "",
                            "field4": "",
                            "description": "Squeeze & Go GRANAROLO g. 85",
                            "grammage": 85,
                            "price": "0.65",
                            "price_label": "€ 0.65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "crema alla vaniglia",
                                "crema al cacao"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Latte",
                            "blueprint": {
                                "top": 76.2911084043849,
                                "left": 64.84165651644336,
                                "width": 31.096224116930568,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/SqueezeGo_SPONGE_vaniglia_fr-818x10241_1524574078.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/SqueezeGo_SPONGE_cacao_fr-818x10241_1524574078.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_9Ktvzsq_c4OfaiQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634284,
                            "field1": "6 Formaggini MIO",
                            "field2": "g. 125",
                            "field3": "",
                            "field4": "",
                            "description": "6 Formaggini MIO g. 125",
                            "grammage": 125,
                            "price": "1.19",
                            "price_label": "€ 1.19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 10.152253349573694,
                                "left": 65.00609013398297,
                                "width": 30.730816077953712,
                                "height": 20.37758830694275
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/446434_Formaggino_mio_classico_125g_alta_1530257599.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_iSPse3Y_yZAsVak.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA'",
                                    "data": {
                                        "titolo": "Formaggino Mio: a quanti mesi è consigliabile?",
                                        "testo": "Il formaggino Mio, come prodotti simili, può essere inserito nella seconda fase dello svezzamento, quella in cui entrambe le poppate giornaliere di pranzo e cena sono sostituite con i cibi solidi. Si raccomanda di inserirlo, quindi, a partire dal settimo-ottavo mese di vita e con una certa moderazione. Il formaggino è un alimento molto magro e ricco di calcio e proteine ma, al tempo stesso, un prodotto che potrebbe contenere conservanti e ingredienti non proprio naturali. Sarebbe preferibile impreziosire le pappe con un cucchiaino di parmigiano, nutriente e dal sapore molto amato. Vi consigliamo quindi di utilizzare il formaggino con moderazione e di alternarlo con cibi freschi.",
                                        "img": [
                                            "/media/interactive_flyer_3/images/curiosita-mio.jpeg"
                                        ]
                                    }
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Spot formaggino Mio",
                                    "data": "/media/interactive_flyer_3/video/Formaggino-mio.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": 634170,
                            "field1": "Biscotto",
                            "field2": "PLASMON",
                            "field3": "g. 720",
                            "field4": "",
                            "description": "Biscotto PLASMON g. 720",
                            "grammage": 720,
                            "price": "4.76",
                            "price_label": "€ 4.76",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Baby Food",
                            "subcategory": "Dispnsa",
                            "blueprint": {
                                "top": 30.73690621193667,
                                "left": 65.08526187576126,
                                "width": 30.609013398294763,
                                "height": 22.204628501827038
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/488451_1524554465.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_bVidqFT_EDL0VD6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634167,
                            "field1": "Omogeneizzati di Frutta",
                            "field2": "PLASMON",
                            "field3": "g. 104 x 2",
                            "field4": "",
                            "description": "Omogeneizzati di Frutta PLASMON g. 104 x 2",
                            "grammage": 208,
                            "price": "1.25",
                            "price_label": "€ 1.25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "4 frutti",
                                "pera"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 53.148599269183926,
                                "left": 65.20706455542023,
                                "width": 30.36540803897686,
                                "height": 22.813641900121798
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/plasmon_4_frutti_omogeneizzato_1502354733_1503678604.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Plasmon_omo_frutta_pera_1502354732_1503678605.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_p7WMlUC_dkZtdbb.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 12,
                    "image_file": "/media/interactive_flyer_3/pages/page_12.jpg",
                    "products": [
                        {
                            "item_id": 634172,
                            "field1": "6 Cornetti ",
                            "field2": "MULINO BIANCO ",
                            "field3": "g. 240  ",
                            "field4": "- Classici - Integrali",
                            "description": "6 Cornetti  MULINO BIANCO  g. 240   - Classici - Integrali",
                            "grammage": 240,
                            "price": "1.79",
                            "price_label": "€ 1.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "classici",
                                "integrali"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 3.940316686967116,
                                "left": 3.940316686967116,
                                "width": 31.339829476248468,
                                "height": 19.768574908647988
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Mulino_Bianco_Cornetti_classici_6pz_1524035882_1526639877_1_UaIQxDL.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Mulino_Bianco_Cornetti_integrali_6pz_1524035882_1526639878__9BKVM2L.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_rCrdPjW_YCUzkxK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634176,
                            "field1": "6 BUONDÌ",
                            "field2": "Classici   ",
                            "field3": "g. 198",
                            "field4": "",
                            "description": "6 BUONDÌ Classici    g. 198",
                            "grammage": 198,
                            "price": "1.29",
                            "price_label": "€ 1.29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 4.183922046285021,
                                "left": 35.487210718635815,
                                "width": 31.21802679658952,
                                "height": 19.646772228989036
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Buondi_motta_Classico_6_pz_1496674245_1498813854.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_5S6LGha_HaZldJ3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634173,
                            "field1": "Fette",
                            "field2": "MULINO BIANCO g. 630",
                            "field3": "- Dorate - Integrali",
                            "field4": "",
                            "description": "Fette MULINO BIANCO g. 630 - Dorate - Integrali",
                            "grammage": 630,
                            "price": "1.89",
                            "price_label": "€ 1.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "dorate",
                                "integrali"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 66.79049939098661,
                                "width": 31.096224116930568,
                                "height": 19.646772228989036
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Fette_72_Dorate_1535535889_1554997836_1562571943.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Fette_integrali_72_sx_1555434206_1562571951.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Jc3EsiY_dZvb3yy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634306,
                            "field1": "MULINO BIANCO",
                            "field2": "g. 350",
                            "field3": "- Molinetti",
                            "field4": "- Buongrano",
                            "description": "MULINO BIANCO g. 350 - Molinetti - Buongrano",
                            "grammage": 350,
                            "price": "1.19",
                            "price_label": "€ 1.19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "buongrano",
                                "molinetti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 23.794153471376376,
                                "left": 3.818514007308164,
                                "width": 31.58343483556638,
                                "height": 30.0
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Buongrano_350g_sx2_1509968703_1513771002_1562570707.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Vh24tgs_bnmgceO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634178,
                            "field1": "Preparato per Torte",
                            "field2": "CAMEO",
                            "field3": "- Limone - Yogurt - Cheesecake",
                            "field4": "",
                            "description": "Preparato per Torte CAMEO - Limone - Yogurt - Cheesecake",
                            "grammage": 0,
                            "price": "2.89",
                            "price_label": "€ 2.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "torta allo yogurt",
                                "cheesecake",
                                "torta al limone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 23.915956151035328,
                                "left": 33.215590742996355,
                                "width": 66.54080389768575,
                                "height": 32.31425091352009
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/CAMEO_TORTA_FREDDA_YOGURT_1562056787.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cameo_torta_fredda_cheesecake_1562056790.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/torta_al_limone_gr.295_1562056811_1562775660.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_fcLkoPx_lbXSuw8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634171,
                            "field1": "Latte UHT Parzialmente Scremato",
                            "field2": "ARBOREA ml. 500",
                            "field3": "SENZA LATTOSIO",
                            "field4": "",
                            "description": "Latte UHT Parzialmente Scremato ARBOREA ml. 500 SENZA LATTOSIO",
                            "grammage": 500,
                            "price": "0.49",
                            "price_label": "€ 0.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 3.5322777101096228,
                                "width": 16.114494518879415,
                                "height": 40.15225334957369
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Latte_AD_Square_500ml_omb_1458308797_1492104017_1562688560.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_cHJtthJ_HxbqsWn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634307,
                            "field1": "Latte UHT",
                            "field2": "GRANAROLO",
                            "field3": "lt. 1",
                            "field4": "- Intero - Parzialmente Scremato - Scremato",
                            "description": "Latte UHT GRANAROLO lt. 1 - Intero - Parzialmente Scremato - Scremato",
                            "grammage": 0,
                            "price": "1.00",
                            "price_label": "€ 1.00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "scremato",
                                "intero",
                                "piacere leggero"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 56.559074299634595,
                                "left": 19.896467722289895,
                                "width": 15.627283800243603,
                                "height": 39.257003654080386
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/496162_1561452727_1562570770.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/496163_1561452728_1562570770.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/496164_1561452729_1562570771.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_msCm3Ht_XzRGoV8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634174,
                            "field1": "KELLOGG'S ",
                            "field2": "Extra",
                            "field3": "g. 375",
                            "field4": "",
                            "description": "KELLOGG'S  Extra g. 375",
                            "grammage": 375,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "chocolate",
                                "original",
                                "fruit"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 56.43727161997563,
                                "left": 35.60901339829477,
                                "width": 31.096224116930568,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/488246_1517310381.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/488249_1517310382.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/488250_1517310383.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_yX09aw3_dEGs25C.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634175,
                            "field1": "Crema Spalmabile al Pistacchio",
                            "field2": "STAMMIBENE",
                            "field3": "g. 200",
                            "field4": "",
                            "description": "Crema Spalmabile al Pistacchio STAMMIBENE g. 200",
                            "grammage": 200,
                            "price": "4.99",
                            "price_label": "€ 4.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 56.43727161997563,
                                "left": 66.79049939098661,
                                "width": 31.339829476248468,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/stammibene_crema_al_pistacchio_200g_1530200156.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/logo_stammi_bene_1537951321.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_MXduCpe_0mMRftE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634177,
                            "field1": "GRAN CEREALE  ",
                            "field2": "Classico ",
                            "field3": "g. 500",
                            "field4": "",
                            "description": "GRAN CEREALE   Classico  g. 500",
                            "grammage": 500,
                            "price": "1.69",
                            "price_label": "€ 1.69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 76.21193666260659,
                                "left": 35.487210718635815,
                                "width": 31.096224116930568,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/GC_biscotto_classico_500g_sx_1531928136.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_my5MfUV_2QY0MBL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634179,
                            "field1": "POLARETTI",
                            "field2": "Fruit",
                            "field3": "",
                            "field4": "",
                            "description": "POLARETTI Fruit",
                            "grammage": null,
                            "price": "1.78",
                            "price_label": "€ 1.78",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Snack",
                            "blueprint": {
                                "top": 76.33373934226553,
                                "left": 66.91230207064557,
                                "width": 30.974421437271616,
                                "height": 19.646772228989036
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/polaretti_fruit_1560411119.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_4sp27cz_AHCIJab.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 13,
                    "image_file": "/media/interactive_flyer_3/pages/page_13.jpg",
                    "products": [
                        {
                            "item_id": 634312,
                            "field1": "- Biscotti - Grissini - Bruschette ",
                            "field2": "È LIGHT",
                            "field3": "g. 200",
                            "field4": "",
                            "description": "- Biscotti - Grissini - Bruschette  È LIGHT g. 200",
                            "grammage": 200,
                            "price": "3.49",
                            "price_label": "€ 3.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "biscotti",
                                "grissini",
                                "bruschette"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Snack",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 1.9914738124238762,
                                "width": 62.76492082825822,
                                "height": 19.524969549330084
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/GRISSINO-LIGHT_1562748821.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/e_light_1563966235.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_CfJWYTT_uPfBdGa.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634291,
                            "field1": "NESTLè Shake",
                            "field2": "- Nescafè",
                            "field3": "- Nesquik",
                            "field4": "",
                            "description": "NESTLè Shake - Nescafè - Nesquik",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "nescafè",
                                "nesquik"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Snack",
                            "blueprint": {
                                "top": 3.818514007308164,
                                "left": 64.96345919610232,
                                "width": 30.852618757612664,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/nescafe_shakissimo_cappuccino_decaf_190_ml_1521794982.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/NESQUIK_SHAKE_1520250410_1562572203.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_DOiyc8L_P06irHW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634185,
                            "field1": "DEK Intenso",
                            "field2": "LAVAZZA g. 250",
                            "field3": "",
                            "field4": "",
                            "description": "DEK Intenso LAVAZZA g. 250",
                            "grammage": 250,
                            "price": "1.49",
                            "price_label": "€ 1.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 23.794153471376376,
                                "left": 30.493300852618763,
                                "width": 20.133982947624848,
                                "height": 29.269183922046288
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/lavazza_dek_intenso_1493826825.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Zwkiyht_mLlYxAx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634182,
                            "field1": "Espresso Crema e Gusto Classico",
                            "field2": "LAVAZZA",
                            "field3": "g. 250",
                            "field4": "",
                            "description": "Espresso Crema e Gusto Classico LAVAZZA g. 250",
                            "grammage": 250,
                            "price": "2.39",
                            "price_label": "€ 2.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 23.794153471376376,
                                "left": 51.6869671132765,
                                "width": 19.890377588306944,
                                "height": 29.51278928136419
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/lavazza_espresso_crema_e_gusto_classico_1_1494351644.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_kdDL2Q3_Hrb9Do3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA'",
                                    "data": {
                                        "titolo": "Fondazione Lavazza",
                                        "testo": "La Fondazione Giuseppe e Pericle Lavazza Onlus, nata nel 2004, promuove e realizza progetti di sostenibilità economica, sociale e ambientale a favore delle comunità produttrici di caffè in tutto il mondo. Per ottenere risultati di grande impatto, la Fondazione realizza i progetti di sviluppo in collaborazione con attori pubblici e privati, organizzazioni internazionali e ONG. Solo attraverso il lavoro di squadra infatti si possono migliorare concretamente le condizioni di vita delle comunità di produttori di caffè.",
                                        "img": [
                                            "/media/interactive_flyer_3/images/curiosita-lavazza.jpeg"
                                        ]
                                    }
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO ",
                                    "data": "/media/interactive_flyer_3/video/video-Lavazza.mp4"
                                }

                            ]
                        },
                        {
                            "item_id": 634183,
                            "field1": "Crema e Gusto Forte",
                            "field2": "LAVAZZA",
                            "field3": "g. 250 x 2",
                            "field4": "",
                            "description": "Crema e Gusto Forte LAVAZZA g. 250 x 2",
                            "grammage": 500,
                            "price": "2.99",
                            "price_label": "€ 2.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 23.915956151035328,
                                "left": 73.73325213154689,
                                "width": 21.35200974421437,
                                "height": 29.269183922046288
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/lavazza_nuova_1491498402.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_kwxazlw_NwRQw0p.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }

                            ]
                        },
                        {
                            "item_id": 634181,
                            "field1": "Succhi ",
                            "field2": "VALFRUTTA",
                            "field3": "lt. 1,5",
                            "field4": "",
                            "description": "Succhi  VALFRUTTA lt. 1,5",
                            "grammage": 1500,
                            "price": "1.09",
                            "price_label": "€ 1.09",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "ace",
                                "ananas"

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Succhi",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 1.94884287454324,
                                "width": 16.114494518879415,
                                "height": 39.9878197320341
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/valfrutta_succo_ace_15_lt_1430813066.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/valfrutta_succo_ananas_15_lt_1439473776.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_J9AGjN3_Sm2JOal.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Succo di frutta ACE fatto in casa",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_3/images/succo-ACE-ricetta.jpg"
                                        ],
                                        "ingredients": "3 Carote, 2 arance, 1 limone, 300ml. di acqua",
                                        "recipe": "Per preparare il succo ACE fatto in casa iniziate a pelare le carote, poi pelate a vivo anche le arance e il limone, scartando la parte bianca sotto la buccia, che sarebbe amara. Tagliate la frutta a pezzi e mettetela in un mixer, frullate tutto per ottenere una purea. Aggiungete l'acqua e lavorate ancora per distribuire il liquido uniformemente. Ora passate il preparato con un colino, schiacciandolo bene, per separare il succo (che andrete a raccogliere in una ciotola) dalla polpa, che risulterà essere uno scarto di centrifuga. Il vostro succo ACE fatto in casa è pronto, riponetelo in frigorifero per farlo raffreddare bene e servitelo! "
                                    }
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA' - Curiosità su succo di frutta ACE",
                                    "data": {
                                        "titolo": "Cosa significa ACE?",
                                        "testo": "L'ACE è una bevanda analcolica costituita prevalentemente da acqua, e succo di arancia, carota e limone. ACE è un acronimo costituito non dalle prime lettere dei frutti in essa contenuti ma dalle iniziali delle tre principali vitamine presenti: Vitamina A, Vitamina C, Vitamina E",
                                        "img": [
                                            "/media/interactive_flyer_3/images/succo-ACE-curiosita.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Succo di frutta ACE - Valfrutta",
                                    "data": "https://www.valfrutta.it/prodotti/succhi-di-frutta-famiglia/succhi-in-brik-da-1500-ml/ace/"
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Succo ACE - Videoricetta",
                                    "data": "/media/interactive_flyer_3/video/succo-ACE.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": 634180,
                            "field1": "Succhi ",
                            "field2": "SELEX",
                            "field3": "lt. 1",
                            "field4": "",
                            "description": "Succhi  SELEX lt. 1",
                            "grammage": null,
                            "price": "0.69",
                            "price_label": "€ 0.69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "mela e banana",
                                "pera",
                                "pesca"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Succhi",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 17.8258221680877,
                                "width": 15.505481120584653,
                                "height": 39.9878197320341
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Selex_succo_mela_e_banana.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Selex_succo_pera_1_Litro.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Selex_succo_pesca.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_BQ8GBVT_1ueCf0C.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634184,
                            "field1": "Succhi  ",
                            "field2": "SELEX ml. 200 x 3 ",
                            "field3": "",
                            "field4": "",
                            "description": "Succhi   SELEX ml. 200 x 3 ",
                            "grammage": 600,
                            "price": "0.79",
                            "price_label": "€ 0.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "agrumi mix",
                                "ace",
                                "ananas"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Succhi",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 33.538367844092576,
                                "width": 31.339829476248468,
                                "height": 20.133982947624848
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Selex_succo_agrumi_200_ml_x_3.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Selex_succo_Ace_200_ml_x_3.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/selex_ananas_x3_1562776591.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_NhUL7IK_jXfMZLY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634186,
                            "field1": "CROCCANTELLE",
                            "field2": "g. 210",
                            "field3": "",
                            "field4": "",
                            "description": "CROCCANTELLE g. 210",
                            "grammage": 210,
                            "price": "1.00",
                            "price_label": "€ 1.00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "bacon",
                                "ketchup",
                                "prosciutto"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 56.43727161997563,
                                "left": 64.84165651644336,
                                "width": 30.974421437271616,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/1051865_CROCCANTELLE_210GR_bacon_1487781885_1492101447.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/1052276_CROCCANTELLE_210GR_ketchup_1487781885_1492101447.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/5065357_CROCCANTELLE_210GR_prosciutto_1487781886_1492101447.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_HZJDfqH_VEHKrSN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634201,
                            "field1": "SINISI",
                            "field2": "- Noci Brasiliane Sgusciate g. 150 - Arachidi Pralinate g. 200 ",
                            "field3": "- Anacardi Tostati non Salati g. 150",
                            "field4": "- 4 frutti g. 250",
                            "description": "SINISI - Noci Brasiliane Sgusciate g. 150 - Arachidi Pralinate g. 200  - Anacardi Tostati non Salati g. 150 - 4 frutti g. 250",
                            "grammage": 0,
                            "price": "0.00",
                            "price_label": "€ 0.00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "sconto del 20% alle casse",
                            "varieties": [
                                "noci brasiliane",
                                "arachidi prallinate",
                                "anacardi tostati",
                                "4 frutti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 76.16930572472594,
                                "left": 33.41656516443362,
                                "width": 63.00852618757612,
                                "height": 20.133982947624848
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/NOCI_BRASILIANE_DEFINITIVO_1527776029.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/NOCI_PECAN_DEFINITIVO_1527776029.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/SINISI_NOCI_SGUSCIATE_1527865278.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/MISTO_SINISI_FRONTE_1527579426_1562572443.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/SINISI_MANDORLE_SALATE_MOLLESCHE_1527864913_1562572481.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/SINISI_ARACHIDI_PRALINATE_1527864667_1562572522.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/mandorle_sinisi_1495796775_1527776144_1562572560.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/anacardi_sinisi_2_1492519887_1495796775_1527776233_1562572596.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Sinisi_four_fruit_gr350_1562776724.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_35LxfIv_HkwfTfA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 14,
                    "image_file": "/media/interactive_flyer_3/pages/page_14.jpg",
                    "products": [
                        {
                            "item_id": 634193,
                            "field1": "Pasta Integrale",
                            "field2": "LA MOLISANA",
                            "field3": "g. 500 ",
                            "field4": "",
                            "description": "Pasta Integrale LA MOLISANA g. 500 ",
                            "grammage": 500,
                            "price": "0.85",
                            "price_label": "€ 0.85",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "penne rigate",
                                "maccheroni",
                                "spaghetti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 3.818514007308164,
                                "left": 4.183922046285021,
                                "width": 31.096224116930568,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/molisana_integrale_penne_rigate_1562835365.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/molisana_integrale_maccheroni_1562835366.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/molisana_integrale_spaghetto_quadrato_1562835367.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_w1Ru3X7_ggtlElM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634195,
                            "field1": "SIMMENTHAL",
                            "field2": "g. 140 x 2",
                            "field3": "FORMATO GRANDE",
                            "field4": "",
                            "description": "SIMMENTHAL g. 140 x 2 FORMATO GRANDE",
                            "grammage": 280,
                            "price": "2.99",
                            "price_label": "€ 2.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 35.487210718635815,
                                "width": 31.096224116930568,
                                "height": 19.768574908647988
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/simmenthal_140x2.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_3vuQCJv_MriCDKW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639251,
                            "field1": "Farine Magiche per Pizza",
                            "field2": "LO CONTE kg. 1",
                            "field3": "",
                            "field4": "",
                            "description": "Farine Magiche per Pizza LO CONTE kg. 1",
                            "grammage": 0,
                            "price": "1.00",
                            "price_label": "€ 1.00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "pizza",
                                "manitoba"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 3.818514007308164,
                                "left": 66.83313032886724,
                                "width": 31.339829476248468,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/lo_conte_farina_pizza_00_1_kg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/251052_LOCONTE_FARINA_MANITOBA_100_1523864438_1530199116.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_v3zvyLs_E7P0jKi.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634194,
                            "field1": "Filetti di Tonno",
                            "field2": "CALLIPO",
                            "field3": "g. 170",
                            "field4": "",
                            "description": "Filetti di Tonno CALLIPO g. 170",
                            "grammage": 170,
                            "price": "3.99",
                            "price_label": "€ 3.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "naturale",
                                "olio di oliva",
                                "olio extravergine"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispnsa",
                            "blueprint": {
                                "top": 23.672350791717424,
                                "left": 4.183922046285021,
                                "width": 31.339829476248468,
                                "height": 30.48721071863581
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Callipo_Filetti_Tonno_g._170_Naturale_vaso_vetro_Sale_Presa_ajWaKqh.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Callipo_Filetti_Tonno_g._170_Olio_Oliva_Sale_Presal__149811_3naa3ZU.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Callipo_olio_evo_1492100914_1529340041.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_0ak96jY_bpVaXot.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634199,
                            "field1": "Riso Bell'Insalata",
                            "field2": "FLORA",
                            "field3": "kg. 1",
                            "field4": "",
                            "description": "Riso Bell'Insalata FLORA kg. 1",
                            "grammage": null,
                            "price": "1.69",
                            "price_label": "€ 1.69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 24.03775883069428,
                                "left": 35.487210718635815,
                                "width": 21.108404384896467,
                                "height": 30.0
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/flora_bellinsalata_1557501543.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_2ECDogp_mWPaAgU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638375,
                            "field1": "Mais",
                            "field2": "BONDUELLE",
                            "field3": "g. 150 x 3",
                            "field4": "",
                            "description": "Mais BONDUELLE g. 150 x 3",
                            "grammage": 450,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 23.915956151035328,
                                "left": 56.559074299634595,
                                "width": 41.49208282582217,
                                "height": 13.434835566382459
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Bonduelle_mais_g_150_x_3_1432305004.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_fhGUfwI_6mNeIiN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639252,
                            "field1": "Olive Nere Denocciolate ",
                            "field2": "ALISA g. 200",
                            "field3": "",
                            "field4": "",
                            "description": "Olive Nere Denocciolate  ALISA g. 200",
                            "grammage": 200,
                            "price": "0.79",
                            "price_label": "€ 0.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],

                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 36.948842874543246,
                                "left": 56.19366626065774,
                                "width": 18.672350791717417,
                                "height": 17.088915956151034
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/alisa_olive_nere_denocciolate_200_gr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_sSueBch_QxN2fw1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634192,
                            "field1": "Farciriso ",
                            "field2": "SELEX",
                            "field3": "",
                            "field4": "",
                            "description": "Farciriso  SELEX",
                            "grammage": 0,
                            "price": "1.15",
                            "price_label": "€ 1.15",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "con olio",
                                "senza olio"

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 36.09622411693057,
                                "left": 74.50669914738125,
                                "width": 23.300852618757613,
                                "height": 17.819732034104746
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/80324591_1523268202.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/80674443_1523268202.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Yn6PI2h_nyJFCBr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634198,
                            "field1": "Olio di Semi di Girasole",
                            "field2": "SELEX",
                            "field3": "lt. 1",
                            "field4": "",
                            "description": "Olio di Semi di Girasole SELEX lt. 1",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 4.019488428745433,
                                "width": 15.749086479902559,
                                "height": 39.86601705237515
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/olio_girasole_selex_1477900464.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_PuB4tZN_TDsYUjN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634200,
                            "field1": "Salsa Pronta",
                            "field2": "AGROMONTE",
                            "field3": "g. 330",
                            "field4": "- Ciliegino e Basilico - Datterino extra - Ciliegino",
                            "description": "Salsa Pronta AGROMONTE g. 330 - Ciliegino e Basilico - Datterino extra - Ciliegino",
                            "grammage": 330,
                            "price": "1.00",
                            "price_label": "€ 1.00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "ciliegino",
                                "ciliegino e basilico",
                                "datterino extra"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Rossi",
                            "blueprint": {
                                "top": 56.07186358099878,
                                "left": 19.774665042630943,
                                "width": 15.627283800243603,
                                "height": 40.10962241169305
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/agomonte_ciliegino_fresco_e_siciliano_1533551258.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/agromonte_salsa_ciliegino_al_basilico_1533551258.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_aPUXLJO_nDahAy9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634196,
                            "field1": "Passata Extrafine",
                            "field2": "PETTI g. 500",
                            "field3": "/100% pomodoro toscano/",
                            "field4": "",
                            "description": "Passata Extrafine PETTI g. 500 /100% pomodoro toscano/",
                            "grammage": 1500,
                            "price": "1.50",
                            "price_label": "€ 1.50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Rossi",
                            "blueprint": {
                                "top": 56.68087697929355,
                                "left": 35.60901339829477,
                                "width": 30.974421437271616,
                                "height": 19.768574908647988
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/logo_vegan_ok_1518773119.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Delicato_500g_2018_1551452256.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_GazH3jr_f5bOlAH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634187,
                            "field1": "6 uova medie FRESCHE da",
                            "field2": "galline felici allevate a terra",
                            "field3": "MASSERIA",
                            "field4": "",
                            "description": "6 uova medie FRESCHE da galline felici allevate a terra MASSERIA",
                            "grammage": 0,
                            "price": "1.79",
                            "price_label": "€ 1.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 56.19366626065774,
                                "left": 66.79049939098661,
                                "width": 31.096224116930568,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/2790_MasseriaBIO_1533574367_1536912882.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/LOGO_LATTE_MASSERIA_VERDE_1488804016_1562777420.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_mgdaTbK_JcF62T8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634190,
                            "field1": "Riso 3 Cereali ",
                            "field2": "GALLO g. 400",
                            "field3": "",
                            "field4": "",
                            "description": "Riso 3 Cereali  GALLO g. 400",
                            "grammage": 400,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 76.2911084043849,
                                "left": 35.36540803897686,
                                "width": 31.339829476248468,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/gallo_riso_3_cereali_1549447772.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_KMaPsCD_U6st4yZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634189,
                            "field1": "Riso Rosso Integrale",
                            "field2": "GALLO",
                            "field3": "g. 500",
                            "field4": "",
                            "description": "Riso Rosso Integrale GALLO g. 500",
                            "grammage": 500,
                            "price": "2.39",
                            "price_label": "€2.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 76.2911084043849,
                                "left": 66.66869671132764,
                                "width": 31.461632155907427,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/gallo_rosso_integrale_1525854120.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_gIFre5d_t10LFOo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 15,
                    "image_file": "/media/interactive_flyer_3/pages/page_15.jpg",
                    "products": [
                        {
                            "item_id": 634214,
                            "field1": "Pasta Trafilata al Bronzo",
                            "field2": "GRANORO DEDICATO",
                            "field3": "g. 500",
                            "field4": "",
                            "description": "Pasta Trafilata al Bronzo GRANORO DEDICATO g. 500",
                            "grammage": 500,
                            "price": "0.79",
                            "price_label": "€ 0.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "strascinati",
                                "spaghettoni"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 17.947624847746653,
                                "left": 2.3568818514007335,
                                "width": 23.666260657734473,
                                "height": 22.204628501827038
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pasta_semola_granoro_dedicato_strascinati_1527264784_1539361252.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/principale_8007290931108_1527589151_1539361252.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_wYJLM7h_nTm4lhK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634215,
                            "field1": "Datterini",
                            "field2": "DEDICATO",
                            "field3": "g. 400",
                            "field4": "",
                            "description": "Datterini DEDICATO g. 400",
                            "grammage": 400,
                            "price": "0.55",
                            "price_label": "€ 0.55",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Rossi",
                            "blueprint": {
                                "top": 40.115712545676004,
                                "left": 2.1132764920828286,
                                "width": 22.32643118148599,
                                "height": 17.210718635809986
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/granoro_dedicato_pomodori_datterini_400g_1531813303.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_vP1M3Qd_swPir0f.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634216,
                            "field1": "- Polpa - Pelati",
                            "field2": "DEDICATO",
                            "field3": "g. 400",
                            "field4": "",
                            "description": "- Polpa - Pelati DEDICATO g. 400",
                            "grammage": 400,
                            "price": "0.39",
                            "price_label": "€ 0.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "polpa in pezzi",
                                "pomodori pelati"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Rossi",
                            "blueprint": {
                                "top": 26.352009744214374,
                                "left": 24.403166869671136,
                                "width": 20.98660170523751,
                                "height": 30.121802679658952
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/granoro_dedicato_polpa_in_pezzi_400g_1531813323.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/granoro_odedicato_pomodori_pelati_400g_1531813324.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_WCaobuT_5wJOZT8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 640436,
                            "field1": "Five Roses I.G.T",
                            "field2": "DE CASTRIS",
                            "field3": "cl. 75",
                            "field4": "",
                            "description": "Five Roses I.G.T DE CASTRIS cl. 75",
                            "grammage": 750,
                            "price": "6.99",
                            "price_label": "€ 6.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 22.33252131546894,
                                "left": 49.3727161997564,
                                "width": 15.140073081607795,
                                "height": 33.89768574908648
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Five_Roses_1544111314.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_MrG45an_2cpIXi4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634209,
                            "field1": "Colombaio Bombino Rosè D.O.C.G.",
                            "field2": "CANTINE SPAGNOLETTI ZEULI",
                            "field3": "cl. 75",
                            "field4": "",
                            "description": "Colombaio Bombino Rosè D.O.C.G. CANTINE SPAGNOLETTI ZEULI cl. 75",
                            "grammage": 750,
                            "price": "4.90",
                            "price_label": "€ 4.90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 21.72350791717418,
                                "left": 64.47624847746651,
                                "width": 15.505481120584653,
                                "height": 34.26309378806334
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/406101_COLOMBAIO_BOMBINO_ROSEDOCG_75_1555076168.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_2qYdZks_VKZRwBt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634210,
                            "field1": "VILLA CARRISI",
                            "field2": "Salento D.O.P./I.G.P.",
                            "field3": "cl. 75",
                            "field4": "",
                            "description": "VILLA CARRISI Salento D.O.P./I.G.P. cl. 75",
                            "grammage": 750,
                            "price": "2.99",
                            "price_label": "€ 2.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "primitivo",
                                "negroamaro",
                                "rosato negroamaro"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 22.21071863580999,
                                "left": 80.23142509135201,
                                "width": 15.870889159561509,
                                "height": 34.26309378806334
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/primitivo_carrisi_1480696822_1481565604.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/negroamaro_carrisi_1480696788_1481565605.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/rosato_negroamaro_carrisi_1481565605.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_4bPaD7C_GeLsf8w.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634293,
                            "field1": "Cacioricotta",
                            "field2": "MASSERIA g. 250",
                            "field3": "",
                            "field4": "",
                            "description": "Cacioricotta MASSERIA g. 250",
                            "grammage": 250,
                            "price": "2.18",
                            "price_label": "€ 2.18",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 56.559074299634595,
                                "left": 3.209500609013401,
                                "width": 23.422655298416565,
                                "height": 16.114494518879415
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Cacioricotta_latte_di_masseria_1470305876.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Cacioricotta_Le-pianelle1-3623389028_1499845740.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_pL3fjCS_rJ0vxqS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634292,
                            "field1": "Pasta FRESCA",
                            "field2": "DIVELLA",
                            "field3": "g. 500",
                            "field4": "",
                            "description": "Pasta FRESCA DIVELLA g. 500",
                            "grammage": 500,
                            "price": "0.79",
                            "price_label": "€ 0.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "orecchiette",
                                "maritati"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 56.19366626065774,
                                "left": 43.526187576126674,
                                "width": 26.71132764920828,
                                "height": 22.204628501827038
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Divella_pasta_fresca_maritati_500_gr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pasta_fresca_divella_trofie_1529661103.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_qb0mD2r_VyDONzm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638829,
                            "field1": "Taralli",
                            "field2": "\"I Caserecci di NONNA CARMEN\"",
                            "field3": "g. 250",
                            "field4": "",
                            "description": "Taralli \"I Caserecci di NONNA CARMEN\" g. 250",
                            "grammage": 250,
                            "price": "1.59",
                            "price_label": "€ 1.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "di semola",
                                "olio extravergine",
                                "multicereali"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 72.39342265529841,
                                "left": 3.087697929354449,
                                "width": 19.159561510353225,
                                "height": 22.570036540803898
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Taralli_NONNA_CARMEN_1485442839.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Taralli_NONNA_CARMEN2_1485442839.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Taralli_NONNA_CARMEN3_1485442840.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_JBNSCHA_ESb7nuO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634211,
                            "field1": "Melanzane di Puglia",
                            "field2": "BIO",
                            "field3": "g. 280",
                            "field4": "",
                            "description": "Melanzane di Puglia BIO g. 280",
                            "grammage": 280,
                            "price": "2.79",
                            "price_label": "€ 2.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "melanzane",
                                "pomodori secchi"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 76.53471376370281,
                                "left": 22.697929354445797,
                                "width": 28.416565164433617,
                                "height": 19.159561510353225
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Melanzane_a_filetti_280g_1562926636.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_SjePtiN_cWkTXjB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634213,
                            "field1": "Gioiello di Carciofo",
                            "field2": "BIO",
                            "field3": "g. 280",
                            "field4": "",
                            "description": "Gioiello di Carciofo BIO g. 280",
                            "grammage": 280,
                            "price": "3.74",
                            "price_label": "€ 3.74",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 77.8745432399513,
                                "left": 50.71254567600488,
                                "width": 19.037758830694273,
                                "height": 18.306942752740557
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Gioiello_di_carciofo_280g_1562926619.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_TGYCDWQ_ddKQxPU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638385,
                            "field1": "Extra Vergine di Oliva",
                            "field2": "ANNESE 3 LITRI",
                            "field3": "",
                            "field4": "",
                            "description": "Extra Vergine di Oliva ANNESE 3 LITRI",
                            "grammage": 3000,
                            "price": "19.90",
                            "price_label": "€ 19.90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 57.655298416565174,
                                "left": 70.85261875761267,
                                "width": 24.76248477466504,
                                "height": 37.673568818514
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/latta_3_lt_1562754176.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_v8ILjy9_bAbiSid.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 16,
                    "image_file": "/media/interactive_flyer_3/pages/page_16.jpg",
                    "products": [
                        {
                            "item_id": 634302,
                            "field1": "4 Fiori di Merluzzo Porzionato",
                            "field2": "FINDUS",
                            "field3": "gr. 300",
                            "field4": "",
                            "description": "4 Fiori di Merluzzo Porzionato FINDUS g. 300",
                            "grammage": 300,
                            "price": "4.89",
                            "price_label": "€ 4.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 4.427527405602926,
                                "width": 30.85261875761267,
                                "height": 19.646772228989036
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Findus_4_Fiori_di_merluzzo_300gr_1537176433.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_xrCX5WJ_XfUhFFS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634297,
                            "field1": "4 Maxibon",
                            "field2": "MOTTA",
                            "field3": "g. 380",
                            "field4": "",
                            "description": "4 Maxibon MOTTA g. 380",
                            "grammage": 380,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 35.73081607795372,
                                "width": 30.852618757612664,
                                "height": 19.768574908647988
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Maxibon_x4_380g_alta_1464627005_1554135540.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_KVKqidE_3FZ43Y6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634301,
                            "field1": "6 Ghiaccioli ",
                            "field2": "alla fragola SELEX",
                            "field3": "g. 300",
                            "field4": "",
                            "description": "6 Ghiaccioli  alla fragola SELEX g. 300",
                            "grammage": 300,
                            "price": "1.39",
                            "price_label": "€ 1.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 66.91230207064557,
                                "width": 31.21802679658952,
                                "height": 19.768574908647988
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Selex_ghiaccioli_alla_fragola_6_pezzi_300_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_86UiwCw_Pyup9lt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634314,
                            "field1": "5 Bastoncini",
                            "field2": "FINDUS",
                            "field3": "g. 125",
                            "field4": "",
                            "description": "5 Bastoncini FINDUS g. 125",
                            "grammage": 125,
                            "price": "1.49",
                            "price_label": "€ 1.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Preparati",
                            "blueprint": {
                                "top": 23.672350791717424,
                                "left": 3.818514007308164,
                                "width": 31.461632155907427,
                                "height": 30.609013398294763
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/5_Bastoncini_125g_1561533022_1561563969_1562237455_1562571354.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_aBR3TvZ_hlN4szF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634299,
                            "field1": "LA CREMERIA",
                            "field2": "gr. 500",
                            "field3": "",
                            "field4": "",
                            "description": "LA CREMERIA g. 500",
                            "grammage": 500,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "spagnola",
                                "stracciatella"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 23.794153471376376,
                                "left": 35.73081607795372,
                                "width": 33.654080389768566,
                                "height": 32.43605359317904
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/la_cremeria_spagnola_1524230659.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/la_cremeria_stracciatella_1524230660.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_v45WTEA_1IPqEPA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634218,
                            "field1": "4 Brioche",
                            "field2": "Artigianali",
                            "field3": "g.192",
                            "field4": "",
                            "description": "4 Brioche Artigianali g.192",
                            "grammage": 192,
                            "price": "0.79",
                            "price_label": "€ 0.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 23.794153471376376,
                                "left": 68.00852618757614,
                                "width": 30.0,
                                "height": 32.557856272837995
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/dolci_sapori_brioche_1562922931.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_ly8ZBYa_r2rYgcq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634296,
                            "field1": "Sofficini FINDUS",
                            "field2": "g. 266",
                            "field3": "",
                            "field4": "",
                            "description": "Sofficini FINDUS g. 266",
                            "grammage": 266,
                            "price": "1.98",
                            "price_label": "€ 1.98",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "formaggi più mozzarella",
                                "pomodoro più mozzarella"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Preparati",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 4.062119366626068,
                                "width": 30.974421437271616,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/55428-ZR3_1550077031_1554209999.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/55430-ZR3_1550077032_1554210000.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_oJ7DVTp_PW0kgt0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634300,
                            "field1": "Patate",
                            "field2": "MC CAIN g. 600",
                            "field3": "- Kid Smile - Forno Country",
                            "field4": "",
                            "description": "Patate MC CAIN g. 600 - Kid Smile - Forno Country",
                            "grammage": 600,
                            "price": "1.59",
                            "price_label": "€ 1.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "kid smile",
                                "forno country"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Preparati",
                            "blueprint": {
                                "top": 76.45554202192449,
                                "left": 4.062119366626068,
                                "width": 31.21802679658952,
                                "height": 19.646772228989036
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Mc_forno_country_600_gr_1440429324_1446649238_1494415740.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/MC_CAIN_KID_SMILE_GR.600_1549471990_1549472283.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_nZi0T1X_zMh6JZI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634295,
                            "field1": "Chicchi di Caffè",
                            "field2": "COPPA DEL NONNO",
                            "field3": "g. 229",
                            "field4": "",
                            "description": "Chicchi di Caffè COPPA DEL NONNO g. 229",
                            "grammage": 229,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 35.97442143727162,
                                "width": 30.609013398294756,
                                "height": 20.012180267965892
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Chicchi_al_Caffe_Coppa_del_Nonno_1558024665.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/chicchi_al_caffe_1562923985.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_wNwersU_5waROeP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634313,
                            "field1": "6 MAGNUM",
                            "field2": "Mini",
                            "field3": "- Classic",
                            "field4": "- Double",
                            "description": "6 MAGNUM Mini - Classic - Double",
                            "grammage": 0,
                            "price": "3.79",
                            "price_label": "€ 3.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "classic",
                                "double"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 76.2911084043849,
                                "left": 35.73081607795372,
                                "width": 31.096224116930568,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Magnum_mini_classic_1465838643_1549382967.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_NGa4u5a_MCtzMNc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634294,
                            "field1": "4 Coppe Gelato ",
                            "field2": "SELEX",
                            "field3": "g. 280",
                            "field4": "",
                            "description": "4 Coppe Gelato  SELEX g. 280",
                            "grammage": 280,
                            "price": "1.69",
                            "price_label": "€ 1.69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 66.79049939098661,
                                "width": 31.339829476248468,
                                "height": 40.10962241169305
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/selex_4_coppe_caffe_280_gr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_FFl3q2g_qKMv4dS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 17,
                    "image_file": "/media/interactive_flyer_3/pages/page_17.jpg",
                    "products": [
                        {
                            "item_id": 634225,
                            "field1": "Acqua ",
                            "field2": "FONTE ESSENZIALE",
                            "field3": "/1 LITRO per 6 bottiglie/",
                            "field4": "",
                            "description": "Acqua  FONTE ESSENZIALE /1 LITRO per 6 bottiglie/",
                            "grammage": 6000,
                            "price": "3.90",
                            "price_label": "€ 3.90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Acqua",
                            "blueprint": {
                                "top": 3.940316686967116,
                                "left": 2.1132764920828286,
                                "width": 23.422655298416565,
                                "height": 50.58465286236297
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/etichetta_FE_2015_fronte_1430993976.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/FARDELLO_FE_2015_fronte_1430993990.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_a5esiC2_nimiaHR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634226,
                            "field1": "Cuvée extra dry ",
                            "field2": "SERGIO MIONETTO cl.75 ",
                            "field3": "WHITE EDITION",
                            "field4": "",
                            "description": "Cuvée extra dry  SERGIO MIONETTO cl.75  WHITE EDITION",
                            "grammage": 750,
                            "price": "6.90",
                            "price_label": "€ 6.90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 25.621193666260666,
                                "width": 23.300852618757613,
                                "height": 50.46285018270402
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/489328_Spumante_Sergio_MO_White_Edition_Mionetto_cl.75_EAN__KaXiko7.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_CFRuk6W_UyJXNsP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634223,
                            "field1": "MASCHIO I.G.T.",
                            "field2": "cl. 75",
                            "field3": "- Chardonnay - Pinot Rosa - Pinot Bianco",
                            "field4": "",
                            "description": "MASCHIO I.G.T. cl. 75 - Chardonnay - Pinot Rosa - Pinot Bianco",
                            "grammage": 750,
                            "price": "2.49",
                            "price_label": "€ 2.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "chardonnay",
                                "pinot rosa",
                                "pinot bianco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 4.141291108404385,
                                "left": 49.12911084043849,
                                "width": 15.505481120584653,
                                "height": 50.34104750304506
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Maschio_Chardonnay_75_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/662723_Maschio_pinot_rosa_veneto_rose_1430836235.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Maschio_Pinot_frizzante_75_cl_1432308197.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_2tUPHvG_1LPuJao.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634227,
                            "field1": "GATORADE",
                            "field2": "lt. 1,5",
                            "field3": "/MAXIFORMATO/",
                            "field4": "",
                            "description": "GATORADE lt. 1,5 /MAXIFORMATO/",
                            "grammage": 1500,
                            "price": "1.19",
                            "price_label": "€ 1.19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "arancia",
                                "arancia rossa",
                                "limone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 64.71985383678441,
                                "width": 15.627283800243603,
                                "height": 50.34104750304506
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/gatorade_arancia_15_lt_1432198995_1434114362.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/gatorade_arancia_rossa_15_lt_1432199004_1434114370.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/gatorade_limone_15_lt_1432199012_1434114380.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_GA6GpE0_xEKrYHd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634219,
                            "field1": "PEPSI",
                            "field2": "Cola",
                            "field3": "lt. 1,5",
                            "field4": "- Regular - Max Zero - Twist",
                            "description": "PEPSI Cola lt. 1,5 - Regular - Max Zero - Twist",
                            "grammage": 1500,
                            "price": "0.98",
                            "price_label": "€ 0.98",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "regular",
                                "max zero",
                                "twist"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 3.940316686967116,
                                "left": 80.35322777101098,
                                "width": 15.992691839220461,
                                "height": 50.58465286236297
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pepsi_max_zero_15_1_1468309612_1470237966_1477399937_1495212085.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pepsi_15_classica_1468309603_1470237957_1477399936_14952120_LvOQeHL.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pepsi_twist_limone_15_1468309625_1470237976_1477399938_1495_NDlY0si.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_MNEwdIB_XD0FDSs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO",
                                    "data": "/media/interactive_flyer_3/video/video-pepsi.mp4"
                                },
                                {
                                    "type": "world",
                                    "title": "LINK- sito ufficiale Pepsi",
                                    "data": "https://pepsimax.it/"
                                }
                            ]
                        },
                        {
                            "item_id": 634228,
                            "field1": "STARBERG",
                            "field2": "cl. 50 x 4",
                            "field3": "",
                            "field4": "",
                            "description": "STARBERG cl. 50 x 4",
                            "grammage": 2000,
                            "price": "2.79",
                            "price_label": "€ 2.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 56.559074299634595,
                                "left": 1.9914738124238762,
                                "width": 31.461632155907427,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Birra_STARBERG_45_4X50CL_1557906596_1557997538.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_0eLEOKF_sBczOfm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634220,
                            "field1": "ARIZONA TEA",
                            "field2": "cl. 50",
                            "field3": "",
                            "field4": "",
                            "description": "ARIZONA TEA cl. 50",
                            "grammage": 500,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "original green tea",
                                "pomegranate green tea",
                                "blueberry white tea"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 76.16930572472594,
                                "left": 2.1132764920828286,
                                "width": 31.339829476248468,
                                "height": 19.890377588306944
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/arizona_ginseng_miele_new_1_1_1529057023.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/arizona_melograno_new_1_1529057024.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/arizona_mirtilli_new_1_1529057024.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_3MwaooI_tBzjYz5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634224,
                            "field1": "Sciroppi zero zuccheri",
                            "field2": "TOSCHI",
                            "field3": "cl. 56",
                            "field4": "- Amarena - Menta - Orzata",
                            "description": "Sciroppi zero zuccheri TOSCHI cl. 56 - Amarena - Menta - Orzata",
                            "grammage": 560,
                            "price": "3.19",
                            "price_label": "€ 3.19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "menta",
                                "amarena",
                                "orzata"

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 33.538367844092576,
                                "width": 15.505481120584653,
                                "height": 39.74421437271619
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/496033_1561452709_1562059508.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/496034_1561452709_1562059508.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/496035_1561452710_1562059508.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_uIpjhzX_bsKIivY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634221,
                            "field1": "CORONA ",
                            "field2": "Extra ",
                            "field3": "cl. 35,5",
                            "field4": "",
                            "description": "CORONA  Extra  cl. 35,5",
                            "grammage": 355,
                            "price": "1.00",
                            "price_label": "€ 1.00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 56.19366626065774,
                                "left": 49.12911084043849,
                                "width": 15.505481120584653,
                                "height": 39.9878197320341
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Corona_Birra_cl_355.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/corona_limone_1460567220.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_g6zoDJZ_9tyuad8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634229,
                            "field1": "PAULANER ",
                            "field2": "Hefe-Weizen  ",
                            "field3": "cl. 50",
                            "field4": "",
                            "description": "PAULANER  Hefe-Weizen   cl. 50",
                            "grammage": 500,
                            "price": "1.19",
                            "price_label": "€ 1.19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 64.71985383678441,
                                "width": 15.627283800243603,
                                "height": 39.86601705237515
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/682044_paulaner_Hefe-Weizen_cl50_1504691722.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_39OvV3b_oeyjthA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634222,
                            "field1": "Birra Cruda",
                            "field2": "PERONI",
                            "field3": "cl. 50",
                            "field4": "",
                            "description": "Birra Cruda PERONI cl. 50",
                            "grammage": 500,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": true,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 56.3154689403167,
                                "left": 80.35322777101098,
                                "width": 15.627283800243603,
                                "height": 39.74421437271619
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/494901_peroni_cruda_cl_50_1553190947.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_OWq5P86_jXleypB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 18,
                    "image_file": "/media/interactive_flyer_3/pages/page_18.jpg",
                    "products": [
                        {
                            "item_id": 634230,
                            "field1": "BREF",
                            "field2": "Pavimenti Brillante  ",
                            "field3": "1,25 LITRI",
                            "field4": "",
                            "description": "BREF Pavimenti Brillante   1,25 LITRI",
                            "grammage": 1250,
                            "price": "1.79",
                            "price_label": "€ 1.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "pavimenti",
                                "spray"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Pavimenti",
                            "blueprint": {
                                "top": 4.305724725943973,
                                "left": 3.7758830694275276,
                                "width": 23.78806333739342,
                                "height": 30.0
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Bref_brillante_pavimenti_ml_1250_1435687693.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_K9Qh5Oq_UyVIBIs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634237,
                            "field1": "PRIL 12 Azioni",
                            "field2": "Gel ",
                            "field3": "33 Lavaggi ",
                            "field4": "ml. 600",
                            "description": "PRIL 12 Azioni Gel  33 Lavaggi  ml. 600",
                            "grammage": 600,
                            "price": "3.39",
                            "price_label": "€ 3.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 4.183922046285021,
                                "left": 27.5700365408039,
                                "width": 23.544457978075517,
                                "height": 30.0
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/489884_1549562953.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_OKQHnqm_VREZXwh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634236,
                            "field1": "Detergente Piatti",
                            "field2": "WINNI'S ml. 500",
                            "field3": "",
                            "field4": "",
                            "description": "Detergente Piatti WINNI'S ml. 500",
                            "grammage": 500,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 4.183922046285021,
                                "left": 51.19975639464068,
                                "width": 23.422655298416565,
                                "height": 30.36540803897686
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/winnis_piatti_ml_500_1515144137.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_Z8Oz4oy_56rcifx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634233,
                            "field1": "PERLANA",
                            "field2": "lt. 1,5",
                            "field3": "",
                            "field4": "",
                            "description": "PERLANA lt. 1,5",
                            "grammage": 1500,
                            "price": "2.99",
                            "price_label": "€ 2.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 34.269183922046295,
                                "left": 4.183922046285021,
                                "width": 23.422655298416565,
                                "height": 30.36540803897686
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Perlana_capi_delicati_rosa_lt_15.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_wkUdB3w_k3EGUhD.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634234,
                            "field1": "LYSOFORM",
                            "field2": "/18 lavaggi Confezione Doppia/",
                            "field3": "",
                            "field4": "",
                            "description": "LYSOFORM /18 lavaggi Confezione Doppia/",
                            "grammage": 0,
                            "price": "3.99",
                            "price_label": "€ 3.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 34.51278928136419,
                                "left": 27.5700365408039,
                                "width": 23.422655298416565,
                                "height": 30.0
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/lysoform_bucato_x_2_1497462637.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_IVOtR9O_u15TRwA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634232,
                            "field1": "Ricarica ",
                            "field2": "COCCOLINO",
                            "field3": "ml. 700",
                            "field4": "",
                            "description": "Ricarica  COCCOLINO ml. 700",
                            "grammage": 700,
                            "price": "1.10",
                            "price_label": "€ 1.10",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 34.269183922046295,
                                "left": 51.07795371498174,
                                "width": 23.544457978075517,
                                "height": 30.48721071863581
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/coccolino_creations_ricarica_1436450428.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_MRc3WKC_BAkQzRc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634235,
                            "field1": "Candeggina Classica ",
                            "field2": "ACE lt. 3",
                            "field3": "",
                            "field4": "",
                            "description": "Candeggina Classica  ACE lt. 3",
                            "grammage": 3000,
                            "price": "1.79",
                            "price_label": "€ 1.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 66.66869671132764,
                                "left": 3.89768574908648,
                                "width": 23.666260657734473,
                                "height": 29.51278928136419
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Ace_Classica_Lt_3_1459343583_1459763227.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_xHpdpQn_Ch9sw1c.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634238,
                            "field1": "240 Tovaglioli Tuttidì",
                            "field2": " FOXY",
                            "field3": "1 Velo",
                            "field4": "",
                            "description": "240 Tovaglioli Tuttidì  FOXY 1 Velo",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€ 1.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 66.79049939098661,
                                "left": 27.691839220462853,
                                "width": 23.544457978075517,
                                "height": 29.51278928136419
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/principale_8008260003429_1512575136.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_3CYnVEQ_HtwfyBP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634241,
                            "field1": "TuttoFare ",
                            "field2": "SCOTTEX 2 Rotoli",
                            "field3": "",
                            "field4": "",
                            "description": "TuttoFare  SCOTTEX 2 Rotoli",
                            "grammage": null,
                            "price": "1.69",
                            "price_label": "€ 1.69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 66.66869671132764,
                                "left": 51.19975639464068,
                                "width": 23.422655298416565,
                                "height": 29.390986601705237
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Scottex_tuttofare_2_maxi_rotoli_1493886148.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_hadtleX_cmUecpM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634242,
                            "field1": "Patè",
                            "field2": "CESAR",
                            "field3": "g. 150",
                            "field4": "",
                            "description": "Patè CESAR g. 150",
                            "grammage": 150,
                            "price": "0.79",
                            "price_label": "€ 0.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "tenero vitello e pollo",
                                "delicato manzo e pollo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 4.183922046285021,
                                "left": 74.75030450669915,
                                "width": 23.179049939098658,
                                "height": 22.935444579780757
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cesar_pate_Vitello_Pollo_150_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cesar_pate_Manzo_Pollo_150_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_VPduO4T_ewCOvsd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634239,
                            "field1": "4 Ropper Snack ",
                            "field2": "PEDIGREE g. 70",
                            "field3": "",
                            "field4": "",
                            "description": "4 Ropper Snack  PEDIGREE g. 70",
                            "grammage": 70,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 27.204628501827045,
                                "left": 74.82947624847746,
                                "width": 23.057247259439706,
                                "height": 22.813641900121798
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Pedigree_ropper.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_wdl50Gp_It2Q52m.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634240,
                            "field1": "Patè",
                            "field2": "SHEBA g. 85",
                            "field3": "",
                            "field4": "",
                            "description": "Patè SHEBA g. 85",
                            "grammage": 85,
                            "price": "0.50",
                            "price_label": "€ 0.50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "manzo",
                                "pollo",
                                "salmone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 50.10353227771011,
                                "left": 74.82947624847746,
                                "width": 23.057247259439706,
                                "height": 23.057247259439706
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Sheba_Pate_Classico_Manzo_4008429065323_1519893033.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Sheba_Pate_Classico_Pollo_4008429065347_1519893033.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Sheba_Pate_Classico_Salmone_4008429065279_1519893034.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_SfVN4ht_RGLdmrr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634243,
                            "field1": "CATISFACTIONS",
                            "field2": "g. 60",
                            "field3": "",
                            "field4": "",
                            "description": "CATISFACTIONS g. 60",
                            "grammage": 60,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "formaggio",
                                "pollo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 73.12423873325213,
                                "left": 74.82947624847746,
                                "width": 23.057247259439706,
                                "height": 22.813641900121798
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/catisfactions_croccantini_gatto_formaggio_60g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/catisfactions_croccantini_gatto_pollo_60g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_lgyHwuN_XjEJh65.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 19,
                    "image_file": "/media/interactive_flyer_3/pages/page_19.jpg",
                    "products": [
                        {
                            "item_id": 634244,
                            "field1": "Deo Spray",
                            "field2": "NEUTRO ROBERTS",
                            "field3": "ml. 150",
                            "field4": "",
                            "description": "Deo Spray NEUTRO ROBERTS ml. 150",
                            "grammage": 150,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "delicato",
                                "fresco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 1.9914738124238762,
                                "width": 23.909866017052373,
                                "height": 30.36540803897686
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/neutro_deo_spray_delicato_1550566426.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/neutro_deo_spray_uomo_1550566426.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_hohODws_q2q4MuQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634310,
                            "field1": "Gel Aloe BIO",
                            "field2": "I PROVENZALI ",
                            "field3": "ml. 150",
                            "field4": "",
                            "description": "Gel Aloe BIO I PROVENZALI  ml. 150",
                            "grammage": 150,
                            "price": "3.79",
                            "price_label": "€ 3.79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 4.062119366626068,
                                "left": 25.742996345919614,
                                "width": 23.300852618757613,
                                "height": 30.48721071863581
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Gel_Aloe_BIO_I_PROVENZALI_ml._150_1562778367.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_8TpK9pA_0u1qzry.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634247,
                            "field1": "Carta igienica 2 Veli",
                            "field2": "SCOTTONELLE - 12 Rotoli",
                            "field3": "",
                            "field4": "",
                            "description": "Carta igienica 2 Veli SCOTTONELLE - 12 Rotoli",
                            "grammage": null,
                            "price": "3.55",
                            "price_label": "€ 3.55",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 4.305724725943973,
                                "left": 49.12911084043849,
                                "width": 46.808769792935436,
                                "height": 30.0
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/scottonelle_1562835967.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_9Ic8LaG_ET9PVQn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634249,
                            "field1": "Sapone Liquido",
                            "field2": "MANTOVANI",
                            "field3": "ecoricarica",
                            "field4": "/ML. 750/",
                            "description": "Sapone Liquido MANTOVANI ecoricarica /ML. 750/",
                            "grammage": 750,
                            "price": "1.39",
                            "price_label": "€ 1.39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "classico",
                                "talco e fiori bianchi",
                                "olio di argan"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 34.390986601705244,
                                "left": 2.1132764920828286,
                                "width": 23.422655298416565,
                                "height": 30.36540803897686
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/mantovani_sapone_liquido_ricarica_classico_1427902187_1431965320.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/mantovani_sapone_liquido_ricarica_talco_1427902206_1431965327.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/MANTOVANI_ECORICARICA_ARGAN_750_ML_1444894741.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_BgyyVO4_JVFm3Dj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634248,
                            "field1": "Bagnoschiuma ",
                            "field2": "NEUTRO MED ml. 650",
                            "field3": "",
                            "field4": "",
                            "description": "Bagnoschiuma  NEUTRO MED ml. 650",
                            "grammage": 650,
                            "price": "1.59",
                            "price_label": "€ 1.59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 34.348355663824606,
                                "left": 25.628045066991472,
                                "width": 23.629719853836786,
                                "height": 30.45066991473812
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/552037_neutromed_idratante_650_1487177650.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_JSYwlsN_tX49blk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634246,
                            "field1": "Dentifricio",
                            "field2": "MENTADENT",
                            "field3": "ml. 75",
                            "field4": "",
                            "description": "Dentifricio MENTADENT ml. 75",
                            "grammage": 75,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "denti sani e forti",
                                "igiene quotidiana"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 34.390986601705244,
                                "left": 49.12911084043849,
                                "width": 23.300852618757613,
                                "height": 15.018270401948842
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/MENTADENT_TP_PROT_FAMIGLIA_DENTI_SANI_E_FORTI__1559149775.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/MENTADENT_TP_PROT_FAMIGLIA_IGIENE_QUOTIDIANA_1559149776.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_rGwXk0W_Y4YqZoy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634245,
                            "field1": "Assorbenti",
                            "field2": "LINES Seta Ultra",
                            "field3": "/PACCO DOPPIO/",
                            "field4": "",
                            "description": "Assorbenti LINES Seta Ultra /PACCO DOPPIO/",
                            "grammage": null,
                            "price": "3.99",
                            "price_label": "€ 3.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intimo",
                            "blueprint": {
                                "top": 49.3727161997564,
                                "left": 49.12911084043849,
                                "width": 23.422655298416565,
                                "height": 15.018270401948842
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Lines_Seta_Ultra_pacco_doppio_1543243671.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_izKUcFO_Auw9jFT.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634311,
                            "field1": "PALETTE",
                            "field2": "- Shampoo - Balsamo ",
                            "field3": "ml. 500",
                            "field4": "",
                            "description": "PALETTE - Shampoo - Balsamo  ml. 500",
                            "grammage": 500,
                            "price": "2.99",
                            "price_label": "€ 2.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "shampoo",
                                "balsamo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 34.390986601705244,
                                "left": 72.55785627283801,
                                "width": 23.179049939098658,
                                "height": 30.36540803897686
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/8015700158540_ok_1551196736_1562571188.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/8015700158595_ok_1551196737_1562571188.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_3oLxrgW_cS8q2WA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 639254,
                            "field1": "POCHETTE",
                            "field2": "MULTIUSO",
                            "field3": "",
                            "field4": "",
                            "description": "POCHETTE MULTIUSO",
                            "grammage": 0,
                            "price": "2.90",
                            "price_label": "€ 2.90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "blu",
                                "verde"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Esterno",
                            "blueprint": {
                                "top": 66.99147381242388,
                                "left": 42.924025578562734,
                                "width": 14.981729598051158,
                                "height": 20.706455542021924
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Pochette_Saltillo_1558435558_1558947910_1558947930.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_rdPakSY_E3GcLN8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 634251,
                            "field1": "Pannolini HUGGIES   ",
                            "field2": "Unistar",
                            "field3": "",
                            "field4": "",
                            "description": "Pannolini HUGGIES    Unistar",
                            "grammage": 0,
                            "price": "5.98",
                            "price_label": "€ 5.98",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intimo",
                            "blueprint": {
                                "top": 67.03410475030451,
                                "left": 2.1132764920828286,
                                "width": 40.840438489646765,
                                "height": 20.86479902557856
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/pannolini_huggies_1562778809_XyPeMu8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_FZJtyEI_Av3zSqu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 20,
                    "image_file": "/media/interactive_flyer_3/pages/page_20.jpg",
                    "products": [
                        {
                            "item_id": 638214,
                            "field1": "ASIAGO FRESCO D.O.P.",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "ASIAGO FRESCO D.O.P.",
                            "grammage": 0,
                            "price": "0.58",
                            "price_label": "€ 0.58/etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 23.550548112058465,
                                "left": 4.792935444579784,
                                "width": 22.204628501827038,
                                "height": 22.935444579780757
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Asiago_2_1520346853.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/445136_asiago_pressato_dop_1430212033_1457425612_1538065770.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_g8HtZh9_XpOn4Oi.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638217,
                            "field1": "Negronetto a Fette",
                            "field2": "g. 75",
                            "field3": "",
                            "field4": "",
                            "description": "Negronetto a Fette g. 75",
                            "grammage": 75,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 23.672350791717424,
                                "left": 27.813641900121805,
                                "width": 22.082825822168086,
                                "height": 22.691839220462846
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Negroni_Negronetto_1491562587_1562685391_1562747230.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_kLiBV0e_E1lCgFG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638212,
                            "field1": "4 MaxiStecchi",
                            "field2": "SELEX",
                            "field3": "g. 350",
                            "field4": "",
                            "description": "4 MaxiStecchi SELEX g. 350",
                            "grammage": 350,
                            "price": "1.49",
                            "price_label": "€ 1.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 46.93666260657735,
                                "left": 5.036540803897688,
                                "width": 22.082825822168086,
                                "height": 22.935444579780757
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/selex_maxistecco_350g_1562686085.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_tPumAj3_F2Edf1X.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638215,
                            "field1": "Grana Padano D.O.P.",
                            "field2": "SELEX",
                            "field3": "g. 300",
                            "field4": "",
                            "description": "Grana Padano D.O.P. SELEX g. 300",
                            "grammage": 300,
                            "price": "2.99",
                            "price_label": "€ 2.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 47.180267965895254,
                                "left": 27.813641900121805,
                                "width": 22.204628501827038,
                                "height": 22.691839220462846
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/450835_Grana_Padano_Selex_1507905087_1533031593.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_GfvgFVO_4rtvAwD.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638213,
                            "field1": "Prosecco di Valdobbiadene ",
                            "field2": "D.O.C.G.",
                            "field3": "MIONETTO cl. 75",
                            "field4": "",
                            "description": "Prosecco di Valdobbiadene  D.O.C.G. MIONETTO cl. 75",
                            "grammage": 750,
                            "price": "5.99",
                            "price_label": "€ 5.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 70.32277710109624,
                                "left": 4.671132764920832,
                                "width": 22.448233861144942,
                                "height": 22.935444579780757
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/404568_Spumante_Valdobbiadene_Prosecco_Docg_Extra_Dry_Prest_BmlJLhC.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_TQvsfex_8J7aRMI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638218,
                            "field1": "Sgrassatore",
                            "field2": "CIF",
                            "field3": "ml. 650",
                            "field4": "+ Ricarica ml. 650",
                            "description": "Sgrassatore CIF ml. 650 + Ricarica ml. 650",
                            "grammage": 1300,
                            "price": "1.99",
                            "price_label": "€ 1.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 70.44457978075518,
                                "left": 27.5700365408039,
                                "width": 22.448233861144942,
                                "height": 22.691839220462846
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cif_sgrassatore_con_ricarica_1562686879.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_51Lk37X_h5FOu86.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638210,
                            "field1": "LAVAZZA Suerte",
                            "field2": "50 Cialde",
                            "field3": "",
                            "field4": "",
                            "description": "LAVAZZA Suerte 50 Cialde",
                            "grammage": 0,
                            "price": "4.99",
                            "price_label": "€ 4.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 23.672350791717424,
                                "left": 52.052375152253354,
                                "width": 22.32643118148599,
                                "height": 22.935444579780757
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/489656_LAVAZZA_SUERTE_CIALDE_X_50_1534751164.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cialde_1553621163_1561108258_1562780009.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_61uv0pM_OyqnRy0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638206,
                            "field1": "SALMONE ",
                            "field2": "NORVEGESE AFFUMICATO",
                            "field3": "g. 250",
                            "field4": "",
                            "description": "SALMONE  NORVEGESE AFFUMICATO g. 250",
                            "grammage": 250,
                            "price": "5.49",
                            "price_label": "€ 5.49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 23.672350791717424,
                                "left": 75.23751522533497,
                                "width": 22.448233861144942,
                                "height": 22.813641900121798
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/salmone_affumicato_formato_gran_risarmio_1528881426_1546881378.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_WDkFghX_lwLgL1V.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638208,
                            "field1": "INSALATONA",
                            "field2": "",
                            "field3": "BUSTONE G. 500",
                            "field4": "",
                            "description": "INSALATONA BUSTONE G. 500",
                            "grammage": 500,
                            "price": "0.99",
                            "price_label": "€ 0.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Vegetali",
                            "blueprint": {
                                "top": 47.0584652862363,
                                "left": 52.29598051157126,
                                "width": 22.204628501827038,
                                "height": 22.813641900121798
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Insalatona_gr_500_1437043556.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_8VJe5Do_9TAdpRg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638209,
                            "field1": "SALAME NAPOLI",
                            "field2": "GALBANI",
                            "field3": "",
                            "field4": "",
                            "description": "SALAME NAPOLI GALBANI",
                            "grammage": 0,
                            "price": "0.89",
                            "price_label": "€ 0.89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 47.0584652862363,
                                "left": 75.07308160779537,
                                "width": 22.448233861144942,
                                "height": 22.813641900121798
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Galbani_Salame_Napoli_Dok.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Salame_Napoli_1523284959.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/Galbani_salame_napoli_piatto_DOK_Campania_1442594946_152717_Rw57z7S.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_ixc4pIA_yF2cB70.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638207,
                            "field1": "2 Tartufi ",
                            "field2": "SAPER DI SAPORI g. 160",
                            "field3": "",
                            "field4": "",
                            "description": "2 Tartufi  SAPER DI SAPORI g. 160",
                            "grammage": 160,
                            "price": "1.29",
                            "price_label": "€ 1.29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "bianco",
                                "nero"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 70.40194884287453,
                                "left": 52.18102923264312,
                                "width": 21.92448233861145,
                                "height": 22.89890377588307
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/saper_di_sapori_tartufo_bianco_160_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/saper_di_sapori_tartufo_nero_160_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_44q5jYg_WoIkAxF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 638211,
                            "field1": "San Marzano",
                            "field2": "BORSCI cl. 70",
                            "field3": "",
                            "field4": "",
                            "description": "San Marzano BORSCI cl. 70",
                            "grammage": 700,
                            "price": "9.70",
                            "price_label": "€ 9.70",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "blueprint": {
                                "top": 70.32277710109624,
                                "left": 75.19488428745433,
                                "width": 22.448233861144942,
                                "height": 22.813641900121798
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_3/products/borsci_elisir_san_marzano_70_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_3/products/cropped_image_hMZC81q_avRXmW8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 21,
                    "image_file": "/media/interactive_flyer_3/pages/fine.png",
                    "products": []
                }
            ]
        }
    },
    "euroesse": {
        "config": {
            "primary-color": "#3B3B3B",//"rgb(150, 24, 44)",
            "secondary-color": "rgb(205, 219, 0)",
            "tertiary-color": "rgb(56, 56, 56)",
            "hover-color": "rgba(205, 219, 0, 0.4)",
            "clientIcon": require("assets/images/euroesse_clientLogo.png"),
            "serverPath": "https://euroesse.volantinointerattivo.net",
            "pdfPath": "/media/interactive_flyer_4/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino Euroesse!",
            "shareFlyerURL": "https://euroesse.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Euroesse!",
            "ogTitleMeta": "Volantino Interattivo Euroesse",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino - offerte valide dal 23 Settembre al 6 Ottobre 2019.",
            "ogImageMeta": "https://euroesse.volantinointerattivo.net/media/interactive_flyer_4/images/euroesse-logo.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": ""
        },
        "leaflet": {
            "name": "20 Euroesse 2019",
            "code": "20Euroesse2019",
            "id_campaign": "1235",
            "index": {
                "image_file": "/media/interactive_flyer_4/pages/indice.png",
                "links": [
                    {//TRADIZIONE
                        "page": 0,
                        "blueprint": {
                            "top": 11.780932656169568,
                            "left": 2.1556585429089985,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//OFFERTISSIME
                        "page": 2,
                        "blueprint": {
                            "top": 19.022243242904267,
                            "left": 2.1404301143097912,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//MACELLERIA
                        "page": 4,
                        "blueprint": {
                            "top": 26.156729850047125,
                            "left": 2.1336619238212546,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//ORTOFRUTTA
                        "page": 4,
                        "blueprint": {
                            "top": 33.311544901067535,
                            "left": 2.0834645110312744,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//PIU GUSTO INSIEME
                        "page": 6,
                        "blueprint": {
                            "top": 40.4508146714757,
                            "left": 2.1517104317906854,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//SELF SERVICE
                        "page": 8,
                        "blueprint": {
                            "top": 47.64389502861855,
                            "left": 2.1957036699661736,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//CONCORSO VEGE
                        "page": 10,
                        "blueprint": {
                            "top": 54.88520561535324,
                            "left": 2.828529480644347,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//PROMOZIONI
                        "page": 12,
                        "blueprint": {
                            "top": 62.14923622759815,
                            "left": 2.318095114633877,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//ALIMENTARI
                        "page": 14,
                        "blueprint": {
                            "top": 69.23708699290428,
                            "left": 2.2086760350692023,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//BEVANDE
                        "page": 16,
                        "blueprint": {
                            "top": 76.53300536025121,
                            "left": 2.2295446224088566,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//IGIENE
                        "page": 18,
                        "blueprint": {
                            "top": 83.92020242657773,
                            "left": 2.2380048605195273,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    },
                    {//SURGELATI
                        "page": 20,
                        "blueprint": {
                            "top": 91.45527895718998,
                            "left": 2.224468479542454,
                            "width": 95.18951873796252,
                            "height": 6.631055606028214
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Surgelati",
                    "subcategories": [
                        "Verdure",
                        "Gelati",
                        "Ittico"
                    ]
                },
                {
                    "category_name": "Alimentari",
                    "subcategories": [
                        "Colazione",
                        "Dispensa",
                        "Olio",
                        "Snack Dolci",
                        "Snack Salati",
                        "Rossi",
                        "Pasta"
                    ]
                },
                {
                    "category_name": "Igiene Casa",
                    "subcategories": [
                        "Bucato",
                        "Extra",
                        "Superfici"
                    ]
                },
                {
                    "category_name": "Self Service",
                    "subcategories": [
                        "Formaggi",
                        "Pasta",
                        "Salumi",
                        "Snack",
                        "Yogurt",
                        "Latte",
                        "Ittico"
                    ]
                },
                {
                    "category_name": "Bevande",
                    "subcategories": [
                        "Bibite",
                        "Birre",
                        "Succhi",
                        "Vini e Spumanti",
                        "Acqua",
                        "Liquori"
                    ]
                },
                {
                    "category_name": "coloniali",
                    "subcategories": []
                },
                {
                    "category_name": "Macelleria",
                    "subcategories": [
                        "Avicoli",
                        "Suino",
                        "Bovino",
                        "Altro"
                    ]
                },
                {
                    "category_name": "Ortofrutta",
                    "subcategories": [
                        "Frutta",
                        "Verdura"
                    ]
                },
                {
                    "category_name": "Gastronomia",
                    "subcategories": [
                        "Salumi",
                        "Formaggi"
                    ]
                },
                {
                    "category_name": "Igiene Persona",
                    "subcategories": [
                        "Intima",
                        "Corpo",
                        "Capelli",
                        "Orale"
                    ]
                },
                {
                    "category_name": "Baby Food",
                    "subcategories": []
                },
                {
                    "category_name": "No Food",
                    "subcategories": [
                        "Home"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "/media/interactive_flyer_4/pages/page_1.jpg",
                    "products": [
                        {
                            "item_id": 689876,
                            "field1": "findus",
                            "field2": "pisellini primavera",
                            "field3": "750 gr",
                            "field4": "",
                            "description": "findus pisellini primavera 750 gr",
                            "grammage": 750,
                            "price": "2.65",
                            "price_label": "€ 2,65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Verdure",
                            "blueprint": {
                                "top": 39.83218745856045,
                                "left": 1.8598303834227938,
                                "width": 39.25802520780606,
                                "height": 24.65267520214824
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Pisellini_Primavera_750gr__CSR_1538064316_1538991259_1539611209.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_oD2l7Ta.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Farfalle con pisellini primavera, prosciutto e carote",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_4/images/farfalle-pisellini-carote.png"
                                        ],
                                        "ingredients": "360 gr di Farfalle secche , 100 gr di Pisellini Primavera, 100 gr di Prosciutto Cotto a cubetti, 50 gr di Carote fresche a cubetti, 2 cucchiai di Olio EVO, Q.b. di Sale.",
                                        "recipe": "In una pentola con abbondante acqua bollente far cuocere la pasta; nel frattempo, in una padella scaldata con un filo d'olio, far cuocere i Pisellini Primavera Findus seguendo le istruzioni riportate sul retro della confezione. Prima di ultimare la cottura dei Pisellini, aggiungere anche i cubetti di prosciutto cotto e le carote aggiustando di sale. Scolare la pasta e mantecarla insieme al condimento usando acqua di cottura al bisogno. Servire. (Fonte: Findus.it) "
                                    }
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Congelare vs. surgelare",
                                    "data": "/media/interactive_flyer_4/video/Congelare_vs_Surgelare.mp4"
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA' - Pisellini Primavera",
                                    "data": {

                                        "titolo": "Pisellini Primavera",
                                        "testo": "I Pisellini Primavera Findus sono la parte migliore del raccolto perché selezioniamo solo i pisellini più piccoli, teneri e dolci rispetto ai nostri Piselli Novelli. Da sempre i nostri agricoltori coltivano i Pisellini Primavera con passione nei terreni più adatti dove le favorevoli condizioni climatiche permettono alle piante di crescere al meglio e li raccolgono nel loro momento perfetto di maturazione. ✓ Più piccoli, teneri e dolci ✓ Raccolti nel momento perfetto di maturazione e surgelati entro 3 ore Scopri le ricette a base di Pisellini Primavera, teneri, dolci e ricchi di proteine e vitamina C, ideali anche per i più piccoli, insiemi ai consigili nutrizionali per colorare di verde la tua vita con Dolcemente Verde. Scopri di più e clicca qui! (Fonte: findus.it)",
                                        "img": [
                                            "/media/interactive_flyer_4/images/pisellini.jpg"
                                        ]
                                    }
                                },
                            ]
                        },
                        {
                            "item_id": 689746,
                            "field1": "mulino bianco",
                            "field2": "crostatina cacao/albicocca",
                            "field3": "6 pezzi",
                            "field4": "240 gr",
                            "description": "mulino bianco crostatina cacao/albicocca 6 pezzi 240 gr",
                            "grammage": 240,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Cacao",
                                "Albicocca"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 38.47648901689065,
                                "left": 41.84911874614481,
                                "width": 57.12483118146917,
                                "height": 27.578398382076298
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Crostatina_albicocca_6_pz_sx_lr_1568630428.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Crostatina_cacao_6pz_sx_lr_1568630429.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_fIQbvfh_0M7LGYx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "SITO WEB - Scheda prodotto Crostatina Mulino Bianco",
                                    "data": "https://www.mulinobianco.it/crostatina-albicocca"
                                }
                            ]
                        },
                        {
                            "item_id": 692751,
                            "field1": "lavazza ",
                            "field2": "caffè crema e gusto ricco",
                            "field3": " 250 gr x 4",
                            "field4": "",
                            "description": "lavazza  caffè crema e gusto ricco  250 gr x 4",
                            "grammage": 0,
                            "price": "5.99",
                            "price_label": "€ 5,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 64.61960440928547,
                                "left": 2.598794927909733,
                                "width": 53.415952752639825,
                                "height": 17.41803986670413
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/LAVAZZA_CREMA_E_GUSTO_RICCO_X_4_1510221468_1510585059.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_29WMSFw_w3DQKsu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Semifreddo al caffè",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_4/images/semifreddo-caffè.png"
                                        ],
                                        "ingredients": "100 ml caffè, 4 uova 120 gr zucchero, 250 gr panna, 50 gr pan di spagna, 5 gr colla di pesce, salsa al mou",
                                        "recipe": "Monta i 4 rossi con 60 gr di zucchero, monta a neve i 4 bianchi con 60 gr di zucchero, fai ammorbidire la gelatina in acqua fredda. Monta la panna, uniscila con i rossi d’uovo montati e sciogli la gelatina nel caffè e unisci al composto. Aggiungi i bianchi montati mescolando dal basso verso l’alto. Versa il composto in uno stampo rettangolare da plumcake, coprilo con la fetta di pan di spagna e inzuppala con il caffè zuccherato. Riponi in freezer per 6 ore. Taglia una fetta di semifreddo alta circa 2,5 cm, tagliala ulteriormente a metà con un angolo di 45° e disponila sul piatto e servi con una salsa al mou. (Fonte: Lavazza.it)"
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": 689875,
                            "field1": "bio presto",
                            "field2": "detersivo liquido per lavatrice",
                            "field3": "vari tipi",
                            "field4": "36 lavaggi",
                            "description": "bio presto detersivo liquido per lavatrice vari tipi 36 lavaggi",
                            "grammage": 0,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 64.45463171511071,
                                "left": 55.91364316022656,
                                "width": 42.592174213682355,
                                "height": 17.41803986670413
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/BIOPRESTO_36CLASSICO_1554909004_1557161400_1557824744_15578_F3kVFhM.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_vCmyoCw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Spot Bio Presto",
                                    "data": "/media/interactive_flyer_4/video/BioPresto.mp4"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "/media/interactive_flyer_4/pages/page_2.jpg",
                    "products": [
                        {
                            "item_id": 689748,
                            "field1": "COSTA D’ORO ",
                            "field2": "OLIO EXTRA VERGINE",
                            "field3": "di oliva",
                            "field4": "1 lt",
                            "description": "COSTA D’ORO  OLIO EXTRA VERGINE di oliva 1 lt",
                            "grammage": null,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 8.708398907667352,
                                "left": 2.367855490633475,
                                "width": 47.21274972467969,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/104231_Olio-Extra-vergine-1L-Def_1483027079.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_1ViEOCQ_TG2OVEh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "SITO WEB - I classici Costa d'Oro",
                                    "data": "https://www.oliocostadoro.net/olio-costa-doro-i-classici-extra/"
                                }
                            ]
                        },
                        {
                            "item_id": 689879,
                            "field1": "biraghi",
                            "field2": "grattugia facile",
                            "field3": "200 gr",
                            "field4": "",
                            "description": "biraghi grattugia facile 200 gr",
                            "grammage": null,
                            "price": "1.95",
                            "price_label": "€ 1,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 8.753592576761202,
                                "left": 51.28860073554885,
                                "width": 45.928958732935065,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Biraghi_grattugia_facile_200_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_3NjvS7p_LnbuMLT.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689878,
                            "field1": "latteria soresina ",
                            "field2": "soresino",
                            "field3": "senza lattosio ",
                            "field4": "270 gr",
                            "description": "latteria soresina  soresino senza lattosio  270 gr",
                            "grammage": 270,
                            "price": "1.75",
                            "price_label": "€ 1,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 31.3975170306447,
                                "left": 2.4469198627764035,
                                "width": 47.19041515627773,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/latteria_soresina_soresino_270gr_1429018985.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_i5Szc5l_N2gYhN8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689750,
                            "field1": "POWERADE",
                            "field2": "vari gusti",
                            "field3": "50 cl",
                            "field4": "",
                            "description": "POWERADE vari gusti 50 cl",
                            "grammage": 500,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 54.229801112359915,
                                "left": 2.629169940936391,
                                "width": 47.19041515627773,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/principale_54000575_1513090757_1513182849_1514458921.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/principale_54000599_1513090757_1513182849_1514458922.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_f0hwB2o_8mFNl0i.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "SITO WEB - Powerade",
                                    "data": "https://www.coca-cola.it/powerade/it/home/"
                                }
                            ]
                        },
                        {
                            "item_id": 689880,
                            "field1": "ringo",
                            "field2": "8 snack gelato",
                            "field3": "cacao/vaniglia ",
                            "field4": "280 gr",
                            "description": "ringo 8 snack gelato cacao/vaniglia  280 gr",
                            "grammage": null,
                            "price": "1.75",
                            "price_label": "€ 1,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Cacao",
                                "Vaniglia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 76.66577148048289,
                                "left": 2.428158825318758,
                                "width": 47.19041515627773,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/4x2_RINGO_CACAO_-_Alta_Risoluzione_-_JPG_a_300_DPI_15250773_XJUCqGT.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/4x2_RINGO_VANIGLIA_-_Alta_Risoluzione_-_JPG_a_300_DPI_15250_bsIdcmx.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_rOT0aZM_blm3aF7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689877,
                            "field1": "KRAFT ",
                            "field2": "MAYONNAISE MEDIUM JAR ",
                            "field3": "490 ML",
                            "field4": "",
                            "description": "KRAFT  MAYONNAISE MEDIUM JAR  490 ML",
                            "grammage": null,
                            "price": "1.45",
                            "price_label": "€ 1,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 31.204732847796805,
                                "left": 51.41501439270393,
                                "width": 45.88116275655488,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/268381400_KRAFT_MAYO_MEDIUM_JAR_490_ML_1551366677.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_8zNHEl0_zR7PfEO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689749,
                            "field1": "HEINEKEN",
                            "field2": "Birra ",
                            "field3": "classica/0.0",
                            "field4": "33 cl x 3",
                            "description": "HEINEKEN Birra  classica/0.0 33 cl x 3",
                            "grammage": 990,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 53.99877613258644,
                                "left": 51.31852905720747,
                                "width": 45.88116275655488,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/HEINEKEN_CLASSICA_X_3_1468917579.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Heineken_00_birra_x3_1529745506_1530863618_1555066580.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_70285u2_UWnYGip.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689747,
                            "field1": "eridania",
                            "field2": "zucchero astuccio",
                            "field3": "1 kg",
                            "field4": "",
                            "description": "eridania zucchero astuccio 1 kg",
                            "grammage": null,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 76.766272087279,
                                "left": 51.98767272653017,
                                "width": 45.88116275655488,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Eridania_zuccheroclassico_astuccio_1565692816.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_F512vWX_bZHupqY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK WEB - Ricette Eridania",
                                    "data": "https://www.eridania.it/ricette-per-prodotto/classico/"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "/media/interactive_flyer_4/pages/page_3.jpg",
                    "products": [
                        {
                            "item_id": 689883,
                            "field1": "philadelphia light",
                            "field2": "175 gr",
                            "field3": "",
                            "field4": "",
                            "description": "philadelphia light 175 gr",
                            "grammage": 175,
                            "price": "1.45",
                            "price_label": "€ 1,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 8.68659217223046,
                                "left": 2.4987360614689553,
                                "width": 47.157359995042825,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Philadelphia_light_1521712110_1523291805_1523454955_1524574_YMsv80q.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_slPUu7x_ILG5jC1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Cheesecake light",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_4/images/cheesecake-light-png.png"
                                        ],
                                        "ingredients": "(Fondo)180 g di biscotti Oro Saiwa, 80 g di burro leggero con meno grassi, (Crema light a freddo): 400 g di Philadelphia Light (2 confezioni da 200 g), 250 g di yogurt magro, 80 g di fruttosio, 10 g di colla di pesce",
                                        "recipe": "Preparare la base della Cheesecake frullando i biscotti, aggiungendo il burro fuso e amalgamando per bene. Stendere il composto in una tortiera imburrata e foderata con carta forno. Pressare bene aiutandosi con il dorso di un cucchiaio e porre la base in frigorifero per 30 minuti o in freezer per 15 minuti.Mettete Philadelphia Light nello sbattitore elettrico e aggiungete il fruttosio, Ammorbidite la colla di pesce in acqua fredda per 10 minuti e scioglietela in due cucchiai di acqua calda. Incorporate la gelatina sciolta nell’acqua alla crema di Philadelphia, poi unite lo yogurt magro. Continuate a mescolare con le fruste fino ad ottenere un composto omogeneo. A questo punto la crema è pronta, versatela sulla base di biscotto, ormai fredda e compatta, e riponete in frigorifero la vostra cheesecake light per almeno 4 ore per farla rassodare. Potete servirla senza ulteriori guarnizioni."
                                    }

                                }
                            ]
                        },
                        {
                            "item_id": 689882,
                            "field1": "crastan",
                            "field2": "ginseng & caffe",
                            "field3": "200 gr",
                            "field4": "",
                            "description": "crastan ginseng & caffe 200 gr",
                            "grammage": null,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "coloniali",
                            "subcategory": "",
                            "blueprint": {
                                "top": 8.733049999900363,
                                "left": 51.280560290924136,
                                "width": 46.41138541041739,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/crastan_ginseng_caffe_200_g_1484150682.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_8o317YI_QqfgHTg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689751,
                            "field1": "divella ",
                            "field2": "biscotti vari tipi ",
                            "field3": "350/400 gr",
                            "field4": "",
                            "description": "divella  biscotti vari tipi  350/400 gr",
                            "grammage": null,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classici",
                                "Cacao"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 31.346318608314604,
                                "left": 2.4420617090702197,
                                "width": 47.09482320351734,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/classici_1527173431_1527500458.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/18304_1558455747.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_VIFxJhc_haOpATr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689752,
                            "field1": "ferrero ",
                            "field2": "kinder colazione più",
                            "field3": "10 Merendine",
                            "field4": "300 gr",
                            "description": "ferrero  kinder colazione più 10 Merendine 300 gr",
                            "grammage": 300,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 31.354535639058938,
                                "left": 51.21310989435022,
                                "width": 46.32964089006622,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/81rxReWe-uL._SY550__1568631095.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_m1xvGtk_FfbSvnu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689884,
                            "field1": "motta",
                            "field2": "6 buondì classici",
                            "field3": "198 gr",
                            "field4": "",
                            "description": "motta 6 buondì classici 198 gr",
                            "grammage": 198,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 54.05092267384858,
                                "left": 2.579642650426289,
                                "width": 46.99521102844461,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Buond_Classico_6_pz_2inc_H.png_1491378950.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_BCKVQVX_x7PSBMy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689881,
                            "field1": "zuegg le vellutate ",
                            "field2": "confetture",
                            "field3": "vari gusti 700 gr",
                            "field4": "",
                            "description": "zuegg le vellutate  confetture vari gusti 700 gr",
                            "grammage": null,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Frutti di bosco",
                                "Pesche"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 54.04871039634048,
                                "left": 51.13142082534129,
                                "width": 46.384137236967014,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/zuegg_pasticcera_albicocche_700_gr_1490966517_1493801605_14_H4vBsnL.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/zuegg_pasticcera_ciliegie_700_gr_1490966513_1493801605_1496_Ip431X7.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/zuegg_pasticcera_fragole_700_gr_1490966514_1493801606_14968_3uyvcdo.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/zuegg_pasticcera_frutti_bosco_700_gr_1490966515_1493801608__qlh6l68.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/zuegg_pasticcera_pesche_700_gr_1490966516_1493801609_149682_KmsWVt8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_ucn3cFl_PS6Lx4E.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689753,
                            "field1": "nestlè nesquik",
                            "field2": "Solubile 30% di zuccheri in meno",
                            "field3": "350 gr",
                            "field4": "",
                            "description": "nestlè nesquik Solubile 30% di zuccheri in meno 350 gr",
                            "grammage": 350,
                            "price": "2.39",
                            "price_label": "€ 2,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 76.57506810265117,
                                "left": 2.410346621939431,
                                "width": 47.14351256263362,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Nesquik_meno_zucheri_gr_350_1525345792.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_egeUfQC_xeuh8km.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689994,
                            "field1": "nutella",
                            "field2": "925 gr",
                            "field3": "",
                            "field4": "",
                            "description": "nutella 925 gr",
                            "grammage": 925,
                            "price": "4.99",
                            "price_label": "€ 4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 76.68157346268353,
                                "left": 51.2823470563963,
                                "width": 46.123269478032114,
                                "height": 21.557527123985682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/924634_nutella_925GR_1565336821_1566468367_1568273856.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_J3llooB_qtpQ0d6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK WEB - Curiosità su Nutella",
                                    "data": "https://www.nutella.com/it/it/curiosita"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "/media/interactive_flyer_4/pages/page_4.jpg",
                    "products": [
                        {
                            "item_id": 689754,
                            "field1": "spiedini di pollo con alloro",
                            "field2": "al kg",
                            "field3": "",
                            "field4": "",
                            "description": "spiedini di pollo con alloro al kg",
                            "grammage": 0,
                            "price": "7.90",
                            "price_label": "€ 7,90 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 9.887226779835638,
                                "left": 2.044004248805066,
                                "width": 29.14051035655037,
                                "height": 19.66223737884005
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Spiedini_di_pollo_1532601539.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_hUxpDt6_8aqiC6x.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689756,
                            "field1": "arista di suino nazionale",
                            "field2": "a trancio",
                            "field3": "al kg",
                            "field4": "",
                            "description": "arista di suino nazionale a trancio al kg",
                            "grammage": 0,
                            "price": "6.50",
                            "price_label": "€ 6,50 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Suino",
                            "blueprint": {
                                "top": 9.927679854269295,
                                "left": 32.70802659059095,
                                "width": 32.739056017474034,
                                "height": 19.66223737884005
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Arista_di_suino_con_piatto_euroesse_1428934382.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/arista_di_suino_al_kg_1451902104_1479923078_1485437583.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_c3Pr78i_MbYzAMg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689755,
                            "field1": "cuore di reale ",
                            "field2": "di bovino adulto angus",
                            "field3": "al kg",
                            "field4": "",
                            "description": "cuore di reale  di bovino adulto angus al kg",
                            "grammage": 0,
                            "price": "11.90",
                            "price_label": "€ 11,90 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Bovino",
                            "blueprint": {
                                "top": 9.819278256372856,
                                "left": 65.9195298043043,
                                "width": 32.739056017474034,
                                "height": 19.66223737884005
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Arrosto_Reale_di_Bovino__adulto_a_cuore_di__scottona_nazion_xSoXWP4.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_fVE2Fac_R5mVtTW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689885,
                            "field1": "arrosticini",
                            "field2": "golosini nazionali",
                            "field3": "al kg",
                            "field4": "",
                            "description": "arrosticini golosini nazionali al kg",
                            "grammage": 0,
                            "price": "7.90",
                            "price_label": "€ 7,90 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 29.780658211874474,
                                "left": 1.8126735715030093,
                                "width": 30.392139569796164,
                                "height": 18.013142516380505
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/arrosticini_golosini_euroesse_1427709627.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_HDmZUNQ_jNLaMUr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689886,
                            "field1": "Salsiccia Zampina",
                            "field2": "Pugliese",
                            "field3": "al kg",
                            "field4": "",
                            "description": "Salsiccia Zampina Pugliese al kg",
                            "grammage": 0,
                            "price": "6.90",
                            "price_label": "€ 6,90 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 29.73103998776444,
                                "left": 32.99173106063807,
                                "width": 32.612642360318944,
                                "height": 18.013142516380505
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/salsiccia_zampina_1436867664.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_yHKdBgK_t2keaab.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689887,
                            "field1": "Agnello a Pezzi",
                            "field2": "Al Kg",
                            "field3": "",
                            "field4": "",
                            "description": "Agnello a Pezzi Al Kg",
                            "grammage": null,
                            "price": "8.90",
                            "price_label": "€ 8,90 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 29.86851723291007,
                                "left": 65.83248043887889,
                                "width": 33.082114988128126,
                                "height": 18.294101759908013
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Agnello_a_pezzi_euroesse_1443544122.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_MNncRVv_i3PpJZH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689757,
                            "field1": "aia",
                            "field2": "4 cordon bleu",
                            "field3": "confezione famiglia",
                            "field4": "490 gr",
                            "description": "aia 4 cordon bleu confezione famiglia 490 gr",
                            "grammage": 0,
                            "price": "3.25",
                            "price_label": "€ 3,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 61.07395564310424,
                                "left": 2.717670283150397,
                                "width": 28.86534847383823,
                                "height": 18.47298019841934
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/13334107_Aia_cordon_bleu_490_gr_x_4.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_PxRU89q_p0Hd6HN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689759,
                            "field1": "aia",
                            "field2": "durango alette arrosto di pollo",
                            "field3": "500 gr",
                            "field4": "",
                            "description": "aia durango alette arrosto di pollo 500 gr",
                            "grammage": 500,
                            "price": "3.25",
                            "price_label": "€ 3,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 62.50561523048288,
                                "left": 32.619637151061426,
                                "width": 33.24337057199027,
                                "height": 17.203764988063355
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Aia_durango_alette_pollo_1427209451.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_mLx6s4Y_SqImaLp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689758,
                            "field1": "aia",
                            "field2": "la viennese",
                            "field3": "cotoletta di pollo",
                            "field4": "380 gr",
                            "description": "aia la viennese cotoletta di pollo 380 gr",
                            "grammage": 380,
                            "price": "2.65",
                            "price_label": "€ 2,65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 62.032187843751494,
                                "left": 65.95660518785154,
                                "width": 32.25037566083917,
                                "height": 17.491361064115136
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/aia_la_viennese_cotoletta_1431358259.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_lgJwjzo_Fs4tl9F.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689888,
                            "field1": "Del Campo",
                            "field2": "Petto Di Pollo ",
                            "field3": "A Fette",
                            "field4": "al kg",
                            "description": "Del Campo Petto Di Pollo  A Fette al kg",
                            "grammage": 0,
                            "price": "7.99",
                            "price_label": "€ 7,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 80.12956597886476,
                                "left": 2.344236299469639,
                                "width": 27.28852794465991,
                                "height": 19.35852328094361
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/del_campo_Petto_Pollo_a_Fette_1451914798.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_7NYEiwb_x5aTe5k.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689889,
                            "field1": "Del Campo",
                            "field2": "Cotolette Di Filetto Di Pollo Nazionale",
                            "field3": "200 gr",
                            "field4": "",
                            "description": "Del Campo Cotolette Di Filetto Di Pollo Nazionale 200 gr",
                            "grammage": 200,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 80.27708300950947,
                                "left": 32.40701205987477,
                                "width": 33.61635786430299,
                                "height": 19.35852328094361
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Del_campo_Cotolette_rettangolare_1447319416.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_nOFbhLp_fdqmojc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689890,
                            "field1": "Del Campo",
                            "field2": "pollo busto ",
                            "field3": "al kg",
                            "field4": "",
                            "description": "Del Campo pollo busto  al kg",
                            "grammage": 0,
                            "price": "3.49",
                            "price_label": "€ 3,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 80.19870517779427,
                                "left": 65.77262379556164,
                                "width": 33.61635786430299,
                                "height": 19.35852328094361
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Del_campo_pollo_a_busto_1479736704.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_wQOye3L_Oq3m0Ka.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "/media/interactive_flyer_4/pages/page_5.jpg",
                    "products": [
                        {
                            "item_id": 691793,
                            "field1": "UVA RED GLOBE",
                            "field2": "al kg",
                            "field3": "",
                            "field4": "",
                            "description": "UVA RED GLOBE al kg",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1,49 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 10.366269397049924,
                                "left": 2.29420686624925,
                                "width": 31.778669576189788,
                                "height": 19.35852328094361
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/uva_red_globe_1471350509_1471603655_1505125375.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_HCXyNMY_5KJlyJP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 691791,
                            "field1": "pere santa maria",
                            "field2": "cal.60/70",
                            "field3": "al kg",
                            "field4": "",
                            "description": "pere santa maria cal.60/70 al kg",
                            "grammage": 0,
                            "price": "1.29",
                            "price_label": "€ 1,29 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 9.357786893004619,
                                "left": 34.172783037733666,
                                "width": 33.13616464366087,
                                "height": 20.00577247188213
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/pere_coscia_1437733788_1474357807.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_TWsmPcW_uoFRGz7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 691790,
                            "field1": "Meloni",
                            "field2": "gialli",
                            "field3": "al kg",
                            "field4": "",
                            "description": "Meloni gialli al kg",
                            "grammage": 0,
                            "price": "0.89",
                            "price_label": "€ 0,89 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 9.851124777308824,
                                "left": 70.1703003139074,
                                "width": 27.8093700797936,
                                "height": 19.555732018807692
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Melone_di_pane_giallo_1428426467_1471603674_1533042508_1533_caERVwj.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_wCLG9Wl_Z7aRJfB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 691788,
                            "field1": "Melanzane Lunghe",
                            "field2": "al kg",
                            "field3": "",
                            "field4": "",
                            "description": "Melanzane Lunghe al kg",
                            "grammage": 0,
                            "price": "1.19",
                            "price_label": "€ 1,19 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 29.743292050771608,
                                "left": 2.3540635095665006,
                                "width": 31.658509598187248,
                                "height": 18.539980602950084
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Melanzane_Lunghe_al_kg_1430145055_1437733672.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_OJFNdmE_n5n2Jlk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 691789,
                            "field1": "CICORIA",
                            "field2": "a mazzetti",
                            "field3": "al kg",
                            "field4": "",
                            "description": "CICORIA a mazzetti al kg",
                            "grammage": 0,
                            "price": "1.19",
                            "price_label": "€ 1,19 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 29.87508058232501,
                                "left": 34.316617658242286,
                                "width": 32.722528436856585,
                                "height": 18.539980602950084
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/7000566_1520007687.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_jEp0czW_2UJsunR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 691792,
                            "field1": "pomodoro",
                            "field2": "Datterino",
                            "field3": "cestino 250 gr",
                            "field4": "al pezzo",
                            "description": "pomodoro Datterino cestino 250 gr al pezzo",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 29.926595044299116,
                                "left": 68.64390045797528,
                                "width": 29.644378219698567,
                                "height": 18.539980602950084
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/pomodoro_Datterino_CONF-_250GR_1562257136.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_cPnUxpF_3oZ9IJF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689760,
                            "field1": "bonduelle",
                            "field2": " insalata mista ",
                            "field3": "200 gr",
                            "field4": "",
                            "description": "bonduelle  insalata mista  200 gr",
                            "grammage": 200,
                            "price": "1.15",
                            "price_label": "€ 1,15",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 61.08367938895931,
                                "left": 1.3257799803402985,
                                "width": 33.18038708909675,
                                "height": 18.539980602950084
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/bonduelle_mista_200_gr_1478773844.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_XnEyVLG_0rQ5v2i.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689761,
                            "field1": "bonduelle",
                            "field2": "Il lattughino",
                            "field3": "90 gr",
                            "field4": "",
                            "description": "bonduelle Il lattughino 90 gr",
                            "grammage": 90,
                            "price": "1.25",
                            "price_label": "€ 1,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 59.45165066727646,
                                "left": 34.5596177624556,
                                "width": 33.18038708909675,
                                "height": 20.204561407966263
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Bonduelle_Lattughino_Sud_Contadino_1427138082.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_oqw1yhn_BX7f8mM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689762,
                            "field1": "bonduelle",
                            "field2": "insalatine bio",
                            "field3": "80 gr",
                            "field4": "",
                            "description": "bonduelle insalatine bio 80 gr",
                            "grammage": 80,
                            "price": "1.35",
                            "price_label": "€ 1,35",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 61.28878911792371,
                                "left": 66.78303967006627,
                                "width": 32.442899640464056,
                                "height": 18.316540574632928
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/0429421_BONDUELLE_INSALATINE_BIO_80GR_1552315413.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_bXbJob6_EFwY64Q.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689893,
                            "field1": "valfrutta",
                            "field2": "polpa di frutta",
                            "field3": "vari gusti",
                            "field4": "100 gr x 2",
                            "description": "valfrutta polpa di frutta vari gusti 100 gr x 2",
                            "grammage": 200,
                            "price": "0.75",
                            "price_label": "€ 0,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Mela Italiana",
                                "Mela e Banana"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Succhi",
                            "blueprint": {
                                "top": 80.74640188086869,
                                "left": 1.5531458866722434,
                                "width": 32.442899640464056,
                                "height": 18.316540574632928
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/09547728_VALFRUTTA_POLPA_MELA_PESCA_2X100_1554969193.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/09547730_VALFRUTTA_POLPA_MELA_ALBICOCCA_2X100_1554969194.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/09547734_VALFRUTTA_POLPA_MELA_BANANA_2X100_1554969195.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/09547735_VALFRUTTA_POLPA_MELA_MIRTILLO_2X100_1554969195.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/09547736_VALFRUTTA_POLPA_MELA_PRUGNA_2X100_1554969196.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/09547739_VALFRUTTA_POLPA_PERA_2X100_1554969196.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/09547740_VALFRUTTA_POLPA_MELA_2X100_1554969197.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_ziUR0wa_b0SdQtJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689891,
                            "field1": "FATINA",
                            "field2": "noci sgusciate",
                            "field3": "150 gr",
                            "field4": "",
                            "description": "FATINA noci sgusciate 150 gr",
                            "grammage": 150,
                            "price": "2.19",
                            "price_label": "€ 2,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 80.80265693750299,
                                "left": 34.26033454586935,
                                "width": 32.442899640464056,
                                "height": 18.316540574632928
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/pag_14_Fatina_Noci_Sgusciate_1436858175_1500999278.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_cSs9IKX_FRcLP4F.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITÀ - Benefici  nutrizionali delle noci",
                                    "data": {

                                        "titolo": "Benefici nutrizionali",
                                        "testo": "Alcuni studi epidemiologici hanno rivelato che le persone che consumano noci abitualmente corrono meno rischi di subire cardiopatie coronariche. Alcuni studi clinici hanno dimostrato che il consumo di vari tipi di semi, quali ad esempio le mandorle e le noci comuni, può diminuire le concentrazioni del colesterolo LDL. L'alto contenuto di arginina stimola la produzione di ossido nitrico che è indispensabile all'elasticità dei vasi sanguigni. Oltre ai benefici cardiaci, le noci generalmente hanno un bassissimo indice glicemico (GI). Conseguentemente, i dietologi si raccomandano affinché le noci siano incluse nelle diete prescritte ai pazienti con deficienze di insulina (diabete); inoltre sono ricche di sali minerali e di vitamine B. Ricche di Omega-3, calcio, magnesio, acido folico e antiossidanti prevengono l'artrite e rendono la pelle più bella. Le caratteristiche nutrizionali le rendono adatte anche ad un consumo controllato durante il periodo della gravidanza. (Fonte: Wikipedia)",
                                        "img": [
                                            "/media/interactive_flyer_4/images/noce.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": 689892,
                            "field1": "develey",
                            "field2": "dressing",
                            "field3": "vari gusti",
                            "field4": "230 ml",
                            "description": "develey dressing vari gusti 230 ml",
                            "grammage": 230,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.18948946177483,
                                "left": 72.05349567021837,
                                "width": 27.7329858558589,
                                "height": 18.316540574632928
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/58774-DDR_1536912619.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/58773-DDR_1536912620.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/58772-DDR_1536912621.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/58771-DDR_1536912621.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_tWCHHnQ_GZy6WsM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 6,
                    "image_file": "/media/interactive_flyer_4/pages/page_6.jpg",
                    "products": [
                        {
                            "item_id": 689763,
                            "field1": "fiorucci ",
                            "field2": "mortadella suprema ",
                            "field3": "1850",
                            "field4": "all'etto",
                            "description": "fiorucci  mortadella suprema  1850 all'etto",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 23.230979146240852,
                                "left": 0.4225700341650683,
                                "width": 31.719706315608615,
                                "height": 27.763913653111896
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/0750024_FIORUCCI_MORTADELLA_SUPREMA1850_1557504691.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_RFXbvbq_2LbXUYQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689894,
                            "field1": "Rigamonti ",
                            "field2": "Bresaola",
                            "field3": "punta d'anca",
                            "field4": "all'etto",
                            "description": "Rigamonti  Bresaola punta d'anca all'etto",
                            "grammage": 100,
                            "price": "2.89",
                            "price_label": "€ 2,89 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 27.193484202875158,
                                "left": 49.703795213088064,
                                "width": 25.39910311414738,
                                "height": 23.068828701655583
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/bresaola_carpaccio_ambientata_carrefour_1473152071_15335399_Y1qoJEu.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_BAaWTzq_PpWUFsY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689767,
                            "field1": "federiciane",
                            "field2": "falanghina/piedirosso",
                            "field3": "75 cl",
                            "field4": "",
                            "description": "federiciane falanghina/piedirosso 75 cl",
                            "grammage": 750,
                            "price": "5.99",
                            "price_label": "€ 5,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Falanghina",
                                "Piedirosso"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 15.014580481192311,
                                "left": 72.056923795420815,
                                "width": 24.88865608126948,
                                "height": 31.172401213791503
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/vino_piedirosso-cantine-federiciane-14-d-confezione-da-6-bt_1GAkmXS.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/federiciane_FALANGHINA_1524244031.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_N8JvCC2_1p0OXak.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689764,
                            "field1": "fiorucci rostello ",
                            "field2": "arrosto di tacchino classico",
                            "field3": "all'etto",
                            "field4": "",
                            "description": "fiorucci rostello  arrosto di tacchino classico all'etto",
                            "grammage": 100,
                            "price": "1.09",
                            "price_label": "€ 1,09 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 53.16909858394312,
                                "left": 1.843495275897713,
                                "width": 32.27494368608132,
                                "height": 20.214674676574674
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/fiorucci_rostello_petto_di_tacchino__1427815886_1534926668.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_9bcZbdO_6fmUfIQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689765,
                            "field1": "galbani ",
                            "field2": "prosciutto cotto ",
                            "field3": "fetta golosa",
                            "field4": "all'etto",
                            "description": "galbani  prosciutto cotto  fetta golosa all'etto",
                            "grammage": 100,
                            "price": "1.19",
                            "price_label": "€ 1,19 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 51.739335234428566,
                                "left": 34.09595212243093,
                                "width": 31.87649498579037,
                                "height": 21.529715635312538
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/galbani__prosciutto_cotto__fetta_golosa_euroesse_1461863453.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Blu_prosciutto_cotto_senza_polifosfati_di_palo_1474036138_1_P6WqwtF.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_1B4rcJs_Ofs0Cfn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689766,
                            "field1": "aurora",
                            "field2": "pancetta coppata magra",
                            "field3": "all'etto",
                            "field4": "",
                            "description": "aurora pancetta coppata magra all'etto",
                            "grammage": 100,
                            "price": "1.29",
                            "price_label": "€ 1,29 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 52.97125776679102,
                                "left": 66.05716619700259,
                                "width": 31.526735644615687,
                                "height": 20.270929733208973
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/aurora_pancetta_coppata_magra_1477550057.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Pancetta_arrotolata_magra_su_tagliere_1490635958_1533220672.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_2OyYEiZ_4mLlOnl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689897,
                            "field1": "negroni",
                            "field2": "salame nostranello napoli",
                            "field3": "all'etto",
                            "field4": "",
                            "description": "negroni salame nostranello napoli all'etto",
                            "grammage": 100,
                            "price": "1.19",
                            "price_label": "€ 1,19 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 73.43419263734117,
                                "left": 2.525146303525508,
                                "width": 31.526735644615687,
                                "height": 20.270929733208973
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/negroni_salumi_salame_napoli_nostranello.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/negroni_salame_nostranello_napoli_1487932629.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_lV8JFRI_okXHOnU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689895,
                            "field1": "fini",
                            "field2": "salsiccia napoli dolce/piccante",
                            "field3": "all'etto",
                            "field4": "",
                            "description": "fini salsiccia napoli dolce/piccante all'etto",
                            "grammage": 100,
                            "price": "1.19",
                            "price_label": "€ 1,19 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                                "Dolce",
                                "Piccante"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 73.68354791646739,
                                "left": 34.15044846933171,
                                "width": 31.526735644615687,
                                "height": 20.270929733208973
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Fini_salsiccia_dolce_e_piccante_euroesse.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/fini_salsiccia_dolce_e_piccante_euroesse_1459863781.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_equNsRm_Pj2pqan.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689896,
                            "field1": "galbani",
                            "field2": "salame ungherese",
                            "field3": "all'etto",
                            "field4": "",
                            "description": "galbani salame ungherese all'etto",
                            "grammage": 100,
                            "price": "1.39",
                            "price_label": "€ 1,39 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 73.79447783151595,
                                "left": 66.04510553006554,
                                "width": 31.526735644615687,
                                "height": 20.270929733208973
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Galbani_salame_ungherese.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/salame_tipo_milano_piatto_1533541322.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_9CYwvy5_hKUfcAv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689898,
                            "field1": "niro",
                            "field2": "montepulciano d'abruzzo/",
                            "field3": "passerina/pecorino",
                            "field4": "75 cl",
                            "description": "niro montepulciano d'abruzzo/ passerina/pecorino 75 cl",
                            "grammage": 750,
                            "price": "4.99",
                            "price_label": "€ 4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Montepulciano d'Abruzzo",
                                "Passerina",
                                "Pecorino"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 15.475366282163192,
                                "left": 22.237272772722024,
                                "width": 31.166335366901286,
                                "height": 30.75617700262645
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/citra_montepulciano_1467106251_VVhfm8K.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/niro_montepulciano_75_cl_1504863787_FBHan1Z.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_LCve4UD_iiS0rcV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "/media/interactive_flyer_4/pages/page_7.jpg",
                    "products": [
                        {
                            "item_id": 689768,
                            "field1": "murgia",
                            "field2": "monteterno",
                            "field3": "formaggio di pecora",
                            "field4": "all'etto",
                            "description": "murgia monteterno formaggio di pecora all'etto",
                            "grammage": 100,
                            "price": "1.39",
                            "price_label": "€ 1,39 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 25.67364955481691,
                                "left": 3.8612001853307083,
                                "width": 32.94944765182049,
                                "height": 24.877839623985672
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Murgia_Monteterno_caciotta_pecora_di_palo_1434386548.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/murgia_monteterno_formaggio_di_pecora_euroesse_1459863158.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_7eMC8NA_97pojYh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689903,
                            "field1": "SANTA MARGHERITA",
                            "field2": "vino gewurztraminer doc",
                            "field3": "75 cl",
                            "field4": "",
                            "description": "SANTA MARGHERITA vino gewurztraminer doc 75 cl",
                            "grammage": 750,
                            "price": "6.49",
                            "price_label": "€ 6,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 15.484847471483581,
                                "left": 30.299646818958939,
                                "width": 22.26491218619821,
                                "height": 30.567501335150723
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/59778_Santa_Margherita_gewurztraminer_75_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_d2pW0oN_BBv7f2G.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689899,
                            "field1": "Busti ",
                            "field2": "Formaggio Di Pecora",
                            "field3": "vari tipi",
                            "field4": "all'etto",
                            "description": "Busti  Formaggio Di Pecora vari tipi all'etto",
                            "grammage": 100,
                            "price": "1.49",
                            "price_label": "€ 1,49 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 23.103615169703648,
                                "left": 44.1000520010365,
                                "width": 33.44393499623987,
                                "height": 27.42511915473001
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/famiglia_busti_caciotta_Il_mandorlo.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/mandorlato_famiglia_busti_1438770192.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/busti_formaggio_il_pepe_nero_1534432663_1561395834.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/3071_famiglia_busti_delizie_il_pistacchio_1543248391_1568276693.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/busti_formaggio_il_noce_1466414559_1543248342_1568276694.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/busti_formaggio_il_pepe_nero_1534432663_1568276695.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_qacog38_dbQeyFw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689772,
                            "field1": "la guardiense ",
                            "field2": "vino doc",
                            "field3": "vari tipi",
                            "field4": "75 cl",
                            "description": "la guardiense  vino doc vari tipi 75 cl",
                            "grammage": 750,
                            "price": "3.39",
                            "price_label": "€ 3,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 15.267096156758663,
                                "left": 65.9990963191575,
                                "width": 29.33660786711957,
                                "height": 33.38309852366205
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/la_guardiense_guardiolo_aglianico_75_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/la_guardiense_guardiolo_falanghina_75_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_AEzjA9s_7GR1Bv6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689769,
                            "field1": "galbani",
                            "field2": "galbanone",
                            "field3": "all'etto",
                            "field4": "",
                            "description": "galbani galbanone all'etto",
                            "grammage": 100,
                            "price": "0.89",
                            "price_label": "€ 0,89 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 53.92064085740591,
                                "left": 2.472436722096884,
                                "width": 31.61741399232764,
                                "height": 19.470717354568198
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Galbani_Galbanone_al_kg_1470676110.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/galbani_Galbanone_al_Kg_1446658261_1489771097_1491896409_1542356833.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_OYrDNpi_heZbYD4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689770,
                            "field1": "asiago DOP busche",
                            "field2": "forme",
                            "field3": "all'etto",
                            "field4": "",
                            "description": "asiago DOP busche forme all'etto",
                            "grammage": 100,
                            "price": "0.59",
                            "price_label": "€ 0,59 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 53.888720853360596,
                                "left": 34.11694661672877,
                                "width": 31.61741399232764,
                                "height": 19.470717354568198
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Asiago_forme_1458146341.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Asiago_fresco_1522916111.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Asiago_x_ottimo_1463477697_1503073721_1527068115_1533221301.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_CV9PAxt_aXCdRy2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689771,
                            "field1": "Pinna",
                            "field2": "Brigante Caciotta ",
                            "field3": "cry al peperoncino",
                            "field4": "all'etto",
                            "description": "Pinna Brigante Caciotta  cry al peperoncino all'etto",
                            "grammage": 100,
                            "price": "0.89",
                            "price_label": "€ 0,89 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 51.58700412601433,
                                "left": 66.08664782729319,
                                "width": 31.61741399232764,
                                "height": 21.708594073823864
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Brigante_al_peperoncino_1499243082.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Pinna_Brigante_Caciotta_cry_al_peperoncino_1476434588_1499243132.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_216A03I_9KH6WAl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689901,
                            "field1": "latteria soresina",
                            "field2": "provolone",
                            "field3": "dolce",
                            "field4": "all'etto",
                            "description": "latteria soresina provolone dolce all'etto",
                            "grammage": 100,
                            "price": "0.89",
                            "price_label": "€ 0,89 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 73.59663701436384,
                                "left": 2.634138997327069,
                                "width": 31.61741399232764,
                                "height": 19.35536288450347
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/233106_Provolone_Dolce_11_kg_Orizzontale_1432713115.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/soresina_provolone_salame_dolce_e_piccante_1464281570.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_agVbn8p_Et77vVh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689900,
                            "field1": "lindenberger",
                            "field2": "emmental",
                            "field3": "all'etto",
                            "field4": "",
                            "description": "lindenberger emmental all'etto",
                            "grammage": 100,
                            "price": "0.79",
                            "price_label": "€ 0,79 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 73.9474410192182,
                                "left": 34.25676101492504,
                                "width": 31.61741399232764,
                                "height": 19.35536288450347
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Kraft_lindenberger_blocco_2_1429634222_1444647551.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/LINDENBERGER_blocchi_1459863263.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/LINDENBERGER_blocchi_1459863263_1507045858_1507293967_1534755464.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_YQeAHKY_WVsDO4J.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689902,
                            "field1": "brie ",
                            "field2": "president  ",
                            "field3": "all'etto",
                            "field4": "",
                            "description": "brie  president   all'etto",
                            "grammage": 100,
                            "price": "1.09",
                            "price_label": "€ 1,09 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 73.54512255238973,
                                "left": 65.84588117992007,
                                "width": 31.61741399232764,
                                "height": 19.35536288450347
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/brie__president_1480932801.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_aEtG0uN_p5ge1Me.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 8,
                    "image_file": "/media/interactive_flyer_4/pages/page_8.jpg",
                    "products": [
                        {
                            "item_id": 689905,
                            "field1": "fini gli autentici",
                            "field2": "pasta fresca ripiena",
                            "field3": "vari tipi",
                            "field4": "250 gr",
                            "description": "fini gli autentici pasta fresca ripiena vari tipi 250 gr",
                            "grammage": 250,
                            "price": "1.15",
                            "price_label": "€ 1,15",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Funghi porcini",
                                "Prosciutto crudo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 9.390801982200648,
                                "left": 1.0292253218642333,
                                "width": 48.71616059835343,
                                "height": 20.313448118932037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Tortellini_al_crudo_1535615713.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Tortelloni_funghi_porcini_1535615714.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_dkoyz3t_XsI51DK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689904,
                            "field1": "buitoni\t",
                            "field2": "base torta",
                            "field3": "margherita/cioccolato",
                            "field4": "600 gr",
                            "description": "buitoni\t base torta margherita/cioccolato 600 gr",
                            "grammage": 600,
                            "price": "2.25",
                            "price_label": "€ 2,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Cioccolato",
                                "Margherita"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 9.583902204692556,
                                "left": 50.10720592832941,
                                "width": 48.71616059835343,
                                "height": 20.313448118932037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/buitoni_impasto_torta_cioccolato_1489138027.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/buitoni_impasto_torta_margherita_1489138028.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_8YzpTrl_ipuMrYZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689779,
                            "field1": "negroni",
                            "field2": "cubetti di pancetta",
                            "field3": "dolce/affumicata",
                            "field4": "200 gr",
                            "description": "negroni cubetti di pancetta dolce/affumicata 200 gr",
                            "grammage": 200,
                            "price": "1.85",
                            "price_label": "€ 1,85",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Affumicata",
                                "Dolce"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 30.446943264563107,
                                "left": 1.181051977095603,
                                "width": 33.20390946045667,
                                "height": 15.33424352750809
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/18662086_NEGRONI_CUBETTI_PANCETTA_AFFUMICATA_2X100_1548430098.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/18666015_NEGRONI_CUBETTI_PANCETTA_DOLCE_2X100_1548430099.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_xllvQQa_rSxJQSr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689909,
                            "field1": "galbani/president",
                            "field2": "formaggio a fette",
                            "field3": "vari gusti e grammature",
                            "field4": "",
                            "description": "galbani/president formaggio a fette vari gusti e grammature",
                            "grammage": 0,
                            "price": "1.25",
                            "price_label": "€ 1,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 30.424188410194173,
                                "left": 34.1634509289068,
                                "width": 31.738315081920113,
                                "height": 15.04127477750809
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/galbanino_fette_120_g_1506518843_1508944630.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/galbani_belpaese_fette_120g_1507294850_1508944631.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_WrYHm95_s2ukV5v.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689776,
                            "field1": "Nonno Nanni",
                            "field2": "Fior Di Stracchino/ spalmabile",
                            "field3": "150 gr",
                            "field4": "",
                            "description": "Nonno Nanni Fior Di Stracchino/ spalmabile 150 gr",
                            "grammage": 150,
                            "price": "1.35",
                            "price_label": "€ 1,35",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fior di stracchino",
                                "Spalmabile"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 30.45887928792625,
                                "left": 65.98379790995969,
                                "width": 31.947574364650844,
                                "height": 15.17099799777209
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Nonno_Nanni_Fior_di_Stracchino_150g-1_1462963783.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Nonno_Nanni_Il_fresco_Spalmabile_150_gr_1472807847_15064985_qdmf5zA.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_K7osZa7_NGrVwoj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689908,
                            "field1": "galbani",
                            "field2": "bel paese ",
                            "field3": "250 gr",
                            "field4": "",
                            "description": "galbani bel paese  250 gr",
                            "grammage": 250,
                            "price": "2.45",
                            "price_label": "€ 2,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 45.67302775070942,
                                "left": 2.1369715046994573,
                                "width": 31.947574364650844,
                                "height": 15.17099799777209
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/4341218_galbani_bel_paese_1497516643_1510590945.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_atFSl5k_uxgmMPP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK WEB - Scheda prodotto Bel Paese 250g",
                                    "data": "https://www.galbani.it/prodotti/bel-paese/bel-paese-250g"
                                }
                            ]
                        },
                        {
                            "item_id": 689775,
                            "field1": "sottilette",
                            "field2": "light/ fila e fondi",
                            "field3": "200 gr",
                            "field4": "",
                            "description": "sottilette light/ fila e fondi 200 gr",
                            "grammage": 0,
                            "price": "1.25",
                            "price_label": "€ 1,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fila e fondi",
                                "Light"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 45.76941984213337,
                                "left": 34.077191084973286,
                                "width": 31.27887738669618,
                                "height": 15.17099799777209
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Sottilette_Light_1440747896.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/sottilette-fila-e-fondi-200-gr-_1568711782.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_8wPrqCV_k7qVoRb.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689777,
                            "field1": "Viva la Mamma",
                            "field2": "Tramezzini",
                            "field3": "Vari Tipi",
                            "field4": "140 gr",
                            "description": "Viva la Mamma Tramezzini Vari Tipi 140 gr",
                            "grammage": null,
                            "price": "1.25",
                            "price_label": "€ 1,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Prosciutto cotto",
                                "Tonno"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 45.76215093032107,
                                "left": 65.95487387372164,
                                "width": 31.27887738669618,
                                "height": 15.17099799777209
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/viva_la_mamma_cotto_e_provola_1468604291.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/viva_la_mamma_prosciutto_cotto_1468604320.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/viva_la_mamma_tonno_1468604335.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_rYZsDWN_bV0Xhg9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689778,
                            "field1": "jocca ",
                            "field2": "175 gr",
                            "field3": "",
                            "field4": "",
                            "description": "jocca  175 gr",
                            "grammage": 175,
                            "price": "1.25",
                            "price_label": "€ 1,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 61.057521581615575,
                                "left": 1.4638076130644067,
                                "width": 32.67255445497844,
                                "height": 15.17099799777209
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/652240_JOCCA_175G_36PZ_1524466298.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_AZmeJcD_6vSS2nN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689907,
                            "field1": "galbani ",
                            "field2": "santa lucia ",
                            "field3": "ricotta",
                            "field4": "450 gr",
                            "description": "galbani  santa lucia  ricotta 450 gr",
                            "grammage": 450,
                            "price": "1.95",
                            "price_label": "€ 1,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 61.279381411712656,
                                "left": 33.99455318188604,
                                "width": 31.811780188766928,
                                "height": 15.155512055215457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/RICOTTA_SL_GR.450_1549535451.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_yJBQQph_0V2jpVj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689906,
                            "field1": "milka",
                            "field2": "choco snack",
                            "field3": "32 gr x 4",
                            "field4": "",
                            "description": "milka choco snack 32 gr x 4",
                            "grammage": 128,
                            "price": "1.65",
                            "price_label": "€ 1,65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Snack",
                            "blueprint": {
                                "top": 61.104295448929484,
                                "left": 65.9535337996175,
                                "width": 31.811780188766928,
                                "height": 15.155512055215457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/MilkaChocoSnack_1551867816_1555403432_1566212690.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_a1nrpMc_bJbdNZO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK WEB - Pagina Choco Snack Milka",
                                    "data": "https://chocosnack.it/"
                                }
                            ]
                        },
                        {
                            "item_id": 689773,
                            "field1": "fage",
                            "field2": "fruyo 0%",
                            "field3": "linea",
                            "field4": "170 gr",
                            "description": "fage fruyo 0% linea 170 gr",
                            "grammage": 170,
                            "price": "0.95",
                            "price_label": "€ 0,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Ananas",
                                "Mango"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 77.12308084536961,
                                "left": 5.42596004757197,
                                "width": 44.51925622674564,
                                "height": 20.72887117738374
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/fage_fruyo_ananas_0_1461753127.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/fage_fruyo_mango_0_1461753119_1523354701.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_vVtGel0_YvCqeSf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689774,
                            "field1": "milk",
                            "field2": "kefir da bere",
                            "field3": "bianco naturale/multifrutti",
                            "field4": "480 gr",
                            "description": "milk kefir da bere bianco naturale/multifrutti 480 gr",
                            "grammage": 480,
                            "price": "0.75",
                            "price_label": "€ 0,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bianco naturale",
                                "Multifrutti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 76.96379686478708,
                                "left": 50.27019986776175,
                                "width": 48.42691231435243,
                                "height": 20.959264077869175
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Milk_Kefir_da_Bere_Bianco_Naturale_480g_1498048438_1548091196.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_3NNST13_ywJ3OCp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 9,
                    "image_file": "/media/interactive_flyer_4/pages/page_9.jpg",
                    "products": [
                        {
                            "item_id": 689781,
                            "field1": "granarolo",
                            "field2": "panna da cucina",
                            "field3": "uht",
                            "field4": "200 ml",
                            "description": "granarolo panna da cucina uht 200 ml",
                            "grammage": 200,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 8.933103094560556,
                                "left": 1.4280723036212655,
                                "width": 48.42691231435243,
                                "height": 20.959264077869175
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/15516_granarolo_panna_da_cucina_1494495132_1497966520.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_jlrBxxD_Z5HqMs5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689911,
                            "field1": "granarolo",
                            "field2": "besciamella",
                            "field3": "200 ml",
                            "field4": "",
                            "description": "granarolo besciamella 200 ml",
                            "grammage": 200,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 8.791201294398745,
                                "left": 49.839589388971966,
                                "width": 48.42691231435243,
                                "height": 20.959264077869175
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/besciamella-uht-granarolo-250ml_1553161638.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_ajuSIFv_4MVHRlq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Lasagnetta con salmone fresco e sogliola",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_4/images/Lasagnetta.png"
                                        ],
                                        "ingredients": "Pasta: 250 g di farina di grano duro, 1/2 uovo extra fresco Granarolo, Ripieno: 400 g di besciamella Granarolo, 500 g di salmone fresco ,400 g di filetti di sogliole, 90 g di porro, 1/2 bicchiere di vino bianco, qb sale, pepe rosa ,qb olio extravergine d''oliva, 3 cucchiai di Parmigiano Reggiano grattugiato, 2 ciuffi di aneto",
                                        "recipe": "Pasta: impastate la sfoglia con farina, il mezzo uovo sbattuto e 150 g circa di acqua. Ponete la pasta a riposare in frigo per 1 ora. Nel frattempo ponete la fetta di salmone spellata e spinata in congelatore per 30 minuti. In una casseruola bassa e larga cuocete per 10 minuti in olio il porro tritato. Tagliate a fette sottilissime il salmone leggermente congelato e trasferitele in padella con il porro già rosolato. Cuocete a fiamma moderata e a tegame coperto per 2 minuti, poi aggiungete i filetti di sogliole e dopo 2 minuti sfumate con il vino salando e aromatizzando con il pepe rosa pestato. Una volta terminata la cottura allontanate il tegame dalla fiamma. Versate la besciamella in una terrina e aromatizzatela con il formaggio e un pizzico di pepe rosa. Prendete la pasta precedentemente riposta in frigo, stendetela e dividetela in quadrati da lasagne. Lessate i quadrati di pasta in acqua bollente salata, scolateli, raffreddateli con acqua fredda e tamponateli con un canovaccio. Stendete un primo strato di pasta in una teglia da forno da 20×30 cm precedentemente imburrata. Ricoprite con besciamella e successivamente distribuite un poco di pesce e alcuni ciuffi di aneto. Procedete in modo analogo fino ad esaurire gli ingredienti. Ponete in forno a gratinare a 200° per 20 minuti.(Fonte: Granarolo.it)"
                                    }

                                }
                            ]
                        },
                        {
                            "item_id": 689913,
                            "field1": "Parmalat",
                            "field2": "Panna agli aromi",
                            "field3": "Vari Tipi",
                            "field4": "125 ml x 2",
                            "description": "Parmalat Panna agli aromi Vari Tipi 125 ml x 2",
                            "grammage": 250,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Funghi",
                                "Salmone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 30.39029868517544,
                                "left": 1.2489490650375525,
                                "width": 32.54301395824707,
                                "height": 15.162464927383741
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/200_GR_PANNA_CHEF_AI_FUNGHI_1489663635.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/200_GR_PANNA_CHEF_AL_SALMONE_1489663636.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_6cCvxux_atoNn0B.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689995,
                            "field1": "muller",
                            "field2": "yogurt cremoso alla frutta",
                            "field3": "vari gusti",
                            "field4": "500 gr",
                            "description": "muller yogurt cremoso alla frutta vari gusti 500 gr",
                            "grammage": 500,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Ciliegia",
                                "Fragola"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 30.480369983719132,
                                "left": 34.0410090841621,
                                "width": 31.819820633391632,
                                "height": 15.162464927383741
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/16118653_Miller_Yogurt_Cocco_500g_1528818542.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/16118239_Miller_Yogurt_Fragola_500g_1528818543.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Muller_CILIEGIA_1563806074.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_sjZQSMJ_vPQt8KM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689784,
                            "field1": "Parmalat Zymil",
                            "field2": "Yogurt alla Greca",
                            "field3": "Vari gusti",
                            "field4": "150 gr",
                            "description": "Parmalat Zymil Yogurt alla Greca Vari gusti 150 gr",
                            "grammage": 150,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 30.503440877732075,
                                "left": 65.97899520759573,
                                "width": 31.819820633391632,
                                "height": 15.162464927383741
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/zymil_parmalat_alla_greca_mirtillo_1464086603.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_TXmU3bE_qqoM6lv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689912,
                            "field1": "granarolo",
                            "field2": "yogurt gusto di una volta",
                            "field3": "bianco/caffè/fragola",
                            "field4": "170 gr",
                            "description": "granarolo yogurt gusto di una volta bianco/caffè/fragola 170 gr",
                            "grammage": 170,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bianco",
                                "Caffè",
                                "Fragola"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 45.824726779835636,
                                "left": 2.005197551127898,
                                "width": 31.819820633391632,
                                "height": 15.162464927383741
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/6001899-ye6_GranaroloGustodiunavolta170grBianco_1546019767.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/6001900-ye6_GranaroloFragola170gr_1546019767.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/6001902-ye6_GranaroloCaffe170gr_1546019769.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_qyEqpmh_Zlcgd6M.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689785,
                            "field1": "Vipiteno",
                            "field2": "yogurt",
                            "field3": "fitline",
                            "field4": "125 gr x 8",
                            "description": "Vipiteno yogurt fitline 125 gr x 8",
                            "grammage": 0,
                            "price": "2.19",
                            "price_label": "€ 2,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 45.80481628226283,
                                "left": 33.957031106970746,
                                "width": 31.819820633391632,
                                "height": 15.162464927383741
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Vipiteno_Yogurt_FitLine_1488887034.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_XsPfZGy_T8G6qOM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689783,
                            "field1": "DANONE",
                            "field2": "yogoloso  ",
                            "field3": "Vari tipi",
                            "field4": "110 gr x 2",
                            "description": "DANONE yogoloso   Vari tipi 110 gr x 2",
                            "grammage": 220,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 45.82219846268354,
                                "left": 66.44757445266885,
                                "width": 31.819820633391632,
                                "height": 15.162464927383741
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/danone_yogoloso_supermario_banana_x2_1436977426.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/1038553_Supermario_DANONE_Yogoloso_x2_Fragola_1467104247.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_KCkN6uP_OlJA81N.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689782,
                            "field1": "Cameo",
                            "field2": "Torte Versa e Inforna",
                            "field3": "vari tipi 650 gr",
                            "field4": "",
                            "description": "Cameo Torte Versa e Inforna vari tipi 650 gr",
                            "grammage": 650,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Soffice cioccolato",
                                "Soffice paradiso"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 61.12515406543434,
                                "left": 2.144565257956123,
                                "width": 31.819820633391632,
                                "height": 15.162464927383741
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/449043_Cameo_Le_Soffici_Margherita_1447953903_1504775228.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Cameo_Le_Soffici_cioccolato_1504775228.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Cameo_Le_Soffici_Paradiso_1504775228.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_RaKMSb2_4dXss7c.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689914,
                            "field1": "parmalat",
                            "field2": "weight watchers",
                            "field3": "latte uht scremato ",
                            "field4": "500 ml",
                            "description": "parmalat weight watchers latte uht scremato  500 ml",
                            "grammage": 500,
                            "price": "0.50",
                            "price_label": "€ 0,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Latte",
                            "blueprint": {
                                "top": 61.06321029520781,
                                "left": 34.15982898806053,
                                "width": 31.480335193681857,
                                "height": 15.162464927383741
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/parmalat_weight_watchers_500_ml_1503656548.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_udjzo1W_Of0IoFM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK WEB - Scheda nutrizionale parmalat weight watchers",
                                    "data": "https://www.parmalat.it/weight-watchers/prodotti/latte/latte-weight-watchers/"
                                }
                            ]
                        },
                        {
                            "item_id": 689915,
                            "field1": "alpro ",
                            "field2": "soya drink calcio",
                            "field3": "1 lt",
                            "field4": "",
                            "description": "alpro  soya drink calcio 1 lt",
                            "grammage": null,
                            "price": "1.85",
                            "price_label": "€ 1,85",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Latte",
                            "blueprint": {
                                "top": 61.18931011316897,
                                "left": 66.09692172875808,
                                "width": 31.480335193681857,
                                "height": 15.162464927383741
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Alpro_SOYA_1500560747.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_GnG8k3R_yAnYw93.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689780,
                            "field1": "granarolo",
                            "field2": "latte uht",
                            "field3": "parzialmente scremato",
                            "field4": "1 lt",
                            "description": "granarolo latte uht parzialmente scremato 1 lt",
                            "grammage": 0,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Latte",
                            "blueprint": {
                                "top": 76.60445978954438,
                                "left": 3.758907862049735,
                                "width": 46.07374218752201,
                                "height": 21.648230501817398
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Granarolo_latte_scremato_1_lt_1472647889.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_OWIqMzn_pRI6E8B.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689910,
                            "field1": "granarolo ",
                            "field2": "yogurt alta qualità",
                            "field3": "vari gusti",
                            "field4": "125 gr x 2",
                            "description": "granarolo  yogurt alta qualità vari gusti 125 gr x 2",
                            "grammage": 750,
                            "price": "2.00",
                            "price_label": "€ 2,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 76.68663009698776,
                                "left": 50.288067522483324,
                                "width": 47.45223174929093,
                                "height": 21.648230501817398
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/granarolo_yogurt_alla_fragola_1465467883.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/granarolo_yogurt_bianco_1465467900.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_qXNSobW_7nciBLc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 10,
                    "image_file": "/media/interactive_flyer_4/pages/page_10.jpg",
                    "products": []
                },
                {
                    "number": 11,
                    "image_file": "/media/interactive_flyer_4/pages/page_11.jpg",
                    "products": []
                },
                {
                    "number": 12,
                    "image_file": "/media/interactive_flyer_4/pages/page_12.jpg",
                    "products": [
                        {
                            "item_id": 689807,
                            "field1": "fruttolo",
                            "field2": "mono",
                            "field3": "fragola/banana e fragola",
                            "field4": "200 gr",
                            "description": "fruttolo mono fragola/banana e fragola 200 gr",
                            "grammage": 200,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Banana e fragola",
                                "Fragola"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 8.658464643913316,
                                "left": 3.913909766759332,
                                "width": 22.888226729448153,
                                "height": 22.047704611849763
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/331649_FRUTTOLO_MONO_FRAGOLA_E_BANANA_50X4_1525352987.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Fruttolo_Mono_4x50g_Fragola_1520856571_1521824776_152395131_jrvVVjz.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_4VlMzXc_YQ03ZFq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689808,
                            "field1": "nestlè mio ",
                            "field2": "yogurt  ",
                            "field3": "vari gusti ",
                            "field4": "125 gr x 2",
                            "description": "nestlè mio  yogurt   vari gusti  125 gr x 2",
                            "grammage": 250,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 31.062515007990985,
                                "left": 4.0322829792897155,
                                "width": 14.969282156849497,
                                "height": 21.42542255278827
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Mio_yogurt_albicocca_1521805547.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Mio_yogurt_banana_1521805547.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_bj5S8Oc_69IZ9QK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689810,
                            "field1": "Perugina",
                            "field2": "lingotto",
                            "field3": "cioccolato/fiordilatte",
                            "field4": "90 gr",
                            "description": "Perugina lingotto cioccolato/fiordilatte 90 gr",
                            "grammage": 90,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Cioccolato",
                                "Fiordilatte"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Snack",
                            "blueprint": {
                                "top": 30.96169836155086,
                                "left": 19.309574457597854,
                                "width": 14.969282156849497,
                                "height": 21.42542255278827
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/3312113_PERUGINA_LINGOTTO_FIORDILATTE_30X4_1520337790.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/3312114_PERUGINA_LINGOTTO_CIOCCOLATO_30X4_1520337790.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_tz6tHxo_mFvAqmL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK WEB - Lingotto Perugina",
                                    "data": "https://www.perugina.com/it/prodotti/dessert-freschi/lingotto"
                                }
                            ]
                        },
                        {
                            "item_id": 689809,
                            "field1": "nesquik ",
                            "field2": "snack",
                            "field3": "latte/cacao",
                            "field4": "26 gr x 5",
                            "description": "nesquik  snack latte/cacao 26 gr x 5",
                            "grammage": 130,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Cacao",
                                " Latte"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Snack",
                            "blueprint": {
                                "top": 30.982240938411696,
                                "left": 34.8012777925648,
                                "width": 14.969282156849497,
                                "height": 21.42542255278827
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/nesquik_snack_al_cacao_x_5_1506519414_1506590276_1519831656.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/nesquik_snack_al_latte_x_5_1506519415_1506590276_1519831656.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_mYeTfrg_fDpOoAQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689935,
                            "field1": "beretta",
                            "field2": "puro",
                            "field3": "prosciutto crudo",
                            "field4": "90 gr",
                            "description": "beretta puro prosciutto crudo 90 gr",
                            "grammage": 90,
                            "price": "2.45",
                            "price_label": "€ 2,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 20.9675766989295,
                                "left": 52.00336237575379,
                                "width": 22.14671905850311,
                                "height": 10.325162136121605
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/beretta_puro_prosciutto_crudo_90_gr_1556872596.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_W0f9GjT_5KC1LW5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689936,
                            "field1": "beretta",
                            "field2": "puro",
                            "field3": "prosciutto cotto/petto di pollo arrosto",
                            "field4": "100 gr",
                            "description": "beretta puro prosciutto cotto/petto di pollo arrosto 100 gr",
                            "grammage": 100,
                            "price": "2.25",
                            "price_label": "€ 2,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Petto di pollo arrosto",
                                "Prosciutto cotto"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 20.89773193760264,
                                "left": 74.75603389819767,
                                "width": 22.14671905850311,
                                "height": 10.325162136121605
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/0079502_1531991278_1552559330.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Beretta_PURO_POLLO_1545404491_1552559330.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_k4icBQz_nOPbbu6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689937,
                            "field1": "beretta",
                            "field2": "salamini",
                            "field3": "LINEA",
                            "field4": "85 gr",
                            "description": "beretta salamini LINEA 85 gr",
                            "grammage": null,
                            "price": "1.75",
                            "price_label": "€ 1,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 31.38835188096833,
                                "left": 51.42355698003892,
                                "width": 22.14671905850311,
                                "height": 10.325162136121605
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/beretta_salamini_affumicati_gr_85__199_1441015972.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Beretta_Salamini_Piccanti_flash_199_g_85_4_1441016077.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Beretta_Salamini_Classici_flash_199_g_85_6_1441016086.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_SJch6p6_yp1W7NJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689938,
                            "field1": "beretta ",
                            "field2": "i paciocotti  ",
                            "field3": "Linea",
                            "field4": "150 gr",
                            "description": "beretta  i paciocotti   Linea 150 gr",
                            "grammage": 150,
                            "price": "1.75",
                            "price_label": "€ 1,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 31.33114870540199,
                                "left": 74.463451052132,
                                "width": 22.14671905850311,
                                "height": 10.325162136121605
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Beretta_Paciocotti_classici_150_gr_1427133125.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/beretta_paciocotti_tacchino_150_gr_1426001185_1458034482.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/beretta_paciocotti_baby_150_g_1461683559.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_4IHsWQH_Lc4vhaI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689939,
                            "field1": "Beretta",
                            "field2": "Prosciutto Cotto Per Toast",
                            "field3": "400 gr",
                            "field4": "",
                            "description": "Beretta Prosciutto Cotto Per Toast 400 gr",
                            "grammage": 400,
                            "price": "3.45",
                            "price_label": "€ 3,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 41.9295381673761,
                                "left": 51.921617855402616,
                                "width": 22.14671905850311,
                                "height": 10.325162136121605
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Prosciutto_Cotto_per_Toast_g_400_1446571485.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_bzeNd8f_08eSCMW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689940,
                            "field1": "beretta ",
                            "field2": "cubetti di prosciutto cotto ",
                            "field3": "120 gr/",
                            "field4": "cotto julienne 90 gr",
                            "description": "beretta  cubetti di prosciutto cotto  120 gr/ cotto julienne 90 gr",
                            "grammage": 0,
                            "price": "1.35",
                            "price_label": "€ 1,35",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 41.9880055015185,
                                "left": 74.59701177117572,
                                "width": 22.14671905850311,
                                "height": 10.325162136121605
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Il_cotto_cubetti_beretta_120g_1446573932.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Utz2OKO_121oHgh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689806,
                            "field1": "fuze",
                            "field2": "tea",
                            "field3": "vari gusti",
                            "field4": "40 cl",
                            "description": "fuze tea vari gusti 40 cl",
                            "grammage": 400,
                            "price": "0.50",
                            "price_label": "€ 0,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 53.54209884698774,
                                "left": 3.7995567765413014,
                                "width": 45.75167771116576,
                                "height": 24.638597613467883
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/fuzetea_040_1522416830.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_JC196ch_BgA2PNK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689934,
                            "field1": "coca cola/fanta/sprite",
                            "field2": "vari tipi",
                            "field3": "66 cl",
                            "field4": "",
                            "description": "coca cola/fanta/sprite vari tipi 66 cl",
                            "grammage": 660,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Coca Cola",
                                "Fanta",
                                "Sprite"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 53.694113915757974,
                                "left": 52.046244747085545,
                                "width": 44.69971953943348,
                                "height": 43.59339130278828
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/fanta_zero_660_ml_1524048589_1524493108.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Cocacola_660ml_promo_campania7f_1536659347.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_h9LuacP_QgOqaKJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 13,
                    "image_file": "/media/interactive_flyer_4/pages/page_13.jpg",
                    "products": [
                        {
                            "item_id": 689815,
                            "field1": "Mulino Bianco",
                            "field2": "pan carrè integrale",
                            "field3": "16 fette 315 gr",
                            "field4": "",
                            "description": "Mulino Bianco pan carrè integrale 16 fette 315 gr",
                            "grammage": 315,
                            "price": "0.75",
                            "price_label": "€ 0,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 17.523571598817583,
                                "left": 25.550912058840282,
                                "width": 22.46609921196544,
                                "height": 13.83209161544895
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Pancarre_integrale_16_fette_sx_1558104633_1561047148_156320_EIPrC3e.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_LMs7XhS_r1IAKo3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689818,
                            "field1": "Mulino Bianco",
                            "field2": "Piadelle",
                            "field3": "300 gr",
                            "field4": "",
                            "description": "Mulino Bianco Piadelle 300 gr",
                            "grammage": 300,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 31.405361902555903,
                                "left": 2.8180619440174226,
                                "width": 22.46609921196544,
                                "height": 10.559542452676364
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/mulino_bianco_Piadelle_classiche_4pz_1531132240_1533143555__Ba5S4zh.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_pNQAYsf_lN9KZyS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689817,
                            "field1": "mulino bianco",
                            "field2": "biscotti",
                            "field3": "primizie/molinetti",
                            "field4": "700 gr",
                            "description": "mulino bianco biscotti primizie/molinetti 700 gr",
                            "grammage": 700,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Primizie",
                                "Molinetti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 31.411750629191413,
                                "left": 25.67216997917735,
                                "width": 22.46609921196544,
                                "height": 10.559542452676364
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Molinetti_800g_sx_1557825816_1567415487.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Primizie_700g_sx_1557825817_1567415488.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_AB1maPK_UEQ01W6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689819,
                            "field1": "mulino bianco",
                            "field2": "pane integrale/cereali",
                            "field3": "400 gr",
                            "field4": "",
                            "description": "mulino bianco pane integrale/cereali 400 gr",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Integrale",
                                "Cereali"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 17.44356040333472,
                                "left": 2.8331116859032317,
                                "width": 22.46609921196544,
                                "height": 13.892632405947392
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Pan_bauletto_integrale_1556640175_3Qtdk2v.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Pan_bauletto_cereali_soia_1530781683_1568281112_UUYFF4j.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_s6oZfbp_zkaKTta.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689820,
                            "field1": "mulino bianco ",
                            "field2": "10 ",
                            "field3": "gocce di cioccolato",
                            "field4": "350 gr",
                            "description": "mulino bianco  10 plumcake gocce di cioccolato 350 gr",
                            "grammage": null,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 42.0450255513098,
                                "left": 2.778072629863712,
                                "width": 22.46609921196544,
                                "height": 10.339587721367954
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Plumcake_cioccolato_10pz_sx_1528216882_1528366179_1531236109.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_kD8gnuk_VcjD2Km.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK WEB - Plumcake Mulino Bianco",
                                    "data": "https://www.mulinobianco.it/plumcake-gocce-di-cioccolato"
                                }
                            ]
                        },
                        {
                            "item_id": 689816,
                            "field1": "Mulino Bianco",
                            "field2": "Biscotti Classici",
                            "field3": "girotondi tarallucci spicchi macine galletti molinetti",
                            "field4": "350 gr",
                            "description": "Mulino Bianco Biscotti Classici girotondi tarallucci spicchi macine galletti molinetti 350 gr",
                            "grammage": 350,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classici",
                                "Girotondi",
                                "Tarallucci",
                                "Spicchi",
                                "Macine",
                                "Galletti",
                                "Molinetti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 42.1907493636151,
                                "left": 25.651960325787844,
                                "width": 22.46609921196544,
                                "height": 10.339587721367954
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Galletti_350g_sx2_1506522795_1506603307_1509028965_15099685_1oHGnqK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Macine_350g_sx2_1506522793_1506603308_1509028966_1509968593_Otv5jAD.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Tarallucci_350g_sx2_1509004264_1509028967_1509968594_1568280962.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_vEWiJZC_irxWxtA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689946,
                            "field1": "lindt",
                            "field2": "tavoletta excellence",
                            "field3": "vari gusti fondente",
                            "field4": "100 gr",
                            "description": "lindt tavoletta excellence vari gusti fondente 100 gr",
                            "grammage": 100,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "70%",
                                "78%",
                                "85%"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 8.71625559414469,
                                "left": 50.14305028549817,
                                "width": 45.827598544989684,
                                "height": 23.479981510308768
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/312840_TAV._EXCELLENCE_70_1568630073.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/312841_TAV._EXCELLENCE_85_1568630074.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/392973_TAV._EXCELLENCE_78__1568630075.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_hKek0po_7fRhE39.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689944,
                            "field1": "lindt",
                            "field2": "tavolette",
                            "field3": "latte/fondente",
                            "field4": "100 gr",
                            "description": "lindt tavolette latte/fondente 100 gr",
                            "grammage": null,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Latte ",
                                "Fondente"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 32.423299013147805,
                                "left": 50.3412768857655,
                                "width": 45.827598544989684,
                                "height": 10.443936923081347
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Lind_AV_GB_LATTE_1442324604.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_uCoOtN2_vZSLTfb.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITÀ - La storia del cacao",
                                    "data": {

                                        "titolo": "Breve storia sul cacao",
                                        "testo": "l primo europeo a scoprire il cacao fu Cristoforo Colombo. Nel 1502, durante il suo quarto viaggio nel Nuovo Mondo, assaggiò la bevanda al cacao e la trovò troppo amara e speziata. Alcuni anni più tardi, precisamente nel 1528, Hernando Cortés portò in Spagna l’esotica bevanda e la ricetta per prepararla. Gli Spagnoli aggiunsero zucchero e altri ingredienti a questa bevanda energizzante che chiamarono cioccolata. La cioccolata divenne presto una vera prelibatezza e fu consumata alla Corte di Spagna per circa un secolo. Nel 1615, quando la principessa di Spagna Anna sposò il Re di Francia Luigi XIII, la cioccolata fu portata in Francia e da lì si diffuse in tutte le altre Corti reali e nell’alta società d’Europa. Ancora ai tempi della Rivoluzione Industriale la cioccolata – sempre in forma di bevanda calda – era un privilegio da ricchi. Venne poi l’era dei pionieri del cioccolato in Italia, Belgio, Germania, Olanda e, naturalmente, in Svizzera; persone straordinarie che seppero trasformare in realtà le loro ambizioni e le loro incredibili visioni. Fu la loro abilità a permettere di dare una nuova forma alla bevanda, creando il cioccolato solido e soprattutto rendendolo disponibile a tutti. Grandi menti hanno dato un notevole contributo all’evoluzione del cioccolato, ma la più rivoluzionaria delle scoperte è certamente il “concaggio”, inventato dal pasticcere svizzero Rodolphe Lindt nel 1879. Grazie a questo procedimento, ciò che prima era una massa sfaldabile, sabbiosa e piuttosto amara iniziò a sciogliersi nelle bocche di appassionati di tutto il mondo e si elevò a sublime piacere.(Fonte:Lindt.it)",
                                        "img": [
                                            "/media/interactive_flyer_4/images/cacao.png"
                                        ]
                                    }

                                }
                            ]
                        },
                        {
                            "item_id": 689945,
                            "field1": "lindt",
                            "field2": "tavolette gusti golosi",
                            "field3": "extra fondente/bianco/extra cacao",
                            "field4": "100 gr",
                            "description": "lindt tavolette gusti golosi extra fondente/bianco/extra cacao 100 gr",
                            "grammage": 100,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Extra Fondente",
                                "Bianco",
                                "Extra Cacao"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 43.18282734258705,
                                "left": 49.89838448169749,
                                "width": 45.827598544989684,
                                "height": 9.210912682427143
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/lindt_tavoletta_bianco_ricetta_originale_1474295120_1481708180.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/lindt_tavoletta_extra_fondente_1474295194_1481708181.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/lindt_TAV_LATTE_EXTRA_CACAO_1510072614.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_4gkWZSS_QEw2cfs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689813,
                            "field1": "huggies unistar",
                            "field2": "pannolini ",
                            "field3": "varie misure",
                            "field4": "",
                            "description": "huggies unistar pannolini  varie misure",
                            "grammage": null,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 67.86856283713533,
                                "left": 2.299060845269768,
                                "width": 25.57752584869953,
                                "height": 24.015417647380417
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Huggies_Pannolini_TG__4_1470753767_1471619996.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Huggies_Pannolini_TG_3_1470753776_1471620004.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Huggies_Pannolini_TG_5_1470753785_1471620018.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_3gnPtwg_5sbMM6P.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689814,
                            "field1": "Huggies Unistar",
                            "field2": "Salviettina Baby",
                            "field3": "x 56",
                            "field4": "",
                            "description": "Huggies Unistar Salviettina Baby x 56",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 76.8651068402506,
                                "left": 22.55386346043831,
                                "width": 25.57752584869953,
                                "height": 17.59231357806577
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/huggies_unistar_56_salviettine_1465403969.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_fQFsjBh_yDsZpPf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689942,
                            "field1": "tempo",
                            "field2": "carta igienica ",
                            "field3": "regular/pelli sensibili",
                            "field4": "4 rotoli",
                            "description": "tempo carta igienica  regular/pelli sensibili 4 rotoli",
                            "grammage": 0,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Regular",
                                "Pelli Sensibili"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 63.205705068443756,
                                "left": 50.32063723975068,
                                "width": 16.272915429643543,
                                "height": 12.976610696445835
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Tempo_Confort_4_max_rotoli_1484044487.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Et2eh4Y_ZZJAlzj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689941,
                            "field1": "tempo",
                            "field2": "fazzoletti",
                            "field3": "classici 12 pacchetti",
                            "field4": "",
                            "description": "tempo fazzoletti classici 12 pacchetti",
                            "grammage": 0,
                            "price": "1.09",
                            "price_label": "€ 1,09",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 76.43128187919142,
                                "left": 50.38986605242538,
                                "width": 16.272915429643543,
                                "height": 8.715938480713747
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/TEMPO_CLASSIC_12x9_1515666073_1516093197_1520596299_1524849_SHKGaX5.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_dTTm2W7_wsEm7tJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689943,
                            "field1": "TEMPO",
                            "field2": "box light",
                            "field3": "60 pz",
                            "field4": "",
                            "description": "TEMPO box light 60 pz",
                            "grammage": 0,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 85.27784291891103,
                                "left": 50.241088604068565,
                                "width": 16.272915429643543,
                                "height": 10.198123060153002
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Tempo_fazzoletti_moschino_1509118756.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/7322540875041_TEMPO_LIGHT_60x12_STYLE_1535723351.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/7322540875041_TEMPO_LIGHT_60x12_NATURE_1535723352.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/7322540875041_TEMPO_LIGHT_60x12_EXPRESSIVE_1535723353.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_WQUmc81_2VhRXaF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 14,
                    "image_file": "/media/interactive_flyer_4/pages/page_14.jpg",
                    "products": [
                        {
                            "item_id": 689821,
                            "field1": "friol",
                            "field2": "olio per friggere",
                            "field3": "1 lt",
                            "field4": "",
                            "description": "friol olio per friggere 1 lt",
                            "grammage": null,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 8.601562739783299,
                                "left": 0.0,
                                "width": 49.53628493827763,
                                "height": 21.380524247847706
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/2378-MI5050-Sleeve-Friol1Lt-NoPalma-ITA_1497438077.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_L83O6jW_2pEPop7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689948,
                            "field1": "mutti ",
                            "field2": "passata di pomodoro",
                            "field3": "bottiglia 700 gr",
                            "field4": "",
                            "description": "mutti  passata di pomodoro bottiglia 700 gr",
                            "grammage": null,
                            "price": "0.75",
                            "price_label": "€ 0,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Rossi",
                            "blueprint": {
                                "top": 8.632897922805107,
                                "left": 50.23452899243282,
                                "width": 49.53628493827763,
                                "height": 21.380524247847706
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/mutti_passata_700_gr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_DSQDfNz_k0NLXVj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "SITO WEB - Sito web ufficiale prodotti Mutti",
                                    "data": "http://www.mutti-parma.com/it/prodotti/le-passate/passata-di-pomodoro"

                                }
                            ]
                        },
                        {
                            "item_id": 689952,
                            "field1": "Barilla",
                            "field2": "Salsa Pronta",
                            "field3": "Vari Tipi",
                            "field4": "",
                            "description": "Barilla Salsa Pronta Vari Tipi",
                            "grammage": null,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Rossi",
                            "blueprint": {
                                "top": 30.31654034881757,
                                "left": 0.0,
                                "width": 33.81274460861266,
                                "height": 15.270467783673249
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Barilla_Salsa_pronta_classica_1465483579.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/barilla_Salsa_pronta_origano_1465483586.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/barilla_Salsa_pronta_peperoncino_1465483596.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_sUyFMqx_vxLfOVC.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689823,
                            "field1": "desantis",
                            "field2": "olio di oliva",
                            "field3": "1 lt",
                            "field4": "",
                            "description": "desantis olio di oliva 1 lt",
                            "grammage": null,
                            "price": "2.89",
                            "price_label": "€ 2,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 30.28155446486118,
                                "left": 34.263962347727535,
                                "width": 31.410805803638,
                                "height": 15.270467783673249
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/CondiOliva_1479378606.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_aF6tO4v_YlZ1pTu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689951,
                            "field1": "d'amico",
                            "field2": "pesto alla genovese",
                            "field3": "con/senza aglio",
                            "field4": "130 gr",
                            "description": "d'amico pesto alla genovese con/senza aglio 130 gr",
                            "grammage": 130,
                            "price": "1.09",
                            "price_label": "€ 1,09",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 30.455571209409474,
                                "left": 66.6873368146214,
                                "width": 31.410805803638,
                                "height": 15.270467783673249
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/damico_pesto_genovese_gr.130_1547736130.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_hFq50Zi_wCGrWZV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689949,
                            "field1": "neri",
                            "field2": "funghi coltivati",
                            "field3": "290 gr",
                            "field4": "",
                            "description": "neri funghi coltivati 290 gr",
                            "grammage": 290,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 45.686599733552775,
                                "left": 2.4629977577685054,
                                "width": 31.410805803638,
                                "height": 15.270467783673249
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/11803_1551462161.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_IZYda9V_22CNCWw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689950,
                            "field1": "iposea",
                            "field2": "melanzane a filetti  in olio di girasole",
                            "field3": "314 gr",
                            "field4": "",
                            "description": "iposea melanzane a filetti  in olio di girasole 314 gr",
                            "grammage": 314,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 45.67656030598267,
                                "left": 34.42563957484363,
                                "width": 31.410805803638,
                                "height": 15.270467783673249
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/iposea_Melanzane_580ml_1427446104.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_kDaRDDK_qtv6NY8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689825,
                            "field1": "rio mare",
                            "field2": "filetti di sgombro",
                            "field3": "grigliati vari tipi",
                            "field4": "120 gr",
                            "description": "rio mare filetti di sgombro grigliati vari tipi 120 gr",
                            "grammage": null,
                            "price": "2.19",
                            "price_label": "€ 2,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 45.83323622109172,
                                "left": 66.4259012984337,
                                "width": 31.410805803638,
                                "height": 15.270467783673249
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/rio_mare_filetti_di_sgombro_1446719236.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_LB42whz_sRE2h4c.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Sgombro grigliato con insalatina ai lamponi e aceto balsamico",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_4/images/sgombro.png"
                                        ],
                                        "ingredients": "4 confezioni di Filetti di Sgombro Grigliati Rio Mare al naturale, 125 g di insalatina mista, 100 g di lamponi, 2 carote tenere, 2 coste di sedano, 30g di gherigli di noce, olio extra vergine di oliva, aceto balsamico, sale",
                                        "recipe": "Pelate le carote e grattugiatele con una grattugia a fori larghi; private dei filamenti le coste di sedano e affettatele sottilmente; spezzettate i gherigli di noce 2. Radunate in una grande ciotola l’insalata, le carote, il sedano, le noci e i lamponi, condite il tutto con l’olio, il sale e l’aceto balsamico quindi girate con delicatezza. Sistemate in quattro piatti i filetti di sgombro privati della confezione, insaporiteli con un goccio di aceto balsamico e completate con l’insalatina preparata.(Fonte: Riomare.it)"
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": 689824,
                            "field1": "mareblu",
                            "field2": "tonno naturale",
                            "field3": "80 gr x 3",
                            "field4": "",
                            "description": "mareblu tonno naturale 80 gr x 3",
                            "grammage": 0,
                            "price": "2.29",
                            "price_label": "€ 2,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 61.10563935582691,
                                "left": 2.164363431292012,
                                "width": 31.410805803638,
                                "height": 15.270467783673249
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/FAMILA_MAXIDI_EAN8001040910016_1_renditions_600x600_1568640256.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_XqtMtuJ_9wCnlV2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689827,
                            "field1": "rio mare ",
                            "field2": "tonno all’olio di oliva",
                            "field3": "120 gr x 3 + 1 gratis",
                            "field4": "",
                            "description": "rio mare  tonno all’olio di oliva 120 gr x 3 + 1 gratis",
                            "grammage": null,
                            "price": "5.49",
                            "price_label": "€ 5,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 60.96539159492348,
                                "left": 34.35017142090238,
                                "width": 31.410805803638,
                                "height": 15.270467783673249
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Rio_Mare_tonno_120_g_x_31.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_u7LyX4l_Dq86CjE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689826,
                            "field1": "delicius",
                            "field2": "filetti di alici all'olio",
                            "field3": "di semi di girasole 58 gr",
                            "field4": "",
                            "description": "delicius filetti di alici all'olio di semi di girasole 58 gr",
                            "grammage": 58,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 61.023802809876756,
                                "left": 67.46949340005803,
                                "width": 31.410805803638,
                                "height": 15.270467783673249
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Delicius_filetti_di_alici_girasole.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Wjz8gN3_ebmUwpV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689822,
                            "field1": "as do mar",
                            "field2": "tonno",
                            "field3": "all'olio di oliva",
                            "field4": "80 gr x 3 + 1 gratis",
                            "description": "as do mar tonno all'olio di oliva 80 gr x 3 + 1 gratis",
                            "grammage": 320,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 77.0865826969484,
                                "left": 0.0,
                                "width": 50.066895837908625,
                                "height": 22.653304861462818
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/as_do_mar_tonno_olio_di_oliva_1558109582_1560497148.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_OMnbFZ5_AQZv6E8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689947,
                            "field1": "valfrutta",
                            "field2": "legumi lessati ",
                            "field3": "fagioli/piselli",
                            "field4": "400 gr x 3",
                            "description": "valfrutta legumi lessati  fagioli/piselli 400 gr x 3",
                            "grammage": 1200,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fagioli",
                                "Piselli"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 76.9624588651727,
                                "left": 49.93310416209138,
                                "width": 50.066895837908625,
                                "height": 22.653304861462818
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Valfrutta_Borlotti_Italiani_1496242683.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Valfrutta_Cannellini_1496242683.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Valfrutta_Piselli_Medi_Italiani_Lavati_Freschi_1494942780_1_y8hnLHy.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_PZoEHTN_LcHbOml.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 15,
                    "image_file": "/media/interactive_flyer_4/pages/page_15.jpg",
                    "products": [
                        {
                            "item_id": 689828,
                            "field1": "de cecco",
                            "field2": "pasta di semola",
                            "field3": "formati classici",
                            "field4": "varie trafile 500 gr",
                            "description": "de cecco pasta di semola formati classici varie trafile 500 gr",
                            "grammage": null,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 8.516683943054328,
                                "left": 0.0,
                                "width": 50.066895837908625,
                                "height": 21.545012902740076
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/DE_CECCO_TORTIGLIONI_500g_1431351623_1444299083.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/DE_CECCO_PENNE_RIGATE_g500_1431351615_1444299093.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_XmDD4YS_SdKwwRe.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689997,
                            "field1": "barilla",
                            "field2": "pasta integrale",
                            "field3": "500 gr",
                            "field4": "",
                            "description": "barilla pasta integrale 500 gr",
                            "grammage": 500,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Farfalle",
                                "Tortiglioni"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 8.709866867509156,
                                "left": 50.24140887443775,
                                "width": 49.436526649206,
                                "height": 21.545012902740076
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Penne_rigate_int_sx_1558532164_1568274101.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/barilla_farfalle_1559656047_1568274102.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_3XT1INM_iRfvfXR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Pennette integrali rigate con zucchine, melanzane e pomodori ciliegia ",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_4/images/pennette-barilla.png"
                                        ],
                                        "ingredients": "350 gdi Pennette integrali, 200 g di Zucchini, 100 g di Melanzane, 100 g di Pomodori ciliegina, 4 Cucchiai di olio extravergine di oliva, Uno spicchio d'aglio, Sale e pepe q.b., Prezzemolo q.b.",
                                        "recipe": "Pelate le melanzane e tagliatele à la julienne con le zucchine, Scottate i pomodorini in una padella con un filo d’olio, aggiungendo un pizzico di sale. In un’altra padella, aromatizzate l’olio con lo spicchio d’aglio, quindi unite le melanzane e le zucchine. Lasciate cuocere per 3 minuti, poi unite i pomodorini, aggiungendo sale e pepe a vostro gusto. Cuocete le Pennette Rigate Barilla Integrale in acqua bollente salata, scolate al dente e unite alle verdure, guarnendo con un trito di prezzemolo fresco.(Fonte: barilla.com)"
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": 689830,
                            "field1": "knorr",
                            "field2": "brodo granulare",
                            "field3": "vari tipi 150 gr",
                            "field4": "",
                            "description": "knorr brodo granulare vari tipi 150 gr",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 30.4202811003752,
                                "left": 0.0,
                                "width": 33.74480577381389,
                                "height": 15.290753751649731
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Knorr_Brodo_Classico_Nuova_1484326469.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Knorr_Brodo_Verdure_Nuova_1484326469.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_VTkV4vN_He4aq3b.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689829,
                            "field1": "scotti ",
                            "field2": "risette ",
                            "field3": "biologiche",
                            "field4": "150 gr",
                            "description": "scotti  risette  biologiche 150 gr",
                            "grammage": 150,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 30.441576855826913,
                                "left": 34.22569300407506,
                                "width": 31.276218111916368,
                                "height": 15.290753751649731
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/58238_Scotti_risette_gallette_di_riso_biologiche_150_gr_1456394121.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_6Mx3i44_uBLkFHW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689955,
                            "field1": "mellin ",
                            "field2": "omogeneizzati alla carne",
                            "field3": "vari gusti 80 gr x 2",
                            "field4": "",
                            "description": "mellin  omogeneizzati alla carne vari gusti 80 gr x 2",
                            "grammage": 160,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Baby Food",
                            "subcategory": "",
                            "blueprint": {
                                "top": 30.87448914165246,
                                "left": 67.10679906880966,
                                "width": 31.276218111916368,
                                "height": 14.890393549157519
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Mellin_omo_pollo_gr_80_x_2_1440589619_1451389309.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Mellin_omo_prosciutto_gr_80_x_2_1440589631_1451389317.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Pe53Wo7_HxGgj3r.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689956,
                            "field1": "mellin ",
                            "field2": "omogeneizzati alla frutta 104 gr x 2",
                            "field3": "vari gusti ",
                            "field4": "",
                            "description": "mellin  omogeneizzati alla frutta 104 gr x 2 vari gusti ",
                            "grammage": null,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Baby Food",
                            "subcategory": "",
                            "blueprint": {
                                "top": 46.256717529502936,
                                "left": 3.114436585111258,
                                "width": 31.276218111916368,
                                "height": 14.890393549157519
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/mellin_omogeneizzato_mela_1444723028_1451388560.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/mellin_omogeneizzato_frutta_mista_1444723007_1451388572.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_jE1U4sI_NQZx0Mo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689958,
                            "field1": "RIGONI",
                            "field2": "confetture/nocciolata bio ",
                            "field3": "vari gusti ",
                            "field4": "250 gr",
                            "description": "RIGONI confetture/nocciolata bio  vari gusti  250 gr",
                            "grammage": 250,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 45.71337154040635,
                                "left": 34.229132945077524,
                                "width": 31.276218111916368,
                                "height": 15.287711500870914
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/temp_BLOB532004745_1514391636_1514882710.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_nCYPJgy_1e2WwJr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689957,
                            "field1": "piana",
                            "field2": "miele millefiori",
                            "field3": "500 gr",
                            "field4": "",
                            "description": "piana miele millefiori 500 gr",
                            "grammage": 500,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 45.681525019470406,
                                "left": 66.05334467327678,
                                "width": 32.744798402511734,
                                "height": 15.2906566394081
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/millefiori_500g_F_nuovo_1551806507.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_3SkwKdg_0gN1wWR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689832,
                            "field1": "nestlè ",
                            "field2": "orzoro solubile ",
                            "field3": "",
                            "field4": "120 gr",
                            "description": "nestlè  orzoro solubile  120 gr",
                            "grammage": 120,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 61.077443535825545,
                                "left": 1.3097850731639877,
                                "width": 32.744798402511734,
                                "height": 15.2906566394081
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/ORZORO_SOLUBILE_GR_120_nestle_1453480150_1535008608.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_PdkkwhL_Cl1qmGK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689998,
                            "field1": "palombini",
                            "field2": "caffè espresso",
                            "field3": "vari gusti",
                            "field4": "18 cialde",
                            "description": "palombini caffè espresso vari gusti 18 cialde",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 61.090525214174455,
                                "left": 34.31644898448875,
                                "width": 31.22305450154408,
                                "height": 15.2906566394081
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/32428_PalombiniCaffeEspresso18_1550664041.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_yBNIDb0_5ssQESa.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 693129,
                            "field1": "l’angelica ",
                            "field2": "le tisane",
                            "field3": "vari tipi",
                            "field4": "20 filtri",
                            "description": "l’angelica  le tisane vari tipi 20 filtri",
                            "grammage": 0,
                            "price": "2.00",
                            "price_label": "€ 2,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 61.09995619158878,
                                "left": 66.10798127316454,
                                "width": 31.22305450154408,
                                "height": 15.2906566394081
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/angelica_linea_1505121819.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/angelica_giusta_regola_1505121819.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_AF2DNj7_kAvEIMU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689953,
                            "field1": "barilla",
                            "field2": "farina tipo \"00\"",
                            "field3": "1 kg",
                            "field4": "",
                            "description": "barilla farina tipo \"00\" 1 kg",
                            "grammage": null,
                            "price": "0.55",
                            "price_label": "€ 0,55",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 76.55246057242991,
                                "left": 0.0,
                                "width": 50.156489779138845,
                                "height": 23.447235202492212
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Barilla_Farina_00_1000g_1453730679.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_GFLz4jv_oDMHHTk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689954,
                            "field1": "RISO scotti ",
                            "field2": "oro classico ",
                            "field3": "1 kg",
                            "field4": "",
                            "description": "RISO scotti  oro classico  1 kg",
                            "grammage": 0,
                            "price": "1.65",
                            "price_label": "€ 1,65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 76.48857330607477,
                                "left": 49.843510220861155,
                                "width": 50.156489779138845,
                                "height": 23.447235202492212
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/scotti_Oro_Classico_astuccio_sottovuoto_kg1.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Lms8ULx_6hlYjor.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK WEB - Valori nutrizionali del riso Scotti",
                                    "data": "https://www.teaweb.ch/demo/riso_scotti/sito/articoli/riso-oro-classico/"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 16,
                    "image_file": "/media/interactive_flyer_4/pages/page_16.jpg",
                    "products": [
                        {
                            "item_id": 689833,
                            "field1": "novi",
                            "field2": "novibloc latte/fondente",
                            "field3": "150 gr",
                            "field4": "",
                            "description": "novi novibloc latte/fondente 150 gr",
                            "grammage": null,
                            "price": "1.15",
                            "price_label": "€ 1,15",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Latte",
                                "Fondente"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 8.645164038161994,
                                "left": 0.0,
                                "width": 49.80389582638568,
                                "height": 21.413490556853585
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/novibloc_latte_novi_150_gr_1443198191_1444297620.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/novibloc_fondente_150gr_1444297636.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_1RJI76w_bHIWpNj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689834,
                            "field1": "perugina ",
                            "field2": "nero tavoletta",
                            "field3": "vari gusti",
                            "field4": "85 gr",
                            "description": "perugina  nero tavoletta vari gusti 85 gr",
                            "grammage": 100,
                            "price": "1.35",
                            "price_label": "€ 1,35",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "70%",
                                "85%",
                                "Pistacchio"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 8.570324669003117,
                                "left": 50.19610417361432,
                                "width": 49.80389582638568,
                                "height": 21.413490556853585
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Nero_Perugina_70_100g_1476274608_1509095232.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Nero_Perugina_85_100g_1476274616_1509095232.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Nero_Perugina_Pistacchio_100g_1476274636_1509095233.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_5HRCW7G_j7L3oxM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689962,
                            "field1": "roberto",
                            "field2": "pane bianco/integrale",
                            "field3": "400 gr",
                            "field4": "",
                            "description": "roberto pane bianco/integrale 400 gr",
                            "grammage": 0,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bianco",
                                "Integrale"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 30.36957262461059,
                                "left": 0.0,
                                "width": 34.030046359559584,
                                "height": 15.328076323987538
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/59730_roberto_pane_bianco_400_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/59729_roberto_pane_integrale_400_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Chgwb7z_Ghp1FqW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689961,
                            "field1": "morato ",
                            "field2": "nuvolatte classico/integrale",
                            "field3": "400 gr",
                            "field4": "",
                            "description": "morato  nuvolatte classico/integrale 400 gr",
                            "grammage": 0,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Integrale"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 30.383566978193144,
                                "left": 34.32244134476953,
                                "width": 31.373121927776836,
                                "height": 15.328076323987538
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Morato_Nuvolatte_10_panini_1429540702_1433511348.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Afn8TI3_7RTO6Kz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689963,
                            "field1": "doriano",
                            "field2": "crackers salati/senza granelli di sale in superficie/integrale",
                            "field3": "700 gr",
                            "field4": "",
                            "description": "doriano crackers salati/senza granelli di sale in superficie/integrale 700 gr",
                            "grammage": null,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "crackers salati",
                                "senza granelli di sale in superficie",
                                "integrale"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 30.413076810747665,
                                "left": 66.09594147965588,
                                "width": 33.90405852034412,
                                "height": 15.105992017133957
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Doriano_classico_con_sale_iodato_700g_H_1496849366.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Doriano_integrale_700g_H_1496849367.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Doriano_a_ridotto_contenuto_di_sale_700g_H_1496849368.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_lxruj7n_ppkHyS2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK WEB - Tipologie crackers Doriano",
                                    "data": "https://www.doria.it/prodotti-salati/crackers-doriano"
                                }

                            ]
                        },
                        {
                            "item_id": 689837,
                            "field1": "tre marie",
                            "field2": "frollini ",
                            "field3": "vari tipi",
                            "field4": "300/350 gr",
                            "description": "tre marie frollini  vari tipi 300/350 gr",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 45.64501801012461,
                                "left": 0.0,
                                "width": 33.90405852034412,
                                "height": 15.105992017133957
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Ancora_Uno_Gocce_Cioccolato_g.350_1449071099_1500917197_1503312629.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/AncoraUno_Cacao_granella_nocciola_g.300_1449071119_15009171_AAL0Iv0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_q67Nuue_tNfUrOE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }

                            ]
                        },
                        {
                            "item_id": 689838,
                            "field1": "galbusera",
                            "field2": "crackers integrali",
                            "field3": "riso su riso",
                            "field4": "380 gr",
                            "description": "galbusera crackers integrali riso su riso 380 gr",
                            "grammage": 380,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 45.63071943146417,
                                "left": 34.33448113827818,
                                "width": 31.290563343717558,
                                "height": 15.425732573987538
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Galbusera_Riso-su-riso-Cracker-integrali-con-riso-soffiato-_14JtxRF.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_xTOfpGW_K1X30yn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689839,
                            "field1": "elledi ",
                            "field2": "wafer poker ",
                            "field3": "vari gusti ",
                            "field4": "45 gr x 5",
                            "description": "elledi  wafer poker  vari gusti  45 gr x 5",
                            "grammage": null,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 45.74754186137072,
                                "left": 65.8805151743762,
                                "width": 31.290563343717558,
                                "height": 15.425732573987538
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Lago_.poker_5pz_cacao_5x45g-1_1498752179.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Lago_poker_5pz_nocciola_5x45g-1_1498752179.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Lago_poker_5pz_vaniglia_5x45g-1_1498752180.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_xlOWLDr_dz19lDZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689835,
                            "field1": "Milka",
                            "field2": "Choco merende",
                            "field3": "Vari Tipi ",
                            "field4": "",
                            "description": "Milka Choco merende Vari Tipi ",
                            "grammage": null,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 61.08778718847351,
                                "left": 0.0,
                                "width": 34.14786433889418,
                                "height": 15.226160922897195
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Milka_cake_e_choc_5_snack_1450691846.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Milka_choco_minis_6_snack_1450691854.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/milka_xl_cookie_choco_1493052408.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_AFta3XM_crT7c9r.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689836,
                            "field1": "bahlsen ",
                            "field2": "pick up! choco/milk",
                            "field3": "multipack x 3",
                            "field4": "",
                            "description": "bahlsen  pick up! choco/milk multipack x 3",
                            "grammage": null,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 61.041544976635514,
                                "left": 34.165924029157146,
                                "width": 31.614347790575042,
                                "height": 15.226160922897195
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Bahlsen_pick_up_choco_x_3.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Pick_Up_Choco_Milk_1433322539_1522926046.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_7p9XYkt_tl3nCiq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689964,
                            "field1": "pai",
                            "field2": "le patatine classiche",
                            "field3": "trasparenti",
                            "field4": "500 gr",
                            "description": "pai le patatine classiche trasparenti 500 gr",
                            "grammage": 500,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 61.07835621105919,
                                "left": 65.96049380268363,
                                "width": 34.03950619731638,
                                "height": 15.226160922897195
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/344017_PAI_CHIPS_TRASPARENTI_GR.500_1492538775_1496849462.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_hohWCEE_AskUUez.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689960,
                            "field1": "Perugina ",
                            "field2": "Caramelle",
                            "field3": "vari tipi",
                            "field4": "175/200 GR",
                            "description": "Perugina  Caramelle vari tipi 175/200 GR",
                            "grammage": 175,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 76.78154205607477,
                                "left": 0.0,
                                "width": 49.504620959170786,
                                "height": 22.903280763239877
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Perugina_LEMONCELLA_175_G_1513188596.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Perugina_ROSSANA_175_G_1513188596.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Perugina_FONDENTI_ASSORTITE_175_G_1513240373.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Perugina_FONDENTI_DOPPIA_MENTA_175_G_1513240373.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Perugina_GLACIA_200_G_1513240373.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Perugina_GRAN_SELEZIONE_175_G_1513240374.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Perugina_SPICCHI_200_G_1513240374.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_hJc3Tnf_WwVdIn9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689959,
                            "field1": "Bounty/ mars/snickers",
                            "field2": "Multipack",
                            "field3": "x 4/6",
                            "field4": "",
                            "description": "Bounty/ mars/snickers Multipack x 4/6",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bounty",
                                "Mars",
                                "Snickers"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 76.79036458333333,
                                "left": 50.433890095410064,
                                "width": 49.504620959170786,
                                "height": 22.903280763239877
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Bounty_x6_1439394902_1481126655.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Mars_4_barrette_1449848546_1472554375_1481652864.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/snickers_4_multipack_1443541738_1481652865.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Pt4nVzV_oRaCerF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 17,
                    "image_file": "/media/interactive_flyer_4/pages/page_17.jpg",
                    "products": [
                        {
                            "item_id": 689840,
                            "field1": "FERRARELLE",
                            "field2": "Acqua Minerale Effervescente Naturale",
                            "field3": "1,5 lt x 6 bottiglie",
                            "field4": "",
                            "description": "FERRARELLE Acqua Minerale Effervescente Naturale 1,5 lt x 6 bottiglie",
                            "grammage": 9000,
                            "price": "1.68",
                            "price_label": "€ 1,68",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Acqua",
                            "blueprint": {
                                "top": 8.501265576323979,
                                "left": 0.0,
                                "width": 49.504620959170786,
                                "height": 21.305794879283493
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Ferrarelle_Acqua_15_litri.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/ferrarelle_1437670249_1455093048_1474445925.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_ZQ2fYp3_3uxSZgF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK WEB - Sito web ufficiale Ferrarelle",
                                    "data": "https://www.ferrarelle.it/it"
                                }
                            ]
                        },
                        {
                            "item_id": 689841,
                            "field1": "pepsi cola ",
                            "field2": "vari tipi",
                            "field3": "1,5 lt",
                            "field4": "",
                            "description": "pepsi cola  vari tipi 1,5 lt",
                            "grammage": 1500,
                            "price": "0.85",
                            "price_label": "€ 0,85",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Normale",
                                "Zero"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 8.73612733644859,
                                "left": 49.89510933589821,
                                "width": 49.504620959170786,
                                "height": 21.305794879283493
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/pepsi_15_classica_1468309603.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/pepsi_max_zero_15_1_1468309612.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/pepsi_twist_limone_15_1468309625.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_R2FS7ra_ocWbWSp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689999,
                            "field1": "rocchetta",
                            "field2": "acqua minerale naturale",
                            "field3": "50 cl x 6",
                            "field4": "",
                            "description": "rocchetta acqua minerale naturale 50 cl x 6",
                            "grammage": 500,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Acqua",
                            "blueprint": {
                                "top": 30.50799503504672,
                                "left": 0.0,
                                "width": 33.84686950117806,
                                "height": 14.896685163551403
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Rocchetta_acqua_6_x_05_L_1490279145_1568274351.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Rocchetta_acqua_50_cl_1491837403_1568274351.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_kszIsdm_PIk3OEG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689842,
                            "field1": "Sant'Anna",
                            "field2": "acqua minerale ",
                            "field3": "naturale ",
                            "field4": "1,5 lt x 6",
                            "description": "Sant'Anna acqua minerale  naturale  1,5 lt x 6",
                            "grammage": 9000,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Acqua",
                            "blueprint": {
                                "top": 30.46053592289719,
                                "left": 34.247622627965804,
                                "width": 31.462990386466362,
                                "height": 15.108730042834893
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Santanna_Acqua_naturale_15_litri_1433868140.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Fardello-15-litri-Naturale_Rebruant_1467876019_1471364009.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_oH04typ_t0rJQu8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 690003,
                            "field1": "RAUCH",
                            "field2": "succhi bravo alla frutta ",
                            "field3": "vari gusti",
                            "field4": "2 lt",
                            "description": "RAUCH succhi bravo alla frutta  vari gusti 2 lt",
                            "grammage": 2000,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Arancia",
                                "Multi"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 30.349797994548283,
                                "left": 65.91276462127438,
                                "width": 34.08723537872565,
                                "height": 15.108730042834893
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Rauch_bravo_arancia_2_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Rauch_bravo_multivitaminico_2_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Vg1UajZ_4esDE2c.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689967,
                            "field1": "VALFRUTTA",
                            "field2": "triangolini",
                            "field3": "vari gusti",
                            "field4": "100 ml x 8",
                            "description": "VALFRUTTA triangolini vari gusti 100 ml x 8",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Succhi",
                            "blueprint": {
                                "top": 45.95867406542056,
                                "left": 0.020639646014819876,
                                "width": 34.08723537872565,
                                "height": 15.108730042834893
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Tringolini_PERA_x_8_1550054283.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Tringolini_PESCA_x_8_1550054284.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Tringolini_ALBICOCCA_x_8_1550054284.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_bqrOAWc_bw07pzq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689845,
                            "field1": "burn",
                            "field2": "energy drink",
                            "field3": "vari gusti",
                            "field4": "250 ml",
                            "description": "burn energy drink vari gusti 250 ml",
                            "grammage": 250,
                            "price": "0.75",
                            "price_label": "€ 0,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 45.62798140576324,
                                "left": 34.19387354980221,
                                "width": 31.318512864362624,
                                "height": 15.322296047507788
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/BURN_APPLEKIWI_SLEEK_CAN_CL_25_1517926284.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/BURN_ORIGINAL_SLEEK_CAN_CL_25_1517926285.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_C7wd5NO_9rMN4iy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689843,
                            "field1": "poretti ",
                            "field2": "birra 4 luppoli ",
                            "field3": "33 cl x 3",
                            "field4": "",
                            "description": "poretti  birra 4 luppoli  33 cl x 3",
                            "grammage": 990,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 45.69065177180685,
                                "left": 65.98629336020217,
                                "width": 33.84944945692991,
                                "height": 15.322296047507788
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/poretti_4_luppoli_33_cl_x_3_1568648047.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_c4BMREW_seiJRvk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Come nasce la birra Poretti 4 luppoli",
                                    "data": "/media/interactive_flyer_4/video/birra-poretti.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": 689844,
                            "field1": "chiarli vino igt",
                            "field2": "lambrusco/trebbiano frizzante",
                            "field3": "1,5 lt",
                            "field4": "",
                            "description": "chiarli vino igt lambrusco/trebbiano frizzante 1,5 lt",
                            "grammage": 1500,
                            "price": "3.49",
                            "price_label": "€ 3,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Lambrusco",
                                "Trebbiano Frizzante"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 61.120643496884725,
                                "left": 0.0,
                                "width": 33.84944945692991,
                                "height": 15.322296047507788
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Chiarli_trebbiano_amabile_doc_15_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/664576_chiarli_lambrusco_amabile_15_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_RUkoV0T_rKrdgoz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689969,
                            "field1": "Baileys",
                            "field2": "vari tipi",
                            "field3": "70 cl",
                            "field4": "",
                            "description": "Baileys vari tipi 70 cl",
                            "grammage": 700,
                            "price": "9.90",
                            "price_label": "€ 9,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "blueprint": {
                                "top": 61.03028864875389,
                                "left": 34.092825282854655,
                                "width": 31.572638505920093,
                                "height": 15.322296047507788
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Baileys_Irish_Cream_the_original_70_cl_1443795637_1478591570.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_XTA7icx_ymK5aVM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689968,
                            "field1": "AVERNA ",
                            "field2": "Amaro 70 cl",
                            "field3": "",
                            "field4": "",
                            "description": "AVERNA  Amaro 70 cl",
                            "grammage": 700,
                            "price": "9.90",
                            "price_label": "€ 9,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "blueprint": {
                                "top": 60.98465488707164,
                                "left": 65.5610306537718,
                                "width": 34.438969346228205,
                                "height": 15.322296047507788
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Averna_Amaro_70_cl_1442927867.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Scatola_Averna_Natalizia_1480931733.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_bwjY66c_Z3aOuwc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689965,
                            "field1": "Peroni",
                            "field2": "Birra",
                            "field3": "66 cl",
                            "field4": "",
                            "description": "Peroni Birra 66 cl",
                            "grammage": 660,
                            "price": "0.85",
                            "price_label": "€ 0,85",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 76.47275360202492,
                                "left": 0.0,
                                "width": 49.72477718332887,
                                "height": 23.09524678738318
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/peroni_birra_66_cl_1467280241.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_mwBYMrv_6VNgbJ9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689966,
                            "field1": "maschio ",
                            "field2": "vino frizzante igt ",
                            "field3": "vari tipi",
                            "field4": "75 cl",
                            "description": "maschio  vino frizzante igt  vari tipi 75 cl",
                            "grammage": 750,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Pinot",
                                "Pinot Rosa"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 76.38026917834891,
                                "left": 50.275222816671146,
                                "width": 49.72477718332887,
                                "height": 23.09524678738318
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/662723_Maschio_pinot_rosa_veneto_rose.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Maschio_Pinot_frizzante_75_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_tv0HH2s_41wrFyY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 18,
                    "image_file": "/media/interactive_flyer_4/pages/page_18.jpg",
                    "products": [
                        {
                            "item_id": 689846,
                            "field1": "chanteclair",
                            "field2": "detersivo per lavatrice",
                            "field3": "in polvere",
                            "field4": "108 misurini",
                            "description": "chanteclair detersivo per lavatrice in polvere 108 misurini",
                            "grammage": null,
                            "price": "6.90",
                            "price_label": "€ 6,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 8.442854361370717,
                                "left": 0.0,
                                "width": 49.72477718332887,
                                "height": 21.5324425623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/123KS7IT_Polvere_Lav._MuschioBianco_108dosi_IT_1563266807.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_r0QgXGN_qFPzqfe.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689847,
                            "field1": "Soft ",
                            "field2": "Ammorbidente ",
                            "field3": "vari tipi",
                            "field4": "3 lt",
                            "description": "Soft  Ammorbidente  vari tipi 3 lt",
                            "grammage": 3000,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 8.674065420560748,
                                "left": 50.275222816671146,
                                "width": 49.72477718332887,
                                "height": 21.5324425623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/31365_1504173410_1514376299_1543246210.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/52142_1503589847_1514376300_1543246211.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_6Hcjnxv_M9MBZzP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689974,
                            "field1": "ace gentile",
                            "field2": "candeggina",
                            "field3": "2 lt",
                            "field4": "",
                            "description": "ace gentile candeggina 2 lt",
                            "grammage": 2000,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 30.45688522196262,
                                "left": 0.0,
                                "width": 33.9277081147361,
                                "height": 15.25871300623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Ace_2L_regolare_1494924578.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/ace_gentile_profiumata_2l_b_1494924578.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_POBOLou_i5bBEPV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689848,
                            "field1": "svelto ",
                            "field2": "detersivo piatti ",
                            "field3": "classico/aceto",
                            "field4": "eco ricarica 2 lt",
                            "description": "svelto  detersivo piatti  classico/aceto eco ricarica 2 lt",
                            "grammage": 2000,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Aceto"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 30.31876703660436,
                                "left": 34.290191897871374,
                                "width": 31.239394221305812,
                                "height": 15.25871300623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Svelto_Piatti_aceto_ricarica_2_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Svelto_Piatti_limone_ricarica_2_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_UMNdlow_mZYnB3h.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689849,
                            "field1": "smac ",
                            "field2": "express  sgrassatore ",
                            "field3": "disinfettante/ bagno/ con candeggina ",
                            "field4": "650 ml",
                            "description": "smac  express  sgrassatore  disinfettante/ bagno/ con candeggina  650 ml",
                            "grammage": null,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Disinfettante",
                                "Bagno",
                                "Con Candeggina"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 30.37231065031153,
                                "left": 65.86847538086755,
                                "width": 34.110024987867014,
                                "height": 15.25871300623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Spray_Bagno_650ml_1562322494.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Sgrassatore_Disinf_650ml_1562322494.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Sgrassatore_Candeggina_650ml_1562322495.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_tNHTKH0_YIEQOVS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689850,
                            "field1": "vileda",
                            "field2": "panno pavimenti",
                            "field3": "in microfibre",
                            "field4": "",
                            "description": "vileda panno pavimenti in microfibre",
                            "grammage": null,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 45.64988561137072,
                                "left": 0.0,
                                "width": 34.110024987867014,
                                "height": 15.25871300623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/vileda_microfibra_1525268041.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_BKx5lVT_D9Ug8GR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689851,
                            "field1": "vileda glitzi",
                            "field2": "fibra verde + spugna",
                            "field3": "2 pezzi + 1 gratis",
                            "field4": "",
                            "description": "vileda glitzi fibra verde + spugna 2 pezzi + 1 gratis",
                            "grammage": null,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 45.55892231308411,
                                "left": 34.03692624156452,
                                "width": 31.586828262555283,
                                "height": 15.25871300623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Vileda_Abrasivo_Glitzi_Crystal_21x.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_0r3ZK9f_mWu7t45.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689852,
                            "field1": "vileda",
                            "field2": "guanti universali/comfort",
                            "field3": "varie misure",
                            "field4": "",
                            "description": "vileda guanti universali/comfort varie misure",
                            "grammage": null,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 45.67239826713396,
                                "left": 66.08734162714971,
                                "width": 31.586828262555283,
                                "height": 15.25871300623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Vileda_Guanti_Super_Universal_media.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_3j6woai_qDvhFNr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689973,
                            "field1": "scottex scottonelle ",
                            "field2": "carta igienica ",
                            "field3": "12 rotoli",
                            "field4": "",
                            "description": "scottex scottonelle  carta igienica  12 rotoli",
                            "grammage": null,
                            "price": "3.29",
                            "price_label": "€ 3,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 61.08748296339564,
                                "left": 0.0,
                                "width": 33.962967510011424,
                                "height": 15.25871300623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Scottonelle_carta_igienica_12_rotoli.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_8JX2XtW_0W3KEJO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689975,
                            "field1": "fantasy",
                            "field2": "tovaglioli",
                            "field3": "33x33 cm",
                            "field4": "100 pz",
                            "description": "fantasy tovaglioli 33x33 cm 100 pz",
                            "grammage": null,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 61.073792834890966,
                                "left": 33.961247539510175,
                                "width": 31.730015806783094,
                                "height": 15.25871300623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/fantasy_tovaglioli_100_pz.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_tuoG5a1_pFNtxkx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689972,
                            "field1": "ARISTEA ",
                            "field2": "BICCHIERI caffè",
                            "field3": "x100 - 80 cc",
                            "field4": "",
                            "description": "ARISTEA  BICCHIERI caffè x100 - 80 cc",
                            "grammage": 0,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "No Food",
                            "subcategory": "Home",
                            "blueprint": {
                                "top": 61.01599007009346,
                                "left": 65.92523440740833,
                                "width": 31.730015806783094,
                                "height": 15.25871300623053
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/aristea_100_bicchieri_80_cc_1464259473.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_wXU14mq_GhWaaTo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689970,
                            "field1": "regina di cuori  ",
                            "field2": "asciugatutto",
                            "field3": "3 rotoli",
                            "field4": "",
                            "description": "regina di cuori   asciugatutto 3 rotoli",
                            "grammage": null,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 76.53025214174455,
                                "left": 0.0,
                                "width": 49.78755610662394,
                                "height": 23.34684092679128
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/regina_di_cuori_carta_cucina_1539946253.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_bft13YQ_c0b8EHZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689971,
                            "field1": "regina",
                            "field2": "carta igienica",
                            "field3": "4 rotoloni",
                            "field4": "",
                            "description": "regina carta igienica 4 rotoloni",
                            "grammage": null,
                            "price": "2.29",
                            "price_label": "€ 2,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 76.5658464758567,
                                "left": 50.13203527244333,
                                "width": 49.78755610662394,
                                "height": 23.34684092679128
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/regina_rotoloni_1539946277.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_VfwPD6F_nMP8Yzh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 19,
                    "image_file": "/media/interactive_flyer_4/pages/page_19.jpg",
                    "products": [
                        {
                            "item_id": 689976,
                            "field1": "chilly",
                            "field2": "detergente intimo ",
                            "field3": "vari tipi",
                            "field4": "200 ml",
                            "description": "chilly detergente intimo  vari tipi 200 ml",
                            "grammage": null,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 8.408172702492212,
                                "left": 50.207283981872365,
                                "width": 24.682866670598028,
                                "height": 21.80898315809969
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/chilly_gel_intimo_200_ml.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/chilly_delicato_intimo_200_ml.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_8yNH5t5_zdAdQZX.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689977,
                            "field1": "nivea soft",
                            "field2": "crema idratante",
                            "field3": "200 ml",
                            "field4": "",
                            "description": "nivea soft crema idratante 200 ml",
                            "grammage": null,
                            "price": "2.89",
                            "price_label": "€ 2,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 8.447721962616823,
                                "left": 75.31713332940198,
                                "width": 24.682866670598028,
                                "height": 21.80898315809969
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Nivea_crema_idratante_soft_200_ml.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_9IffCoG_tJv8YKz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689861,
                            "field1": "Neutro Roberts",
                            "field2": "deodorante spray 150 ml/",
                            "field3": "roll on 50 ml/vapo 75 ml",
                            "field4": "vari tipi",
                            "description": "Neutro Roberts deodorante spray 150 ml/ roll on 50 ml/vapo 75 ml vari tipi",
                            "grammage": 0,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Roll on 50 ml",
                                "Vapo 75 ml"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 30.416727511682243,
                                "left": 0.022359616516054868,
                                "width": 34.169363970159615,
                                "height": 15.122724396417446
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Neutro_Roberts_Deodorante_Fresco_Roll_On_1522838613.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Neutro_Roberts_Deodorante_Fresco_Spray_1522838614.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Neutro_Roberts_Deodorante_Fresco_Vapo_1522838616.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_qmJXBGI_T3NDjSv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689862,
                            "field1": "NEUTRO ROBERTS",
                            "field2": "Bagnoschiuma ",
                            "field3": "varie profumazioni ",
                            "field4": "700 ml",
                            "description": "NEUTRO ROBERTS Bagnoschiuma  varie profumazioni  700 ml",
                            "grammage": 700,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Idratante",
                                "Nutriente"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 30.32880646417446,
                                "left": 34.13969447901331,
                                "width": 31.5782284100491,
                                "height": 15.122724396417446
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Neutro_Roberts_Idratante_Bagnodoccia_700ml_1522838525.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Neutro_Roberts_Nutriente_Bagnodoccia_700ml_1522838525.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_eWM77tH_YdNif9X.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689863,
                            "field1": "neutro roberts ",
                            "field2": "sapone liquido idratante ricarica",
                            "field3": "300 ml x 2",
                            "field4": "",
                            "description": "neutro roberts  sapone liquido idratante ricarica 300 ml x 2",
                            "grammage": 0,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 30.27952200155763,
                                "left": 65.91104465077312,
                                "width": 32.52464217835365,
                                "height": 15.122724396417446
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/roberts_sapone_duetto_1568730719.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_9EoLnGp_EunwRQo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689864,
                            "field1": "mantovani",
                            "field2": "bagnoschiuma ",
                            "field3": "varie profumazioni ",
                            "field4": "500 ml",
                            "description": "mantovani bagnoschiuma  varie profumazioni  500 ml",
                            "grammage": 500,
                            "price": "1.09",
                            "price_label": "€ 1,09",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 45.75149678738318,
                                "left": 1.5161539968386433,
                                "width": 32.52464217835365,
                                "height": 15.122724396417446
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/bagno_olio_argan_1456832147.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/bagno_talco_1456832167.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_dasodRG_95zhLfq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689865,
                            "field1": "pantene",
                            "field2": "shampoo 250 ml/",
                            "field3": "balsamo 200 ml",
                            "field4": "vari trattamenti",
                            "description": "pantene shampoo 250 ml/ balsamo 200 ml vari trattamenti",
                            "grammage": null,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Shampoo 250 ml",
                                "Balsamo 200 ml"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 45.74571651090343,
                                "left": 34.22569300407506,
                                "width": 31.404081396799057,
                                "height": 15.122724396417446
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/PERFECT_HYDRATATION_SHAMPOO__PRO_V_1435827794.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/PERFECT_HYDRATATION_CONDITIONER__PRO_V_1435827826.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_ZjTnz4y_mgl4Ri9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689981,
                            "field1": "pepsodent",
                            "field2": "dentifricio ",
                            "field3": "HERBAL/COMPLETE",
                            "field4": "100 ml",
                            "description": "pepsodent dentifricio  HERBAL/COMPLETE 100 ml",
                            "grammage": null,
                            "price": "0.59",
                            "price_label": "€ 0,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Herbal",
                                "Complete"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 45.71742357866044,
                                "left": 65.78290684843113,
                                "width": 34.09841518698367,
                                "height": 15.229507398753894
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Pepsodent_Fluor_fresh_dentifricio_75_ml.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Sbfjeum_5bxPrMq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689982,
                            "field1": "mentadent ",
                            "field2": "spazzolino tecnic clean",
                            "field3": "setole medie/dure",
                            "field4": "",
                            "description": "mentadent  spazzolino tecnic clean setole medie/dure",
                            "grammage": null,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Setole Media",
                                "Setole Dure"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 61.045499902647975,
                                "left": 0.00042999262530874746,
                                "width": 34.09841518698367,
                                "height": 15.229507398753894
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/mentadent_spazzolini_tecnic_3.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_7A161nD_zalpoaG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689984,
                            "field1": "tena ",
                            "field2": "lady",
                            "field3": "",
                            "field4": "vari tipi",
                            "description": "tena  lady vari tipi",
                            "grammage": 0,
                            "price": "2.59",
                            "price_label": "€ 2,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Normal",
                                "Extra"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 60.99499853971963,
                                "left": 34.31900140376706,
                                "width": 31.55070888202934,
                                "height": 15.229507398753894
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Tena_Lady_Extra_pz_10_1504000773_1522311539.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Tena_Lady_Normal_pz_12_1504000773_1522311540.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_J9VrHjK_E3Gwk1H.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689983,
                            "field1": "excellence",
                            "field2": "colorazione",
                            "field3": "varie nuances",
                            "field4": "",
                            "description": "excellence colorazione varie nuances",
                            "grammage": null,
                            "price": "6.99",
                            "price_label": "€ 6,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 61.20856454439253,
                                "left": 65.83708591922002,
                                "width": 31.55070888202934,
                                "height": 15.141890576323988
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Loreal_excellence_creme.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_s3R9JFz_3SotPAk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689979,
                            "field1": "lines è",
                            "field2": "assorbenti",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "lines è assorbenti vari tipi",
                            "grammage": null,
                            "price": "2.69",
                            "price_label": "€ 2,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 76.54485494548287,
                                "left": 39.48541786979875,
                                "width": 31.55070888202934,
                                "height": 8.843518788940811
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Lines_e_lungo_x7_1511885168.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_2k7J5s3_l61axjR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689978,
                            "field1": "Lines idea",
                            "field2": "assorbenti",
                            "field3": "Vari Tipi",
                            "field4": "",
                            "description": "Lines idea assorbenti Vari Tipi",
                            "grammage": null,
                            "price": "0.90",
                            "price_label": "€ 0,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 85.67708333333334,
                                "left": 27.614181470274847,
                                "width": 35.393552974413616,
                                "height": 10.654874902647975
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/lines_idea_sottile_con_ali_1467898185_1468923271_1469176861.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/lines_idea_ultra__1467898230_1468427048_1469457860.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_Zz9xGl1_bQm6P36.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689980,
                            "field1": "lines",
                            "field2": "cotone",
                            "field3": "assorbenti notte con ali x 8/ultra con ali x 10/",
                            "field4": "proteggislip distesi x 24",
                            "description": "lines cotone assorbenti notte con ali x 8/ultra con ali x 10/ proteggislip distesi x 24",
                            "grammage": 0,
                            "price": "2.69",
                            "price_label": "€ 2,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Assorbenti notte con ali x 8",
                                "Ultra con ali x 10"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 83.71635270638629,
                                "left": 62.47067365567784,
                                "width": 35.393552974413616,
                                "height": 10.654874902647975
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/COTONE_Proteggislip_Disteso_24pz_1539616314_1547629221.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/LINES_COTONE_NOTTE_con_ALI_8pz_06_1539616315_1547629224.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_zvZKZtf_3WsXN9X.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 20,
                    "image_file": "/media/interactive_flyer_4/pages/page_20.jpg",
                    "products": [
                        {
                            "item_id": 689870,
                            "field1": "findus",
                            "field2": "2 croccole ",
                            "field3": "ai cereali ",
                            "field4": "250 gr",
                            "description": "findus 2 croccole  ai cereali  250 gr",
                            "grammage": 250,
                            "price": "3.65",
                            "price_label": "€ 3,65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 8.530775408878506,
                                "left": 0.010749815632718686,
                                "width": 34.01112668404599,
                                "height": 13.521283586448599
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/croccole_cereali_1559580306_1559581487.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_mCi5ghZ_pORwjS9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689872,
                            "field1": "findus",
                            "field2": "8 bastoncini",
                            "field3": "in pastella",
                            "field4": "224 gr",
                            "description": "findus 8 bastoncini in pastella 224 gr",
                            "grammage": 224,
                            "price": "2.55",
                            "price_label": "€ 2,55",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 8.59192464953271,
                                "left": 34.27729211911211,
                                "width": 31.573928483796017,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/8_bastoncini_1554799443.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_PtnVWNC_sY7K3AO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689991,
                            "field1": "findus",
                            "field2": "i gratinati",
                            "field3": "linea",
                            "field4": "380 gr",
                            "description": "findus i gratinati linea 380 gr",
                            "grammage": 0,
                            "price": "3.95",
                            "price_label": "€ 3,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 8.668893594236762,
                                "left": 66.24896378131871,
                                "width": 31.573928483796017,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/40360397_FINDUS_GRATINATI_ZUCCHINE_SEMI_1567438880.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_kyCGUMJ_7P7rYst.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689873,
                            "field1": "petrucci",
                            "field2": "funghi porcini cubettati",
                            "field3": "300 gr",
                            "field4": "",
                            "description": "petrucci funghi porcini cubettati 300 gr",
                            "grammage": 300,
                            "price": "2.95",
                            "price_label": "€ 2,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Verdure",
                            "blueprint": {
                                "top": 22.02680831386293,
                                "left": 2.3103503757839,
                                "width": 31.573928483796017,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/CP30_I_gusti_del_bosco_Funghi_porcini_cubettati_300gr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_f3pyzmX_wWnLbGk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689874,
                            "field1": "orogel",
                            "field2": "minestrone leggerezza",
                            "field3": "750 gr",
                            "field4": "",
                            "description": "orogel minestrone leggerezza 750 gr",
                            "grammage": 0,
                            "price": "1.65",
                            "price_label": "€ 1,65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Verdure",
                            "blueprint": {
                                "top": 22.196870132398754,
                                "left": 34.14528438314233,
                                "width": 31.573928483796017,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/orogel_minestrone_leggerezza_750_g_1538041059_1539598783_1542305226.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_hc9m7z0_WdrJ8EF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689871,
                            "field1": "Pizzoli",
                            "field2": "Pata snella patatine",
                            "field3": "750 gr",
                            "field4": "",
                            "description": "Pizzoli Pata snella patatine 750 gr",
                            "grammage": 750,
                            "price": "1.95",
                            "price_label": "€ 1,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "",
                            "blueprint": {
                                "top": 22.200520833333336,
                                "left": 66.17672502026684,
                                "width": 31.573928483796017,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/pizzoli_pata_snella_patatine_750_gr_1465544931.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_XaMWtxb_lecRLMN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689990,
                            "field1": "soavegel",
                            "field2": "soavelli",
                            "field3": "vari gusti",
                            "field4": "busta 500 gr",
                            "description": "soavegel soavelli vari gusti busta 500 gr",
                            "grammage": 500,
                            "price": "1.85",
                            "price_label": "€ 1,85",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "",
                            "blueprint": {
                                "top": 35.67191150700935,
                                "left": 2.321960176667236,
                                "width": 31.573928483796017,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/42690000_SOAVEGEL_SOAVELLI_FORMAGGIO_BS_500_g_1434015731.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/42690002_SOAVEGEL_SOAVELLI_PROSFORM_BS_500_g_1434015741.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/42690005_SOAVEGEL_SOAVELLI_PIZZAIOLA_BS_500_g_1434015749.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_MBfCi41_1hMRjLF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689992,
                            "field1": "valsoia",
                            "field2": "2 burger di soia",
                            "field3": "150 gr",
                            "field4": "",
                            "description": "valsoia 2 burger di soia 150 gr",
                            "grammage": 0,
                            "price": "1.75",
                            "price_label": "€ 1,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Verdure",
                            "blueprint": {
                                "top": 35.62323549454829,
                                "left": 34.37490044505719,
                                "width": 31.573928483796017,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/principale_8006040252302_1513783413.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_AhROrGR_Tflt6Pz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689989,
                            "field1": "buitoni ",
                            "field2": "lasagne ",
                            "field3": "alla bolognese",
                            "field4": "750 gr",
                            "description": "buitoni  lasagne  alla bolognese 750 gr",
                            "grammage": 750,
                            "price": "2.95",
                            "price_label": "€ 2,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "",
                            "blueprint": {
                                "top": 35.561782028816204,
                                "left": 66.05896211387933,
                                "width": 31.573928483796017,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/buitoni_lasagne_ingredienti_naturali__1508772447.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_L2QFvEw_s3yo7UI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689866,
                            "field1": "algida",
                            "field2": "magnum",
                            "field3": "x 4",
                            "field4": "classico mandorle bianco pistacchio cocco nocciola caffè yogurt",
                            "description": "algida magnum x 4 classico mandorle bianco pistacchio cocco nocciola caffè yogurt",
                            "grammage": 0,
                            "price": "3.15",
                            "price_label": "€ 3,15",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Mandorle",
                                "Bianco",
                                "Pistacchio",
                                "Cocco",
                                "Nocciola",
                                "Caffè",
                                "Yogurt"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 49.03925720404985,
                                "left": 0.0,
                                "width": 24.655777135203575,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Magnum_Classic_1499184421.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/algida_magnum_mandorle_x_4_1556032531.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_7PhAqor_aIwwdvP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689867,
                            "field1": "algida",
                            "field2": "carte d'or classic",
                            "field3": "vari gusti",
                            "field4": "370/380/400 gr",
                            "description": "algida carte d'or classic vari gusti 370/380/400 gr",
                            "grammage": 0,
                            "price": "2.15",
                            "price_label": "€ 2,15",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 49.24491335669782,
                                "left": 25.151558632184564,
                                "width": 24.655777135203575,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/41357161_ALGIDA_CDO_CLS_CIOCCOLATO_FONDENTE_400_1553186208__YTqlq9c.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/41359698_ALGIDA_CDO_CLS_CAFFE_400_1553186309_1553600702.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_YmzLo3F_qG6ZNZ2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Spot 2019 Carte D'Or",
                                    "data": "/media/interactive_flyer_4/video/Cartedor-spot-2019.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": 689868,
                            "field1": "Valsoia",
                            "field2": "gran specialità",
                            "field3": "caramello",
                            "field4": "210 gr",
                            "description": "Valsoia gran specialità caramello 210 gr",
                            "grammage": 0,
                            "price": "0.95",
                            "price_label": "€ 0,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 49.2254429517134,
                                "left": 50.502203849887074,
                                "width": 24.655777135203575,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/46818302_VALSOIA_SPECIALITA_CARAMELLO_1524045575.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_v2bqOGs_tYASlko.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689869,
                            "field1": "motta",
                            "field2": "maxibon black cookie",
                            "field3": "354 gr",
                            "field4": "",
                            "description": "motta maxibon black cookie 354 gr",
                            "grammage": 0,
                            "price": "1.65",
                            "price_label": "€ 1,65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 49.180417640186924,
                                "left": 75.32229324090568,
                                "width": 24.655777135203575,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/25411533_MOTTA_MAXIBON_MINI_BLACK_COOKIE_1554968650.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_JcvdAbR_RFhI5GW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689986,
                            "field1": "ALGIDA ",
                            "field2": "FIORDIFRAGOLA X 8",
                            "field3": "408 gr",
                            "field4": "",
                            "description": "ALGIDA  FIORDIFRAGOLA X 8 408 gr",
                            "grammage": 0,
                            "price": "1.95",
                            "price_label": "€ 1,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 62.69074912383178,
                                "left": 0.10878813420311309,
                                "width": 24.655777135203575,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/41351916_ALGIDA_FIORDIFRAGOLA_X_8_NEW_1522056740.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_m3FX7IR_mCPstbT.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689987,
                            "field1": "algida",
                            "field2": "cremino",
                            "field3": "classico/bianco",
                            "field4": "x 8 - 336 gr",
                            "description": "algida cremino classico/bianco x 8 - 336 gr",
                            "grammage": 0,
                            "price": "2.85",
                            "price_label": "€ 2,85",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Bianco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 62.78171242211839,
                                "left": 24.927532474398703,
                                "width": 24.655777135203575,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/41358401_ALGIDA_CREMINO_X_8_1522313933.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cremino_bianco_1523263521_1527069491.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_LequsyH_Ymi1gNI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689988,
                            "field1": "algida",
                            "field2": "8 cucciolone mini",
                            "field3": "270 gr",
                            "field4": "",
                            "description": "algida 8 cucciolone mini 270 gr",
                            "grammage": 0,
                            "price": "2.25",
                            "price_label": "€ 2,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 62.844382788161994,
                                "left": 50.46651446198645,
                                "width": 24.655777135203575,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/Cucciolone_2016_PACKSHOT_IH_MINI_05_1489134922.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_nXnyQ0U_OWeQwSF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689985,
                            "field1": "motta",
                            "field2": "la cremeria linea classica",
                            "field3": "vari gusti",
                            "field4": "500 gr",
                            "description": "motta la cremeria linea classica vari gusti 500 gr",
                            "grammage": 500,
                            "price": "1.95",
                            "price_label": "€ 1,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 62.91252920560747,
                                "left": 75.18856553443466,
                                "width": 24.655777135203575,
                                "height": 13.359740070093457
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_4/products/la_cremeria_spagnola_1524470641_1526976096_1527064724.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/la_cremeria_stracciatella_1524470642_1526976097_1527064725.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_4/products/cropped_image_aZiKAdk_3Aug2vz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 21,
                    "image_file": "/media/interactive_flyer_4/pages/fine-pagina.png",
                    "products": []
                },
            ]
        }
    },
    "pascar": {
        "config": {
            "primary-color": "rgb(14, 52, 100)",
            "secondary-color": "rgb(230, 106, 54)",
            "tertiary-color": "rgb(99, 44, 56)",
            "hover-color": "rgba(230, 106, 54), 0.2)",
            "clientIcon": require("assets/images/pascar_clientLogo.png"),
            "serverPath": "https://pascar.volantinointerattivo.net",
            "pdfPath": "/media/interactive_flyer_7/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino Pascar!",
            "shareFlyerURL": "https://pascar.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Pascar!",
            "ogTitleMeta": "Volantino Interattivo Pascar",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://pascar.volantinointerattivo.net/media/interactive_flyer_7/media/images/pascar-logo.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": ""
        },
        "leaflet": {
            "name": "Pascar",
            "code": "01pascar19",
            "id_campaign": "1234",
            "index": {
                "image_file": "/media/interactive_flyer_7/pages/indice.png",
                "links": [
                    {//COPERTINA
                        "page": 0,
                        "blueprint": {
                            "top": 11.822860284592732,
                            "left": 2.196325776818627,
                            "width": 95.30877676110775,
                            "height": 8.919717465002574
                        }
                    },
                    {//SALUMI
                        "page": 2,
                        "blueprint": {
                            "top": 21.479310605105553,
                            "left": 2.1372728327412958,
                            "width": 95.30877676110775,
                            "height": 8.919717465002574
                        }
                    },
                    {//FORMAGGI
                        "page": 2,
                        "blueprint": {
                            "top": 30.944788703396156,
                            "left": 2.127351938136304,
                            "width": 95.30877676110775,
                            "height": 8.919717465002574
                        }
                    },
                    {//SELF SERVICE
                        "page": 4,
                        "blueprint": {
                            "top": 40.55950558373804,
                            "left": 2.0886132068215746,
                            "width": 95.30877676110775,
                            "height": 8.919717465002574
                        }
                    },
                    {//SURGELATI
                        "page": 6,
                        "blueprint": {
                            "top": 50.093760391430344,
                            "left": 2.280889592737366,
                            "width": 95.30877676110775,
                            "height": 8.919717465002574
                        }
                    },
                    {//BEVANDE
                        "page": 6,
                        "blueprint": {
                            "top": 59.86405953672949,
                            "left": 2.173649446292932,
                            "width": 95.30877676110775,
                            "height": 8.919717465002574
                        }
                    },
                    {//ALIMENTARI
                        "page": 10,
                        "blueprint": {
                            "top": 69.54955633160128,
                            "left": 2.116013772873456,
                            "width": 95.30877676110775,
                            "height": 8.919717465002574
                        }
                    },
                    {//MONDO BABY
                        "page": 16,
                        "blueprint": {
                            "top": 79.10584639570385,
                            "left": 2.191129117739822,
                            "width": 95.30877676110775,
                            "height": 8.919717465002574
                        }
                    },
                    {//IGIENE E PULIZIA
                        "page": 16,
                        "blueprint": {
                            "top": 88.7803255623705,
                            "left": 2.0862510890584813,
                            "width": 95.30877676110775,
                            "height": 8.919717465002574
                        }
                    }

                ]
            },
            "categories": [
                {
                    "category_name": "Gastronomia",
                    "subcategories": [
                        "Salumi",
                        "Formaggi"
                    ]
                },
                {
                    "category_name": "Pescheria",
                    "subcategories": [
                        "Altro"
                    ]
                },
                {
                    "category_name": "Self Service",
                    "subcategories": [
                        "Formaggi",
                        "Salumi",
                        "Yogurt",
                        "Pasta"
                    ]
                },
                {
                    "category_name": "Surgelati",
                    "subcategories": [
                        "Verdure",
                        "Preparati",
                        "Ittico",
                        "Gelati"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "/media/interactive_flyer_7/pages/page_1.jpg",
                    "products": []
                },
                {
                    "number": 2,
                    "image_file": "/media/interactive_flyer_7/pages/page_2.jpg",
                    "products": [
                        {
                            "item_id": 695366,
                            "field1": "menatti",
                            "field2": "prosciutto crudo mec dolce",
                            "field3": "al kg",
                            "field4": "(prezzo con iva € 8,03)",
                            "description": "menatti prosciutto crudo mec dolce al kg",
                            "grammage": 0,
                            "price": "7.30",
                            "price_label": "€ 7,30 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 10.265785543436568,
                                "left": 34.32374340843212,
                                "width": 65.67625659156788,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/9997_Classico_prosciutto_crudo_piatto_1494839264_1562856478_NumrkDQ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_mSJeR90_v2oKeLa.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695365,
                            "field1": "parmacotto ",
                            "field2": "prosciutto cotto ",
                            "field3": "alta qualità",
                            "field4": "(prezzo con iva € 7,92)",
                            "description": "parmacotto  prosciutto cotto  alta qualità al kg",
                            "grammage": 0,
                            "price": "7.20",
                            "price_label": "€ 7,20 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 10.256658791100119,
                                "left": 0.0,
                                "width": 33.671394346448906,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/Parmacotto_Prosciutto_cotto_tagliere_1519047228_1519299406__Dz5vYG1.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/Prosciutto_Cotto_a_fette_Piatto_1435163452_1541147311_B353RQo.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_bmcrgtX_OfLpPAv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "SITO WEB - PARMACOTTO",
                                    "data": "https://www.parmacotto.com/prodotti/prosciutti-cotti/parmacotto/"
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA' - Curiosità Prosciutto Cotto",
                                    "data": {

                                        "titolo": " Curiosità Prosciutto Cotto",
                                        "testo": "Il prosciutto cotto è il salume preferito dagli italiani: nel 2017 ne sono state consumate 280.300 tonnellate, corrispondenti a circa 4,5 kg a testa (il 26,5% del settore). I motivi sono da ricercare in tre fattori: è molto amato dai bambini, costa meno del prosciutto crudo e in genere risulta meno grasso, quindi viene percepito come più salutare. Al supermercato troviamo, oltre ai marchi noti, come Rovagnati, Citterio e Beretta, almeno una confezione imbustata con il marchio del supermercato. Le tipologie sono tante e il prezzo, per quello confezionato affettato in vaschetta, varia da meno di 8 €/kg a più di 60! (Fonte: ilfattoalimentare.it)",
                                        "img": [
                                            "/media/interactive_flyer_7/images/parmacotto.jpg"
                                        ]
                                    }
                                },
                            ],
                            "related": [695366,695367,695368]
                        },
                        {
                            "item_id": 695367,
                            "field1": "viani",
                            "field2": "prosciutto crudo toscano dop",
                            "field3": "al kg",
                            "field4": "",
                            "description": "viani prosciutto crudo toscano dop al kg",
                            "grammage": 0,
                            "price": "13.30",
                            "price_label": "€ 13,30 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 37.93049300060167,
                                "left": 0.0,
                                "width": 33.671394346448906,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/prosciutto_crudo_1459438058_1490598406_1541526721_1543601013.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_40aFO3K_uTLhVye.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Risotto alla verza e prosciutto crudo toscano DOP",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_7/images/ricetta_toscano.jpg"
                                        ],
                                        "ingredients": "100g di prosciutto toscano DOP Sapori&Dintorni Conad, 240g di riso carnaroli, 1 verza media, Brodo vegetale, 1 scalogno, ½ cucchiaio di concentrato di pomodoro, Parmigiano, Burro, Olio extra vergine di Oliva, Sale, Pepe",
                                        "recipe": "Per preparare la ricetta del risotto con verza e prosciutto crudo toscano, stufate dolcemente in una casseruola lo scalogno insieme alla verza tagliata finemente. Regolate di sale e pepe e continuate la cottura bagnando con il brodo vegetale. A metà cottura aggiungete il concentrato di pomodoro. Nel frattempo in un’altra casseruola, tostate il riso, salate, bagnate con brodo e continuare la cottura. A metà cottura aggiungete la verza stufata e continuate la cottura del riso. Nel frattempo realizzate un crumble di prosciutto crudo tagliandolo finemente e facendolo rosolare a fiamma bassa in una padella. Trascorsi 16 minuti di cottura mantecate il riso con parmigiano e burro. Impiattate posizionando sulla base di un piatto, il riso e sopra il crumble di prosciutto. Il vostro risotto con verza e prosciutto crudo toscano è pronto! (Fonte: saporie.com)"
                                    }
                                },
                            ]
                        },
                        {
                            "item_id": 695368,
                            "field1": "prosciutto crudo di parma dop",
                            "field2": "stagionato 14/16 mesi",
                            "field3": "",
                            "field4": "al kg",
                            "description": "prosciutto crudo di parma dop stagionato 14/16 mesi al kg",
                            "grammage": 0,
                            "price": "8.80",
                            "price_label": "€ 8,80 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 37.94357467895058,
                                "left": 34.23942045703892,
                                "width": 31.375745016682874,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/gennari_piatto_prosciutto_crudo_1452521300_1497862495_15420_XR19xEN.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/gennari_prosciutto_di_parma_dop_1552572813_1554208080.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_isO2OS1_i3v7FgY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Prosciutto Crudo di Parma",
                                    "data": "/media/interactive_flyer_7/video/parma.mp4"
                                },
                            ],
                            "related": [695365,695366,695367]
                        },
                        {
                            "item_id": 695369,
                            "field1": "senfter",
                            "field2": "speck montagna stagionato",
                            "field3": "(prezzo con iva € 6,59)",
                            "field4": "",
                            "description": "senfter speck montagna stagionato al kg",
                            "grammage": 0,
                            "price": "5.99",
                            "price_label": "€ 5,99 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 37.98008168829638,
                                "left": 66.12253068560547,
                                "width": 33.877469314394546,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/speck_ambientato_1453477715_1470128608_1542617458.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_J36jhDx_J5rxR5k.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695370,
                            "field1": "bresaola sottofesa",
                            "field2": "alta qualità",
                            "field3": "al kg",
                            "field4": "",
                            "description": "bresaola sottofesa alta qualità al kg",
                            "grammage": 0,
                            "price": "13.00",
                            "price_label": "€ 13,00 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 65.64935252162972,
                                "left": 0.0,
                                "width": 33.877469314394546,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/bresaola_carpaccio_ambientata_carrefour_1473152071_15335399_utfPyFe.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_g917JDD_0RehI8A.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                
                            ],
                            "related": [695369]
                        },
                        {
                            "item_id": 695371,
                            "field1": "raspini",
                            "field2": "salame",
                            "field3": "milano/ungherese",
                            "field4": "(prezzo con iva € 6,27)",
                            "description": "raspini salame milano/ungherese al kg",
                            "grammage": null,
                            "price": "5.70",
                            "price_label": "€ 5,70 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 65.71841161430883,
                                "left": 34.282442371015044,
                                "width": 31.279375929376354,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/salame_ungherese_generico__1471613481_1530886954_1558016253_ZsFwQK8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_dYjp2pu_UcU6Ynj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695372,
                            "field1": "becher",
                            "field2": "fesa di tacchino",
                            "field3": "arrosto",
                            "field4": "(prezzo con iva € 7,69)",
                            "description": "becher fesa di tacchino arrosto al kg",
                            "grammage": 0,
                            "price": "6.99",
                            "price_label": "€ 6,99 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 65.65908772412193,
                                "left": 66.12339112388497,
                                "width": 33.87660887611502,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/fesa_petto_tacchino_2_1524585800_1549012493_1565187717.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_fJnnqz7_S76tjkx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "/media/interactive_flyer_7/pages/page_3.jpg",
                    "products": [
                        {
                            "item_id": 695376,
                            "field1": "iriai",
                            "field2": "pecorino sardo",
                            "field3": "semistagionato",
                            "field4": "al kg",
                            "description": "iriai pecorino sardo semistagionato al kg",
                            "grammage": 0,
                            "price": "9.90",
                            "price_label": "€ 9,90 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 10.287385523966163,
                                "left": 0.009895040214508771,
                                "width": 49.589502517615195,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/iriai_pecorino_sardo_1544457572.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_28CBScG_gHKyVqE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Pasta con salciccia e pecorino",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_7/images/pecorino_sardo.jpg"
                                        ],
                                        "ingredients": "320 gr di pasta corta, 120 gr di salsiccia fresca di maiale, 70 gr di pecorino sardo stagionato, 3 cucchiai di panna fresca, prezzemolo, olio extravergine d’oliva, vino bianco, sale e pepe",
                                        "recipe": "Portare ad ebollizione abbondante acqua, salarla e calare la pasta. Nel frattempo sbiciolare la salsiccia con la forchetta,  dopo aver eliminato la pelle e rosolarla ad alta temperatura in una padella. Sfumare con il vino bianco, far rosolare di nuovo e tenere da parte. Scaldare la panna a fuoco dolce per 5 minuti. Grattugiare il formaggio e tritare il prezzemolo( circa un cucchiaio). Scolare la pasta bene al dente e mantecarla con la panna e il formaggio per circa 2 minuti. Togliere dal fuoco, unire la salsiccia, il prezzemolo, una generosa macinata di pepe e un cucchiaio di olio. Mescolare e servire nei piatti. (Fonte: ricettedellanonna.net)"
                                    }
                                },
                            ],
                            "related": [695375, 695373, 634267]
                        },
                        {
                            "item_id": 695375,
                            "field1": "jager",
                            "field2": "bavarella ",
                            "field3": "formaggio a pasta filata ",
                            "field4": "al kg",
                            "description": "jager bavarella  formaggio a pasta filata  al kg",
                            "grammage": 0,
                            "price": "4.20",
                            "price_label": "€ 4,20 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 10.249965839386723,
                                "left": 50.41049748238481,
                                "width": 49.589502517615195,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/jager_bavarella_pasta_filata_1429890335_1528101901.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_Zu44YIR_cIM5nFl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ],
                            "related": [695376, 695373, 634267]
                        },
                        {
                            "item_id": 695373,
                            "field1": "grana padano scelto",
                            "field2": "stagionato 15 mesi",
                            "field3": "(prezzo con iva € 8,84)",
                            "field4": "",
                            "description": "grana padano scelto stagionato 15 mesi al kg",
                            "grammage": 0,
                            "price": "8.50",
                            "price_label": "€ 8,50 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 37.90280851851445,
                                "left": 0.0,
                                "width": 100.00000000000001,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/grana_padano_mezza_forma_amb_1453196988_1542618150.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_nSWJMT9_dQRso78.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "SITO WEB - IL GRANA PADANO DOP",
                                    "data": "https://www.granapadano.it/it-it/il-grana-padano-dop-1.aspx/"
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Grana Padano",
                                    "data": "/media/interactive_flyer_7/video/grana.mp4"
                                },
                            ],
                            "related": [695376, 695375, 634267]
                        },
                        {
                            "item_id": 695374,
                            "field1": "provolone piccante",
                            "field2": "trancio",
                            "field3": "al kg",
                            "field4": "",
                            "description": "provolone piccante trancio al kg",
                            "grammage": 0,
                            "price": "5.50",
                            "price_label": "€ 5,50 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 65.67612432848331,
                                "left": 0.0,
                                "width": 49.65618648427819,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/fette_provolone_generiche_1432030928_1493808231_1497888487__0Gk3yaF.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_IQWhfBO_xmRSmve.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ],
                            "related": [695376, 695375, 695373]
                        },
                        {
                            "item_id": 695484,
                            "field1": "acciughe salate",
                            "field2": "latta",
                            "field3": "5 kg - al kg",
                            "field4": "",
                            "description": "acciughe salate latta 5 kg - al kg",
                            "grammage": null,
                            "price": "7.00",
                            "price_label": "€ 7,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pescheria",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 65.73666511898175,
                                "left": 50.007382148428526,
                                "width": 49.65618648427819,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/Acciughe_Salate_1512989155_1568975179.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_ANBt4qj_hrW2Szn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "/media/interactive_flyer_7/pages/page_4.jpg",
                    "products": [
                        {
                            "item_id": 695377,
                            "field1": "osella                    ",
                            "field2": "stracchino",
                            "field3": "100 gr",
                            "field4": "",
                            "description": "osella                     stracchino 100 gr",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 10.256658791100127,
                                "left": 0.0,
                                "width": 33.97340818256131,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/stracchino-osella-100g_1505372844_1562856727.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_nEFFwS9_f2wfesL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695378,
                            "field1": "citterio",
                            "field2": "taglio fresco prosciutto crudo del poggio",
                            "field3": "vaschetta",
                            "field4": "70 gr",
                            "description": "citterio taglio fresco prosciutto crudo del poggio vaschetta 70 gr",
                            "grammage": 70,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 10.299250302003555,
                                "left": 34.31255771079833,
                                "width": 31.654096800108412,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/tf-crudo-poggio-70gr-h152-new-r00186hQPL4O_1552383318.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_WmJQfGM_7wXcMCx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695379,
                            "field1": "citterio",
                            "field2": "taglio fresco bresaola della valtellina igp",
                            "field3": "",
                            "field4": "70 gr",
                            "description": "citterio taglio fresco bresaola della valtellina igp 70 gr",
                            "grammage": 70,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 10.25696301617801,
                                "left": 66.03734729593272,
                                "width": 33.96265270406729,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/citterio_taglio_fresco_Bresaola_g70_1538650777_1542626106.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_nw5WQ1J_jQ9fxGX.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695380,
                            "field1": "citterio",
                            "field2": "taglio fresco ",
                            "field3": "salame milano 70 gr/",
                            "field4": "prosciutto cotto di alta qualità 80 gr",
                            "description": "citterio taglio fresco  salame milano 70 gr/ prosciutto cotto di alta qualità 80 gr",
                            "grammage": 0,
                            "price": "1.10",
                            "price_label": "€ 1,10",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Salame Milano 70gr.",
                                "Prosciutto Cotto 80gr."
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 37.956960582377384,
                                "left": 0.0,
                                "width": 65.63796708812916,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/citterio_taglio_fresco_salame_milano_70_g_1469026570.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/citterio_taglio_fresco_cotto_80_gr_1554285911_1568975243.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_jwCBJAX_uXHTvlW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695381,
                            "field1": "bayernland",
                            "field2": "pasta filata",
                            "field3": "fette 140 gr",
                            "field4": "",
                            "description": "bayernland pasta filata fette 140 gr",
                            "grammage": 140,
                            "price": "0.90",
                            "price_label": "€ 0,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 37.9882957653992,
                                "left": 65.99991823077347,
                                "width": 34.000081769226526,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/scamorza_1506011223_1552384974.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_fQFz3Rz_Rbj1ZmB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695485,
                            "field1": "bayernland",
                            "field2": "emmental",
                            "field3": "bavarese",
                            "field4": "250 gr",
                            "description": "bayernland emmental bavarese 250 gr",
                            "grammage": 250,
                            "price": "1.40",
                            "price_label": "€ 1,40",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 66.01381416493192,
                                "left": 0.29685120643526314,
                                "width": 34.000081769226526,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/BLD-EMMENTAL-BAV-250g_1495104465_1568971504.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_btOveSC_7EnxgfT.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695382,
                            "field1": "kraft",
                            "field2": "sottilette ",
                            "field3": "classiche",
                            "field4": "200 gr",
                            "description": "kraft sottilette  classiche 200 gr",
                            "grammage": 200,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 65.68555530589764,
                                "left": 34.264373167145074,
                                "width": 31.48588111646177,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/Sottilette_Classiche_1441877756_1543602401.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_18avsfg_7FtVsI6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Spot Sottilette",
                                    "data": "/media/interactive_flyer_7/video/sottilette.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": 695383,
                            "field1": "susanna",
                            "field2": "formaggini ",
                            "field3": "140 gr",
                            "field4": "",
                            "description": "susanna formaggini  140 gr",
                            "grammage": 140,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 65.66730180122474,
                                "left": 65.92247878561648,
                                "width": 34.077521214383545,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/Sottilette_Susanna_8_formaggini_140_g_1430734725_1533723064.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_C8pnqtO_M3itQMW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "/media/interactive_flyer_7/pages/page_5.jpg",
                    "products": [
                        {
                            "item_id": 695486,
                            "field1": "philadelphia ",
                            "field2": "classico",
                            "field3": "250 gr",
                            "field4": "",
                            "description": "philadelphia  classico 250 gr",
                            "grammage": 250,
                            "price": "2.10",
                            "price_label": "€ 2,10",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 10.23686964563863,
                                "left": 0.0,
                                "width": 34.077521214383545,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/Philadelphia_gr_250_1523263220_1536591314_1567007514_1568971558.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_AlzLKFD_POrLsfh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Philadelphia Spot",
                                    "data": "/media/interactive_flyer_7/video/philadelphia.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": 695384,
                            "field1": "jocca",
                            "field2": "fiocchi di formaggio",
                            "field3": "",
                            "field4": "175 gr",
                            "description": "jocca fiocchi di formaggio 175 gr",
                            "grammage": 175,
                            "price": "1.25",
                            "price_label": "€ 1,25",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 10.273376654984423,
                                "left": 34.32632472327069,
                                "width": 31.301747324643955,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/Jocca_gr_175_1455893594_1496317223_1536048538_1536575529.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_uSUXDLF_WW3OzsD.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695487,
                            "field1": "merano bella vita",
                            "field2": "yogurt drink classico",
                            "field3": "vari gusti ",
                            "field4": "200 gr",
                            "description": "merano bella vita yogurt drink classico vari gusti  200 gr",
                            "grammage": 200,
                            "price": "0.35",
                            "price_label": "€ 0,35",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 10.257861176012462,
                                "left": 65.98744187572042,
                                "width": 34.01255812427959,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/39631_1548944470_1559315670_1568971622.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/39632_1548944471_1559315670_1568971622.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_NenSJx4_kw0VTuY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695488,
                            "field1": "yogurt",
                            "field2": "parmalat",
                            "field3": "classico/magro",
                            "field4": "125 gr x 8",
                            "description": "yogurt parmalat classico/magro 125 gr x 8",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Magro"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 38.079548773364486,
                                "left": 0.010325259354270023,
                                "width": 34.01255812427959,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/parmalat_yogurt_pezzi_magro_125_gr_x_8_1467104589_149682477_lMBeHOm.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/parmalat_yogurt_vellutato_125_gr_x_8_1467104364_1496824770__nqGyhVl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_2mEvL56_qssqnyq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695385,
                            "field1": "nestlè",
                            "field2": "nesquik",
                            "field3": "dessert choc pots",
                            "field4": "60 gr x 6",
                            "description": "nestlè nesquik dessert choc pots 60 gr x 6",
                            "grammage": 360,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 37.93352073598131,
                                "left": 34.273837988219825,
                                "width": 31.412313643562594,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/3D_Nesquik_Choc_Pots_IT-UK_1553944459_1566317931.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_DCTYxQ1_GftYSH8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695489,
                            "field1": "granarolo",
                            "field2": "la merenda della lola",
                            "field3": "latte al cacao + loacker wafer 97,2 gr/",
                            "field4": "yomino + loacker wafer 97,2 gr",
                            "description": "granarolo la merenda della lola latte al cacao + loacker wafer 97,2 gr/ yomino + loacker wafer 97,2 gr",
                            "grammage": 0,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Latte al Cacao/Loacker",
                                "Yomino/Loacker"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 37.96455169392524,
                                "left": 66.02185940690133,
                                "width": 33.9781405930987,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/8002670050813_1560256856_1560874547_1568971705.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/8002670050820_1560256856_1560874548_1568971705.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_7PPx8fe_hgGDotX.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695386,
                            "field1": "nestlè fruttolo ",
                            "field2": "yogurt & smarties ",
                            "field3": "fragola/vaniglia",
                            "field4": "120 gr",
                            "description": "nestlè fruttolo  yogurt & smarties  fragola/vaniglia 120 gr",
                            "grammage": 120,
                            "price": "0.62",
                            "price_label": "€ 0,62",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fragola",
                                "Vaniglia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 65.94595745716511,
                                "left": 0.43538176943838597,
                                "width": 33.9781405930987,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/nestle_fruttolo_smarties_fragola_1440154177_1441009493_1441_Up5ncuH.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/nestle_fruttolo_smarties_vaniglia_1440154191_1441009499_144_M2RNjQT.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_aZkGcSO_EHwYTKg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695387,
                            "field1": "fontaneto",
                            "field2": "pasta fresca ripiena fattibene",
                            "field3": "vari tipi",
                            "field4": "250 gr",
                            "description": "fontaneto pasta fresca ripiena fattibene vari tipi 250 gr",
                            "grammage": 250,
                            "price": "0.91",
                            "price_label": "€ 0,91",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 65.65724785825546,
                                "left": 34.21747928091109,
                                "width": 65.78252071908892,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/fontaneto_pasta_fresca_250_gr_1554288116.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_GSno0A6_h3VDOHY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "SITO WEB - Prodotti Fontaneto",
                                    "data": "http://www.fontaneto.com/it/linea-eccellenza/fatti-bene"
                                }

                            ]
                        }
                    ]
                },
                {
                    "number": 6,
                    "image_file": "/media/interactive_flyer_7/pages/page_6.jpg",
                    "products": [
                        {
                            "item_id": 695388,
                            "field1": "findus ",
                            "field2": "minestrone tradizionale igp",
                            "field3": "",
                            "field4": "1 kg",
                            "description": "findus  minestrone tradizionale igp 1 kg",
                            "grammage": null,
                            "price": "2.89",
                            "price_label": "€ 2,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Verdure",
                            "blueprint": {
                                "top": 10.238390771028039,
                                "left": 0.0,
                                "width": 33.7514151064445,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/Findus_minestrone_tradizionale_1kg_1532682749_1535959579_1559062490.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_bVE4osZ_vkP2AyO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695389,
                            "field1": "findus",
                            "field2": "4 sofficini",
                            "field3": "vari gusti",
                            "field4": "266 gr",
                            "description": "findus 4 sofficini vari gusti 266 gr",
                            "grammage": 266,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Preparati",
                            "blueprint": {
                                "top": 10.261511876947042,
                                "left": 34.41365920864222,
                                "width": 65.58634079135778,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/55428-ZR3_1550077031_1554209967.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/55430-ZR3_1550077032_1554209968.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_19smPh4_EUP9zxJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695390,
                            "field1": "findus",
                            "field2": "fiori di merluzzo",
                            "field3": "300 gr",
                            "field4": "",
                            "description": "findus fiori di merluzzo 300 gr",
                            "grammage": 300,
                            "price": "4.29",
                            "price_label": "€ 4,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 37.94934044003116,
                                "left": 0.010325259354270023,
                                "width": 33.73850853225166,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/findus_fiori_merluzzo_300_gr_1559580378_1561566951.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_yJvCB6G_vCr4S1w.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Fiori di Merluzzo agli agrumi",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_7/images/agliagrumi.jpg"
                                        ],
                                        "ingredients": "1 limone fresco, 4 Fiori di Merluzzo, 1 Arancia, 1 cucchiaino di amido di riso, 1 cucchiaio di olio EVO, 1 rametto di finocchietto selvatico, 1 cucchiaio di scorza d'arancia candita, 1 cucchiaio di mandorle a lamelle tostate",
                                        "recipe": "Ricavare due rondelle dagli agrumi. Con uno spremiagrumi spremere il resto. In una padella antiaderente scaldare l'olio e soffriggere velocemente i Fiori di Merluzzo d'Alaska con le rondelle di agrumi. Sfumare con i succhi e bagnare con mezzo bicchiere d'acqua. Aggiungere infine le scorze candite a lasciar cuocere per 5-6 minuti. Nel frattempo sciogliere l'amido in un bicchiere con un po' d'acqua e versarlo in padella. Aiutandosi con l'amido che farà tirare la salsa, portarla alla densità desiderata. Nei piatti mettere prima le rondelle, sopra i Fiori e aggiungere un cucchiaio di salsa. Cospargere con le mandorle tostate e decorare con il finocchietto."
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": 695391,
                            "field1": "algida ",
                            "field2": "carte d'or",
                            "field3": "vari gusti",
                            "field4": "400/530/600 gr",
                            "description": "algida  carte d'or vari gusti 400/530/600 gr",
                            "grammage": 0,
                            "price": "2.39",
                            "price_label": "€ 2,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 37.93990946261682,
                                "left": 34.24243199101725,
                                "width": 65.75756800898276,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/41350554_ALGIDA_CDO_CLS_TIRAMISU_400_1553186195_1553600700__69wXUHy.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/41359694_ALGIDA_CDO_CLS_NOCCIOLA_400_1553186316_1553600701__feyyqRf.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_HECHclM_pCd2d7T.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 695392,
                            "field1": "algida ",
                            "field2": "cornetto x 5",
                            "field3": "vari gusti",
                            "field4": "375 gr",
                            "description": "algida  cornetto x 5 vari gusti 375 gr",
                            "grammage": 375,
                            "price": "3.39",
                            "price_label": "€ 3,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 65.63716900311526,
                                "left": 0.030975778062810072,
                                "width": 65.75756800898276,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/55985-zt4_1510994195_1554210489.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/55989-zt4_1510994196_1554210490.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_bHxCyPh_XsxRKum.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Spot Cornetto",
                                    "data": "/media/interactive_flyer_7/video/cornetto.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": 695393,
                            "field1": "algida",
                            "field2": "cucciolone",
                            "field3": "480 gr",
                            "field4": "",
                            "description": "algida cucciolone 480 gr",
                            "grammage": 480,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 65.77011536214954,
                                "left": 65.90828155400436,
                                "width": 34.08096296750162,
                                "height": 27.427078640541197
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_7/products/Algida_cucciolone_x_6_1486631653_1488279327_1552486983_1559062914.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_7/products/cropped_image_PQaA3aK_HHZQ9Xr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "/media/interactive_flyer_7/pages/page_7.jpg",
                    "products": []
                },
                {
                    "number": 8,
                    "image_file": "/media/interactive_flyer_7/pages/page_8.jpg",
                    "products": []
                },
                {
                    "number": 9,
                    "image_file": "/media/interactive_flyer_7/pages/page_9.jpg",
                    "products": []
                },
                {
                    "number": 10,
                    "image_file": "/media/interactive_flyer_7/pages/page_10.jpg",
                    "products": []
                },
                {
                    "number": 11,
                    "image_file": "/media/interactive_flyer_7/pages/page_11.jpg",
                    "products": []
                },
                {
                    "number": 12,
                    "image_file": "/media/interactive_flyer_7/pages/page_12.jpg",
                    "products": []
                },
                {
                    "number": 13,
                    "image_file": "/media/interactive_flyer_7/pages/page_13.jpg",
                    "products": []
                },
                {
                    "number": 14,
                    "image_file": "/media/interactive_flyer_7/pages/page_14.jpg",
                    "products": []
                },
                {
                    "number": 15,
                    "image_file": "/media/interactive_flyer_7/pages/page_15.jpg",
                    "products": []
                },
                {
                    "number": 16,
                    "image_file": "/media/interactive_flyer_7/pages/page_16.jpg",
                    "products": []
                },
                {
                    "number": 17,
                    "image_file": "/media/interactive_flyer_7/pages/page_17.jpg",
                    "products": []
                },
                {
                    "number": 18,
                    "image_file": "/media/interactive_flyer_7/pages/page_18.jpg",
                    "products": []
                },
                {
                    "number": 19,
                    "image_file": "/media/interactive_flyer_7/pages/page_19.jpg",
                    "products": []
                },
                {
                    "number": 20,
                    "image_file": "/media/interactive_flyer_7/pages/page_20.jpg",
                    "products": []
                },
                {
                    "number": 21,
                    "image_file": "/media/interactive_flyer_7/pages/page_21.png",
                    "products": []
                }
            ]
        }
    },
    "eurospar": {
        "config": {
            "primary-color": "rgb(220, 16, 39)",
            "secondary-color": "rgb(6, 98, 39)",
            "tertiary-color": "rgb(255, 255, 255)",
            "hover-color": "rgba(19, 696, 172, 0.2)",
            "clientIcon": require("assets/images/eurospar_clientLogo.png"),
            "serverPath": "https://eurospar.volantinointerattivo.net",
            "pdfPath": "/media/interactive_flyer_11/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino Eurospar!",
            "shareFlyerURL": "https://eurospar.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Eurospar!",
            "ogTitleMeta": "Volantino Interattivo Eurospar",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://eurospar.volantinointerattivo.net/media/interactive_flyer_11/images/eurospar_clientLogo.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": ""
        },
        "leaflet": {
            "name": "Eurospar Calabria",
            "code": "eurospar19",
            "id_campaign": "12346",
            "index": {
                "image_file": "/media/interactive_flyer_11/pages/indice.png",
                "links": [
                    {   //GRANDI OCCASIONI
                        "page": 0,
                        "blueprint": {
                            "top": 11.215937794066667,
                            "left": 2.2131147540983642,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //COLAZIONE
                        "page": 2,
                        "blueprint": {
                            "top": 17.42655885617288,
                            "left": 2.2131147540983642,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //DOLCE PAUSA
                        "page": 4,
                        "blueprint": {
                            "top": 23.72718891917918,
                            "left": 2.2131147540983642,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //DISPENSA
                        "page": 6,
                        "blueprint": {
                            "top": 29.93780998128539,
                            "left": 2.2131147540983642,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //FRESCHI
                        "page": 8,
                        "blueprint": {
                            "top": 36.32844904519178,
                            "left": 2.2131147540983642,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //FORMAGGI
                        "page": 10,
                        "blueprint": {
                            "top": 42.629079108198084,
                            "left": 2.2131147540983642,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //MACELLERIA
                        "page": 12,
                        "blueprint": {
                            "top": 48.929709171204394,
                            "left": 2.2131147540983642,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //SURGELATI
                        "page": 14,
                        "blueprint": {
                            "top": 54.96031223151042,
                            "left": 2.303232658810795,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //PESCHERIA
                        "page": 16,
                        "blueprint": {
                            "top": 61.35095129541681,
                            "left": 2.2131147540983642,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //BEVANDE
                        "page": 18,
                        "blueprint": {
                            "top": 67.56157235752303,
                            "left": 2.2131147540983642,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //CURA PERSONA
                        "page": 18,
                        "blueprint": {
                            "top": 73.95221142142941,
                            "left": 2.303232658810795,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //IGIENE DENTALE
                        "page": 20,
                        "blueprint": {
                            "top": 80.16283248353564,
                            "left": 2.303232658810795,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //CASA CALDA
                        "page": 22,
                        "blueprint": {
                            "top": 86.55347154744202,
                            "left": 2.303232658810795,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    },
                    {   //MY DESPAR
                        "page": 24,
                        "blueprint": {
                            "top": 92.76409260954824,
                            "left": 2.303232658810795,
                            "width": 95.84947839046201,
                            "height": 4.894522421440353
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Alimentari",
                    "subcategories": [
                        "Colazione",
                        "Dispensa",
                        "Snack Dolci",
                        "Pasta",
                        "Rossi",
                        "Olio",
                        "Snack Salati"
                    ]
                },
                {
                    "category_name": "Macelleria",
                    "subcategories": [
                        "Avicoli",
                        "Altro"
                    ]
                },
                {
                    "category_name": "Self Service",
                    "subcategories": [
                        "Formaggi",
                        "Yogurt",
                        "Pasta",
                        "Ittico"
                    ]
                },
                {
                    "category_name": "Baby Food",
                    "subcategories": []
                },
                {
                    "category_name": "Pet",
                    "subcategories": [
                        "Food"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "/media/interactive_flyer_11/pages/page_1.jpg",
                    "products": [
                        {
                            "item_id": 689072,
                            "field1": "latte ps uht ",
                            "field2": "granarolo",
                            "field3": "1l",
                            "field4": "",
                            "description": "latte ps uht  granarolo 1l",
                            "grammage": null,
                            "price": "0.68",
                            "price_label": "€ 0,68",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 26.180326476855107,
                                "left": 1.7415502191029033,
                                "width": 35.02166451330281,
                                "height": 31.319811733960826
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1026347_Latte_UHT_Parzialmente_Scremato_Granarolo_1L_1525360435.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_HLIpbJc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Spot Granarolo",
                                    "data": "/media/interactive_flyer_11/video/granarolo.mp4"
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.granarolo.it/prodotti/latte/tutti"
                                }
                            ]
                        },
                        {
                            "item_id": 689270,
                            "field1": "Minestrone Tradizione",
                            "field2": "FINDUS",
                            "field3": "1kg",
                            "field4": "",
                            "description": "Minestrone Tradizione FINDUS 1kg",
                            "grammage": null,
                            "price": "1.95",
                            "price_label": "€ 1,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 25.85854878948706,
                                "left": 36.19147613505118,
                                "width": 31.798587331190742,
                                "height": 31.319811733960826
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Minestrone_Tradizione_1000g_con_lente_1539859358.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_KbMMsst_ipPGeLg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Frittata di Minestrone",
                                    "data": {

                                        "ingredients": "300 gr di Minestrone Tradizione, 4 Uova, 20 gr di Latte, 1 cucchiaio di Farina, 1 filo di Olio EVO, Q.b. di Basilico",
                                        "recipe": "1. Rompere le uova e sbatterle con una forchetta, aggiungere la farina setacciata e infine il latte. 2.Ungere con l’olio una padella antiaderente e farla riscaldare. Aggiungere il Minestrone Tradizione già cotto nella padella e distribuirlo omogeneamente sulla superficie, quindi versarvi sopra le uova sbattute. 3. Cuocere coprendo la frittata su entrambi i lati: rigirarla solo quando l’uovo in superficie sarà rappreso e non liquido. 4. Servire guarnendo con foglie di basilico.(Fonte: findus.it)",
                                        "img": [
                                            "/media/interactive_flyer_11/images/minestrone.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA' - 5 domande sul Minestrone",
                                    "data": {

                                        "titolo": "5 domande e 5 risposte sul minestrone Findus",
                                        "testo": "[1] Il Minestrone Findus ha ingredienti 100% italiani (Vero). Le 15 varietà di verdure contenute nel Minestrone Tradizione provengono esclusivamente da colture italiane. Tra gli ingredienti presenti, spiccano molte eccellenze della nostra produzione agroalimentare, come la Patata del Fucino IGP, il Basilico Genovese DOP e la Cipolla rossa di Tropea Calabria IGP. [2] Il Minestrone surgelato Findus ha tempi di cottura lenti (Falso). In pentola, il minestrone surgelato Findus cuoce in appena 18 minuti. Per chi è sempre di corsa, c'è anche il nuovo Minestrone Tradizione 5 minuti: in questo caso, i tempi di preparazione si riducono a soli 5 minuti dal bollore. [3] Il Minestrone surgelato Findus deve prima essere scongelato per la cottura (Falso). Il Minestrone Tradizione non ha bisogno di essere preventivamente decongelato: lo si versa direttamente in pentola, così come si presenta. [4] Il Minestrone surgelato Findus può essere cucinato anche in pentola a pressione (Vero). Per velocizzare ulteriormente i tempi di cottura del Minestrone Tradizione, è possibile ricorrere alla pentola a pressione. Basta versare il prodotto ancora surgelato in pentola con 600 ml di acqua, quindi proseguire la cottura per 6 minuti a partire dal sibilo. [5] Le verdure del Minestrone Findus sono surgelate entro poche ore dal raccolto (Vero). Gran parte delle verdure presenti nel Minestrone Tradizione 100% italiano di Findus (tranne quelle che non ne hanno bisogno) vengono lavorate in loco, direttamente nelle aree di raccolta o in stabilimenti presenti nelle immediate vicinanze. In questo modo, il processo di surgelazione può essere completato in pochissime ore, così da garantire sempre ortaggi dal sapore autentico e ricchi sotto il profilo nutritivo. (Fonte: findus.it)",
                                        "img": [
                                            "/media/interactive_flyer_11/images/minestrone_curiosità.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito Findus",
                                    "data": "https://www.findus.it"
                                }
                            ]
                        },
                        {
                            "item_id": 689188,
                            "field1": "petto di pollo ",
                            "field2": "A FETTE",
                            "field3": "Al Kg",
                            "field4": "",
                            "description": "petto di pollo  A FETTE Al Kg",
                            "grammage": null,
                            "price": "5.99",
                            "price_label": "€ 5,99 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 55.370533270779575,
                                "left": 3.4934696695339382,
                                "width": 39.84091626438039,
                                "height": 30.55199261816795
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/petto_pollo_a_fette_tagliere_1524555521.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/petto_pollo_passo_dopo_passo_1559117550.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_SkrAgJL_S9qOZq3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                
                                
                            ]
                        },
                        {
                            "item_id": 689083,
                            "field1": "PHILADELPHIA ",
                            "field2": "classico",
                            "field3": "250g",
                            "field4": "",
                            "description": "PHILADELPHIA  classico 250g",
                            "grammage": 250,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 57.01299471836148,
                                "left": 42.74623098418911,
                                "width": 24.94665441093212,
                                "height": 28.63876701902025
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/445670_Philly_ClassicoTub250_1521712718_1521827087_1522080_0KIAMkB.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_Ls9PmiJ_7YFjcyG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - CHEESECAKE OREO CON ESSENZA DI VANIGLIA",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_11/images/philadelphia.jpg"
                                        ],
                                        "ingredients": "Base: 300 g circa di biscotti Oreo, 100 g burro, Crema: 500 g di Philadelphia Original, 250 ml di panna da montare, 2 cucchiai di latte, 8-10 g di colla di pesce, 80 g di zucchero a velo, 1 cucchiaio di essenza di vaniglia",

                                        "recipe": "1.Prendete circa 220 g di biscotti Oreo, eliminate la crema dall’interno con un coltello e mettetela in una ciotolina da parte. Tritate i biscotti e amalgamateli con il burro fuso. Stendete il composto in uno stampo e fate riposare in frigo per 30 minuti. 2.Ammorbidite la crema estratta precedentemente dagli Oreo in una ciotola unendola a 50 ml di panna liquida da montare. Montate a parte a neve i 200 ml di panna rimanenti. In un’altra ciotola mescolate il Philadelphia con lo zucchero a velo e l’essenza di vaniglia. Mettete la colla di pesce in ammollo in abbondante acqua fredda per almeno 10 minuti. Scaldate 4 cucchiai di panna e scioglietevi dentro la colla di pesce scolata. Mescolate finché la preparazione sarà completamente fredda. Unite la colla di pesce alla crema di Philadelphia e aggiungete i biscotti Oreo rimasti (tritati o a pezzi). Unite i composti e amalgamate bene il tutto. Versate il composto ottenuto sulla base di biscotti. 3.Decorate a piacere con i biscotti Oreo restanti facendo riposare in frigo la cheesecake per 6-8 ore prima di servirla. (Fonte: philadelphia.it)"
                                    }
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Spot Philadelphia",
                                    "data": "/media/interactive_flyer_11/video/philadelphia.mp4"
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.philadelphia.it/"
                                }


                            ]
                        }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "/media/interactive_flyer_11/pages/page_2.jpg",
                    "products": [
                        {
                            "item_id": 689139,
                            "field1": "caffè mauro",
                            "field2": "original",
                            "field3": "250g x4",
                            "field4": "",
                            "description": "caffè mauro original 250g x4",
                            "grammage": 0,
                            "price": "4.99",
                            "price_label": "€ 4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 18.873182489590658,
                                "left": 4.109714281602903,
                                "width": 31.471523080113155,
                                "height": 25.158510243177727
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1061219_CAFFE__ORIGINAL__MAURO__x4__250g_1499354142.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_ggBypFs_SAC4F7t.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689130,
                            "field1": "Biscotti ricchi",
                            "field2": "BALOCCO",
                            "field3": "Vari Tipi",
                            "field4": "700g",
                            "description": "Biscotti ricchi BALOCCO Vari Tipi 700g",
                            "grammage": 700,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 44.45581667473022,
                                "left": 4.18421926543911,
                                "width": 31.078793419552813,
                                "height": 25.413577922188985
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Balocco_Faccine-700g_1478888428_1485343809.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Balocco_Girandole-700g_1478888429_1485343810.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Balocco_Gocciolotti-700g_1478888430_1485343811.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_4l74SlQ_hWKEgqz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689154,
                            "field1": "special k classic",
                            "field2": "kellogg's",
                            "field3": "375g + 125g",
                            "field4": "",
                            "description": "special k classic kellogg's 375g + 125g",
                            "grammage": 500,
                            "price": "2.69",
                            "price_label": "€ 2,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 70.40382985988934,
                                "left": 4.207370531602903,
                                "width": 31.078793419552813,
                                "height": 25.413577922188985
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1055935_SPECIAL_K_KELLOGGS_375G_125G_CLASSIC_1520673366.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_QmRwfjy_CQLrTZJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "SITO WEK - KELLOGGS SPECIAL K",
                                    "data": "https://www.kelloggs.it/it_IT/brands/special-k-consumer-brand.html"
                                },
                                {
                                    "type": "play",
                                    "title": "SPOT - Special K #PoweringYou",
                                    "data": "/media/interactive_flyer_11/video/Special K.mp4"
                                }
                            ]
                        },

                        {
                            "item_id": 689129,
                            "field1": "Biscotti\t",
                            "field2": "BALOCCO",
                            "field3": "vari tipi ",
                            "field4": "700g",
                            "description": "Biscotti\t BALOCCO vari tipi  700g",
                            "grammage": 700,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 6.782974504456955,
                                "left": 35.68383200789601,
                                "width": 20.71881227731143,
                                "height": 22.4613330425561
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1104472_1541427852.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1104475_1541427853.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_AduSVOx_wXD6Tie.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689131,
                            "field1": "Grancereale",
                            "field2": "MULINO BIANCO",
                            "field3": "500g",
                            "field4": "",
                            "description": "Grancereale MULINO BIANCO 500g",
                            "grammage": 500,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 6.8339880402592055,
                                "left": 56.54817400681842,
                                "width": 20.25031483657867,
                                "height": 22.4613330425561
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/gran_cereale_biscotto_classico_500_g_1527067982_1550154406.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_fTxJbBr_OsX9t0R.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689135,
                            "field1": "CROISSANT FARCITI",
                            "field2": "BAULI",
                            "field3": "GUSTI ASSORTITI",
                            "field4": "x6 300G",
                            "description": "CROISSANT FARCITI BAULI GUSTI ASSORTITI x6 300G",
                            "grammage": 300,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 6.672067578827509,
                                "left": 76.81926858836206,
                                "width": 19.440328663793103,
                                "height": 22.444647713611307
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Croissant_Albicocca_6pz_H_1440587890.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Croissant_Cioccolato_6pz_H_1440587899.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_xW8hOmz_4EC3Zkd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689173,
                            "field1": "Confetture",
                            "field2": "ZUEGG",
                            "field3": "gusti assortiti",
                            "field4": "320g",
                            "description": "Confetture ZUEGG gusti assortiti 320g",
                            "grammage": 320,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 29.077038097925954,
                                "left": 35.70093615301724,
                                "width": 20.82856276939655,
                                "height": 22.497841315046134
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/I_Frutteti_di_OZ_320330g_ALBICOCCHE_ITA_1486123401_1489749821.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/I_Frutteti_di_OZ_320330g_AMARENE_ITA_1486123403_1489749821.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/I_Frutteti_di_OZ_320330g_ARANCE_ITA_1486123404_1489749822.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/I_Frutteti_di_OZ_320330g_CILIEGIE_ITA_1486123405_1489749822.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/I_Frutteti_di_OZ_320330g_FRAGOLE_ITA_1486123406_1489749823.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/I_Frutteti_di_OZ_320330g_PESCHE_ITA_1486123406_1489749823.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/I_Frutteti_di_OZ_320330g_PRUGNE_ITA_1486123407_1489749824.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_Wl5YcpV_S3UU1km.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689164,
                            "field1": "NESQUIK",
                            "field2": "NESTLè",
                            "field3": "1KG",
                            "field4": "",
                            "description": "NESQUIK NESTLè 1KG",
                            "grammage": null,
                            "price": "4.69",
                            "price_label": "€ 4,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 51.40701435926382,
                                "left": 35.79732960668103,
                                "width": 20.82856276939655,
                                "height": 22.43113130668934
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/nesquick_1_kg_1480345242_1480604708_1485181798_1504196345.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_oW3kv2V_pyKybyN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689156,
                            "field1": "caffè kimbo",
                            "field2": "aroma italiano",
                            "field3": "250g x4",
                            "field4": "",
                            "description": "caffè kimbo aroma italiano 250g x4",
                            "grammage": 0,
                            "price": "5.99",
                            "price_label": "€ 5,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 73.77317971010243,
                                "left": 35.657580145474135,
                                "width": 20.82856276939655,
                                "height": 21.8272531264661
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Kimbo_3d_restyling_4x_Aroma_italiano_06062017_1518621137_1_Z1agBd9.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_ZLgq9fd_RX0P9ug.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689157,
                            "field1": "cialda ",
                            "field2": "kosè audace 50 pz",
                            "field3": "",
                            "field4": "",
                            "description": "cialda  kosè audace 50 pz",
                            "grammage": 0,
                            "price": "4.99",
                            "price_label": "€ 4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 74.20570473160528,
                                "left": 56.66293440193965,
                                "width": 20.156334186422413,
                                "height": 21.348510713552663
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/kose_cilade50_1558020595.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_TcEJVo0_zEvzXKe.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689153,
                            "field1": "caffè classico",
                            "field2": "hag",
                            "field3": "250g x 2",
                            "field4": "",
                            "description": "caffè classico hag 250g x 2",
                            "grammage": 500,
                            "price": "3.59",
                            "price_label": "€ 3,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 74.40191063853703,
                                "left": 76.83905239762932,
                                "width": 19.386028421336206,
                                "height": 21.348510713552663
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Hag_Classico_gr_250_x_2_1457536269.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_dOfrq9R_VHmcOfy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "/media/interactive_flyer_11/pages/page_3.jpg",
                    "products": [
                        {
                            "item_id": 689145,
                            "field1": "Zucchero zefiro",
                            "field2": "ERIDANIA  ",
                            "field3": "1kg",
                            "field4": "",
                            "description": "Zucchero zefiro ERIDANIA   1kg",
                            "grammage": null,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 6.7446870540601305,
                                "left": 3.5707671066810347,
                                "width": 19.386028421336206,
                                "height": 22.2698064498788
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1042751_ZUCCHERO_ZEFIRO_ERIDANIA_1KG_8000121310370_copia_1_DlX44Iy.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_9whFiO0_0aZkTu2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 123456,
                            "field1": "Zucchero\t",
                            "field2": "SEMOLATO\t",
                            "field3": "Despar\t",
                            "field4": "1Kg",
                            "description": "Zucchero\t SEMOLATO\t Despar\t 1kg",
                            "grammage": null,
                            "price": "0.59",
                            "price_label": "€ 0,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 7.951663453846864,
                                "left": 22.988901027565355,
                                "width": 21.229405152076506,
                                "height": 20.952797775941878
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/zucchero_despar.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/zucchero_despar2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
},
                        {
                            "item_id": 689146,
                            "field1": "Zucchero di canna\t",
                            "field2": "DEMERARA\t",
                            "field3": "tropicana\t",
                            "field4": "500g",
                            "description": "Zucchero di canna\t DEMERARA\t tropicana\t 500g",
                            "grammage": 500,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 7.003242838083509,
                                "left": 44.26437904094827,
                                "width": 20.379428205818964,
                                "height": 22.2698064498788
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Tropicana_Zucchero_canna_500_g_1464959335.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_Y3a323d_edsUenl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689133,
                            "field1": "Flauti",
                            "field2": "MULINO BIANCO",
                            "field3": "cioccolato/ stracciatella/ latte",
                            "field4": "280g",
                            "description": "Flauti MULINO BIANCO cioccolato/ stracciatella/ latte 280g",
                            "grammage": 280,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Cioccolato",
                                "Stracciatella",
                                "Latte"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 29.05155461969271,
                                "left": 43.37158203125,
                                "width": 21.347151131465516,
                                "height": 22.2698064498788
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Mulino_Bianco_Flauti_cioccolato_8pz_1524036116_1524581587__t8wv1Qd.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Mulino_Bianco_Flauti_latte_8pz_1524036117_1524581588_1524728887.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_kjpwVy9_VMm3kPG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "SITO WEB - MULINO BIANCO/FLAUTI",
                                    "data": "https://www.mulinobianco.it/flauti-cioccolato"
                                }
                            ]
                        },
                        {
                            "item_id": 689141,
                            "field1": "BISCOTTI",
                            "field2": "MISURA ",
                            "field3": "VARI TIPI",
                            "field4": "230/330G",
                            "description": "BISCOTTI MISURA  VARI TIPI 230/330G",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 51.41641193115177,
                                "left": 43.59088766163793,
                                "width": 21.347151131465516,
                                "height": 22.2698064498788
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/BISCOTTO_FBX_1_2_1468849406_1529661807.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1099258_multigrain_1538573126.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1099259_1538573126.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_6WrPtw9_rC1DPV0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689171,
                            "field1": "caffè intermezzo",
                            "field2": "segafredo",
                            "field3": "250g x2",
                            "field4": "",
                            "description": "caffè intermezzo segafredo 250g x2",
                            "grammage": 500,
                            "price": "2.59",
                            "price_label": "€ 2,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 36.09709073104816,
                                "left": 3.8064890894396552,
                                "width": 39.68337486530173,
                                "height": 22.225333110974276
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Intermezzo_2x250g_1517993869.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_dn8GBmQ_FXklasp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689162,
                            "field1": "Omogeneizzati frutta",
                            "field2": "MELLIN",
                            "field3": "vari gusti ",
                            "field4": "100g x2",
                            "description": "Omogeneizzati frutta MELLIN vari gusti  100g x2",
                            "grammage": 200,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Baby Food",
                            "subcategory": "",
                            "blueprint": {
                                "top": 74.22857213107943,
                                "left": 3.94287109375,
                                "width": 19.216392780172413,
                                "height": 21.460566087067008
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1000980_frutta-mista_1542793430.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1013744_omogeneizzato-pesca-con-mela-2-pz-mellin_550_1542793430.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_hXlkcyE_gs8m2y1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689163,
                            "field1": "Omogeneizzati carne",
                            "field2": "MELLIN",
                            "field3": "vari gusti",
                            "field4": "80g x2",
                            "description": "Omogeneizzati carne MELLIN vari gusti 80g x2",
                            "grammage": 160,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Baby Food",
                            "subcategory": "",
                            "blueprint": {
                                "top": 74.25429690554382,
                                "left": 23.20261988146552,
                                "width": 20.121817753232758,
                                "height": 21.460566087067008
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1002223_Mellin_OmoCarne_vitello_1542793453.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_qdU5ewS_mEepJm8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689165,
                            "field1": "Latte MIO",
                            "field2": "nestlè",
                            "field3": "brick",
                            "field4": "500ml",
                            "description": "Latte MIO nestlè brick 500ml",
                            "grammage": 500,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 73.99835720027953,
                                "left": 43.350535425646555,
                                "width": 21.349255792025865,
                                "height": 21.460566087067008
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/nestle_mio_latte_crescita_classico_500_ml_1429284082_1451929015.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_IDIyn07_Xc76UQx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689140,
                            "field1": "plumcake yogurt",
                            "field2": "despar ",
                            "field3": "x6",
                            "field4": "198g",
                            "description": "plumcake yogurt despar  x6 198g",
                            "grammage": 198,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 9.65507467354758,
                                "left": 66.08634159482759,
                                "width": 28.731142241379313,
                                "height": 33.38378104474548
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1002179_plumcake_2_yog_DESPAR_x6_198g_8001300738428_1470144358.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_Ij6O4MM_MAKnaxc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689169,
                            "field1": "Crema spalmabile alle nocciole",
                            "field2": "DESPAR",
                            "field3": "",
                            "field4": "400g",
                            "description": "Crema spalmabile alle nocciole DESPAR 400g",
                            "grammage": 400,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 43.03144349514231,
                                "left": 66.16884428879311,
                                "width": 28.731142241379313,
                                "height": 26.441144031247557
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1045913_CREMA_SPALMABILE_DESPAR_400G_NOCCIOLE_1495610538.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_wbB8IIk_r9AnFEg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689143,
                            "field1": "Merendine/ bomboloni",
                            "field2": "DESPAR",
                            "field3": "vari tipi",
                            "field4": "288/252g",
                            "description": "Merendine/ bomboloni DESPAR vari tipi 288/252g",
                            "grammage": 288,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 69.39628522924974,
                                "left": 66.10780913254311,
                                "width": 28.731142241379313,
                                "height": 25.289197350994996
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1055066_merend_glass_DESPAR_x6_228g_8001300801115_14647674_Ws9h8q2.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1055067_pandorino_DESPAR_x6_228g_8001300801108_1464767467__OshaU2h.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_Xl05vdv_jWjDOyy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "/media/interactive_flyer_11/pages/page_4.jpg",
                    "products": [
                        {
                            "item_id": 689170,
                            "field1": "Tisana",
                            "field2": "POMPADOUR",
                            "field3": "vari tipi",
                            "field4": "15/18 filtri",
                            "description": "Tisana POMPADOUR vari tipi 15/18 filtri",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 23.280048864013995,
                                "left": 3.928980334051724,
                                "width": 29.515338766163794,
                                "height": 26.237961914291578
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/pompadour_tisane_regolarita.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1033922_POMPADUR_snell_x15f_1490706807.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1033921_POMPADUR_rilass_x15f_1490706808.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1033907_POMPADUR_depur_x15_1490706808.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_ZeoMvaZ_cKSPLSw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689142,
                            "field1": "Tisane ",
                            "field2": "L'ANGELICA ",
                            "field3": "vari tipi",
                            "field4": "",
                            "description": "Tisane  L'ANGELICA  vari tipi",
                            "grammage": null,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 23.446169865216202,
                                "left": 33.651838631465516,
                                "width": 30.9814453125,
                                "height": 26.237961914291578
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/depurativa_1511956482.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/drenante_1511956482.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/finocchio_1511956482.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_iz3hNGX_KgxTYPz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689159,
                            "field1": "infuso",
                            "field2": "twinings",
                            "field3": "gusti assortiti 30/ 40g",
                            "field4": "20 filtri",
                            "description": "infuso twinings gusti assortiti 30/ 40g 20 filtri",
                            "grammage": 0,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 23.42916535328212,
                                "left": 65.09588833512932,
                                "width": 30.9814453125,
                                "height": 26.237961914291578
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/53980_1507287940.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/53978_1507287941.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_515GGOB_RHScUCQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689132,
                            "field1": "ringo",
                            "field2": "PAVESI",
                            "field3": "cacao/ vaniglia 330g/",
                            "field4": "nocciola e choco 310g",
                            "description": "ringo PAVESI cacao/ vaniglia 330g/ nocciola e choco 310g",
                            "grammage": 0,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Cacao",
                                "Vaniglia",
                                "Nocciola e Choco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 49.748225709105476,
                                "left": 3.909196524784483,
                                "width": 29.487136314655174,
                                "height": 23.09474328524513
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Ringo_fam_cacao_sx_1519228172_1521476901.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Ringo_fam_vaniglia_sx_1519228174_1521476902.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_VwCvmIK_yWP1YYM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "SPOT - RINGO SBAGLIATO",
                                    "data": "/media/interactive_flyer_11/video/ringo.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": 689134,
                            "field1": "Ringo Thin",
                            "field2": "Pavesi",
                            "field3": "234g",
                            "field4": "cioccolato/ vaniglia",
                            "description": "Ringo Thin Pavesi 234g cioccolato/ vaniglia",
                            "grammage": 234,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Cioccolato",
                                "Vaniglia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 49.712472632731256,
                                "left": 33.56175915948276,
                                "width": 31.146450700431032,
                                "height": 23.09474328524513
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Ringo_thin_cioccolato_multi_sx_1558449229.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Ringo_thin_vaniglia_multi_sx_1558449231.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_JA38SwU_rvWyJM1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689137,
                            "field1": "sfogliatine ",
                            "field2": "despar ",
                            "field3": "sifamì zuccherati/ glassati ",
                            "field4": "200g",
                            "description": "sfogliatine  despar  sifamì zuccherati/ glassati  200g",
                            "grammage": 200,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Sifamì Zuccherati",
                                "Glassati"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 49.7225009346411,
                                "left": 64.79913119612068,
                                "width": 31.146450700431032,
                                "height": 23.09474328524513
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1043313_sfogl_DESPAR_z_gr200_1490612704.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1043319_sfogl_DESPAR_g_gr200_1490612704.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_AIusKEl_Zqi3Ok0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689160,
                            "field1": "M&M’s Pouch",
                            "field2": "Crispy/ Choco/ Peanut",
                            "field3": "170/200g",
                            "field4": "",
                            "description": "M&M’s Pouch Crispy/ Choco/ Peanut 170/200g",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Crispy",
                                "Choco",
                                "Peanut"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 72.984673260468,
                                "left": 4.052734375,
                                "width": 21.90446524784483,
                                "height": 23.09474328524513
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/MM_s_Choco_200_gr_1442250122_1511371321_1513770052_1514485253.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/MM_s_Peanut_200_gr_1514485255.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_mSze2BX_ve9dvOp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689222,
                            "field1": "crema",
                            "field2": "zaini crockki ",
                            "field3": "frozen/ avengers",
                            "field4": "18g x2",
                            "description": "crema zaini crockki  frozen/ avengers 18g x2",
                            "grammage": 36,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Frozen",
                                "Avengers"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 72.89354651702637,
                                "left": 25.987506734913794,
                                "width": 23.423609240301722,
                                "height": 23.09474328524513
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/frozen_zaini_croccki_1525678319.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_mmcYQbv_uH6xPJ2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689151,
                            "field1": "Caramelle",
                            "field2": "FIDA",
                            "field3": "vari tipi",
                            "field4": "160/200g",
                            "description": "Caramelle FIDA vari tipi 160/200g",
                            "grammage": 0,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 72.86651370318243,
                                "left": 49.272629310344826,
                                "width": 23.423609240301722,
                                "height": 23.09474328524513
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1001443_bonelle_FIDA_g200_8006150100029_1458753129.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_4OdIQeQ_IAQJC8o.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689136,
                            "field1": "Bignoline/ Bignè ",
                            "field2": "LOIZZO  ",
                            "field3": "180g",
                            "field4": "",
                            "description": "Bignoline/ Bignè  LOIZZO   180g",
                            "grammage": 180,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 72.93801985593088,
                                "left": 72.62341729525862,
                                "width": 23.423609240301722,
                                "height": 23.09474328524513
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/20180123_113638_1517224305.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/20180123_113701_1517224306.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_KO7ZYKq_JSnZYn0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "/media/interactive_flyer_11/pages/page_5.jpg",
                    "products": [
                        {
                            "item_id": 689158,
                            "field1": "tortina",
                            "field2": "loacker",
                            "field3": "vari gusti",
                            "field4": "21g x3",
                            "description": "tortina loacker vari gusti 21g x3",
                            "grammage": 63,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 24.02781137598719,
                                "left": 3.9853852370689657,
                                "width": 29.518706223060345,
                                "height": 25.614899156501682
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/loacker_tortina_classica_1464101564.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/loacker_tortina_whithe_1464172308.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_uXKGWlk_FuIQeEG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689221,
                            "field1": "Torte in Festa",
                            "field2": "BALOCCO",
                            "field3": "gusti assortiti",
                            "field4": "400g",
                            "description": "Torte in Festa BALOCCO gusti assortiti 400g",
                            "grammage": 400,
                            "price": "2.29",
                            "price_label": "€ 2,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 22.966119412923227,
                                "left": 33.572703394396555,
                                "width": 31.13676926185345,
                                "height": 26.895469709076156
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/20659_Voglia_di_Panna_e_Cioccolato_400g_1477401838.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/20651_Voglia_di_Cioccolato_400g_1477401839.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/20661_Voglia_di_Yogurt_e_Cioccolato_400g_1477401839.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_Ad4DZ8M.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689148,
                            "field1": "Fiesta",
                            "field2": "FERRERO ",
                            "field3": "x10 400g",
                            "field4": "",
                            "description": "Fiesta FERRERO  x10 400g",
                            "grammage": 400,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 22.746368797159683,
                                "left": 64.95992726293103,
                                "width": 31.13676926185345,
                                "height": 26.895469709076156
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Fiesta_Orange_t10_1484755780_1486569863_1486630689.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_5gpwCzd_EgCHClA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689149,
                            "field1": "kinder bueno",
                            "field2": "classico/ dark",
                            "field3": "43g x3",
                            "field4": "",
                            "description": "kinder bueno classico/ dark 43g x3",
                            "grammage": 129,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Dark"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 49.694160081417635,
                                "left": 3.827535695043103,
                                "width": 22.90375808189655,
                                "height": 23.186742054939792
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Kinder_Bueno_x_3_1460364827_1543589641.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/kinder_BUENO_DARK_x_3_1475145971_1484586799_1537871723_153_MmgYpWt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_HMJ8n1C_nULj8Ke.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689172,
                            "field1": "Tavoletta Walcor",
                            "field2": "vari tipi",
                            "field3": "90G ",
                            "field4": "",
                            "description": "Tavoletta Walcor vari tipi 90G ",
                            "grammage": 90,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 49.675847530104015,
                                "left": 26.739712419181032,
                                "width": 23.27880859375,
                                "height": 23.186742054939792
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1105857_TAVOLETTA_WALCOR_90G_CATTIVISSIMO_ME_1507028348.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1112446_TAVOLETTA_WALCOR_90G_PJMASKS_1507028349.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1112447_TAVOLETTA_WALCOR_90G_WINGS_1507028350.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Tavoletta_CM3_01_1537785822.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Tavoletta_LOL_01_1537785822.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Tavoletta_LOL_02_1537785822.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_d3Uu9Rw_blmvOcO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689144,
                            "field1": "ovetto ",
                            "field2": "dolfin",
                            "field3": "barbie/ bing",
                            "field4": "20g x 3",
                            "description": "ovetto  dolfin barbie/ bing 20g x 3",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Barbie",
                                "Bing"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 49.7948791136426,
                                "left": 50.16079606681035,
                                "width": 23.27880859375,
                                "height": 23.186742054939792
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/TriPACK_Barbie_8002267014662_1536936072_1543574142.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_pbPHxK3_u8pAhjO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689167,
                            "field1": "nesquik",
                            "field2": "uht latte e cacao",
                            "field3": "nestlè",
                            "field4": "180ml x3",
                            "description": "nesquik uht latte e cacao nestlè 180ml x3",
                            "grammage": 540,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 49.8097035599441,
                                "left": 73.43539533943965,
                                "width": 22.721494477370687,
                                "height": 23.043729749442882
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1046935_NESQUIK_UHT_LATTE_E_CACAO_3x180ml_1546856164.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_H3PDgwQ_csLgPnl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689152,
                            "field1": "barrette cereali light ",
                            "field2": "hero",
                            "field3": "20g x6",
                            "field4": "cioccolato/ frutti rossi",
                            "description": "barrette cereali light  hero 20g x6 cioccolato/ frutti rossi",
                            "grammage": 120,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Cioccolato",
                                "Frutti Rossi"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 73.09232192338142,
                                "left": 4.007273706896552,
                                "width": 22.721494477370687,
                                "height": 23.043729749442882
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/principale_8000080004532_1524470061.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_3iaGC5a_IPW9vDB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689168,
                            "field1": "barretta nesquik",
                            "field2": "nestlè",
                            "field3": "maxi choco",
                            "field4": "25g x6 ",
                            "description": "barretta nesquik nestlè maxi choco 25g x6 ",
                            "grammage": 150,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 72.97852249736101,
                                "left": 26.542295258620687,
                                "width": 23.422346443965512,
                                "height": 23.043729749442882
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Nesquik_Barretta_Maxi_Choco_25_g_x_6_1504081465_1504273443_rOuRn4l.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_prQMa2a_xi2ikNW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689155,
                            "field1": "barretta mandorle",
                            "field2": "kellogg's ",
                            "field3": "32g x4",
                            "field4": "vari gusti",
                            "description": "barretta mandorle kellogg's  32g x4 vari gusti",
                            "grammage": 128,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 72.99829541637115,
                                "left": 50.01031746591347,
                                "width": 23.422346443965512,
                                "height": 23.043729749442882
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/kellog_barrette_mandorle_e_cioccolato_1519323387_1535723932.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/kellog_barrette_mandorlefrutta_1519323389_1535723933.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/kellog_barrette_mandorlemielesemi_1519323388_1535723934.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_UMP5s1d_Pqaflpg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689147,
                            "field1": "zucchero di canna",
                            "field2": "tropicana",
                            "field3": "bustine 500g",
                            "field4": "",
                            "description": "zucchero di canna tropicana bustine 500g",
                            "grammage": 500,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 73.04451280778173,
                                "left": 73.41161730427552,
                                "width": 22.748013200431032,
                                "height": 23.043729749442882
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/46913_1501664555.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_6P6sFWX_37PEqMM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 6,
                    "image_file": "/media/interactive_flyer_11/pages/page_6.jpg",
                    "products": [
                        {
                            "item_id": 689243,
                            "field1": "Pasta di Semola",
                            "field2": "Barilla",
                            "field3": "varie trafile",
                            "field4": "1Kg",
                            "description": "Pasta di Semola Barilla varie trafile 1Kg",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 18.883398245218196,
                                "left": 4.213109509698276,
                                "width": 31.441103178879313,
                                "height": 25.359831477490424
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Pennette_rigate_72_cello_1kg_1505830607.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Tortiglioni_83_cello_1Kg_1505830608.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_b6rhz2i_POIPoh5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689252,
                            "field1": "Passata extrafine",
                            "field2": "PETTI",
                            "field3": "il delicato",
                            "field4": "700g",
                            "description": "Passata extrafine PETTI il delicato 700g",
                            "grammage": 700,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Rossi",
                            "blueprint": {
                                "top": 44.30339953416769,
                                "left": 3.87341729525862,
                                "width": 31.441103178879313,
                                "height": 25.53641679372899
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/PETTI_Passata_extrafine_Il_delicato_700g_EAN_8003496004073_YCMFOJb.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_Jby0Fdi_SqjmCZj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689249,
                            "field1": "Tonno All'olio Di Oliva  ",
                            "field2": "CALLIPO",
                            "field3": "80g x6 ",
                            "field4": "",
                            "description": "Tonno All'olio Di Oliva   CALLIPO 80g x6 ",
                            "grammage": 480,
                            "price": "4.79",
                            "price_label": "€ 4,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 70.23615225989877,
                                "left": 4.071676320043103,
                                "width": 31.441103178879313,
                                "height": 25.53641679372899
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1065693_1563532006.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_tnaMMJh_IQDTd3u.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689244,
                            "field1": "cannelloni ALL’UOVO ",
                            "field2": "BARILLA ",
                            "field3": "250g",
                            "field4": "",
                            "description": "cannelloni ALL’UOVO  BARILLA  250g",
                            "grammage": 250,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 6.844098046905544,
                                "left": 35.68073141163793,
                                "width": 20.972100619612068,
                                "height": 22.29727527684925
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Cannelloni_250g_sx_1454598348_1454931740_1458572935_1460129862.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_goXycLO_47hRX9p.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689260,
                            "field1": "pasta di semola",
                            "field2": "garofalo",
                            "field3": "formati speciali",
                            "field4": "varie trafile 500g",
                            "description": "pasta di semola garofalo formati speciali varie trafile 500g",
                            "grammage": 500,
                            "price": "1.09",
                            "price_label": "€ 1,09",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classica",
                                "Integrale"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 6.875490992014622,
                                "left": 56.67892982219827,
                                "width": 20.189166891163794,
                                "height": 22.29727527684925
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1064994_8000139002021_Garofalo_Calamarata_Pasta_di_Gragnan_j0lorbk.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1064576_8000139001352_Garofalo_Pappardelle_No._1_35_500_g__Fvi7jME.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_sfp9BhB_xYBuHrb.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689246,
                            "field1": "Pan carrè",
                            "field2": "MULINO BIANCO",
                            "field3": "285g",
                            "field4": "",
                            "description": "Pan carrè MULINO BIANCO 285g",
                            "grammage": 285,
                            "price": "0.50",
                            "price_label": "€ 0,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 6.832325692489639,
                                "left": 76.90261314655173,
                                "width": 20.189166891163794,
                                "height": 22.29727527684925
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/mulino_bianco_Pancarre_16_fette_1531132693_1532165374.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_IdC6mCE_01iartP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689267,
                            "field1": "Brodo pronto ",
                            "field2": "STAR ",
                            "field3": "vari tipi ",
                            "field4": "1l",
                            "description": "Brodo pronto  STAR  vari tipi  1l",
                            "grammage": 0,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 29.135269139983578,
                                "left": 35.70388267780173,
                                "width": 23.115907866379313,
                                "height": 32.159456185491436
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/IL_MIO_BRODO_Verdure_1485856830.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/IL_MIO_BRODO_Manzo_1485856832.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/IL_MIO_BRODO_Pollo_1485856833.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/IL_MIO_BRODO_Manzo_meno_sale_1485856834.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_4pNunE7_lkoIHYq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689265,
                            "field1": "gran Ragù",
                            "field2": "STAR ",
                            "field3": "VARI GUSTI",
                            "field4": "180G X2",
                            "description": "gran Ragù STAR  VARI GUSTI 180G X2",
                            "grammage": 360,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 39.184499679900696,
                                "left": 59.066035829741374,
                                "width": 37.15567753232759,
                                "height": 15.1183191487802
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/STAR_Il_Mio_GranRagu_FUNGHI_180x2_1485857161.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/STAR_Il_Mio_GranRagu_DATTERINO_180x2_1485857163.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/STAR_Il_Mio_GranRagu_EXTRAGUSTO_180x2_1485857163.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/STAR_Il_Mio_GranRagu_CLASSICO_180x2_1485857164.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/STAR_Il_Mio_GranRagu_SALSICCIA_180x2_1485857164.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_w9SkrJd_6MWWve1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689265,
                            "field1": "gran Ragù",
                            "field2": "STAR ",
                            "field3": "VARI GUSTI",
                            "field4": "180G X2",
                            "description": "gran Ragù STAR  VARI GUSTI 180G X2",
                            "grammage": 360,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Polpette",
                                "Polpette e Salsiccia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 55.18836148863281,
                                "left": 65.75548895474138,
                                "width": 30.379512392241384,
                                "height": 18.31691144489405
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/STAR_Il_Mio_GranRagu_FUNGHI_180x2_1485857161_Ot9TGAX.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/STAR_Il_Mio_GranRagu_DATTERINO_180x2_1485857163_gn47pwH.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/STAR_Il_Mio_GranRagu_EXTRAGUSTO_180x2_1485857163_Ee7risr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/STAR_Il_Mio_GranRagu_CLASSICO_180x2_1485857164_ltNu991.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/STAR_Il_Mio_GranRagu_SALSICCIA_180x2_1485857164_3fm5RiJ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_2cVogdC_eLxvZkK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689266,
                            "field1": "dadi",
                            "field2": "star",
                            "field3": "vari tipi",
                            "field4": "x10 cubi",
                            "description": "dadi star vari tipi x10 cubi",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 60.707415644059346,
                                "left": 35.82342739762931,
                                "width": 29.147865032327584,
                                "height": 12.989267052007586
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/3D_Il_Mio_Dado_FUNGHI_PORCINI_10_Frontale_1485857011.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/DADO_CLASSICO_10_Fustella_-_3D_DEF_1485857011.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/3D_Il_Mio_Dado_VEGETALE_10_Frontale_1485857012.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/AAFF_DADO_CLASSICO_-sal_10u_CS3trz_-_3D_DEF_1485857013.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/AAFF_DADO_VEGETALE_-sal_10u_CS3trz_-_3D_DEF_1485857013.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/3D_Il_Mio_Dado_DELICATO_10_Frontale_1485857014.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_MazaItO_3M64IvW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689261,
                            "field1": "pasta di semola di grano duro",
                            "field2": "pernopasta",
                            "field3": "formati speciali",
                            "field4": "500g",
                            "description": "pasta di semola di grano duro pernopasta formati speciali 500g",
                            "grammage": 500,
                            "price": "0.95",
                            "price_label": "€ 0,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 73.5763430731488,
                                "left": 36.02463294719827,
                                "width": 20.680815598060345,
                                "height": 22.04351563721753
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1069162_TAGLIATELLE__PERNOPAST__500g_1498728337.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1069190_FILEJA__PERNOPAST__500g_1498728337.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1069193_PAPPARDELLE__PERNOPAST__500g_1498728338.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1069197_FUSILLI__VIBONESI__PERNOPAST__500g_1498728339.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_I5URF8c_hDC0r5G.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689253,
                            "field1": "olio extra vergine ",
                            "field2": "di oliva classico",
                            "field3": "monini",
                            "field4": "1l",
                            "description": "olio extra vergine  di oliva classico monini 1l",
                            "grammage": null,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 73.79434963640628,
                                "left": 56.72733701508621,
                                "width": 20.15801791487069,
                                "height": 22.04351563721753
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Monini_extra_vergine_oliva_Lt_1_1455534094.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_K6wabFa_enLoCyt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689258,
                            "field1": "farina tipo \"00\"",
                            "field2": "granoro",
                            "field3": "1kg",
                            "field4": "",
                            "description": "farina tipo \"00\" granoro 1kg",
                            "grammage": null,
                            "price": "0.45",
                            "price_label": "€ 0,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 73.66310968532528,
                                "left": 76.83484307650862,
                                "width": 19.467689251077587,
                                "height": 22.04351563721753
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1034270_farina_GRANORO_kg1_1458754900.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_hbjTSLJ_EyFewZ9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "/media/interactive_flyer_11/pages/page_7.jpg",
                    "products": [
                        {
                            "item_id": 689242,
                            "field1": "fette biscottate\t\t\t",
                            "field2": "mulino bianco",
                            "field3": "dorate/ integrali",
                            "field4": "315g",
                            "description": "fette biscottate\t\t\t mulino bianco dorate/ integrali 315g",
                            "grammage": 315,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Dorate",
                                "Integrali"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 6.971849892974431,
                                "left": 3.5817113415948274,
                                "width": 19.467689251077587,
                                "height": 22.04351563721753
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Fette_dorate_36_sx_1562581600_1568126110.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Fette_integrali_36_1556525874_1568126111.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_yFTWNXx_U98hT4c.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689245,
                            "field1": "pan bauletto",
                            "field2": "al grano duro",
                            "field3": "MULINO BIANCO",
                            "field4": "400g",
                            "description": "pan bauletto al grano duro MULINO BIANCO 400g",
                            "grammage": 400,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 6.7656156841328485,
                                "left": 23.284701643318964,
                                "width": 20.128552667025865,
                                "height": 22.365293324585583
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Pan_bauletto_grano_duro_1530781067.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_U59pL5v_jK1QrJm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689247,
                            "field1": "carne in gelatina",
                            "field2": "simmenthal",
                            "field3": "90g x3",
                            "field4": "",
                            "description": "carne in gelatina simmenthal 90g x3",
                            "grammage": 270,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 6.855434388194933,
                                "left": 43.549636314655174,
                                "width": 20.196743669181036,
                                "height": 22.508305630082493
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1008311_carne_SIMMENTHAL_gr90x3_1458754802.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_oEglPis_4hIus7J.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689254,
                            "field1": "CIBO PER GATTI ",
                            "field2": "ONE CAT PURINA ",
                            "field3": "GUSTI ASSORTITI ",
                            "field4": "800G",
                            "description": "CIBO PER GATTI  ONE CAT PURINA  GUSTI ASSORTITI  800G",
                            "grammage": 800,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 40.41492872292595,
                                "left": 3.852370689655173,
                                "width": 13.14276333512931,
                                "height": 30.166876197318008
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/principale_8002205319903_1522249956.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/principale_7613031384600_1522249957.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_ghKYsJy_6uaPgDd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689251,
                            "field1": "legumi",
                            "field2": "despar",
                            "field3": "ceci/ fagioli cannellini/ fagioli borlotti/ piselli fini",
                            "field4": "340/370g",
                            "description": "legumi despar ceci/ fagioli cannellini/ fagioli borlotti/ piselli fini 340/370g",
                            "grammage": 0,
                            "price": "0.50",
                            "price_label": "€ 0,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Ceci",
                                "Cannellini",
                                "Borlotti",
                                "Piselli"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 29.244272421612322,
                                "left": 43.57531317349138,
                                "width": 20.46403556034483,
                                "height": 22.402354440339355
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1055008_ceci_DESPAR_340g_8001300240181_1470133442.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1055009_borlotti_DESPAR_340g_8001300240167_1470133450.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_nVwxHBu_DvqRzNR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689269,
                            "field1": "maionese ",
                            "field2": "calvè",
                            "field3": "500ml",
                            "field4": "",
                            "description": "maionese  calvè 500ml",
                            "grammage": 500,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 51.97843285122957,
                                "left": 43.635085533405174,
                                "width": 20.46403556034483,
                                "height": 21.65677199399875
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Calve_maionese_Classica_VASO_500_ml_1456239331_1462953714__HWNvMPD.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_eWCRGoN_5XnuBOJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689262,
                            "field1": "riso",
                            "field2": "gallo",
                            "field3": "3 cereali 400g/ 3 cereali integrale 400g",
                            "field4": "blond integrale 500g",
                            "description": "riso gallo 3 cereali 400g/ 3 cereali integrale 400g blond integrale 500g",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "3 Cereali",
                                "3 Cereali Integrale"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 73.85708894728869,
                                "left": 3.754293507543103,
                                "width": 19.42559603987069,
                                "height": 21.65677199399875
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/gallo_Blond_Integrale_1_kg_1434534912.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/gallo_3_cereali_riso_farro_orzo_400_gr_1544710207.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_UHZ4Kaa_7TyfpaD.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689264,
                            "field1": "Patatine ",
                            "field2": "SAN CARLO ",
                            "field3": "artigianali/ pomodoro, prezzemolo e basilico",
                            "field4": "150g",
                            "description": "Patatine  SAN CARLO  artigianali/ pomodoro, prezzemolo e basilico 150g",
                            "grammage": 150,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Artigianali",
                                "Pomodoro/Prezzemolo/Basilico"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 73.82438796280006,
                                "left": 23.2666015625,
                                "width": 20.024161503232758,
                                "height": 21.65677199399875
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/san_carlo_1936_antica_ricetta_1516264511_1541692977_1550759944.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/san_carlo_1936_pomodoro_1516264512_1542193130_1550759944.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_g7sRjjN_cFwl9Y7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689250,
                            "field1": "CRACKERS",
                            "field2": "COLUSSI",
                            "field3": "SALATI/ NON SALATI",
                            "field4": "500G",
                            "description": "CRACKERS COLUSSI SALATI/ NON SALATI 500G",
                            "grammage": 500,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Salati",
                                "Non Salati"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Salati",
                            "blueprint": {
                                "top": 73.74982971816601,
                                "left": 43.603515625,
                                "width": 20.36511651400862,
                                "height": 21.65677199399875
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Colussi_crackers_a_ridotto_contenuto_sale_500_gr_146072999_jXQt1NK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Colussi_Crackers_salata_500_gr_1460729997_1462977815_14643_uOVWeWm.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_Easc1P1_EY5CVSI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689263,
                            "field1": "RISO RIBE",
                            "field2": "DESPAR",
                            "field3": "1KG",
                            "field4": "",
                            "description": "RISO RIBE DESPAR 1KG",
                            "grammage": 0,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 9.456688700983268,
                                "left": 65.34171268857759,
                                "width": 29.638671875,
                                "height": 33.24643690989327
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1005963_RISO_DESPAR_1KG_RIBE_8001300417422_1493037382.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_gkG7nLT_sHdMnoN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689248,
                            "field1": "polpa pomodoro ",
                            "field2": "despar ",
                            "field3": "400g",
                            "field4": "",
                            "description": "polpa pomodoro  despar  400g",
                            "grammage": 1200,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Rossi",
                            "blueprint": {
                                "top": 43.23418959897177,
                                "left": 65.32571726831897,
                                "width": 29.638671875,
                                "height": 26.01036306225076
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1001270_POLPA_POMODORO_DESPAR_400G_1522916276.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_1yOdLBh_yvbbOyL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689257,
                            "field1": "OLIO",
                            "field2": "di SEMI di ARACHIDE",
                            "field3": "DESPAR",
                            "field4": "1L ",
                            "description": "OLIO di SEMI di ARACHIDE DESPAR 1L ",
                            "grammage": null,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 69.60465292438815,
                                "left": 65.43600148168103,
                                "width": 29.638671875,
                                "height": 25.190222371276096
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1002094_OLIO_SEMI_ARACHIDI_DESPAR_1L_PET_1491211426.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_O0rRxTk_9Lrgv9h.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 8,
                    "image_file": "/media/interactive_flyer_11/pages/page_8.jpg",
                    "products": [
                        {
                            "item_id": 689070,
                            "field1": "Yogurt Mix",
                            "field2": "ACTIVIA",
                            "field3": "gusti assortiti",
                            "field4": "125g x8",
                            "description": "Yogurt Mix ACTIVIA gusti assortiti 125g x8",
                            "grammage": 0,
                            "price": "2.69",
                            "price_label": "€ 2,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 18.74023361140433,
                                "left": 4.200060614224138,
                                "width": 31.138032058189655,
                                "height": 25.436133774630544
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/8001630008420_T5_1539337075.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/8001630005986_T5_1539337077.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/8001630005641_T5_1539337078.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/8001630005733_T5_1539337080.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/8001630005726_T5_1539337081.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/8001630005566_T5_1539337083.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_N48jaWG_wFEsNUJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689077,
                            "field1": "Pasta fresca di semola",
                            "field2": "DESPAR",
                            "field3": "500g",
                            "field4": "orecchiette/ trofie/ cavatelli/ fusilli/ strozzapreti",
                            "description": "Pasta fresca di semola DESPAR 500g orecchiette/ trofie/ cavatelli/ fusilli/ strozzapreti",
                            "grammage": 500,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Orecchiette",
                                "Trofie",
                                "Cavatelli",
                                "Fusilli",
                                "Strozzapreti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 44.423150815642344,
                                "left": 4.23583984375,
                                "width": 31.138032058189655,
                                "height": 25.436133774630544
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1018323_f_DESPAR_trofie_gr500_1457458810_1484814423.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1018322_DESPAR_orecchiet_gr500_1457458794_1484814424.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1018326_DESPAR_fusilli_gr500_1457458803_1484814424.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/trofie-2_1484814492.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/orecchiette_1484814492.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/FUSILLI_1484814493.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_FLQNlzO_AtNyhCk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689089,
                            "field1": "pasta fresca ripiena sfogliagrezza",
                            "field2": "rustici",
                            "field3": "rana",
                            "field4": "vari gusti 250g",
                            "description": "pasta fresca ripiena sfogliagrezza rustici rana vari gusti 250g",
                            "grammage": 250,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 6.7185262664692305,
                                "left": 35.67273370150862,
                                "width": 20.977993669181036,
                                "height": 17.71695738280945
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Rana_rustici_tortellini_caserecci_250_gr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Rana_rustici_raviolini_vitello_250_gr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Rana_rustici_formaggi_tortelloni_250_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/rana_rustici_prosciutto_crudo_cappelletti_250_g_1429192637_xMO4blR.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Rana_tortelloni_rustici_ricotta_e_spinaci_gr_250_151125877_UAV1jMF.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_OSyvZ5h_8hYTJQN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689087,
                            "field1": "snack di parmigiano reggiano ",
                            "field2": "PARMAREGGIO ",
                            "field3": "20g x5",
                            "field4": "",
                            "description": "snack di parmigiano reggiano  PARMAREGGIO  20g x5",
                            "grammage": 100,
                            "price": "1.98",
                            "price_label": "€ 1,98",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 24.554515232817266,
                                "left": 35.59780778556034,
                                "width": 20.977993669181036,
                                "height": 17.71695738280945
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/parmareggio_5_snacks_di_parmigiano_1459951854.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_55t2eb0_sCTpaPY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689060,
                            "field1": "Formaggio a Fette",
                            "field2": "LEERDAMMER",
                            "field3": "naturali",
                            "field4": "200g",
                            "description": "Formaggio a Fette LEERDAMMER naturali 200g",
                            "grammage": 200,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 42.554009121608416,
                                "left": 35.63611260775862,
                                "width": 20.71533203125,
                                "height": 17.308849096391434
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/leerdammer_200_gr_original_1432045874.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_CI5Dig3_2dQUHKI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "Ricetta Panino Corner - VEGGIE BURGER",
                                    "data": "/media/interactive_flyer_11/video/leerdammer.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": 689088,
                            "field1": "Burro",
                            "field2": "PARMAREGGIO",
                            "field3": "100g",
                            "field4": "",
                            "description": "Burro PARMAREGGIO 100g",
                            "grammage": 100,
                            "price": "0.74",
                            "price_label": "€ 0,74",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 60.032903337340684,
                                "left": 35.745554956896555,
                                "width": 20.71533203125,
                                "height": 17.83860504510713
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1070662_Burro_Parmareggio_100g_80353003_1462351730.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_HJsPSb4_luQzujl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689076,
                            "field1": "TENERONI",
                            "field2": "CASA MODENA",
                            "field3": "VARI TIPI",
                            "field4": "110/150/180G",
                            "description": "TENERONI CASA MODENA VARI TIPI 110/150/180G",
                            "grammage": 0,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 78.01539271419774,
                                "left": 35.791015625,
                                "width": 20.71533203125,
                                "height": 17.83860504510713
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/teneroni_Burger_1515492244_1516197214.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/teneroni_Mozzarella_1515492245_1516197214.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/teneroni_Originale_1515492245_1516197214.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_bKlxxyE_kqsgAHu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689084,
                            "field1": "pasta sfoglia rettangolare",
                            "field2": "buitoni",
                            "field3": "230g",
                            "field4": "",
                            "description": "pasta sfoglia rettangolare buitoni 230g",
                            "grammage": 230,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 6.542376963357182,
                                "left": 56.556017645474135,
                                "width": 20.488870554956897,
                                "height": 22.51571785323325
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Buitoni_Sfoglia_fresca_Rettangolare.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_UKOdOUp_qYAj9ve.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689090,
                            "field1": "sughi freschi",
                            "field2": "rana",
                            "field3": "alla bolognese 225g/ parmigiano reggiano 180g/",
                            "field4": "funghi porcini 180g/ carciofi 180g",
                            "description": "sughi freschi rana alla bolognese 225g/ parmigiano reggiano 180g/ funghi porcini 180g/ carciofi 180g",
                            "grammage": 0,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bolognese",
                                "Parmigiano",
                                "Funghi Porcini",
                                "Carciofi"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 6.604290827322309,
                                "left": 76.87104323814654,
                                "width": 19.36540274784483,
                                "height": 22.51571785323325
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/RANA_sugo_BOLOGNESE_225_1486552372_1507121543_1516702153.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/RANA_sugo_FUNGHI_180_1486552375_1507121544_1516702153.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_NYAbN71_Mbt2hSr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689064,
                            "field1": "mozzarella",
                            "field2": "galbani santa lucia",
                            "field3": "100g x3",
                            "field4": "",
                            "description": "mozzarella galbani santa lucia 100g x3",
                            "grammage": 300,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 38.29328884930408,
                                "left": 56.619157462284484,
                                "width": 13.516971982758621,
                                "height": 19.92797994736688
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/6690265_galbani_santa_lucia_tris_mozzarella_3x100_14672772_EBuRIMd.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_4EfC18q_WN69mdZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689066,
                            "field1": "formaggio a fette",
                            "field2": "galbani",
                            "field3": "120g",
                            "field4": "galbanino/ bel paese",
                            "description": "formaggio a fette galbani 120g galbanino/ bel paese",
                            "grammage": 120,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Galbanino",
                                "Galbanino Bel Paese"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 38.96649311664321,
                                "left": 69.73413927801724,
                                "width": 14.615604795258621,
                                "height": 18.371413085708422
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/56076_1513849287_1562941713.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/galbani_belpaese_fette_120g_1507294850_1562941747.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_0mzFFwT_9K16xkl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689067,
                            "field1": "emmental a fette",
                            "field2": "president",
                            "field3": "100g",
                            "field4": "",
                            "description": "emmental a fette president 100g",
                            "grammage": 100,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 30.853160858452576,
                                "left": 84.36068830818965,
                                "width": 11.52806775323276,
                                "height": 19.534696107250372
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/president_emmental_gusto_delicato_1507820100_1568122002.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_CYwDFnK_XDdLnKc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689062,
                            "field1": "galbanino",
                            "field2": "galbani",
                            "field3": "550g",
                            "field4": "",
                            "description": "galbanino galbani 550g",
                            "grammage": 550,
                            "price": "3.49",
                            "price_label": "€ 3,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 58.76846527044726,
                                "left": 56.72186489762931,
                                "width": 17.980115167025865,
                                "height": 14.229724396942688
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Galbani_Galbanino_550g_1543428018_1545414101_1545931291.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_A4vZCio_ccCQvzV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689063,
                            "field1": "Mascarpone",
                            "field2": "GALBANI SANTA LUCIA",
                            "field3": "250g",
                            "field4": "",
                            "description": "Mascarpone GALBANI SANTA LUCIA 250g",
                            "grammage": 250,
                            "price": "2.15",
                            "price_label": "€ 2,15",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 57.29474090282665,
                                "left": 77.98356681034483,
                                "width": 17.980115167025865,
                                "height": 16.446415132144814
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/Mascarpone_Santa_Lucia_250g_1478248910_1479501613.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_AupfhfB_u9K2779.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689071,
                            "field1": "pancetta a cubetti",
                            "field2": "despar",
                            "field3": "70g x2",
                            "field4": "dolce/ affumicata",
                            "description": "pancetta a cubetti despar 70g x2 dolce/ affumicata",
                            "grammage": 140,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 73.83315480466612,
                                "left": 56.53707570043104,
                                "width": 20.307869746767242,
                                "height": 21.701681346029794
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/8001300552109_1508234513.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/8001300552215_1508234514.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_30sUNqO_CDFUZr1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689091,
                            "field1": "Salmone norvegese",
                            "field2": "premium",
                            "field3": "100g",
                            "field4": "",
                            "description": "Salmone norvegese premium 100g",
                            "grammage": 100,
                            "price": "3.49",
                            "price_label": "€ 3,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 74.02979672472438,
                                "left": 77.17790274784483,
                                "width": 19.179771686422413,
                                "height": 21.701681346029794
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/1018608_SALMONE_NORVEGESE_PREMIUM_100G_1526398380.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_kIzMzFT_zXG3Pit.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 689083,
                            "field1": "PHILADELPHIA ",
                            "field2": "classico",
                            "field3": "250g",
                            "field4": "",
                            "description": "PHILADELPHIA  classico 250g",
                            "grammage": 250,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 70.32482977925366,
                                "left": 4.0419442115598,
                                "width": 31.540555946492454,
                                "height": 25.192083253919378
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_11/products/445670_Philly_ClassicoTub250_1521712718_1521827087_1522080_wxAzKbF.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_11/products/cropped_image_qCuqoWL_0FpmCgn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 9,
                    "image_file": "/media/interactive_flyer_11/pages/page_9.jpg",
                    "products": []
                },
                {
                    "number": 10,
                    "image_file": "/media/interactive_flyer_11/pages/page_10.jpg",
                    "products": []
                },
                {
                    "number": 11,
                    "image_file": "/media/interactive_flyer_11/pages/page_11.jpg",
                    "products": []
                },
                {
                    "number": 12,
                    "image_file": "/media/interactive_flyer_11/pages/page_12.jpg",
                    "products": []
                },
                {
                    "number": 13,
                    "image_file": "/media/interactive_flyer_11/pages/page_13.jpg",
                    "products": []
                },
                {
                    "number": 14,
                    "image_file": "/media/interactive_flyer_11/pages/page_14.jpg",
                    "products": []
                },
                {
                    "number": 15,
                    "image_file": "/media/interactive_flyer_11/pages/page_15.jpg",
                    "products": []
                },
                {
                    "number": 16,
                    "image_file": "/media/interactive_flyer_11/pages/page_16.jpg",
                    "products": []
                },
                {
                    "number": 17,
                    "image_file": "/media/interactive_flyer_11/pages/page_17.jpg",
                    "products": []
                },
                {
                    "number": 18,
                    "image_file": "/media/interactive_flyer_11/pages/page_18.jpg",
                    "products": []
                },
                {
                    "number": 19,
                    "image_file": "/media/interactive_flyer_11/pages/page_19.jpg",
                    "products": []
                },
                {
                    "number": 20,
                    "image_file": "/media/interactive_flyer_11/pages/page_20.jpg",
                    "products": []
                },
                {
                    "number": 21,
                    "image_file": "/media/interactive_flyer_11/pages/page_21.jpg",
                    "products": []
                },
                {
                    "number": 22,
                    "image_file": "/media/interactive_flyer_11/pages/page_22.jpg",
                    "products": []
                },
                {
                    "number": 23,
                    "image_file": "/media/interactive_flyer_11/pages/page_23.jpg",
                    "products": []
                },
                {
                    "number": 24,
                    "image_file": "/media/interactive_flyer_11/pages/page_24.jpg",
                    "products": []
                },
                {
                    "number": 25,
                    "image_file": "/media/interactive_flyer_11/pages/fine.png",
                    "products": []
                }
            ]
        }
    },
    "splendidisplendenti": {
        "config": {
            "primary-color": "rgb(9, 163, 229)",  //azzurro chiaro
            "secondary-color": "rgb(242, 191, 0)", //giallo
            "tertiary-color": "rgb(255, 255, 255)", //bianco
            "hover-color": "rgba(242, 191, 0, 0.5)", //19, 696, 172, 0.2
            "clientIcon": require("assets/images/clientLogo.png"),
            "serverPath": "https://splendidisplendenti.volantinointerattivo.net",
            "pdfPath": "/media/interactive_flyer_19/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino Splendidi e Splendenti!",
            "shareFlyerURL": "https://splendidisplendenti.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Splendidi e Splendenti!",
            "ogTitleMeta": "Volantino Interattivo Splendidi e Splendenti",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://splendidisplendenti.volantinointerattivo.net/media/interactive_flyer_19/images/clientLogo.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": ""
        },
        "leaflet": {
            "name": "Splendidi e Splendenti",
            "code": "splendidi19",
            "id_campaign": "1298",
            "index": {
                "image_file": "/media/interactive_flyer_19/pages/indice.png",
                "links": [
                    {   //PRENDI 2 PAGHI 1
                        "page": 2,
                        "blueprint": {
                            "top": 14.136158193308875,
                            "left": 2.827899805017109,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {   //BOCCA PULITA E PROTETTA
                        "page": 4,
                        "blueprint": {
                            "top": 26.17671586124297,
                            "left": 2.827899805017109,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {   //TANTE OFFERTE PER TE
                        "page": 6,
                        "blueprint": {
                            "top": 37.96378810458898,
                            "left": 2.6927229134415507,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {   //PER I PIU' PICCOLI
                        "page": 6,
                        "blueprint": {
                            "top": 49.87760306022902,
                            "left": 2.6927229134415507,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {   //TANTE OFFERTE PER TE
                        "page": 8,
                        "blueprint": {
                            "top": 61.79141801586907,
                            "left": 2.827899805017109,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {   //CONCORSO VEGE'
                        "page": 10,
                        "blueprint": {
                            "top": 73.83197568380318,
                            "left": 2.827899805017109,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                        }
                    },
                    {   //PUNTI VENDITA
                        "page": 12,
                        "blueprint": {
                            "top": 85.74579063944321,
                            "left": 2.827899805017109,
                            "width": 95.00926992977394,
                            "height": 10.89118171224156
                    },
                   
                          
                }  
                ]
            },
        "categories": [
            {
                "category_name": "Igiene Persona",
                "subcategories": [
                    "Corpo",
                    "Viso",
                    "MakeUp",
                    "Capelli",
                    "Intima",
                    "Orale"
                ]
            },
            {
                "category_name": "Igiene Casa",
                "subcategories": [
                    "Extra",
                    "Bucato",
                    "Pavimenti",
                    "Superfici"
                ]
            },
            {
                "category_name": "No Food",
                "subcategories": [
                    "Home"
                ]
            },
            {
                "category_name": "Pet",
                "subcategories": [
                    "Food"
                ]
            },
            {
                "category_name": "Alimentari",
                "subcategories": [
                    "Pasta",
                    "Dispensa"
                ]
            },
            {
                "category_name": "Medicinali",
                "subcategories": [
                    "Uso Esterno"
                ]
            }
        ],
        "pages": [
            {
                "number": 1,
                "image_file": "/media/interactive_flyer_19/pages/page_1.jpg",
                "products": [
                    {
                        "item_id": 705384,
                        "field1": "splendidi e splendenti",
                        "field2": "sapone liquido",
                        "field3": "crema latte/igienizzante/talco",
                        "field4": "750 ml",
                        "description": "splendidi e splendenti sapone liquido crema latte/igienizzante/talco 750 ml",
                        "grammage": 750,
                        "price": "1.50",
                        "price_label": "€ 1,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Crema latte",
                            "Igienizzante",
                            "Talco"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 30.096014492753632,
                            "left": 0.0,
                            "width": 100.0,
                            "height": 61.54710144927537
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8018700036160_1554890675.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8018700036177_1554890676.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8018700036719_1554890677.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_MF09Bgl.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "VIDEO - Tutorial Splendidi e Splendenti",
                                "data": "/media/interactive_flyer_19/video/tutorial.mp4"
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.splendidisplendenti.it/spesa-consegna-domicilio/89128"
                            }


                        ],
                        "related": [705329, 705390, 705470]
                    },
                    
                ]
            },
            {
                "number": 2,
                "image_file": "/media/interactive_flyer_19/pages/page_2.jpg",
                "products": [
                    {
                        "item_id": 705329,
                        "field1": "splendidi e splendenti",
                        "field2": "catturacolore",
                        "field3": "12 fogli",
                        "field4": "",
                        "description": "splendidi e splendenti catturacolore 12 fogli",
                        "grammage": 0,
                        "price": "1.30",
                        "price_label": "€ 1,30",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Extra",
                        "blueprint": {
                            "top": 7.984213250517603,
                            "left": 0.0,
                            "width": 32.56913650665145,
                            "height": 27.261387163561075
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_zKn5Z2M_DLv3CRe.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705328,
                        "field1": "dr. beckmann ",
                        "field2": "additivo cura lavatrice ",
                        "field3": "",
                        "field4": "50 lavaggi - 200 ml",
                        "description": "dr. beckmann  additivo cura lavatrice  50 lavaggi - 200 ml",
                        "grammage": 200,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Bucato",
                        "blueprint": {
                            "top": 7.984213250517603,
                            "left": 32.72185320797071,
                            "width": 26.740142412923873,
                            "height": 27.261387163561075
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/4008455404516_1554884466.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_DBeSHn4_9K9vOek.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.dr-beckmann.it/trattamento-lavatrice/per-lavatrici/il-cura-lavatrice/"
                            },
                            {
                                "type": "info",
                                "title": "GUIDA - Cura Lavatrice",
                                "data": {

                                    "titolo": "GUIDA - Cura Lavatrice",
                                    "testo": "1. Pulire il filtro dalla lanuggine. Agitare bene la bottigila prima dell’uso. Estrarre la vaschetta del detersivo e immergerla per 30 minuti in una soluzione di 50ml di prodotto diluiti  in 4 litri di acqua tiepida (guardare la tacca di misurazione sul retro dell’etichetta del flacone). Pulire il dispensatore da eventuali residui e reinserirlo nella macchina. 2. Rimuovere sporco e residui dalle guarnizioni di gomma del cestello. Versare una piccola quantità di prodotto su un panno e strofinare il vetro dell’oblò e la guarnizione di gomma del cestello. Per una corretta manutenzione, lasciare agire per pochi minuti e pulire con un panno umido. 3. Versare il restante contenuto del flacone nella vaschetta del detersivo e avviare un normale ciclo di lavaggio (almeno a 60°) senza il ciclo di pre-lavaggio, senza aggiungere detersivo e senza bucato.",
                                    "img": [
                                        "/media/interactive_flyer_19/images/cura_lavatrice.jpg"
                                    ]
                                }
                            },
                            
                        ]
                    },
                    {
                        "item_id": 705369,
                        "field1": "splendidi e splendenti",
                        "field2": "vassoi alluminio",
                        "field3": "1 porzione - 4 pz",
                        "field4": "",
                        "description": "splendidi e splendenti vassoi alluminio 1 porzione - 4 pz",
                        "grammage": 0,
                        "price": "0.80",
                        "price_label": "€ 0,80",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Extra",
                        "blueprint": {
                            "top": 0.0,
                            "left": 59.747189460707645,
                            "width": 40.25281053929235,
                            "height": 22.41666666666666
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8021719141299_1554887252_1555063520.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_qVcfh33_7aaBYUK.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705350,
                        "field1": "splendidi e splendenti",
                        "field2": "panno microfibra multiuso",
                        "field3": "",
                        "field4": "35 x 35 cm",
                        "description": "splendidi e splendenti panno microfibra multiuso 35 x 35 cm",
                        "grammage": 0,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 22.608695652173914,
                            "left": 59.747189460707645,
                            "width": 40.25281053929235,
                            "height": 32.72722567287785
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/920896_splendidi_e_splendenti_panno_microfibra_multiuso_1569921180.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_BEzRHJl_zbp1Grv.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705353,
                        "field1": "gamex dust",
                        "field2": "panno cattura polvere 3d",
                        "field3": "",
                        "field4": "22x28 cm - x 20",
                        "description": "gamex dust panno cattura polvere 3d 22x28 cm - x 20",
                        "grammage": 0,
                        "price": "1.99",
                        "price_label": "€ 1,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Extra",
                        "blueprint": {
                            "top": 35.27950310559007,
                            "left": 0.13247713849380854,
                            "width": 20.778671180702492,
                            "height": 20.056418219461698
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_ooo4rDP_Qsb8O4W.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705354,
                        "field1": "arix",
                        "field2": "strofi panno pavimenti",
                        "field3": "",
                        "field4": "cm 50 x 60",
                        "description": "arix strofi panno pavimenti cm 50 x 60",
                        "grammage": 0,
                        "price": "2.99",
                        "price_label": "€ 2,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Extra",
                        "blueprint": {
                            "top": 35.1552795031056,
                            "left": 21.19634215900937,
                            "width": 19.18894551877679,
                            "height": 20.180641821946168
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8008990006110_arix_strofi_x1_1569920345.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_8wmNJ2A_w4d5hEa.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705372,
                        "field1": "splendidi e splendenti",
                        "field2": "pinze in plastica",
                        "field3": "",
                        "field4": "10 pz",
                        "description": "splendidi e splendenti pinze in plastica 10 pz",
                        "grammage": null,
                        "price": "0.90",
                        "price_label": "€ 0,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 35.1552795031056,
                            "left": 40.40552724061161,
                            "width": 19.18894551877679,
                            "height": 20.180641821946168
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8053629801031_1554888045.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_a313hrH_MvZnrlf.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705360,
                        "field1": "fatigati freschissimo",
                        "field2": "deodorante per ambiente spray",
                        "field3": "aria di primavera",
                        "field4": "250 ml",
                        "description": "fatigati freschissimo deodorante per ambiente spray aria di primavera 250 ml",
                        "grammage": 250,
                        "price": "3.49",
                        "price_label": "€ 3,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Extra",
                        "blueprint": {
                            "top": 55.52795031055901,
                            "left": 0.0,
                            "width": 20.778671180702492,
                            "height": 20.55331262939959
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/freschissimo_deodorante_primavera_1556529599.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_ZrMl6lM_BvoRhiy.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705375,
                        "field1": "fatigati",
                        "field2": "colla forza 10 blister ",
                        "field3": "",
                        "field4": "3 gr",
                        "description": "fatigati colla forza 10 blister  3 gr",
                        "grammage": null,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 55.27950310559007,
                            "left": 20.93138788202175,
                            "width": 19.321422657270595,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8011690046577_1555680195.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_tjy7uZK_znmAuEY.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705358,
                        "field1": "splendidi e splendenti",
                        "field2": "scopa bassa",
                        "field3": "per tutti i pavimenti",
                        "field4": "",
                        "description": "splendidi e splendenti scopa bassa per tutti i pavimenti",
                        "grammage": null,
                        "price": "1.50",
                        "price_label": "€ 1,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Pavimenti",
                        "blueprint": {
                            "top": 55.27950310559007,
                            "left": 40.14057296362399,
                            "width": 19.321422657270595,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002120120356_1554885180.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002120120356bis_1554885181.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_02vTAr0_fOFfP1Z.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705370,
                        "field1": "splendidi e splendenti",
                        "field2": "sacco gelo",
                        "field3": "piccolo/medio/grande",
                        "field4": "",
                        "description": "splendidi e splendenti sacco gelo piccolo/medio/grande",
                        "grammage": null,
                        "price": "0.90",
                        "price_label": "€ 0,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Piccolo", "Medio", "Grande"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 55.40372670807454,
                            "left": 59.48223518372004,
                            "width": 19.321422657270595,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/spl_sacco_gelo_grande_1555063541.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/spl_sacco_gelo_medio_1555063542.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/spl_sacco_gelo_piccolo_1555063543.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_nbzSh0G_dtQPPbY.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705323,
                        "field1": "nicky",
                        "field2": "milleusi ",
                        "field3": "310 strappi",
                        "field4": "1 rotolo",
                        "description": "nicky milleusi  310 strappi 1 rotolo",
                        "grammage": 0,
                        "price": "2.49",
                        "price_label": "€ 2,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Superfici",
                        "blueprint": {
                            "top": 55.40372670807454,
                            "left": 78.95637454230989,
                            "width": 21.04362545769011,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8004260450744_1555675785.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_jsD4UDb_HKKY6hx.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 708888,
                        "field1": "morando miglior gatto",
                        "field2": "straccetti",
                        "field3": "vari tipi",
                        "field4": "100 gr",
                        "description": "morando miglior gatto straccetti vari tipi 100 gr",
                        "grammage": 100,
                        "price": "0.59",
                        "price_label": "€ 0,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Pet",
                        "subcategory": "Food",
                        "blueprint": {
                            "top": 76.5217391304348,
                            "left": 0.0,
                            "width": 25.81280244346722,
                            "height": 19.06262939958592
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/straccetti-mazo-carote_1504630456_1558603330_1560880060.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/straccetti-salmone-tonno_1504630456_1558603330_1560880060.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_SBPh29r_GDJcPEv.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "VIDEO - Spot",
                                "data": "/media/interactive_flyer_19/video/migliorgatto.mp4"
                            },
                        ],
                        "related": [708772, 708889, 708890]
                    },
                    {
                        "item_id": 708772,
                        "field1": "morando miglior cane",
                        "field2": "unico 100% patè",
                        "field3": "vari tipi",
                        "field4": "100 gr",
                        "description": "morando miglior cane unico 100% patè vari tipi 100 gr",
                        "grammage": 100,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Pet",
                        "subcategory": "Food",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 25.70056486779886,
                            "width": 24.223076781541515,
                            "height": 19.06262939958592
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/MC_Unico_Prosciutto_1482403557_1537787637_1547565684_15476_V8eHEGa.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/MCUnico_Tacchino_1482403557_1537787637_1565345305.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/MCUnico_Vitello_1482403558_1537787638_1565345305.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_N6Tivra_ilQAlJC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 708889,
                        "field1": "minerva",
                        "field2": "pet panno",
                        "field3": "in microfibra con tasca",
                        "field4": "35x75 cm",
                        "description": "minerva pet panno in microfibra con tasca 35x75 cm",
                        "grammage": 0,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 50.20883548915344,
                            "width": 23.825645366060087,
                            "height": 19.06262939958592
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_nGnohX4_mtYaMdL.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 708890,
                        "field1": "minerva",
                        "field2": "pet panno",
                        "field3": "in microfibra con tasca",
                        "field4": "45x90 cm",
                        "description": "minerva pet panno in microfibra con tasca 45x90 cm",
                        "grammage": 0,
                        "price": "4.99",
                        "price_label": "€ 4,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 74.31967469502659,
                            "width": 25.680325304973408,
                            "height": 21.050207039337472
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_BUGERqc_oYZUZHn.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 3,
                "image_file": "/media/interactive_flyer_19/pages/page_3.jpg",
                "products": [
                    {
                        "item_id": 705478,
                        "field1": "clendy",
                        "field2": "salviettine",
                        "field3": "struccanti/multiuso/igienizzanti",
                        "field4": "15 pz",
                        "description": "clendy salviettine struccanti/multiuso/igienizzanti 15 pz",
                        "grammage": 0,
                        "price": "0.79",
                        "price_label": "€ 0,79",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Struccanti",
                            "Multiuso",
                            "Igienizzanti"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 0.2484472049689441,
                            "left": 0.0,
                            "width": 21.04362545769011,
                            "height": 22.292443064182198
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/59759_1547637189_1569923403.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/SALVIETTE-IGIENIZZANTI-RINFRESCANTI-ALLA-MENTA-15-PZ_1569923513.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_hN1Z7mN_bSZANgI.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705477,
                        "field1": "fresh & clean ",
                        "field2": "salviettine milleusi disinfettanti",
                        "field3": "x 20",
                        "field4": "",
                        "description": "fresh & clean  salviettine milleusi disinfettanti x 20",
                        "grammage": 0,
                        "price": "1.99",
                        "price_label": "€ 1,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 0.12422360248447205,
                            "left": 21.19634215900937,
                            "width": 18.923991241789174,
                            "height": 22.292443064182198
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002340006225_1536249169_1569916347.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_C4N2nk7_PWG0e0A.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705316,
                        "field1": "clinians",
                        "field2": "gel detergente viso",
                        "field3": "esfoliante",
                        "field4": "150 ml",
                        "description": "clinians gel detergente viso esfoliante 150 ml",
                        "grammage": 150,
                        "price": "3.60",
                        "price_label": "€ 3,60",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 22.608695652173914,
                            "left": 0.0,
                            "width": 40.25281053929235,
                            "height": 32.60300207039338
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510024643_clinians_gel_detergente_esfoliante_1569920811.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_cyD4C7b_1BrvBPh.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705467,
                        "field1": "clinians",
                        "field2": "gel detergente rinfrescante",
                        "field3": "hydra plus",
                        "field4": "150 ml",
                        "description": "clinians gel detergente rinfrescante hydra plus 150 ml",
                        "grammage": 150,
                        "price": "2.59",
                        "price_label": "€ 2,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 8.074534161490684,
                            "left": 40.538004379105416,
                            "width": 29.522162321293855,
                            "height": 27.137163561076605
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/HydraPlusGel150_8003510020096_1536248575_1559123689_1564494594.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_AxjlVHz_q4jrvKA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705468,
                        "field1": "clinians",
                        "field2": "acqua micellare",
                        "field3": "antistress/purificante",
                        "field4": "400 ml",
                        "description": "clinians acqua micellare antistress/purificante 400 ml",
                        "grammage": 400,
                        "price": "3.98",
                        "price_label": "€ 3,98",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Antistress",
                            "Purificante"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 7.826086956521739,
                            "left": 70.21288340171851,
                            "width": 29.522162321293855,
                            "height": 27.137163561076605
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510030491_1554885389.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/CliniansHydraPlusAcquaMicellareAttiva_8003510030316_153624_YU6vA4Z.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_lZriuSJ_q1UjfG3.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705381,
                        "field1": "venus",
                        "field2": "sapone liquido mani viso",
                        "field3": "antibatterico/neutro/sensitive",
                        "field4": "250 ml",
                        "description": "venus sapone liquido mani viso antibatterico/neutro/sensitive 250 ml",
                        "grammage": 250,
                        "price": "1.00",
                        "price_label": "€ 1,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Antibatterico",
                            "Neutro",
                            "Sensitive"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 35.40372670807454,
                            "left": 40.538004379105416,
                            "width": 19.321422657270595,
                            "height": 20.056418219461698
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8009150105667_VenusSaponeLiquidoMentolypton250_1553795286.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/vens_sapone_liquido_sensitive_250_ml_1462983344_1542821296_ldJsVwA.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/venus_sapone_liquido_neutro_250_ml_1462983357_1542821296_1_iIM9iqg.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_xScRkU1_z7B5wI1.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705384,
                        "field1": "splendidi e splendenti",
                        "field2": "sapone liquido",
                        "field3": "crema latte/igienizzante/talco",
                        "field4": "750 ml",
                        "description": "splendidi e splendenti sapone liquido crema latte/igienizzante/talco 750 ml",
                        "grammage": 750,
                        "price": "1.50",
                        "price_label": "€ 1,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Crema latte",
                            "Igienizzante",
                            "Talco"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 35.27950310559007,
                            "left": 59.747189460707645,
                            "width": 19.321422657270595,
                            "height": 20.056418219461698
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8018700036160_1554890675_aO6UtBN.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8018700036177_1554890676_oupg8c3.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8018700036719_1554890677_UZ5IIE0.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_uXp51IN_ucdNLOo.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705389,
                        "field1": "erbaviva",
                        "field2": "bagnoschiuma",
                        "field3": "vari tipi",
                        "field4": "500 ml",
                        "description": "erbaviva bagnoschiuma vari tipi 500 ml",
                        "grammage": 500,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 35.40372670807454,
                            "left": 79.22132881929751,
                            "width": 20.778671180702492,
                            "height": 20.056418219461698
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/principale_8004395100002_1558598835_1564471440.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/principale_8004395100095_1558598836_1564471440.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_AyrpNKU_NZYwl9o.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705390,
                        "field1": "splendidi e splendenti",
                        "field2": "bagnodoccia cremoso",
                        "field3": "varie profumazioni",
                        "field4": "750 ml",
                        "description": "splendidi e splendenti bagnodoccia cremoso varie profumazioni 750 ml",
                        "grammage": 750,
                        "price": "1.40",
                        "price_label": "€ 1,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.40372670807454,
                            "left": 0.0,
                            "width": 20.778671180702492,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8018700036122_1554890551.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8018700036139_1554890597.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8018700036146_1554890619.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8018700036153_1554890643.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_nI4enC0_EDNMQei.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705392,
                        "field1": "johnson's",
                        "field2": "bagnoschiuma",
                        "field3": "vari tipi",
                        "field4": "750 ml",
                        "description": "johnson's bagnoschiuma vari tipi 750 ml",
                        "grammage": 750,
                        "price": "2.59",
                        "price_label": "€ 2,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.52795031055902,
                            "left": 21.19634215900937,
                            "width": 19.05646838028298,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Johnsons_Vita-Rich_Bagno_Schiuma_Rigenerante_Lampone_750_m_XSpMBFV.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/Johnsons_Vita-Rich_Bagno_Schiuma_Vellutante_Papaya_750_ml__r6tN4hR.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/Johnsons_Vita-Rich_smoothies_Bagno_Schiuma_Rilassante_con__Xc0Qzmm.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_pSMtaxI_yWnyKBs.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705380,
                        "field1": "spuma sciampagna",
                        "field2": "sapone profumato",
                        "field3": "argan/rosa e vaniglia",
                        "field4": "90 gr",
                        "description": "spuma sciampagna sapone profumato argan/rosa e vaniglia 90 gr",
                        "grammage": 90,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Argan",
                            "Rosa e Vaniglia"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.52795031055902,
                            "left": 40.80295865609303,
                            "width": 19.05646838028298,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8007750010428_spuma_di_sciampagna_sapone_argan_e_patchouli_PyQgug6.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/SAPONETTE_SPUMA_SCIAMPAGNA_ROSA-VANIGLIA_GR.90_1569922227.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_5t1S0AB_5gZqXex.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705413,
                        "field1": "cotoneve",
                        "field2": "spugna bagno",
                        "field3": "soft relax",
                        "field4": "",
                        "description": "cotoneve spugna bagno soft relax",
                        "grammage": 0,
                        "price": "1.99",
                        "price_label": "€ 1,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 55.40372670807454,
                            "left": 59.61471232221385,
                            "width": 19.05646838028298,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/SPUGNA_BAGNO_COTONEVE_SOFT_RELAX_1569922630.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_k4IfkDX_mFL8835.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705314,
                        "field1": "lycia",
                        "field2": "dischetti viso struccanti",
                        "field3": "80 pz",
                        "field4": "",
                        "description": "lycia dischetti viso struccanti 80 pz",
                        "grammage": 0,
                        "price": "1.45",
                        "price_label": "€ 1,45",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "MakeUp",
                        "blueprint": {
                            "top": 55.40372670807454,
                            "left": 78.95637454230989,
                            "width": 21.04362545769011,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/LYCIA_DISCHETTI_STRUCCANTI_PZ.80_1560777782_1560864606_1560928638.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_SYYZDGH_Bvlo87W.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705396,
                        "field1": "erbaviva",
                        "field2": "shampoo",
                        "field3": "vari trattamenti",
                        "field4": "500 ml",
                        "description": "erbaviva shampoo vari trattamenti 500 ml",
                        "grammage": 500,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 0.0,
                            "width": 21.04362545769011,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/58191_erbaviva_1532010726_1560870092.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/58192_erbaviva_1532010727_1560870093.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/58194_erbaviva_1532010728_1560870094.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_0Y6Woqa_xQIWQGS.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705397,
                        "field1": "mantovani",
                        "field2": "shampoo",
                        "field3": "lisci/ricci/normali",
                        "field4": "400 ml",
                        "description": "mantovani shampoo lisci/ricci/normali 400 ml",
                        "grammage": 400,
                        "price": "1.89",
                        "price_label": "€ 1,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Lisci",
                            "Ricci",
                            "Normali"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 76.27329192546584,
                            "left": 21.19634215900937,
                            "width": 19.18894551877679,
                            "height": 20.925983436853
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002340008540_mantonavi_shampo_neutro_naturali_1559668794.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/principale_8002340008557_1569922426.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_H4T6Zvn_ADq6w2W.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705470,
                        "field1": "splendidi e splendenti",
                        "field2": "gel",
                        "field3": "vari tipi",
                        "field4": "500 gr",
                        "description": "splendidi e splendenti gel vari tipi 500 gr",
                        "grammage": 500,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 76.5217391304348,
                            "left": 40.538004379105416,
                            "width": 19.18894551877679,
                            "height": 18.93840579710145
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/spl_fixgel_extra_forte_cocco_1555056493.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/spl_fixgel_extra_forte_1555056494.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/spl_fixgel_iper_forte_1555056495.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_mrdZ9Sj_D3FDzDN.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705479,
                        "field1": "fazzolettini",
                        "field2": "4 veli",
                        "field3": "18 pz",
                        "field4": "gormiti/hutik/flinstones/winx",
                        "description": "fazzolettini 4 veli 18 pz gormiti/hutik/flinstones/winx",
                        "grammage": null,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Gormiti",
                            "Hutik",
                            "Flinstones",
                            "Winx"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 76.5217391304348,
                            "left": 59.747189460707645,
                            "width": 19.18894551877679,
                            "height": 18.93840579710145
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/FAZZOLETTINI_GORMITI_4VELI_PZ.18_1564473347.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_oXY005p_Jti4rlh.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705425,
                        "field1": "lines lady",
                        "field2": "assorbente",
                        "field3": "anatomico",
                        "field4": "9 pz",
                        "description": "lines lady assorbente anatomico 9 pz",
                        "grammage": null,
                        "price": "0.89",
                        "price_label": "€ 0,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Intima",
                        "blueprint": {
                            "top": 76.77018633540374,
                            "left": 78.95637454230989,
                            "width": 21.04362545769011,
                            "height": 18.93840579710145
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8001480054851_1555675097.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_Y1vyzfj_5mwMjKI.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 4,
                "image_file": "/media/interactive_flyer_19/pages/page_4.jpg",
                "products": [
                    {
                        "item_id": 705438,
                        "field1": "oral b",
                        "field2": "dentifricio pro-repair",
                        "field3": "vari tipi",
                        "field4": "ml 75",
                        "description": "oral b dentifricio pro-repair vari tipi ml 75",
                        "grammage": 75,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Orale",
                        "blueprint": {
                            "top": 19.378881987577643,
                            "left": 0.0,
                            "width": 31.50931939870099,
                            "height": 24.031573498964807
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/oral_b_gengive_smalto_pro_repair_classico_1556802360_15623_jcSzG7y.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/oral_b_gengive_smalto_pro_repair_sbiancante_delicato_15568_rhGXDdw.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_hLd1y0P_kNBZv9T.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705437,
                        "field1": "colgate",
                        "field2": "dentifricio",
                        "field3": "baking soda/fresh gel",
                        "field4": "75 ml",
                        "description": "colgate dentifricio baking soda/fresh gel 75 ml",
                        "grammage": 75,
                        "price": "0.69",
                        "price_label": "€ 0,69",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Baking soda",
                            "Fresh gel"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Orale",
                        "blueprint": {
                            "top": 19.1304347826087,
                            "left": 33.64919317742737,
                            "width": 31.50931939870099,
                            "height": 24.031573498964807
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/colgate_baking_soda_75_ml_1565098474_1569922950.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/colgate_fresh_gel_75_ml_1565098476_1569922951.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_wAx5Nwq_752s9YI.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705434,
                        "field1": "aquafresh",
                        "field2": "dentrifico",
                        "field3": "junior 6+ anni",
                        "field4": "75 ml",
                        "description": "aquafresh dentrifico junior 6+ anni 75 ml",
                        "grammage": 75,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Orale",
                        "blueprint": {
                            "top": 19.503105590062113,
                            "left": 68.49068060129902,
                            "width": 31.50931939870099,
                            "height": 24.031573498964807
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8016825673239_1544871692_1563284889.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_lCDVrLj_2UAWbZW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705436,
                        "field1": "pearl drops  ",
                        "field2": "dentifricio 4d",
                        "field3": "vari tipi",
                        "field4": "50 ml",
                        "description": "pearl drops   dentifricio 4d vari tipi 50 ml",
                        "grammage": 50,
                        "price": "2.49",
                        "price_label": "€ 2,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Orale",
                        "blueprint": {
                            "top": 42.857142857142854,
                            "left": 0.0,
                            "width": 39.06051629284807,
                            "height": 26.764492753623188
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/5010724528990_1557245625.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/5010724530009_1557245626.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/5010724530160_1557245627.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_rd3E9ng_PfttfBU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705443,
                        "field1": "oral-b ",
                        "field2": "collutorio pro expert",
                        "field3": "500 ml",
                        "field4": "",
                        "description": "oral-b  collutorio pro expert 500 ml",
                        "grammage": 500,
                        "price": "2.59",
                        "price_label": "€ 2,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Orale",
                        "blueprint": {
                            "top": 42.98136645962733,
                            "left": 39.478187271154944,
                            "width": 22.89830539660343,
                            "height": 26.764492753623188
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/4015600573034_oral_b_pro_expert_collutorio_1559667403.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_83mRaNN_81vfyxn.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705444,
                        "field1": "listerine",
                        "field2": "collutorio",
                        "field3": "vari tipi",
                        "field4": "400/500 ml",
                        "description": "listerine collutorio vari tipi 400/500 ml",
                        "grammage": 0,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Orale",
                        "blueprint": {
                            "top": 43.975155279503106,
                            "left": 62.794163646065236,
                            "width": 37.20583635393475,
                            "height": 26.764492753623188
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/listerin_coll_1523517650_1560874524.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/listerine_protezione_anti_carie_1524038557_1560874525.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_u7wrC6s_cbNrhHz.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.listerine.it"
                            }
                        ]
                    },
                    {
                        "item_id": 705442,
                        "field1": "aquafresh",
                        "field2": "spazzolino flex dinamic",
                        "field3": "duro/medio",
                        "field4": "",
                        "description": "aquafresh spazzolino flex dinamic duro/medio",
                        "grammage": 0,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Duro",
                            "Medio"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Orale",
                        "blueprint": {
                            "top": 70.12991718426501,
                            "left": 0.0,
                            "width": 26.342710997442452,
                            "height": 25.522256728778466
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/AQ_FLEX_DINAMIC_DURO_1444643883_1485191805_1564493992.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/AQ_FLEX_DINAMIC_medium_1444643891_1485191807_1564493992.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_45N8e8f_VbJDoKA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705440,
                        "field1": "oral b",
                        "field2": "spazzolino classic care",
                        "field3": "",
                        "field4": "",
                        "description": "oral b spazzolino classic care",
                        "grammage": 0,
                        "price": "0.95",
                        "price_label": "€ 0,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Orale",
                        "blueprint": {
                            "top": 70.12991718426501,
                            "left": 25.568087729305045,
                            "width": 19.321422657270595,
                            "height": 25.522256728778466
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Oral_b_classic_care_spazzolino_1569916832.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_ZiSGCcj_MWVNNZ5.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705485,
                        "field1": "fatigati",
                        "field2": "spazzolino carbon plus medio",
                        "field3": "",
                        "field4": "2 pz",
                        "description": "fatigati spazzolino carbon plus medio 2 pz",
                        "grammage": 0,
                        "price": "1.99",
                        "price_label": "€ 1,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Orale",
                        "blueprint": {
                            "top": 70.12991718426501,
                            "left": 46.234521334339185,
                            "width": 25.680325304973408,
                            "height": 25.522256728778466
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_C8zyRup_hKAcdv3.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705441,
                        "field1": "oral-b",
                        "field2": "spazzolino classic care",
                        "field3": "40 medio",
                        "field4": "3 pz",
                        "description": "oral-b spazzolino classic care 40 medio 3 pz",
                        "grammage": null,
                        "price": "2.69",
                        "price_label": "€ 2,69",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Orale",
                        "blueprint": {
                            "top": 70.12991718426501,
                            "left": 72.06756334063185,
                            "width": 25.680325304973408,
                            "height": 25.522256728778466
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/3014260762797_oral_b_spazzolino_3_pezzi_1559666936.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_RASYZx6_z2HwxrI.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 5,
                "image_file": "/media/interactive_flyer_19/pages/page_5.jpg",
                "products": [
                    {
                        "item_id": 705327,
                        "field1": "dash",
                        "field2": "simply",
                        "field3": "polvere lavatrice",
                        "field4": "73 misurini",
                        "description": "dash simply polvere lavatrice 73 misurini",
                        "grammage": null,
                        "price": "6.99",
                        "price_label": "€ 6,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Bucato",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 21.04362545769011,
                            "height": 22.665113871635608
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/secondaria_8001841143309_1560866152.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_20TNIUr_fEuviBA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705334,
                        "field1": "ace gentile",
                        "field2": "candeggina  ",
                        "field3": "classica/profumata",
                        "field4": "2 lt",
                        "description": "ace gentile candeggina   classica/profumata 2 lt",
                        "grammage": 2000,
                        "price": "2.49",
                        "price_label": "€ 2,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Classica ",
                            "Profumata"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Bucato",
                        "blueprint": {
                            "top": 0.0,
                            "left": 21.19634215900937,
                            "width": 19.18894551877679,
                            "height": 22.665113871635608
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8001480021136_1555671005.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8001480021143_1555671006.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_2pdno3l_TNyyvB9.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705325,
                        "field1": "omino bianco",
                        "field2": "lavatrice ",
                        "field3": "igienizzante/muschio bianco",
                        "field4": "23+3 lavaggi",
                        "description": "omino bianco lavatrice  igienizzante/muschio bianco 23+3 lavaggi",
                        "grammage": 0,
                        "price": "2.19",
                        "price_label": "€ 2,19",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Igienizzante",
                            "Muschio bianco"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Bucato",
                        "blueprint": {
                            "top": 22.857142857142858,
                            "left": 0.0,
                            "width": 40.25281053929235,
                            "height": 32.35455486542443
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003650015402_omino_bianco_detersivo_igienizzante_23e3_1569920789.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003650015518_omino_bianco_detersivo_muschio_23e3_1569920790.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_qCoUyH6_00uql5W.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705332,
                        "field1": "vernel",
                        "field2": "ammorbidente",
                        "field3": "vari tipi",
                        "field4": "3 lt",
                        "description": "vernel ammorbidente vari tipi 3 lt",
                        "grammage": 3000,
                        "price": "1.79",
                        "price_label": "€ 1,79",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Bucato",
                        "blueprint": {
                            "top": 7.950310559006211,
                            "left": 40.40552724061161,
                            "width": 30.581979429244324,
                            "height": 27.261387163561075
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8015100564477_AMMORB.VERNEL_BLU_CLASSICO_LT.3_1561995620.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8015100564484_AMMORB.VERNEL_LAVANDA_LT.3_1561995620.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_gbD5oJN_b52Srsq.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705326,
                        "field1": "bio presto",
                        "field2": "lavatrice",
                        "field3": "classico/color",
                        "field4": "36 dosi",
                        "description": "bio presto lavatrice classico/color 36 dosi",
                        "grammage": null,
                        "price": "2.99",
                        "price_label": "€ 2,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Classico",
                            "Color"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Bucato",
                        "blueprint": {
                            "top": 7.826086956521739,
                            "left": 70.34536054021234,
                            "width": 29.654639459787667,
                            "height": 27.261387163561075
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/BIOPRESTO_36CLASSICO_1554909004_1557161400_1562259952.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/BIOPRESTO_36COLOR_1554909005_1557161401_1562259953.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_x91Vtsu_wvkHq55.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705363,
                        "field1": "orphea",
                        "field2": "salvalana sacchetti profumati per cassetti",
                        "field3": "vari tipi",
                        "field4": "",
                        "description": "orphea salvalana sacchetti profumati per cassetti vari tipi",
                        "grammage": 0,
                        "price": "2.99",
                        "price_label": "€ 2,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 35.52795031055901,
                            "left": 40.538004379105416,
                            "width": 38.39813060037903,
                            "height": 19.55952380952381
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8001365181726_orphea_salvalana_x3_1569920325.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_lpGwFXC_JB0pEAU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705359,
                        "field1": "spontex",
                        "field2": "full action system",
                        "field3": "lavapavimenti",
                        "field4": "",
                        "description": "spontex full action system lavapavimenti",
                        "grammage": 0,
                        "price": "14.90",
                        "price_label": "€ 14,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Extra",
                        "blueprint": {
                            "top": 35.52795031055901,
                            "left": 78.95637454230989,
                            "width": 21.04362545769011,
                            "height": 19.55952380952381
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Full_Action_System__Set_1432811512_1564492302.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_7JPI23u_Y3HRMNv.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705330,
                        "field1": "omino bianco",
                        "field2": "additivo totale 100 più",
                        "field3": "classico/color",
                        "field4": "500 gr",
                        "description": "omino bianco additivo totale 100 più classico/color 500 gr",
                        "grammage": 500,
                        "price": "2.29",
                        "price_label": "€ 2,29",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Classico",
                            "Color"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Bucato",
                        "blueprint": {
                            "top": 55.65217391304348,
                            "left": 0.0,
                            "width": 21.04362545769011,
                            "height": 20.67753623188406
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/omino_bianco_100piu_classico_1504705342_1542895924_1554992_fnzBt6e.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/omino_bianco_100piu_color_1504705341_1542895923_1554992594_5RSi2QW.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_Atjlqhu_vggIFxV.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705333,
                        "field1": "spuma di sciampagna",
                        "field2": "bucato delicato",
                        "field3": "neropuro argan/puro lana",
                        "field4": "1 lt",
                        "description": "spuma di sciampagna bucato delicato neropuro argan/puro lana 1 lt",
                        "grammage": 0,
                        "price": "1.59",
                        "price_label": "€ 1,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Neropuro argan",
                            "Puro lana"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Bucato",
                        "blueprint": {
                            "top": 55.65217391304348,
                            "left": 20.93138788202175,
                            "width": 19.453899795764407,
                            "height": 20.67753623188406
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Spuma_di_Sciampagna_NeroPuro_Bucato_Delicato_1000_ml_1561996107.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/Spuma_di_Sciampagna_PuroLana_Bucato_Delicato_1000_ml_1561996151.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_pPVGDYw_JN2H7mr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705331,
                        "field1": "spuma di sciampagna",
                        "field2": "additivo biancopuro oxy",
                        "field3": "active/color",
                        "field4": "1 lt",
                        "description": "spuma di sciampagna additivo biancopuro oxy active/color 1 lt",
                        "grammage": null,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Active",
                            "Color"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Bucato",
                        "blueprint": {
                            "top": 55.40372670807454,
                            "left": 40.538004379105416,
                            "width": 19.05646838028298,
                            "height": 20.67753623188406
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8007750085051_SpumaDiSciampagnaOxyActive_1553795112.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8007750085372_SpumaDiSciampagnaOxyColor_1553795113.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_yHYJSow_naFFCwo.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705336,
                        "field1": "fairy",
                        "field2": "platinum plus lemon",
                        "field3": "lavastoviglie",
                        "field4": "x 20",
                        "description": "fairy platinum plus lemon lavastoviglie x 20",
                        "grammage": 0,
                        "price": "2.99",
                        "price_label": "€ 2,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Extra",
                        "blueprint": {
                            "top": 55.52795031055902,
                            "left": 59.747189460707645,
                            "width": 19.05646838028298,
                            "height": 20.67753623188406
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8001841002965_81683743_fairy_caps_platinum_plus_5x20_pi_-__z1Whgmp.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_PaQb8lj_dXxvqiZ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705324,
                        "field1": "regiò",
                        "field2": "tovaglioli maxi scorta 1 velo 33x33 cm",
                        "field3": "",
                        "field4": "500 strappi",
                        "description": "regiò tovaglioli maxi scorta 1 velo 33x33 cm 500 strappi",
                        "grammage": null,
                        "price": "1.89",
                        "price_label": "€ 1,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Superfici",
                        "blueprint": {
                            "top": 55.52795031055902,
                            "left": 78.82389740381609,
                            "width": 21.176102596183917,
                            "height": 20.67753623188406
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8056684220007_TOVAGLIOLI_REGIO_1VELO_33X33_MAXI_SCORTA_S50_PRS1FhZ.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_zC1zvmO_GIXvuDm.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705340,
                        "field1": "smac ",
                        "field2": "sgrassatore/scioglicalcare/bagno",
                        "field3": "vari tipi",
                        "field4": "650 ml",
                        "description": "smac  sgrassatore/scioglicalcare/bagno vari tipi 650 ml",
                        "grammage": 650,
                        "price": "1.29",
                        "price_label": "€ 1,29",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Sgrassatore",
                            "Scioglicalcare",
                            "Bagno"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Superfici",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 0.0,
                            "width": 21.176102596183917,
                            "height": 20.869565217391305
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003650002518_1558373360.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003650006325_1558373372.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_AKgBiZu_MMHC9U6.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705338,
                        "field1": "smac",
                        "field2": "brilla acciaio crema",
                        "field3": "",
                        "field4": "500 ml",
                        "description": "smac brilla acciaio crema 500 ml",
                        "grammage": 500,
                        "price": "1.89",
                        "price_label": "€ 1,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Spray",
                            "Crema"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Superfici",
                        "blueprint": {
                            "top": 76.27329192546584,
                            "left": 20.79891074352794,
                            "width": 19.586376934258215,
                            "height": 20.869565217391305
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Smac_Brilla_Acciaio_Crema_500ml_std_1489663722_1496822998__1dfThso.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_SLAySzY_Mbvc7Az.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705335,
                        "field1": "sole",
                        "field2": "piatti",
                        "field3": "aceto/limone verde/normale",
                        "field4": "1,10 lt",
                        "description": "sole piatti aceto/limone verde/normale 1,10 lt",
                        "grammage": 1100,
                        "price": "0.79",
                        "price_label": "€ 0,79",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Aceto",
                            "Limone verde",
                            "Normale"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Superfici",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 40.27305010211779,
                            "width": 19.586376934258215,
                            "height": 19.25465838509317
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002910022235_sole_piatti_limone_verde_1559724237.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002910022273_sole_piatti_aceto_1559724238.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_HipNK2M_q4UybPp.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705339,
                        "field1": "bref",
                        "field2": "pavimenti",
                        "field3": "vari tipi",
                        "field4": "1,25 lt",
                        "description": "bref pavimenti vari tipi 1,25 lt",
                        "grammage": 1250,
                        "price": "1.79",
                        "price_label": "€ 1,79",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Superfici",
                        "blueprint": {
                            "top": 76.5217391304348,
                            "left": 59.48223518372004,
                            "width": 19.586376934258215,
                            "height": 19.25465838509317
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/BrefBrillante1250_IT_P122767_1522944763_1563272717.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/BrefBrillanteFloralEu1250ml_IT_P122769_1522944763_1563272717.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_rcbmKqw_zleDIxv.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705371,
                        "field1": "diesse",
                        "field2": "piatti",
                        "field3": "fondi/piani/quadri",
                        "field4": "1 kg",
                        "description": "diesse piatti fondi/piani/quadri 1 kg",
                        "grammage": null,
                        "price": "2.49",
                        "price_label": "€ 2,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Fondi",
                            "Piani",
                            "Quadri"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Extra",
                        "blueprint": {
                            "top": 76.5217391304348,
                            "left": 78.95637454230989,
                            "width": 21.04362545769011,
                            "height": 19.25465838509317
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8032642751028_1558422623.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/PIATTI_DIESSE_PIANI_KG.1_1565341824.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_MOogfSp_cGNkCbX.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 6,
                "image_file": "/media/interactive_flyer_19/pages/page_6.jpg",
                "products": [
                    {
                        "item_id": 705465,
                        "field1": "kaloderma",
                        "field2": "crema viso bio ",
                        "field3": "",
                        "field4": "",
                        "description": "kaloderma crema viso bio ",
                        "grammage": null,
                        "price": "4.99",
                        "price_label": "€ 4,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 7.950310559006211,
                            "left": 0.0,
                            "width": 28.992253767318623,
                            "height": 27.080745341614907
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8004395130191_1558421952.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8004395130238_1558421996.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_YBrJuKp_c9rzPts.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705461,
                        "field1": "olaz",
                        "field2": "crema viso",
                        "field3": "vari tipi",
                        "field4": "",
                        "description": "olaz crema viso vari tipi",
                        "grammage": 50,
                        "price": "5.89",
                        "price_label": "€ 5,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 7.950310559006211,
                            "left": 28.880016191650263,
                            "width": 30.581979429244324,
                            "height": 27.080745341614907
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/5000174243598_1553794935.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/5410076363902_1553794964.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_s61r6K0_tkCcdQW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705313,
                        "field1": "laura rossi",
                        "field2": "profumo equivalente",
                        "field3": "donna/uomo",
                        "field4": "100 ml",
                        "description": "laura rossi profumo equivalente donna/uomo 100 ml",
                        "grammage": 100,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Donna",
                            "Uomo"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 35.27950310559007,
                            "left": 0.0,
                            "width": 20.9111483191963,
                            "height": 19.87577639751553
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Laura_Rossi_Aileen_For_Her_1558712096.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/Laura_Rossi_Aura_di_Io_For_Him_1558712097.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/Laura_Rossi_One_Clio_For_Him_1558712098.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_llbG5nX_BJ82fB6.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705317,
                        "field1": "labello",
                        "field2": "burro cacao",
                        "field3": "vari tipi",
                        "field4": "",
                        "description": "labello burro cacao vari tipi",
                        "grammage": 0,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 35.27950310559007,
                            "left": 21.05374523910283,
                            "width": 18.679276527627007,
                            "height": 20.248447204968947
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/LABELLO_BLU_1540375847_1569919419.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/LABELLO_ROSA_1540375848_1569919420.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_TnK8ULp_WqEKpu6.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705382,
                        "field1": "dermomed",
                        "field2": "sapone liquido",
                        "field3": "varie profumazioni",
                        "field4": "1 lt",
                        "description": "dermomed sapone liquido varie profumazioni 1 lt",
                        "grammage": null,
                        "price": "1.19",
                        "price_label": "€ 1,19",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 35.27950310559007,
                            "left": 40.13045318221126,
                            "width": 19.341662220096048,
                            "height": 19.627329192546583
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8032680390807_dermomed_crema_sapone_olio_di_argan_1559724539.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8032680397073_dermomed_crema_sapone_talco_e_iris_1559724554.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_Tg1YQZJ_toO39uN.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705466,
                        "field1": "hq",
                        "field2": "trattamento viso",
                        "field3": "crema/maschera vari trattamenti",
                        "field4": "10/15 ml",
                        "description": "hq trattamento viso crema/maschera vari trattamenti 10/15 ml",
                        "grammage": 0,
                        "price": "1.50",
                        "price_label": "€ 1,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Crema",
                            "Maschera"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 0.0,
                            "left": 59.73706967929493,
                            "width": 19.341662220096048,
                            "height": 22.608695652173914
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8050043996466_1555676619.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8050043996558_1555676668.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_WhWCBEN_aRhSj4C.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705315,
                        "field1": "fria",
                        "field2": "salviettine struccanti utility con tappo",
                        "field3": "",
                        "field4": "pz 64",
                        "description": "fria salviettine struccanti utility con tappo pz 64",
                        "grammage": null,
                        "price": "1.89",
                        "price_label": "€ 1,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 0.0,
                            "left": 78.81377762240336,
                            "width": 21.186222377596643,
                            "height": 22.608695652173914
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8009432016957_1554886385.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_SojftRW_tCJMMAr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705480,
                        "field1": "foxy",
                        "field2": "carta igienica mega",
                        "field3": "2 veli - 4 rotoloni",
                        "field4": "",
                        "description": "foxy carta igienica mega 2 veli - 4 rotoloni",
                        "grammage": 0,
                        "price": "1.89",
                        "price_label": "€ 1,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Extra",
                        "blueprint": {
                            "top": 22.608695652173914,
                            "left": 59.73706967929493,
                            "width": 40.26293032070507,
                            "height": 32.67080745341615
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Foxy_Mega_4_igienica_1491647551_1492502992_1537264428_1560879356.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_1l4aZS2_Q9OHGvx.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705388,
                        "field1": "neutromed",
                        "field2": "bagnoschiuma",
                        "field3": "varie profumazioni",
                        "field4": "500/650 ml",
                        "description": "neutromed bagnoschiuma varie profumazioni 500/650 ml",
                        "grammage": 0,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.52795031055901,
                            "left": 0.0,
                            "width": 21.31869951609045,
                            "height": 20.49689440993789
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8015700154436_neutro_med_idratante_bagno_1559724337_1559816861.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8015700155822_neutro_med_sensual_e_oil_bagno_1559724356_1559816862.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_bA86bEE_7fYKhfb.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705395,
                        "field1": "neutromed",
                        "field2": "doccia shampoo",
                        "field3": "power/sport/idratante",
                        "field4": "250 ml",
                        "description": "neutromed doccia shampoo power/sport/idratante 250 ml",
                        "grammage": 250,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Power",
                            "Sport",
                            "Idratante"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 55.65217391304348,
                            "left": 21.063865020515557,
                            "width": 19.331542438683325,
                            "height": 20.49689440993789
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/neutro_med_1563199622_1569917866_1569917919.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/neutro_med_idratante_1563204535_1569917866_1569917919.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_xnrotMC_8W2snUy.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705394,
                        "field1": "felce azzurra",
                        "field2": "doccia gel",
                        "field3": "vari tipi",
                        "field4": "250 ml",
                        "description": "felce azzurra doccia gel vari tipi 250 ml",
                        "grammage": 250,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.65217391304348,
                            "left": 40.27305010211779,
                            "width": 19.331542438683325,
                            "height": 20.49689440993789
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8001280012464_felce_azzurra_doccia_gel_1559668151.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/felce_doccia_lime_1492100088_1541528578_1569922352.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_UrbgzBG_6xeC4J9.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705383,
                        "field1": "spuma di sciampagna",
                        "field2": "sapone liquido",
                        "field3": "vari tipi",
                        "field4": "400 ml",
                        "description": "spuma di sciampagna sapone liquido vari tipi 400 ml",
                        "grammage": 400,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.65217391304348,
                            "left": 59.48223518372004,
                            "width": 19.331542438683325,
                            "height": 20.49689440993789
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8007750004403_SdS_SaponeLiquido_Marsiglia_400_1499680639_1_aZn4C48.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8007750006704_SdS_Saponecrema_Argan_400ml_1499680640_1569918149.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_zSbHUbR_nTIH7O0.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705484,
                        "field1": "felce azzurra",
                        "field2": "bagnodoccia bio",
                        "field3": "aloe vera e limone/argan e miele",
                        "field4": "500 ml",
                        "description": "felce azzurra bagnodoccia bio aloe vera e limone/argan e miele 500 ml",
                        "grammage": 500,
                        "price": "2.99",
                        "price_label": "€ 2,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Aloe vera e limone",
                            "Argan e miele"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.40372670807454,
                            "left": 78.95637454230989,
                            "width": 21.04362545769011,
                            "height": 20.99378881987578
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/felce_azzurra_argan_500ml_1564495414.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/felce_azzurra_aloe_500ml_1564495414.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_FlcWnTm_rhSakof.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705456,
                        "field1": "geomar",
                        "field2": "crema corpo",
                        "field3": "rassodante 500 ml/scrub 600 gr/anticellulite 650 ml - vari tipi",
                        "field4": "",
                        "description": "geomar crema corpo rassodante 500 ml/scrub 600 gr/anticellulite 650 ml - vari tipi",
                        "grammage": 0,
                        "price": "7.99",
                        "price_label": "€ 7,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Rassodante",
                            "Scrub",
                            "Anticellulite"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 0.0,
                            "width": 21.04362545769011,
                            "height": 19.25465838509317
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510022915_1554887131.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510014408_1554887122_1554894206.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510014316_Geomar_fango_alga_oceanica_1558372976.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510022915_Geomar_fango_argilla_bianca_1558373098.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510030200_Geomar_scrub_purificante_1558373221.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510031047_Geomar_crema_fango_rassodante_per_cellulite__90iUvNY.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_4Y39Mb4_b0psVzn.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705451,
                        "field1": "l'erboristica ",
                        "field2": "crema mani mandorle dolci",
                        "field3": "",
                        "field4": "75 ml",
                        "description": "l'erboristica  crema mani mandorle dolci 75 ml",
                        "grammage": 75,
                        "price": "2.99",
                        "price_label": "€ 2,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Mani",
                            "Corpo"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 76.5217391304348,
                            "left": 20.93138788202175,
                            "width": 19.453899795764407,
                            "height": 19.25465838509317
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002842169978_1557246209.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_4GK1uQH_Df8qPPW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705386,
                        "field1": "intima roberts ",
                        "field2": "salviettine intime camomilla",
                        "field3": "x 12",
                        "field4": "",
                        "description": "intima roberts  salviettine intime camomilla x 12",
                        "grammage": 0,
                        "price": "1.29",
                        "price_label": "€ 1,29",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Salviettine",
                            "Detergente"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 76.64596273291926,
                            "left": 59.349758045226224,
                            "width": 19.453899795764407,
                            "height": 23.35403726708075
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Roberts_salviettine_intime_Camomilla_1492766412_1569918062.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_4jwsMMz_SRn2Xr0.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705385,
                        "field1": "sauber",
                        "field2": "igiene intima extra delicato",
                        "field3": "",
                        "field4": "200 ml",
                        "description": "sauber igiene intima extra delicato 200 ml",
                        "grammage": 200,
                        "price": "1.89",
                        "price_label": "€ 1,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Intima",
                        "blueprint": {
                            "top": 76.64596273291926,
                            "left": 78.95637454230989,
                            "width": 21.04362545769011,
                            "height": 23.35403726708075
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8005520003007_sauber_detergenete_intimo_extra_delicato_1557246954.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_mmTD7mX_wdCY0g5.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 7,
                "image_file": "/media/interactive_flyer_19/pages/page_7.jpg",
                "products": [
                    {
                        "item_id": 707441,
                        "field1": "splendidi e splendenti",
                        "field2": "salviette bimbi con tappo",
                        "field3": "",
                        "field4": "pz 64",
                        "description": "splendidi e splendenti salviette bimbi con tappo pz 64",
                        "grammage": 0,
                        "price": "1.40",
                        "price_label": "€ 1,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 22.608695652173914,
                            "left": 0.0,
                            "width": 24.090599643047707,
                            "height": 24.223602484472053
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8009432019170_1554886409_1555055271.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_RITVYCS_oqNRi6q.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705487,
                        "field1": "mellin",
                        "field2": "pastina",
                        "field3": "semini stelline tempestina",
                        "field4": "320 gr",
                        "description": "mellin pastina semini/stelline/tempestina 320 gr",
                        "grammage": 320,
                        "price": "1.00",
                        "price_label": "€ 1,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Semini",
                            "Stelline",
                            "Tempestina"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Alimentari",
                        "subcategory": "Pasta",
                        "blueprint": {
                            "top": 20.99378881987578,
                            "left": 23.315976374910303,
                            "width": 19.98380834973964,
                            "height": 25.714285714285715
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Mellin_Le_Pastine_Semini_1549618997_1569915645.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/mellin_pastina_stelline_1541754794_1549360934_1569915645.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_znWEW03_5nX6uJL.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705318,
                        "field1": "johnson's baby",
                        "field2": "shampoo",
                        "field3": "",
                        "field4": "750 ml",
                        "description": "johnson's baby shampoo 750 ml",
                        "grammage": 750,
                        "price": "2.25",
                        "price_label": "€ 2,25",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 20.000000000000004,
                            "left": 42.92259287199397,
                            "width": 16.40692561040681,
                            "height": 26.459627329192546
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/3574669907729_Johnsons_baby_shampoo_750_ml_1561995832.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_vdmmbwo_s0chZf3.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705320,
                        "field1": "johnson's",
                        "field2": "salviettine bimbi",
                        "field3": "cotton touch",
                        "field4": "56 pz x 2",
                        "description": "johnson's salviettine bimbi cotton touch 56 pz x 2",
                        "grammage": 0,
                        "price": "1.99",
                        "price_label": "€ 1,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 19.87577639751553,
                            "left": 58.9523266297448,
                            "width": 20.513716903714876,
                            "height": 26.459627329192546
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/3574661421896_johnsons_cottontouch_salviette_x112_1569920830.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_4dCZomu_YnvxqWg.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705321,
                        "field1": "pampers",
                        "field2": "pannolini baby dry",
                        "field3": "varie misure",
                        "field4": "",
                        "description": "pampers pannolini baby dry varie misure",
                        "grammage": 0,
                        "price": "4.49",
                        "price_label": "€ 4,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 18.01242236024845,
                            "left": 78.82389740381609,
                            "width": 21.176102596183917,
                            "height": 28.322981366459626
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Pampers_baby_dry_4_1531745470_1540823893_1553275750_155387_OQkblsS.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/Pampers_baby_dry_5_1531745471_1540823894_1553275751_155387_jYbb1Ak.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_5FXOIbO_jdIJtPj.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705488,
                        "field1": "innova",
                        "field2": "giochi",
                        "field3": "",
                        "field4": "",
                        "description": "innova giochi",
                        "grammage": 0,
                        "price": "4.49",
                        "price_label": "€ 4,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Cucina"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 46.7080745341615,
                            "left": 38.8158015786859,
                            "width": 24.090599643047707,
                            "height": 27.080745341614907
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/ingioco_giochiamo_in_cucina_1569936125_FAvkLzy.jpg",
                                "cropped": false
                            },
                           
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_L2ef8OG_Dney4cC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705488,
                        "field1": "innova",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "innova giochi",
                        "grammage": 0,
                        "price": "4.49",
                        "price_label": "€ 4,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Costruzioni"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 47.08074534161491,
                            "left": 0.0,
                            "width": 38.795562015860455,
                            "height": 27.080745341614907
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_19/products/innova_block_animal_1569936127_gCLmSjC.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_VoHcasQ_Y0hX9ub.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705488,
                        "field1": "innova",
                        "field2": "giochi",
                        "field3": "",
                        "field4": "",
                        "description": "innova giochi",
                        "grammage": 0,
                        "price": "4.49",
                        "price_label": "€ 4,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Lavagna Magica"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 48.32298136645963,
                            "left": 61.20443798413955,
                            "width": 38.795562015860455,
                            "height": 27.080745341614907
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_19/products/ingioco_lavagna_magica_1569936126_x8QB0VZ.jpg",
                                "cropped": false
                            },
                            
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_WcQiuTV_ZDP9hwS.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705489,
                        "field1": "innova",
                        "field2": "peluches con plaid",
                        "field3": "4 modelli",
                        "field4": "",
                        "description": "innova peluches con plaid 4 modelli",
                        "grammage": 0,
                        "price": "8.90",
                        "price_label": "€ 8,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 72.9192546583851,
                            "left": 0.0,
                            "width": 38.795562015860455,
                            "height": 27.080745341614907
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/i_morbidoni_coniglio_1569936041.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_aUpzxkt_xcjZAVn.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705488,
                        "field1": "innova",
                        "field2": "giochi",
                        "field3": "",
                        "field4": "",
                        "description": "innova giochi",
                        "grammage": 0,
                        "price": "4.49",
                        "price_label": "€ 4,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Trattore"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 72.67080745341615,
                            "left": 39.87561868663637,
                            "width": 25.680325304973408,
                            "height": 22.857142857142858
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_19/products/ingioco_giochiamo_in_fattoria_1569936126_wiNL6qN.jpg",
                                "cropped": false
                            },
                            
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_dcoQJ0Y_ezr9P9o.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705488,
                        "field1": "innova",
                        "field2": "giochi",
                        "field3": "",
                        "field4": "",
                        "description": "innova giochi",
                        "grammage": 0,
                        "price": "4.49",
                        "price_label": "€ 4,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Allegro Microfono"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 75.77639751552796,
                            "left": 69.28554343226187,
                            "width": 30.71445656773813,
                            "height": 19.751552795031056
                        },
                        "images": [
                            
                            {
                                "image_file": "/media/interactive_flyer_19/products/innova_allegro_microfono_1569936126_NdVEtiI.jpg",
                                "cropped": false
                            },
                            
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_wn5ZbSk_3eIiGYg.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 8,
                "image_file": "/media/interactive_flyer_19/pages/page_8.jpg",
                "products": [
                    {
                        "item_id": 705420,
                        "field1": "neutromed",
                        "field2": "deodorante dermo spray",
                        "field3": "varie profumazioni",
                        "field4": "150 ml",
                        "description": "neutromed deodorante dermo spray varie profumazioni 150 ml",
                        "grammage": 150,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 8.198757763975156,
                            "left": 0.0,
                            "width": 28.32986807484957,
                            "height": 26.832298136645967
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8015700156768_1557244341.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8015700156836_1557244341_1557310959.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_MqcgZm2_PaPqJQ2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705428,
                        "field1": "carefree ",
                        "field2": "assorbenti proteggi slip",
                        "field3": "vari tipi",
                        "field4": "",
                        "description": "carefree  assorbenti proteggi slip vari tipi",
                        "grammage": null,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Intima",
                        "blueprint": {
                            "top": 7.826086956521739,
                            "left": 28.350107637675023,
                            "width": 30.84693370623193,
                            "height": 27.080745341614907
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/3574660067682_CarefreeProteggiSlip44_1553790947.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/3574660550184_CarefreeProteggi24_1553791024.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_Y7iyX8Z_XC7a4JR.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705379,
                        "field1": "mantovani  ",
                        "field2": "saponette",
                        "field3": "argan/neutra",
                        "field4": "100 gr x 4",
                        "description": "mantovani   saponette argan/neutra 100 gr x 4",
                        "grammage": 400,
                        "price": "1.79",
                        "price_label": "€ 1,79",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Argan",
                            "Neutra"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 0.0,
                            "left": 59.747189460707645,
                            "width": 19.18894551877678,
                            "height": 22.484472049689444
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002340008281_MantovaniClassico_1553793426.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002340012424_MantovaniArganX4_1553793426.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_X8xqxNx_fv99lsr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705419,
                        "field1": "malizia",
                        "field2": "deodorante donna",
                        "field3": "varie profumazioni",
                        "field4": "100 ml",
                        "description": "malizia deodorante donna varie profumazioni 100 ml",
                        "grammage": 100,
                        "price": "1.39",
                        "price_label": "€ 1,39",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 0.0,
                            "left": 78.95637454230989,
                            "width": 21.043625457690112,
                            "height": 22.484472049689444
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510015474_MaliziaDeodoranteDonnaPurple_1553793560.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510020478_MaliziaDeodoranteDonnaSeduction_1553793650.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_HtxnMYm_iljaNf9.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705426,
                        "field1": "nuvenia",
                        "field2": "assorbenti/",
                        "field3": "proteggi slip",
                        "field4": "vari tipi",
                        "description": "nuvenia assorbenti/ proteggi slip vari tipi",
                        "grammage": 0,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Intima",
                        "blueprint": {
                            "top": 22.608695652173914,
                            "left": 59.747189460707645,
                            "width": 40.25281053929235,
                            "height": 32.67080745341615
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/nuvenia_notte_sottile_x10_1537463158.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/Nuvenia_Ultra_SOTTILE_Notte_10x12_1532159692_1535624218_1554364160.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/7322540678628_1554892782.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_UsQqjxT_DZwjHg7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705415,
                        "field1": "thermo therapy",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "thermo therapy",
                        "grammage": 0,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Fascia",
                            "Cerotti"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Medicinali",
                        "subcategory": "Uso Esterno",
                        "blueprint": {
                            "top": 35.40372670807454,
                            "left": 0.0,
                            "width": 59.32951848240077,
                            "height": 19.751552795031056
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8009432012218_thermo_therapy_2_dispositivi_cervicale__1569920373.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_j49G0l3_Jtd11Cr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705422,
                        "field1": "i provenzali",
                        "field2": "deodorante argan bio",
                        "field3": "roll-on 50 ml/vapo 75 ml",
                        "field4": "",
                        "description": "i provenzali deodorante argan bio roll-on 50 ml/vapo 75 ml",
                        "grammage": null,
                        "price": "2.59",
                        "price_label": "€ 2,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Roll",
                            "Vapo"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.52795031055901,
                            "left": 0.0,
                            "width": 20.911148319196293,
                            "height": 20.745341614906835
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8025796007592_1558422573.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/I_Provenzali_Bio_Deo_Vapo_Biologico_Argan_75_ml_1569922702.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_ghwVawh_RnX11yU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705424,
                        "field1": "o.b.",
                        "field2": "assorbenti",
                        "field3": "vari tipi",
                        "field4": "",
                        "description": "o.b. assorbenti vari tipi",
                        "grammage": 0,
                        "price": "2.29",
                        "price_label": "€ 2,29",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Intima",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 0.0,
                            "width": 20.911148319196293,
                            "height": 19.25465838509317
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/3574660240009_OBProConfortTampone16_1553790992.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/4001683011426_OBProConfort16_1553791138.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_vB1KDbT_j30ffaW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705373,
                        "field1": "scholl",
                        "field2": "linea",
                        "field3": "vari tipi",
                        "field4": "",
                        "description": "scholl linea vari tipi",
                        "grammage": null,
                        "price": "11.50",
                        "price_label": "€ 3,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.52795031055901,
                            "left": 20.93138788202175,
                            "width": 38.39813060037903,
                            "height": 40.00000000000001
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8410104602147_1555676714.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8410104602154_1555676725.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_nuqFKWW_gvJ1ohW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705411,
                        "field1": "veet",
                        "field2": "crema depilatoria",
                        "field3": "vari tipi",
                        "field4": "",
                        "description": "veet crema depilatoria vari tipi",
                        "grammage": null,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Intima",
                        "blueprint": {
                            "top": 70.18633540372672,
                            "left": 59.73706967929493,
                            "width": 19.341662220096048,
                            "height": 29.813664596273295
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002910043575_1555675619.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002910043636_1555675619.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_VvUK7kV_q6BnvfW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705408,
                        "field1": "veet",
                        "field2": "rasoio sensitive precision elettrico viso corpo",
                        "field3": "",
                        "field4": "",
                        "description": "veet rasoio sensitive precision elettrico viso corpo",
                        "grammage": null,
                        "price": "21.90",
                        "price_label": "€ 21,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 70.18633540372672,
                            "left": 79.73099781044729,
                            "width": 20.269002189552708,
                            "height": 29.813664596273295
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002910045517_1555675633.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_oHva2AQ_CahCBvD.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 9,
                "image_file": "/media/interactive_flyer_19/pages/page_9.jpg",
                "products": [
                    {
                        "item_id": 705405,
                        "field1": "felce azzurra",
                        "field2": "talco",
                        "field3": "",
                        "field4": "100 gr",
                        "description": "felce azzurra talco 100 gr",
                        "grammage": 100,
                        "price": "0.59",
                        "price_label": "€ 0,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 20.93138788202175,
                            "height": 22.36024844720497
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8001280026034_felce_azzurra_saponetta_talco_fresco_1559668385.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_aClP3fd_YdOJjcr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705429,
                        "field1": "roberts",
                        "field2": "cotonfioc",
                        "field3": "con camomilla",
                        "field4": "260 pz",
                        "description": "roberts cotonfioc con camomilla 260 pz",
                        "grammage": null,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 0.0,
                            "left": 20.93138788202175,
                            "width": 19.474139358589856,
                            "height": 22.36024844720497
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8002410008326_1558423843.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_u79RCZp_q3VRnkm.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705398,
                        "field1": "keramine h",
                        "field2": "linea",
                        "field3": "capelli",
                        "field4": "",
                        "description": "keramine h linea capelli",
                        "grammage": 300,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 0.0,
                            "left": 40.40552724061161,
                            "width": 35.50387311634069,
                            "height": 34.90683229813665
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8019971030154_Keramine_H_Kh_Multi_Vita_Color_Shampoo_1569922089.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8019971037214_Keramine_H_Lh_Anticaduta_Shampoo_1569922102.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_8SGcy68_z6Jkv3W.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705471,
                        "field1": "splendor",
                        "field2": "lacca",
                        "field3": "forte/normale",
                        "field4": "300 ml",
                        "description": "splendor lacca forte/normale 300 ml",
                        "grammage": 300,
                        "price": "1.19",
                        "price_label": "€ 1,19",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Forte",
                            "Normale"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 7.950310559006211,
                            "left": 75.49172937864542,
                            "width": 24.50827062135458,
                            "height": 27.204968944099377
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510000579_1557246535.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/splendid_lacca_normale_300_ml_1557506498.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_8djcPW0_3GSqEjZ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705474,
                        "field1": "garnier belle color",
                        "field2": "shampoo colorante",
                        "field3": "varie nuances",
                        "field4": "",
                        "description": "garnier belle color shampoo colorante varie nuances",
                        "grammage": null,
                        "price": "4.89",
                        "price_label": "€ 4,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 22.484472049689444,
                            "left": 0.0,
                            "width": 40.27305010211779,
                            "height": 32.79503105590062
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8001960120120_1554885158.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/SHAMPOO_COL.BELLE_COLOR_CASTANO_SCURO_N.24_1569923302.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_IyMxAdn_hgmFCv4.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705472,
                        "field1": "testanera",
                        "field2": "palette styling",
                        "field3": "",
                        "field4": "vari tipi",
                        "description": "testanera palette styling vari tipi",
                        "grammage": 0,
                        "price": "2.99",
                        "price_label": "€ 2,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 35.1552795031056,
                            "left": 40.40552724061161,
                            "width": 19.20918508160224,
                            "height": 20.372670807453417
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/SKP_Palette-Styling_970x1400_INVISIBILE-Pasta_1557932404_1_gDfADar.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/principale_8015700154320_1560878996.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_YzMvTZZ_RiGFP8I.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705476,
                        "field1": "schwarzkopf",
                        "field2": "shampoo colorante",
                        "field3": "varie nuances",
                        "field4": "",
                        "description": "schwarzkopf shampoo colorante varie nuances",
                        "grammage": null,
                        "price": "4.99",
                        "price_label": "€ 4,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 35.40372670807454,
                            "left": 59.747189460707645,
                            "width": 19.20918508160224,
                            "height": 20.000000000000004
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/tone_supreme_int_baseline_6_0_light_brown_970x1400_1562056496.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/tone_supreme_int_baseline_7_1_ashy_dark_blonde_970x1400_1562056497.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_cLCLecU_XUkGce3.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705406,
                        "field1": "naturaverde ",
                        "field2": "strisce depilatorie corpo cotone ",
                        "field3": "",
                        "field4": "20 + 10 pz",
                        "description": "naturaverde  strisce depilatorie corpo cotone  20 + 10 pz",
                        "grammage": null,
                        "price": "2.99",
                        "price_label": "€ 2,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 35.1552795031056,
                            "left": 79.22132881929751,
                            "width": 20.778671180702492,
                            "height": 20.000000000000004
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Naturaverde_striscie_depilatorie_braccia_e_gambe_155871314_N03qeei.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_kLAtlIn_on6cxIH.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705457,
                        "field1": "nivea men ",
                        "field2": "crema viso idratante",
                        "field3": "",
                        "field4": "75 ml",
                        "description": "nivea men  crema viso idratante 75 ml",
                        "grammage": 75,
                        "price": "4.90",
                        "price_label": "€ 4,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 55.52795031055901,
                            "left": 0.0,
                            "width": 20.778671180702492,
                            "height": 20.62111801242236
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/4005808888085_NiveaMenCremaVisoIdrantante75_1553791764.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_THlihkh_dPTdLUz.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705399,
                        "field1": "ultra dolce ",
                        "field2": "balsamo 200 ml/shampoo 250 ml",
                        "field3": "vari tipi",
                        "field4": "",
                        "description": "ultra dolce  balsamo 200 ml/shampoo 250 ml vari tipi",
                        "grammage": null,
                        "price": "1.49",
                        "price_label": "€ 1,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Balsamo",
                            "Shampoo"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 55.52795031055901,
                            "left": 20.93138788202175,
                            "width": 38.92803915435426,
                            "height": 20.62111801242236
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/3600541887626_FructisUltraDolceShampooCapelliDelicati_1553791106.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/3600541887985_FructisBalsamoUltraDolceBalsamoDelicati200_1_D8kIZXt.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_zP9ILO2_Ff1OaFM.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705423,
                        "field1": "malizia",
                        "field2": "deodorante spray uomo",
                        "field3": "vari tipi",
                        "field4": "150 ml",
                        "description": "malizia deodorante spray uomo vari tipi 150 ml",
                        "grammage": 150,
                        "price": "1.99",
                        "price_label": "€ 1,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.77639751552796,
                            "left": 59.747189460707645,
                            "width": 19.18894551877679,
                            "height": 20.62111801242236
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510001729_1555675668.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8003510020492_1555675697.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_ynufXHg_nEAkQyX.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705445,
                        "field1": "neutromed",
                        "field2": "schiuma da barba",
                        "field3": "fresca/ultrarapida",
                        "field4": "300 ml",
                        "description": "neutromed schiuma da barba fresca/ultrarapida 300 ml",
                        "grammage": 300,
                        "price": "1.29",
                        "price_label": "€ 1,29",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Fresca",
                            "Ultrarapida"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 55.52795031055901,
                            "left": 78.82389740381609,
                            "width": 21.176102596183917,
                            "height": 20.62111801242236
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/NEUTROMED_SCHIUMA_DA_BARBA_ULTRARAPIDA_1531810670_1560874655.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/58728_1550164516_1560874655.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_fQJaPXd_vmxg9Bz.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705447,
                        "field1": "bic",
                        "field2": "rasoi flex4 ",
                        "field3": "",
                        "field4": "4 pz",
                        "description": "bic rasoi flex4  4 pz",
                        "grammage": null,
                        "price": "2.49",
                        "price_label": "€ 2,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 0.0,
                            "width": 21.176102596183917,
                            "height": 23.602484472049692
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/3086123242555_1554884321.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_EBQW7xz_ahiHHcQ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705449,
                        "field1": "wilkinson",
                        "field2": "rasoi sword extra3 essentials ",
                        "field3": "x 4+2/lady x 4",
                        "field4": "",
                        "description": "wilkinson rasoi sword extra3 essentials  x 4+2/lady x 4",
                        "grammage": 0,
                        "price": "2.49",
                        "price_label": "€ 2,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Extra3 4+2",
                            "Extra3 Lady x 4"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 20.93138788202175,
                            "width": 19.18894551877679,
                            "height": 23.602484472049692
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/wilkinson_Extra_3_Essentials_42_rasoi_1436341050_1569916593.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/principale_4027800005901_1569923033.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_U3dnVvD_leSwP1d.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705448,
                        "field1": "gillette",
                        "field2": "rasoi blue ii",
                        "field3": "vari tipi",
                        "field4": "4/5 pz",
                        "description": "gillette rasoi blue ii vari tipi 4/5 pz",
                        "grammage": null,
                        "price": "1.69",
                        "price_label": "€ 1,69",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 76.39751552795032,
                            "left": 40.538004379105416,
                            "width": 19.18894551877679,
                            "height": 19.25465838509317
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/7702018094684_1554883750.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/7702018094714_1554883751.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_pAG4qZx_FkXoBz2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705450,
                        "field1": "gillette",
                        "field2": "fusion5 power ricambi",
                        "field3": "",
                        "field4": "4 pz",
                        "description": "gillette fusion5 power ricambi 4 pz",
                        "grammage": null,
                        "price": "12.50",
                        "price_label": "€ 12,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 76.27329192546584,
                            "left": 59.61471232221385,
                            "width": 19.18894551877679,
                            "height": 19.25465838509317
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/7702018482337_1558422858.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_93YBBx8_FPTwJ8t.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705446,
                        "field1": "proraso",
                        "field2": "dopo barba",
                        "field3": "protettivo/lozione/crema vari trattamenti",
                        "field4": "100 ml",
                        "description": "proraso dopo barba protettivo/lozione/crema vari trattamenti 100 ml",
                        "grammage": 100,
                        "price": "2.99",
                        "price_label": "€ 2,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Protettivo",
                            "Lozione",
                            "Crema"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 76.5217391304348,
                            "left": 78.95637454230989,
                            "width": 21.04362545769011,
                            "height": 19.25465838509317
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/8004395001064_1554992133_1569916721.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8004395001071_1554992434_1569916722.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/8004395001101_ProrasoCremaLiquida_1561998340_1569916722.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_v95Go0h_dZnVqbr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 10,
                "image_file": "/media/interactive_flyer_19/pages/page_10.jpg",
                "products": [
                    {
                        "item_id": 705486,
                        "field1": "chupa chups",
                        "field2": "",
                        "field3": "",
                        "field4": "busta 10 pz",
                        "description": "chupa chups busta 10 pz",
                        "grammage": 0,
                        "price": "1.50",
                        "price_label": "€ 1,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Alimentari",
                        "subcategory": "Dispensa",
                        "blueprint": {
                            "top": 73.04347826086958,
                            "left": 65.1787521389538,
                            "width": 30.581979429244324,
                            "height": 22.98136645962733
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/chupa_cola_1544787672_1569915710.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/chupa_fruit_1544787673_1569915711.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_oYZsk3m_28USC57.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 11,
                "image_file": "/media/interactive_flyer_19/pages/page_11.jpg",
                "products": [
                    {
                        "item_id": 705462,
                        "field1": "nivea",
                        "field2": "crema viso antirughe q10",
                        "field3": "vari tipi",
                        "field4": "50 ml",
                        "description": "nivea crema viso antirughe q10 vari tipi 50 ml",
                        "grammage": 50,
                        "price": "7.50",
                        "price_label": "€ 7,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 3.9751552795031055,
                            "left": 72.7299490331009,
                            "width": 22.89830539660343,
                            "height": 22.98136645962733
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/4005800097812_NiveaCremaViso50Antirughe_1553791335.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/81289a_19919_1569923627.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_5VcreSD_cPtZiN0.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 12,
                "image_file": "/media/interactive_flyer_19/pages/page_12.jpg",
                "products": [
                    {
                        "item_id": 705356,
                        "field1": "vileda",
                        "field2": "glitzi crystal fibraverde ",
                        "field3": "2+1 pz",
                        "field4": "",
                        "description": "vileda glitzi crystal fibraverde  2+1 pz",
                        "grammage": 0,
                        "price": "1.39",
                        "price_label": "€ 1,39",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "No Food",
                        "subcategory": "Home",
                        "blueprint": {
                            "top": 0.0,
                            "left": 77.10169460339658,
                            "width": 22.89830539660343,
                            "height": 21.73913043478261
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/Vileda_Abrasivo_Glitzi_Crystal_21x_1542821347_1569918409.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_JqijSjP_ds3QWV0.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705348,
                        "field1": "vileda",
                        "field2": "guanti multiuso color nitrile",
                        "field3": "taglia - s/m - m/l",
                        "field4": "50 pz",
                        "description": "vileda guanti multiuso color nitrile taglia - s/m - m/l 50 pz",
                        "grammage": null,
                        "price": "3.49",
                        "price_label": "€ 3,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Taglia S/M",
                            "Taglia M/L"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Superfici",
                        "blueprint": {
                            "top": 21.73913043478261,
                            "left": 77.10169460339658,
                            "width": 22.89830539660343,
                            "height": 27.080745341614907
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/4023103200791_1557245509.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/4023103200814_1557245510.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_vFkhzfC_BMUUMD4.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705357,
                        "field1": "vileda",
                        "field2": "spugna abrasiva ",
                        "field3": "ondattiva",
                        "field4": "3 pz +1 gratis",
                        "description": "vileda spugna abrasiva  ondattiva 3 pz +1 gratis",
                        "grammage": 0,
                        "price": "1.29",
                        "price_label": "€ 1,29",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Superfici",
                        "blueprint": {
                            "top": 17.267080745341616,
                            "left": 50.20883548915344,
                            "width": 25.547848166479596,
                            "height": 12.04968944099379
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/vileda_ondattiva_colors_31_1539098433_1557242056.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_gBQHNIQ_hxcOPmK.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705352,
                        "field1": "vileda",
                        "field2": "panno multiuso",
                        "field3": "vari tipi",
                        "field4": "",
                        "description": "vileda panno multiuso vari tipi",
                        "grammage": null,
                        "price": "2.49",
                        "price_label": "€ 2,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Superfici",
                        "blueprint": {
                            "top": 28.571428571428573,
                            "left": 50.34131262764725,
                            "width": 25.547848166479596,
                            "height": 19.503105590062113
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/4023103185975_1557245482.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_m3oBkUw_PkxUHVH.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 705341,
                        "field1": "pronto",
                        "field2": "vari tipi",
                        "field3": "",
                        "field4": "",
                        "description": "pronto vari tipi",
                        "grammage": null,
                        "price": "1.99",
                        "price_label": "€ 1,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Casa",
                        "subcategory": "Superfici",
                        "blueprint": {
                            "top": 0.0,
                            "left": 0.0,
                            "width": 49.6069725958558,
                            "height": 36.866857174346016
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_19/products/5000204670905_1554884573.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/pronto_multiuso_gelsomino_5000204671070_1563461179.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_19/products/cropped_image_s23Chlr_SHh0Dnv.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 13,
                "image_file": "/media/interactive_flyer_19/pages/fine.png",
                "products": []
            }





        ]
    }
    },
    "magnone": {
        "config": {
            "primary-color": "rgb(237, 27, 36)",  
            "secondary-color": "rgb(162, 32, 42)", 
            "tertiary-color": "rgb(255, 255, 255)",
            "hover-color": "rgba(237, 27, 36, 0.5)", 
            "clientIcon": require("assets/images/magnone_clientLogo.png"),
            "serverPath": "https://magnone.volantinointerattivo.net",
            "pdfPath": "/media/interactive_flyer_23/volantino_magnone.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino di Magnone Catering!",
            "shareFlyerURL": "https://magnone.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Magnone Catering!",
            "ogTitleMeta": "Volantino Interattivo Magnone Catering",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://magnone.volantinointerattivo.net/media/interactive_flyer_23/images/clientLogo.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": ""
        },
        "leaflet": {
            "name": "Magnone",
            "code": "magnone19",
            "id_campaign": "12398",
            "index": {
                "image_file": "/media/interactive_flyer_23/pages/indice.png",
                "links": [
                    {   //RISTORANTE
                        "page": 0,
                        "blueprint": {
                            "top": 13.209417446859229,
                            "left": 2.5437179189474928,
                            "width": 95.36460151699434,
                            "height": 7.294301835504068
                        }
                    },
                    {   //2. SALUMERIA
                        "page": 2,
                        "blueprint": {
                            "top": 21.25231288921848,
                            "left": 2.6341253899253547,
                            "width": 95.36460151699434,
                            "height": 7.160253578131415
                        }
                    },
                    {   //LIQUORI
                        "page": 8,
                        "blueprint": {
                            "top": 29.027111816832416,
                            "left": 2.6341253899253547,
                            "width": 95.36460151699434,
                            "height": 7.160253578131415
                        }
                    },
                    {   //BEVANDE
                        "page": 10,
                        "blueprint": {
                            "top": 36.833395388526775,
                            "left": 2.478084025426191,
                            "width": 95.52386022921513,
                            "height": 6.938191429352159
                        }
                    },
                    {   //VINI
                        "page": 14,
                        "blueprint": {
                            "top": 44.484640940128195,
                            "left": 2.478084025426191,
                            "width": 95.52386022921513,
                            "height": 7.116127372412656
                        
                        }
                    },
                    {   //SPECIAL PIZZA
                        "page": 16,
                        "blueprint": {
                            "top": 52.31382243479012,
                            "left": 2.5980910954430683,
                            "width": 95.36178871427002,
                            "height": 6.938191429352159
                        }
                    },
                    {   //7. HORECA
                        "page": 18,
                        "blueprint": {
                            "top": 60.04449515668531,
                            "left": 2.573317517986508,
                            "width": 95.36460151699434,
                            "height": 7.160253578131415
                    }
                    },
                    {   //8. PESCE
                        "page": 20,
                        "blueprint": {
                            "top": 67.87367665134724,
                            "left": 2.333303377952753,
                            "width": 95.36460151699434,
                            "height": 7.160253578131415
                    }
                    },
                    {   //9.DISPENSA
                        "page": 22,
                        "blueprint": {
                            "top": 75.70285814600915,
                            "left": 2.213296307935875,
                            "width": 95.36460151699434,
                            "height": 7.160253578131415
                    }
                    },
                    {   //10.IGIENE
                        "page": 26,
                        "blueprint": {
                            "top": 83.44307166914083,
                            "left": 2.45331044796963,
                            "width": 95.36460151699434,
                            "height": 7.160253578131415
                    }
                    },
                    {   //11. SERVIZIO
                        "page": 28,
                        "blueprint": {
                            "top": 91.18328519227252,
                            "left": 2.333303377952753,
                            "width": 95.36460151699434,
                            "height": 6.982317635070917
                    }
                    },
    
    
                ]
            },
        "categories": [
            {
                "category_name": "Bevande",
                "subcategories": [
                    "Birre",
                    "Liquori",
                    "Succhi",
                    "Bibite",
                    "Acqua",
                    "Vini e Spumanti"
                ]
            },
            {
                "category_name": "Macelleria",
                "subcategories": [
                    "Bovino",
                    "Suino",
                    "Avicoli"
                ]
            },
            {
                "category_name": "Gastronomia",
                "subcategories": [
                    "Salumi",
                    "Formaggi"
                ]
            },
            {
                "category_name": "Self Service",
                "subcategories": [
                    "Formaggi"
                ]
            },
            {
                "category_name": "Ortofrutta",
                "subcategories": [
                    "Frutta",
                    "Verdura"
                ]
            }
        ],
        "pages": [
            {
                "number": 1,
                "image_file": "/media/interactive_flyer_23/pages/page_1.jpg",
                "products": [
                    {
                        "item_id": 619639,
                        "field1": "heineken",
                        "field2": "birra ",
                        "field3": "",
                        "field4": "cl. 33",
                        "description": "heineken birra  cl. 33",
                        "grammage": 330,
                        "price": "0.59",
                        "price_label": "€ 0,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 70.9710397457348,
                            "left": 3.2565280181106155,
                            "width": 31.990100455512042,
                            "height": 19.358840350843117
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Heineken_Cl_33_x_3_1560763931_1560788363_1560788417.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_eK7rGfW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.heineken.com/it/we-are-heineken/our-beer"
                            },
                            {
                                "type": "play",
                                "title": "VIDEO - Spot Birra Moretti",
                                "data": "/media/interactive_flyer_23/video/heineken.mp4"
                            },
                        ],
                        "related": [619636,619637,619645,619641,619642,619644]
                    },
                    {
                        "item_id": 619638,
                        "field1": "ichnusa ",
                        "field2": "birra non filtrata",
                        "field3": "",
                        "field4": "cl. 50",
                        "description": "ichnusa  birra non filtrata cl. 50",
                        "grammage": 500,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 70.9710397457348,
                            "left": 35.36951264114585,
                            "width": 29.185867995472346,
                            "height": 19.358840350843117
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/489092_ichnusaNonFiltrata50cl_1539255169_1554211360_1560788284.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_9fQmmJZ_zU39R5B.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.birraichnusa.it/le-birre/"
                            },
                            {
                                "type": "info",
                                "title": "CURIOSITA' - Storia Ichnusa",
                                "data": {

                                    "titolo": "Curiosità Birra Ichnusa",
                                    "testo": "Anima Sarda. La nostra isola vive dentro di noi. La Sardegna è una terra magica, con un’anima forte. Noi di Ichnusa ne conserviamo l’istinto e la fierezza. Un’anima che apprezza le cose semplici, che sa emozionarsi per le cime del Gennargentu e per le coste di Capo Spartivento. È L’anima di chi si ribella. Di chi non segue, né insegue. Per questo manteniamo forti le nostre radici e la nostra Anima Sarda, e supportiamo attività legate al territorio, alla cultura e alle tradizioni della nostra isola. Il nostro carattere. Siamo fieri, siamo di poche parole, siamo autentici e a volte siamo impetuosi. Proprio come la nostra terra. Da oltre 100 anni raccontiamo la Sardegna attraverso la nostra birra: colori e profumi, paesaggi e storie antiche, occasioni e momenti in cui solo chi ama davvero la nostra isola si può riconoscere. Per questo abbiamo lasciato nel cuore di ogni sardo un’impronta indelebile. Un’emozione che si ritrova in ogni brindisi con Ichnusa.(Fonte: birraichnusa.it)",
                                    "img": [
                                        "/media/interactive_flyer_23/images/ichnusa.jpg"
                                    ]
                                }
                            },
                        ],
                        "related": [619636,619637,619645,619641,619642,619644]
                    },
                    {
                        "item_id": 619635,
                        "field1": "moretti",
                        "field2": "birra",
                        "field3": "cl. 66",
                        "field4": "",
                        "description": "moretti birra cl. 66",
                        "grammage": 660,
                        "price": "0.71",
                        "price_label": "€ 0,71",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 70.9710397457348,
                            "left": 64.6782648041414,
                            "width": 32.17101867874041,
                            "height": 19.358840350843117
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/MORETTI_BIRRA_CL_66_1435336589_1542732367_1560788051.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_HYg4qW1_ywcRToc.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "VIDEO - Spot Birra Moretti",
                                "data": "/media/interactive_flyer_23/video/moretti.mp4"
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.birramoretti.it/le-birre-di-casa/birra-moretti-ricetta-originale/"
                            }

                        ],
                        "related": [619636,619637,619645,619641,619642,619644]
                    }
                ]
            },
            {
                "number": 2,
                "image_file": "/media/interactive_flyer_23/pages/page_2.jpg",
                "products": [
                    {
                        "item_id": 622241,
                        "field1": "mec",
                        "field2": "scamone di bovino adulto",
                        "field3": "",
                        "field4": "al kg",
                        "description": "mec scamone di bovino adulto al kg",
                        "grammage": null,
                        "price": "8.66",
                        "price_label": "€ 8,66 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Macelleria",
                        "subcategory": "Bovino",
                        "blueprint": {
                            "top": 10.392853149151367,
                            "left": 5.970301366536138,
                            "width": 43.56886674212756,
                            "height": 24.155160981592132
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/SCAMONE_DI_SCOTTONA_1561474347.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_o8VelEU_OMIprO2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622240,
                        "field1": "mec",
                        "field2": "noce di bovino adulto",
                        "field3": "",
                        "field4": "al kg",
                        "description": "mec noce di bovino adulto al kg",
                        "grammage": null,
                        "price": "7.40",
                        "price_label": "€ 7,40 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Macelleria",
                        "subcategory": "Bovino",
                        "blueprint": {
                            "top": 11.181289417219698,
                            "left": 49.119297606501796,
                            "width": 44.92575341634032,
                            "height": 28.425857433628924
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Mb-Noce_scottona_IMG_3161_1561474312.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_OfRg1tP_lTOJRZF.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622247,
                        "field1": "aia",
                        "field2": "lonza di suino nazionale confezionata ",
                        "field3": "sottovuoto al kg",
                        "field4": "",
                        "description": "aia lonza di suino nazionale confezionata  sottovuoto al kg",
                        "grammage": null,
                        "price": "4.98",
                        "price_label": "€ 4,98 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 40.156322268730875,
                            "left": 5.879842254921955,
                            "width": 88.33077460177032,
                            "height": 18.176185948740617
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/lonza_4794_1558512519.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_2OrOIJ1_xY8XQKS.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622246,
                        "field1": "aia",
                        "field2": "filetto di suino nazionale",
                        "field3": "sottovuoto",
                        "field4": "al kg",
                        "description": "aia filetto di suino nazionale sottovuoto al kg",
                        "grammage": null,
                        "price": "7.95",
                        "price_label": "€ 7,95 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Macelleria",
                        "subcategory": "Suino",
                        "blueprint": {
                            "top": 58.44004198053846,
                            "left": 5.907586966501776,
                            "width": 44.14079316560802,
                            "height": 17.851518633017168
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/filetto_suino_vassoio_1512386728_1561020120.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_erAmS0r_efQiBEy.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622239,
                        "field1": "mec",
                        "field2": "gallinella di bovino adulto",
                        "field3": "",
                        "field4": "al kg",
                        "description": "mec gallinella di bovino adulto al kg",
                        "grammage": null,
                        "price": "5.59",
                        "price_label": "€ 5,59 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Macelleria",
                        "subcategory": "Bovino",
                        "blueprint": {
                            "top": 58.50574500287749,
                            "left": 50.16028280269843,
                            "width": 44.14079316560802,
                            "height": 17.851518633017168
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/GALLINELLA_X_ARROSTI_1561474270.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_3c9YKue_AWKDYk7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622242,
                        "field1": "aia",
                        "field2": "fesa di tacchino maschio ",
                        "field3": "sottovuoto",
                        "field4": "al kg",
                        "description": "aia fesa di tacchino maschio  sottovuoto al kg",
                        "grammage": null,
                        "price": "5.98",
                        "price_label": "€ 5,98 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Macelleria",
                        "subcategory": "Avicoli",
                        "blueprint": {
                            "top": 76.50837312377105,
                            "left": 5.92577722336257,
                            "width": 44.14079316560802,
                            "height": 19.231282102136745
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/fesa_tacchino_aia_1561474420.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_STMWYAD_T0rmOk2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "hat-chef",
                                "title": "RICETTA - Straccetti di fesa di tacchino",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_23/images/fesa.jpg"
                                    ],
                                    "ingredients": "300 gr di straccetti di fesa di tacchino AIA, 150 gr di yogurt greco, 1 gambo di sedano, Qualche foglia di menta, Olio EVO, Sale, 300 gr di insalata mista, 1 cetriolo, 1 carota, 1 spicchio di aglio, Pepe",
                                    "recipe": "Sbucciate il cetriolo, tagliatelo a metà per il senso della lunghezza e riducetelo in pezzetti. Condite il sale e lasciate riposare per 10 minuti. Unite lo yogurt, lo spicchio di aglio tritato, un filo di olio EVO, sale, pepe e qualche foglia di menta tritata. Mescolate bene e lasciate riposare la salsa tzatziki in frigo. Nel frattempo grigliate gli straccetti di di fesa di tacchino AIA, aggiungendo un po’ di sale e lasciateli poi raffreddare. Pelate le carote e riducetele in bastoncini; lavate il sedano e tagliatelo a rondelle; tagliate l’insalata e unite tutti gli ingredienti, condendo con la salsa tzatziki e un pizzico di sale e pepe. (Fonte: aiafood.com)"
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 622243,
                        "field1": "aia",
                        "field2": "involtini di pollo alla salvia",
                        "field3": "",
                        "field4": "al kg",
                        "description": "aia involtini di pollo alla salvia al kg",
                        "grammage": null,
                        "price": "7.30",
                        "price_label": "€ 7,30 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Macelleria",
                        "subcategory": "Avicoli",
                        "blueprint": {
                            "top": 76.44267010143201,
                            "left": 50.16028280269843,
                            "width": 44.14079316560802,
                            "height": 19.231282102136745
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/involtini_alla_salvia_1561474539.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_wdDnuCa_3kVMbiX.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 3,
                "image_file": "/media/interactive_flyer_23/pages/page_3.jpg",
                "products": [
                    {
                        "item_id": 622251,
                        "field1": "tabachetti salumificio di moncalvo",
                        "field2": "salame cotto",
                        "field3": "intero/metà sottovuoto ",
                        "field4": "al kg",
                        "description": "tabachetti salumificio di moncalvo salame cotto intero/metà sottovuoto  al kg",
                        "grammage": null,
                        "price": "6.90",
                        "price_label": "€ 6,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Intero",
                            "Metà sottovuoto"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 11.068162874099562,
                            "left": 6.016236334976755,
                            "width": 42.69344737978107,
                            "height": 30.269389855093387
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/salame_cotto_extra_1558516717.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_ABDYN2D_9wvl6qC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622244,
                        "field1": "aia aequilibrium",
                        "field2": "petto di pollo",
                        "field3": "al forno",
                        "field4": "al kg",
                        "description": "aia aequilibrium petto di pollo al forno al kg",
                        "grammage": null,
                        "price": "7.56",
                        "price_label": "€ 7,56",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Macelleria",
                        "subcategory": "Avicoli",
                        "blueprint": {
                            "top": 10.936756829421505,
                            "left": 50.06982369108425,
                            "width": 42.69344737978107,
                            "height": 30.269389855093387
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/aia_petto_di_pollo_al_forno_1532681134_1561018396.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_V7LMDhM_H89zwGs.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622245,
                        "field1": "aia aequilibrium",
                        "field2": "tarky roast tacchino",
                        "field3": "sottovuoto gr. 2,8 circa",
                        "field4": "al kg",
                        "description": "aia aequilibrium tarky roast tacchino sottovuoto gr. 2,8 circa al kg",
                        "grammage": null,
                        "price": "7.56",
                        "price_label": "€ 7,56",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 42.53991057449378,
                            "left": 5.92577722336257,
                            "width": 29.305498860881873,
                            "height": 15.814724940507313
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/1468_aia_aequilibrium_tarky_tacchino_forno_1471612738_1529_4RmNZFD.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_v2P9mjd_hOlVxEv.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622248,
                        "field1": "salumificio tabachetti di moncalvo",
                        "field2": "manzo affumicato",
                        "field3": "",
                        "field4": "al kg",
                        "description": "salumificio tabachetti di moncalvo manzo affumicato al kg",
                        "grammage": null,
                        "price": "8.28",
                        "price_label": "€ 8,28 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 42.53991057449378,
                            "left": 35.41544760958648,
                            "width": 29.305498860881873,
                            "height": 15.814724940507313
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/MANZO_AFFUMICATO__1561474585.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_tfks9RU_Zx6yYKT.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622249,
                        "field1": "salumificio tabachetti di moncalvo",
                        "field2": "salsiccia campagnola in vaschetta",
                        "field3": "",
                        "field4": "al kg",
                        "description": "salumificio tabachetti di moncalvo salsiccia campagnola in vaschetta al kg",
                        "grammage": null,
                        "price": "4.38",
                        "price_label": "€ 4,38 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 42.60561359683281,
                            "left": 64.72419977258201,
                            "width": 29.305498860881873,
                            "height": 15.814724940507313
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Salsiccia-campagnola_1561474607.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_G6HqBMB_Z1TZWWr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622254,
                        "field1": "Borgo Dora ",
                        "field2": "Prosciutto Cotto Scelto \"Delle Alpi\" Intero",
                        "field3": "al kg",
                        "field4": "",
                        "description": "Borgo Dora  Prosciutto Cotto Scelto \"Delle Alpi\" Intero al kg",
                        "grammage": null,
                        "price": "6.30",
                        "price_label": "€ 6,30 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 58.44004198053846,
                            "left": 5.92577722336257,
                            "width": 29.305498860881873,
                            "height": 17.982924677695223
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/cotto-delle-alpi-borgodora-salumificio_1558023801.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_58bsYqd_aI9Ki9o.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622255,
                        "field1": "borgo dora",
                        "field2": "salsiccia napoli piccante ",
                        "field3": "curva/dritta",
                        "field4": "al kg",
                        "description": "borgo dora salsiccia napoli piccante  curva/dritta al kg",
                        "grammage": null,
                        "price": "5.99",
                        "price_label": "€ 5,99 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 58.374338958199424,
                            "left": 35.41544760958648,
                            "width": 29.305498860881873,
                            "height": 17.982924677695223
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/salame_tipo_napoli_piccante_1561476726.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_hAzrLEx_FNeV3vo.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622256,
                        "field1": "borgo dora",
                        "field2": "lardo monferrino",
                        "field3": "",
                        "field4": "al kg",
                        "description": "borgo dora lardo monferrino al kg",
                        "grammage": null,
                        "price": "5.99",
                        "price_label": "€ 5,99 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 58.44004198053846,
                            "left": 64.90511799581039,
                            "width": 29.305498860881873,
                            "height": 17.982924677695223
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/lardo_monferrino_fette_1561476754.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_2TwKa5u_lX2CrPv.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622253,
                        "field1": "salumificio tabachetti di moncalvo",
                        "field2": "moncalvino - cacciatorino di moncalvo",
                        "field3": "",
                        "field4": "al kg",
                        "description": "salumificio tabachetti di moncalvo moncalvino - cacciatorino di moncalvo al kg",
                        "grammage": null,
                        "price": "7.30",
                        "price_label": "€ 7,30 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 76.37696707909299,
                            "left": 5.925777223362581,
                            "width": 29.305498860881873,
                            "height": 19.231282102136745
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/moncalvino_1561474676.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_7MTXPAs_9AqB6x7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622250,
                        "field1": "salumificio tabachetti di moncalvo",
                        "field2": "salamelle per griglia",
                        "field3": "",
                        "field4": "al kg",
                        "description": "salumificio tabachetti di moncalvo salamelle per griglia al kg",
                        "grammage": null,
                        "price": "4.38",
                        "price_label": "€ 4,38 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 76.31126405675397,
                            "left": 35.41544760958649,
                            "width": 29.305498860881873,
                            "height": 19.231282102136745
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/SALAMELLE_1561474627.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_as56su2_jnLgdhq.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622252,
                        "field1": "salumificio tabachetti di moncalvo",
                        "field2": "salame crudo paesano antica ricetta monferrina",
                        "field3": "",
                        "field4": "al kg",
                        "description": "salumificio tabachetti di moncalvo salame crudo paesano antica ricetta monferrina al kg",
                        "grammage": null,
                        "price": "10.30",
                        "price_label": "€ 10,30 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 76.31126405675397,
                            "left": 64.72419977258203,
                            "width": 29.305498860881873,
                            "height": 19.231282102136745
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/salame_paesano_1561474655.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_rlGHvaD_QRyRS24.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 4,
                "image_file": "/media/interactive_flyer_23/pages/page_4.jpg",
                "products": [
                    {
                        "item_id": 622258,
                        "field1": "beretta",
                        "field2": "prosciutto cotto alta qualità",
                        "field3": "gran riserva",
                        "field4": "al kg",
                        "description": "beretta prosciutto cotto alta qualità gran riserva al kg",
                        "grammage": null,
                        "price": "9.50",
                        "price_label": "€ 9,50 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 10.578186596583443,
                            "left": 5.9421723730355955,
                            "width": 43.872669132879125,
                            "height": 26.281208935611044
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Blu_prosciutto_cotto_senza_polifosfat_1470043060_155082880_K9VKjr5.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/Prosciutto-Cotto-Gran-Riserva_1561476837.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_88UcQsZ_GqgZHyK.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622257,
                        "field1": "beretta",
                        "field2": "prosciutto cotto di coscia ",
                        "field3": "gran sigillo",
                        "field4": "al kg",
                        "description": "beretta prosciutto cotto di coscia  gran sigillo al kg",
                        "grammage": null,
                        "price": "5.98",
                        "price_label": "€ 5,98 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 11.103810775295663,
                            "left": 48.72933216654452,
                            "width": 45.41047403032025,
                            "height": 29.172141918528254
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/prosciutto_cotto_gran_sigillo_1561476805.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_V5kaxkl_2Sfz80i.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622265,
                        "field1": "limonta",
                        "field2": "parma sant' alessandro senza osso",
                        "field3": "",
                        "field4": "al kg",
                        "description": "limonta parma sant' alessandro senza osso al kg",
                        "grammage": null,
                        "price": "10.38",
                        "price_label": "€ 10,38 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 42.50985545335085,
                            "left": 3.3188581362242666,
                            "width": 32.02252551142105,
                            "height": 15.965834428383708
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/prosciutto_parma__1561477057.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_whD9Ltv_eLfO6Pn.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622264,
                        "field1": "Limonta",
                        "field2": "Prosciutto Crudo nazionale nostrano Senza Osso",
                        "field3": "al kg",
                        "field4": "",
                        "description": "Limonta Prosciutto Crudo nazionale nostrano Senza Osso al kg",
                        "grammage": 0,
                        "price": "8.40",
                        "price_label": "€ 8,40 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 40.99868593955322,
                            "left": 35.250924536031135,
                            "width": 29.399211274609723,
                            "height": 17.47700394218134
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/prosciutto_crudo_nazionale_nostrano_dolcevalle_1561476994.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_pawZ2Pv_JnYeHu4.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622262,
                        "field1": "pini",
                        "field2": "bresaola la leggendaria",
                        "field3": "",
                        "field4": "al kg",
                        "description": "pini bresaola la leggendaria al kg",
                        "grammage": null,
                        "price": "22.80",
                        "price_label": "€ 22,80 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 42.57555847568988,
                            "left": 64.74059492225504,
                            "width": 29.399211274609723,
                            "height": 15.768725361366624
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/lazzeri_bresaola_punta_d_anca_1498233196_1504172191_155171_r5D5iD9.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_5FPKR2W_WyS7UV0.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622260,
                        "field1": "rosa",
                        "field2": "girello cotto per vitello tonnato",
                        "field3": "",
                        "field4": "al kg",
                        "description": "rosa girello cotto per vitello tonnato al kg",
                        "grammage": null,
                        "price": "12.80",
                        "price_label": "€ 12,80 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 58.40998685939554,
                            "left": 3.4997763594526345,
                            "width": 31.751148176578507,
                            "height": 18.00262812089356
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/girello-di-manzo-alla-piemontese_1561476884.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_nvqupQ3_v8yjNZX.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622259,
                        "field1": "rosa",
                        "field2": "lingua bovina cotta",
                        "field3": "",
                        "field4": "al kg",
                        "description": "rosa lingua bovina cotta al kg",
                        "grammage": null,
                        "price": "8.70",
                        "price_label": "€ 8,70 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 58.40998685939554,
                            "left": 35.341383647645316,
                            "width": 29.21829305138136,
                            "height": 18.00262812089356
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/lingua_bovina_cotta_1561476862.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_tl9CyBF_EPSMzZp.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622261,
                        "field1": "pini",
                        "field2": "bresaola punta d'anca",
                        "field3": "",
                        "field4": "al kg",
                        "description": "pini bresaola punta d'anca al kg",
                        "grammage": null,
                        "price": "16.35",
                        "price_label": "€ 16,35 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 58.40998685939554,
                            "left": 64.83105403386924,
                            "width": 29.21829305138136,
                            "height": 18.00262812089356
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/BRESAOLA_punta-d-anca_0_1561476938.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_oqiG6w0_90ZErW2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622263,
                        "field1": "consorzio lardo di colonnata - giannarelli",
                        "field2": "lardo di colonnata i.g.p.",
                        "field3": "",
                        "field4": "al kg",
                        "description": "consorzio lardo di colonnata - giannarelli lardo di colonnata i.g.p. al kg",
                        "grammage": null,
                        "price": "14.98",
                        "price_label": "€ 14,98 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Salumi",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 3.3188581362242666,
                            "width": 31.932066399806864,
                            "height": 19.185282522996058
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Lardo_alle_erbe_1428392686_1561020625.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_edxkVQz_fKvWlvA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622267,
                        "field1": "fontanacervo",
                        "field2": "robiola d'alba ",
                        "field3": "al naturale/all' erba cipollina/al peperoncino",
                        "field4": "al kg",
                        "description": "fontanacervo robiola d'alba  al naturale/all' erba cipollina/al peperoncino al kg",
                        "grammage": null,
                        "price": "7.20",
                        "price_label": "€ 7,20 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [
                            "Al naturale",
                            "All'erba cipollina",
                            "Al Peperoncino"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 35.52230187087368,
                            "width": 28.946915716538804,
                            "height": 19.250985545335087
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/robiola_alba_naturale_1561477164.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_dvutKb8_ylzXBhY.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622266,
                        "field1": "fontanacervo",
                        "field2": "toma la granda",
                        "field3": "",
                        "field4": "al kg",
                        "description": "fontanacervo toma la granda al kg",
                        "grammage": null,
                        "price": "5.50",
                        "price_label": "€ 5,50 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 76.34691195795007,
                            "left": 64.83105403386924,
                            "width": 29.21829305138136,
                            "height": 19.250985545335087
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/toma_la_granda_1561477136.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_7f46uIC_Cw3izNZ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 5,
                "image_file": "/media/interactive_flyer_23/pages/page_5.jpg",
                "products": [
                    {
                        "item_id": 622275,
                        "field1": "la deliziosa",
                        "field2": "stracciatella",
                        "field3": "",
                        "field4": "al kg",
                        "description": "la deliziosa stracciatella al kg",
                        "grammage": null,
                        "price": "10.70",
                        "price_label": "€ 10,70 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 11.103810775295663,
                            "left": 6.032631484649779,
                            "width": 50.47618428071454,
                            "height": 30.35479632063075
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/stracciatella_1542641754_1560155631_1561021113.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/STRACCIATELLA_05_1561477409.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_vKxRBTq_Su6Ef2x.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622276,
                        "field1": "la deliziosa",
                        "field2": "mozzarella fiordilatte ciuffo",
                        "field3": "",
                        "field4": "al kg",
                        "description": "la deliziosa mozzarella fiordilatte ciuffo al kg",
                        "grammage": 0,
                        "price": "1.90",
                        "price_label": "€ 1,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 11.103810775295663,
                            "left": 55.423306425994106,
                            "width": 38.71649977087065,
                            "height": 30.35479632063075
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/ciuffo_1561477430.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_8v6hrVb_Ex7zsMD.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622274,
                        "field1": "la deliziosa",
                        "field2": "toma sestriere",
                        "field3": "",
                        "field4": "al kg",
                        "description": "la deliziosa toma sestriere al kg",
                        "grammage": null,
                        "price": "6.00",
                        "price_label": "€ 6,00 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 42.31274638633377,
                            "left": 5.851713261421412,
                            "width": 29.399211274609723,
                            "height": 16.031537450722734
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/TOMA_DI_ALPEGGIO_SESTRIERE__1561477374.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_P997ZU1_XCT1N0q.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622268,
                        "field1": "fontanacervo",
                        "field2": "tomino a rotolo ",
                        "field3": "",
                        "field4": "al pz",
                        "description": "fontanacervo tomino a rotolo  al pz",
                        "grammage": null,
                        "price": "1.98",
                        "price_label": "€ 1,98",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 42.3784494086728,
                            "left": 35.341383647645316,
                            "width": 29.399211274609723,
                            "height": 16.031537450722734
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/TOMINI_A_ROTOLO_FONTANACERVO_1561477216.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_5MDFkyx_Jb3rbSq.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622269,
                        "field1": "caseificio longo",
                        "field2": "tomino a rotolo ",
                        "field3": "",
                        "field4": "al pz",
                        "description": "caseificio longo tomino a rotolo  al pz",
                        "grammage": null,
                        "price": "1.50",
                        "price_label": "€ 1,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 42.44415243101183,
                            "left": 64.65013581064086,
                            "width": 29.399211274609723,
                            "height": 16.031537450722734
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/tomino-rosso-longo_per1_1561477235.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_lmIW99u_o8rQmTZ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622270,
                        "field1": "caseificio longo",
                        "field2": "tomino del boscaiolo ",
                        "field3": "per griglia",
                        "field4": "al kg",
                        "description": "caseificio longo tomino del boscaiolo  per griglia al kg",
                        "grammage": null,
                        "price": "7.40",
                        "price_label": "€ 7,40 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 58.47568988173456,
                            "left": 5.851713261421412,
                            "width": 58.888881660833626,
                            "height": 17.80551905387648
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/tomino_boscaiolo_1561020853.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_05GzXQg_ftLgzR0.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622271,
                        "field1": "caseificio longo",
                        "field2": "ricotta piemontese seirass reale",
                        "field3": "con latte di pecora",
                        "field4": "al kg",
                        "description": "caseificio longo ricotta piemontese seirass reale con latte di pecora al kg",
                        "grammage": null,
                        "price": "6.95",
                        "price_label": "€ 6,95 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 58.47568988173456,
                            "left": 64.65013581064086,
                            "width": 32.02252551142105,
                            "height": 17.80551905387648
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/SEIRASS_AMBIENT2_1561477281.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_RAFIblg_8afmkZS.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622272,
                        "field1": "caseificio longo",
                        "field2": "primo sale ",
                        "field3": "con/senza rucola",
                        "field4": "al kg",
                        "description": "caseificio longo primo sale  con/senza rucola al kg",
                        "grammage": null,
                        "price": "5.70",
                        "price_label": "€ 5,70 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [
                            "Con Rucola",
                            "Senza Rucola"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 76.34691195795007,
                            "left": 5.851713261421412,
                            "width": 58.798422549219445,
                            "height": 19.38239159001314
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/PRIMO_SALE_LONGO_1561477318.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_fuosVws_AaqF2AO.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622273,
                        "field1": "caseificio longo",
                        "field2": "paglierina confezione x 6",
                        "field3": "",
                        "field4": "al kg",
                        "description": "caseificio longo paglierina confezione x 6 al kg",
                        "grammage": null,
                        "price": "7.58",
                        "price_label": "€ 7,58 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 64.74059492225504,
                            "width": 32.112984623035246,
                            "height": 19.38239159001314
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/PAGLIERINA007_1561477353.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_fwSAzQ5_nGx3pcw.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 6,
                "image_file": "/media/interactive_flyer_23/pages/page_6.jpg",
                "products": [
                    {
                        "item_id": 622284,
                        "field1": "biraghi",
                        "field2": "burro catering ",
                        "field3": "kg. 1",
                        "field4": "al kg",
                        "description": "biraghi burro catering  kg. 1 al kg",
                        "grammage": null,
                        "price": "4.80",
                        "price_label": "€ 4,80",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Self Service",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 10.97240473061761,
                            "left": 6.032631484649779,
                            "width": 45.32001491870607,
                            "height": 26.346911957950066
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/biraghi_burro_1445414703_1561021411.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_dTS544I_r1gzHwy.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622283,
                        "field1": "biraghi",
                        "field2": "gorgonzola incarto ",
                        "field3": "argento 1/8",
                        "field4": "al kg",
                        "description": "biraghi gorgonzola incarto  argento 1/8 al kg",
                        "grammage": null,
                        "price": "5.82",
                        "price_label": "€ 5,82 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 11.103810775295663,
                            "left": 50.71943262205656,
                            "width": 43.32991446319403,
                            "height": 29.369250985545335
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/gorgonzola_al_cucchiaio_1537257541_1561021265.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/gorgonzola_biraghi_argento__1561477695.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_XHlc9rM_BHZTpTP.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622282,
                        "field1": "diaslp",
                        "field2": "delice de borgogne",
                        "field3": "",
                        "field4": "al kg",
                        "description": "diaslp delice de borgogne al kg",
                        "grammage": null,
                        "price": "15.98",
                        "price_label": "€ 15,98 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 42.04993429697766,
                            "left": 3.047480801381715,
                            "width": 32.203443734649426,
                            "height": 16.360052562417874
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/DELICE_DE_BOURGOGNE_2KG_LINCET_EXPORT_1561477671.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_4Bh7b9D_37ztfjP.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622281,
                        "field1": "diaslp",
                        "field2": "brie cantorel",
                        "field3": "",
                        "field4": "al kg",
                        "description": "diaslp brie cantorel al kg",
                        "grammage": null,
                        "price": "4.95",
                        "price_label": "€ 4,95 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 42.11563731931669,
                            "left": 35.341383647645316,
                            "width": 29.308752162995553,
                            "height": 16.360052562417874
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/BRIE_ROTONDO-1_1561477628.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_aXCKkBZ_Y4mCFSd.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622285,
                        "field1": "biraghi",
                        "field2": "grattugiato gran biraghi ",
                        "field3": "",
                        "field4": "gr. 500",
                        "description": "biraghi grattugiato gran biraghi  gr. 500",
                        "grammage": 500,
                        "price": "4.99",
                        "price_label": "€ 4,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 42.11563731931669,
                            "left": 64.74059492225504,
                            "width": 29.308752162995553,
                            "height": 16.360052562417874
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/grattugiato_500_gr._granbiraghi__1561477720.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_7tWS1Ef_dEA6NFr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622277,
                        "field1": "caseificio la bruna - società agricola montegrana",
                        "field2": "toma della borgata",
                        "field3": "",
                        "field4": "al kg",
                        "description": "caseificio la bruna - società agricola montegrana toma della borgata al kg",
                        "grammage": 0,
                        "price": "6.98",
                        "price_label": "€ 6,98 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 58.40998685939554,
                            "left": 3.4997763594526345,
                            "width": 31.841607288192694,
                            "height": 17.871222076215506
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Toma_Borgata_1561477467.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_P3ayd4e_P73HKmC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622279,
                        "field1": "caseificio la bruna - società agricola montegrana",
                        "field2": "piramidino",
                        "field3": "",
                        "field4": "al kg",
                        "description": "caseificio la bruna - società agricola montegrana piramidino al kg",
                        "grammage": 0,
                        "price": "9.20",
                        "price_label": "€ 9,20 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 58.47568988173456,
                            "left": 35.4318427592595,
                            "width": 29.12783393976718,
                            "height": 17.871222076215506
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Piramidino-1_1561477583.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_aYrVv0Q_yrnVkMb.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622280,
                        "field1": "caseificio la bruna - società agricola montegrana",
                        "field2": "castelmagno",
                        "field3": "1/4",
                        "field4": "al kg",
                        "description": "caseificio la bruna - società agricola montegrana castelmagno 1/4 al kg",
                        "grammage": 0,
                        "price": "15.50",
                        "price_label": "€ 15,50 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 58.5413929040736,
                            "left": 64.83105403386924,
                            "width": 29.12783393976718,
                            "height": 17.871222076215506
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Castelmagno_montagna_1_1561477604.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_phtGrVh_bWu2QV2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622288,
                        "field1": "la casearia di bertinotti",
                        "field2": "toma delizia del nonno forma",
                        "field3": "al naturale",
                        "field4": "kg. 2 circa - al kg",
                        "description": "la casearia di bertinotti toma delizia del nonno forma al naturale kg. 2 circa - al kg",
                        "grammage": null,
                        "price": "4.95",
                        "price_label": "€ 4,95 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 3.409317247838451,
                            "width": 31.841607288192694,
                            "height": 19.316688567674113
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/toma_del_nonno_1561477811.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_1oiihOJ_KSrvODV.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622289,
                        "field1": "la casearia di bertinotti",
                        "field2": "toma delizia del nonno forma ",
                        "field3": "al naturale e al peperoncino",
                        "field4": "gr. 700 circa - al kg",
                        "description": "la casearia di bertinotti toma delizia del nonno forma  al naturale e al peperoncino gr. 700 circa - al kg",
                        "grammage": null,
                        "price": "4.95",
                        "price_label": "€ 4,95 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 35.341383647645316,
                            "width": 29.308752162995546,
                            "height": 19.316688567674113
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/toma_peperoncino_1561477902.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_HXucqg2_CNHewFC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622278,
                        "field1": "caseificio la bruna - società agricola montegrana",
                        "field2": "toma muretino",
                        "field3": "",
                        "field4": "al kg",
                        "description": "caseificio la bruna - società agricola montegrana toma muretino al kg",
                        "grammage": 0,
                        "price": "6.00",
                        "price_label": "€ 6,00 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 64.83105403386924,
                            "width": 29.308752162995546,
                            "height": 19.316688567674113
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Muretino_2_1561477561.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_w6nm9oI_wIWuwqM.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 7,
                "image_file": "/media/interactive_flyer_23/pages/page_7.jpg",
                "products": [
                    {
                        "item_id": 622291,
                        "field1": "galbani professional",
                        "field2": "mozzarella vaccina julienne ",
                        "field3": "busta kg. 1,5 circa",
                        "field4": "al kg",
                        "description": "galbani professional mozzarella vaccina julienne  busta kg. 1,5 circa al kg",
                        "grammage": 1500,
                        "price": "5.25",
                        "price_label": "€ 5,25",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 11.169513797634691,
                            "left": 5.851713261421412,
                            "width": 45.500933141934425,
                            "height": 27.201051248357423
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Filante_Julienne_15_kg_1440685683_1561021651.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_3TBBIUV_j3pGDLe.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622293,
                        "field1": "galbani professional",
                        "field2": "mozzarella vaccina ",
                        "field3": "",
                        "field4": "gr. 100",
                        "description": "galbani professional mozzarella vaccina  gr. 100",
                        "grammage": 100,
                        "price": "0.49",
                        "price_label": "€ 0,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 10.97240473061761,
                            "left": 51.08126906851329,
                            "width": 43.148996239965655,
                            "height": 27.201051248357423
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/44141980_GALBANI_MOZZARELLA_PALLA_100_1527601331_1561477971.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_me87J9y_GoJqPVc.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622294,
                        "field1": "galbani professional",
                        "field2": "mozzarella bufala ",
                        "field3": "",
                        "field4": "gr. 100",
                        "description": "galbani professional mozzarella bufala  gr. 100",
                        "grammage": 100,
                        "price": "1.04",
                        "price_label": "€ 1,04",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 46.12352168199737,
                            "left": 5.761254149807228,
                            "width": 44.32496469095004,
                            "height": 22.99605781865966
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/BUFALA_1561477992.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_rQLRVTO_BCbhuZj.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622296,
                        "field1": "galbani professional",
                        "field2": "mozzarella panetto ",
                        "field3": "kg. 1 circa",
                        "field4": "al kg",
                        "description": "galbani professional mozzarella panetto  kg. 1 circa al kg",
                        "grammage": null,
                        "price": "6.15",
                        "price_label": "€ 6,15",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 46.12352168199737,
                            "left": 49.99575972914309,
                            "width": 44.32496469095004,
                            "height": 22.99605781865966
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/PANETTO_1561478177.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_HtvtS7O_rNlvBdL.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622295,
                        "field1": "galbani professional",
                        "field2": "mozzarella vaccina mini ovoline gr. 8",
                        "field3": "secchiello per kg. 1",
                        "field4": "al kg",
                        "description": "galbani professional mozzarella vaccina mini ovoline gr. 8 secchiello per kg. 1 al kg",
                        "grammage": null,
                        "price": "6.93",
                        "price_label": "€ 6,93",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 69.18528252299606,
                            "left": 5.761254149807228,
                            "width": 44.32496469095004,
                            "height": 27.003942181340346
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/mini_ovoline_1561478168.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_IwECCw4_QANSEPH.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622292,
                        "field1": "galbani professional",
                        "field2": "latticino spalmabile x 1 kg. secchiello",
                        "field3": "",
                        "field4": "al kg",
                        "description": "galbani professional latticino spalmabile x 1 kg. secchiello al kg",
                        "grammage": 0,
                        "price": "4.76",
                        "price_label": "€ 4,76",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 69.11957950065704,
                            "left": 50.06657038897058,
                            "width": 44.32496469095004,
                            "height": 27.003942181340346
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/spalmabile_1561477927.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_UfTiENe_PrhCZey.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 8,
                "image_file": "/media/interactive_flyer_23/pages/page_8.jpg",
                "products": [
                    {
                        "item_id": 622287,
                        "field1": "casarrigoni",
                        "field2": "taleggio",
                        "field3": "d.o.p.g.",
                        "field4": "al kg",
                        "description": "casarrigoni taleggio d.o.p.g. al kg",
                        "grammage": null,
                        "price": "6.32",
                        "price_label": "€ 6,32 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 10.512483574244415,
                            "left": 5.851713261421412,
                            "width": 45.86276958839117,
                            "height": 27.332457293035482
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/taleggio-dop_jpg_665_515_contain_70-1_1561477773.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_xh8suRg_oKPDCEJ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622286,
                        "field1": "casarrigoni",
                        "field2": "quartirolo",
                        "field3": "d.o.p.",
                        "field4": "al kg",
                        "description": "casarrigoni quartirolo d.o.p. al kg",
                        "grammage": null,
                        "price": "6.45",
                        "price_label": "€ 6,45 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 11.103810775295663,
                            "left": 48.36749572008778,
                            "width": 45.86276958839117,
                            "height": 29.96057818659658
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/quartirolo-lombardo-stagionato_jpg_665_515_contain_70_1561477753.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_ZN4mgcz_uWINkNB.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622290,
                        "field1": "prealpi",
                        "field2": "fontal prealpinella 1/4",
                        "field3": "",
                        "field4": "al kg",
                        "description": "prealpi fontal prealpinella 1/4 al kg",
                        "grammage": null,
                        "price": "5.98",
                        "price_label": "€ 5,98 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 43.16688567674113,
                            "left": 3.228399024610083,
                            "width": 46.676901592918824,
                            "height": 15.440210249671484
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/prealpinella_1561021614.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_T4OvaPm_KOOjj1Y.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 622297,
                        "field1": "latteria sociale mantova",
                        "field2": "gran mantovano forme",
                        "field3": "",
                        "field4": "al kg",
                        "description": "latteria sociale mantova gran mantovano forme al kg",
                        "grammage": null,
                        "price": "7.20",
                        "price_label": "€ 7,20 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Gastronomia",
                        "subcategory": "Formaggi",
                        "blueprint": {
                            "top": 43.1011826544021,
                            "left": 49.9053006175289,
                            "width": 46.676901592918824,
                            "height": 15.440210249671484
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/gran_mantovano_1561021808.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_sNhCaZM_O2gW4gr.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 624196,
                        "field1": "pesche",
                        "field2": "nettarine italia",
                        "field3": "al kg",
                        "field4": "",
                        "description": "pesche nettarine italia al kg",
                        "grammage": 0,
                        "price": "1.50",
                        "price_label": "€ 1,50 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Ortofrutta",
                        "subcategory": "Frutta",
                        "blueprint": {
                            "top": 58.60709592641262,
                            "left": 35.341383647645316,
                            "width": 29.12783393976717,
                            "height": 17.80551905387648
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Pesche_Gialle_Multicedi_1434011017_1434036518_1558026762.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_bfbGtrv_fC652CU.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 624194,
                        "field1": "frutti di bosco",
                        "field2": "nostrani ",
                        "field3": "cestino gr. 250",
                        "field4": "",
                        "description": "frutti di bosco nostrani  cestino gr. 250",
                        "grammage": 250,
                        "price": "1.65",
                        "price_label": "€ 1,65",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Ortofrutta",
                        "subcategory": "Frutta",
                        "blueprint": {
                            "top": 58.60709592641262,
                            "left": 64.74059492225504,
                            "width": 28.494620158467885,
                            "height": 17.80551905387648
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/frutti_di_bosco_petrucci_1499067501_1561361530.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_B7nLqBD_wRObB0V.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 624198,
                        "field1": "pomodoro piccadilly ",
                        "field2": "a grappolo italia",
                        "field3": "al kg",
                        "field4": "",
                        "description": "pomodoro piccadilly  a grappolo italia al kg",
                        "grammage": 0,
                        "price": "1.90",
                        "price_label": "€ 1,90 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Ortofrutta",
                        "subcategory": "Verdura",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 3.2565280181106155,
                            "width": 32.02252551142105,
                            "height": 18.528252299605782
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/POMODORO_PICCADILLY_1472223479_1561362139.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_C3Ni1CK_NoMSIoO.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 624197,
                        "field1": "orsero",
                        "field2": "ananas",
                        "field3": "costa rica",
                        "field4": "al kg",
                        "description": "orsero ananas costa rica al kg",
                        "grammage": 0,
                        "price": "1.50",
                        "price_label": "€ 1,50 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Ortofrutta",
                        "subcategory": "Frutta",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 35.36951264114585,
                            "width": 29.21829305138136,
                            "height": 18.528252299605782
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/ANANAS_B-1_800x1200px_1561478213.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_2uqjqAa_kk0gLbq.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 624195,
                        "field1": "anguria",
                        "field2": "italia",
                        "field3": "al kg",
                        "field4": "",
                        "description": "anguria italia al kg",
                        "grammage": 0,
                        "price": "0.00",
                        "price_label": "€ 0,00 al Kg",
                        "equivalence": 10,
                        "quantity_step": 1,
                        "grocery_label": "etto/i",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Ortofrutta",
                        "subcategory": "Frutta",
                        "blueprint": {
                            "top": 76.34691195795007,
                            "left": 64.81140558208253,
                            "width": 28.313701935239518,
                            "height": 18.725361366622867
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/anguria_1532685896_1561361765.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_hJjItQH_X4Rnndd.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 624199,
                        "field1": "insalata gentilina",
                        "field2": "",
                        "field3": "al kg",
                        "field4": "",
                        "description": "insalata gentilina al kg",
                        "grammage": 0,
                        "price": "1.40",
                        "price_label": "€ 1,40",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Ortofrutta",
                        "subcategory": "Verdura",
                        "blueprint": {
                            "top": 58.553168523658606,
                            "left": 3.2488518234215045,
                            "width": 32.08055956712623,
                            "height": 17.716264792367426
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/lattuga_gentilina_1558027524_Gfl6fBc.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_CGpBFyl_CrKxLmn.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 9,
                "image_file": "/media/interactive_flyer_23/pages/page_9.jpg",
                "products": [
                    {
                        "item_id": 619589,
                        "field1": "whisky",
                        "field2": "ballantine's",
                        "field3": "lt. 1",
                        "field4": "",
                        "description": "whisky ballantine's lt. 1",
                        "grammage": 0,
                        "price": "11.44",
                        "price_label": "€ 11,44",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 11.049883372541652,
                            "left": 6.053084283461201,
                            "width": 32.35193690196878,
                            "height": 31.053978327190027
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/592128-ballantines-1-litro_1481132080_1560781355.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_GkKA1JH_3hSMbsE.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619588,
                        "field1": "bellini",
                        "field2": "limoncello",
                        "field3": "cl. 70",
                        "field4": "",
                        "description": "bellini limoncello cl. 70",
                        "grammage": 700,
                        "price": "3.29",
                        "price_label": "€ 3,29",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 10.984180350202625,
                            "left": 35.36183644645674,
                            "width": 28.55265421417306,
                            "height": 30.13413601444364
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/LIMONCELLO_1561457622.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_JZxeVIC_qk3iWQu.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619595,
                        "field1": "keglevich ",
                        "field2": "vodka ",
                        "field3": "vari gusti",
                        "field4": "cl. 70",
                        "description": "keglevich  vodka  vari gusti cl. 70",
                        "grammage": 700,
                        "price": "5.77",
                        "price_label": "€ 5,77",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 10.918477327863597,
                            "left": 63.494620158467896,
                            "width": 28.55265421417306,
                            "height": 30.13413601444364
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/keglevich_vodka_pesca_70_cl_1557829111_1560782314.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/vodka_keglevich_menta_1561101598.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_jszDChY_5150yzZ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 625186,
                        "field1": "BACARDI",
                        "field2": "BREEZER   ",
                        "field3": "27,5 cl",
                        "field4": "",
                        "description": "BACARDI BREEZER    27,5 cl",
                        "grammage": 2000,
                        "price": "0.89",
                        "price_label": "€ 0,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 43.11295827398712,
                            "left": 5.78170694861865,
                            "width": 29.54770444192908,
                            "height": 15.153846921145348
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/BREEZER_LIME_1466435651_1497447495_1551115924_1561451854.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/BREEZER_ORANGE_1466435659_1497447495_1551115924_1561451854.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_a06G6ds_T8l087e.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619593,
                        "field1": "BORYSKA",
                        "field2": "vodka bianca",
                        "field3": "lt. 2",
                        "field4": "",
                        "description": "BORYSKA vodka bianca lt. 2",
                        "grammage": 2000,
                        "price": "11.28",
                        "price_label": "€ 11,28",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 43.11295827398712,
                            "left": 35.36183644645674,
                            "width": 29.276327107086534,
                            "height": 15.153846921145348
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio239_bellini_vodka_bianca_lt2_1560866536.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_pRjyvct_TLtn7Tc.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619598,
                        "field1": "malibu",
                        "field2": "cl. 70",
                        "field3": "",
                        "field4": "",
                        "description": "malibu cl. 70",
                        "grammage": 700,
                        "price": "8.15",
                        "price_label": "€ 8,15",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 58.553168523658606,
                            "left": 5.879842254921944,
                            "width": 29.276327107086534,
                            "height": 17.78196781470645
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/malibu_1560782635.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_SAzttTJ_ER7pJGp.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619590,
                        "field1": "campari",
                        "field2": "bitter",
                        "field3": "",
                        "field4": "cl. 70",
                        "description": "campari bitter cl. 70",
                        "grammage": 700,
                        "price": "8.95",
                        "price_label": "€ 8,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 58.61887154599764,
                            "left": 35.279053529531666,
                            "width": 29.276327107086534,
                            "height": 17.78196781470645
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/CAMPARI_Bottiglia_70_cl_-_01_2018-_OK_1559920638_1560781641.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_TrND916_lfWZ7sY.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619597,
                        "field1": "beefeater",
                        "field2": "gin",
                        "field3": "cl. 70",
                        "field4": "",
                        "description": "beefeater gin cl. 70",
                        "grammage": 700,
                        "price": "7.99",
                        "price_label": "€ 7,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 76.42439059987412,
                            "left": 5.970301366536129,
                            "width": 29.276327107086534,
                            "height": 19.358840350843117
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/BEEFEATER_GIN_1444223468_1560782554.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_g2WV2G6_akuCAbA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619591,
                        "field1": "molinari",
                        "field2": "sambuca",
                        "field3": "lt. 1,5",
                        "field4": "",
                        "description": "molinari sambuca lt. 1,5",
                        "grammage": 1500,
                        "price": "20.95",
                        "price_label": "€ 20,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 76.3586875775351,
                            "left": 35.279053529531666,
                            "width": 29.276327107086534,
                            "height": 19.358840350843117
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/sambuca_molinari_1_5lt_1445327661_1561101860.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_cZmJ46o_bFF32x5.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619599,
                        "field1": "havana",
                        "field2": "especial ",
                        "field3": "cl. 70",
                        "field4": "",
                        "description": "havana especial  cl. 70",
                        "grammage": 700,
                        "price": "8.25",
                        "price_label": "€ 8,25",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 76.42439059987412,
                            "left": 64.85918302736975,
                            "width": 31.80918223228367,
                            "height": 19.358840350843117
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/havana_club_anejo_especial_1540562430_1560782685.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_3GnDCaJ_FVv0PFQ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619610,
                        "field1": "bellini",
                        "field2": "brandy napoleon",
                        "field3": "lt. 2",
                        "field4": "",
                        "description": "bellini brandy napoleon lt. 2",
                        "grammage": 2000,
                        "price": "11.49",
                        "price_label": "€ 11,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 38.57944973259422,
                            "left": 64.76872391575557,
                            "width": 31.80918223228367,
                            "height": 19.75305848487728
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio256_bellini_grappa_bianca_veneta_lt2_1560866601.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_LQTwvP1_5UJSCi8.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619594,
                        "field1": "Wodka",
                        "field2": "WYBOROWA",
                        "field3": "70cl",
                        "field4": "",
                        "description": "Wodka WYBOROWA 70cl",
                        "grammage": 700,
                        "price": "6.34",
                        "price_label": "€ 6,34",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 58.487465501319576,
                            "left": 64.67058860945228,
                            "width": 31.990100455512042,
                            "height": 17.78196781470645
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/wyborowa_wodka_1445327688_1478704883_1479314240_1560782248_rJJHF59.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_tbXcyam_ZbxtS3N.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 10,
                "image_file": "/media/interactive_flyer_23/pages/page_10.jpg",
                "products": [
                    {
                        "item_id": 619601,
                        "field1": "jameson",
                        "field2": "irish whiskey",
                        "field3": "cl. 70",
                        "field4": "",
                        "description": "jameson irish whiskey cl. 70",
                        "grammage": 700,
                        "price": "11.36",
                        "price_label": "€ 11,36",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 9.998635015117218,
                            "left": 5.879842254921955,
                            "width": 28.37173599094469,
                            "height": 27.308906053865453
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Jameson_700ml_1496765658_1560783099.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_s6Um18I_F3zHkKE.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619600,
                        "field1": "savio",
                        "field2": "genepi",
                        "field3": "cl. 70",
                        "field4": "",
                        "description": "savio genepi cl. 70",
                        "grammage": 700,
                        "price": "9.20",
                        "price_label": "€ 9,20",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 10.524259193829439,
                            "left": 32.9271166275629,
                            "width": 28.37173599094469,
                            "height": 29.34569974637531
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio246_GenepySavio_1560855928.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_QT65Sne_jQU5mPI.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619603,
                        "field1": "aperol",
                        "field2": "aperitivo",
                        "field3": "lt. 3",
                        "field4": "",
                        "description": "aperol aperitivo lt. 3",
                        "grammage": 3000,
                        "price": "34.90",
                        "price_label": "€ 34,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 10.984180350202632,
                            "left": 60.51714566988895,
                            "width": 32.17101867874041,
                            "height": 31.251087394207108
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/APEROL_LT_3_ASTUCCIATO_1477385327_1560783205.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_meBVZZx_v15reaA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619602,
                        "field1": "campari",
                        "field2": "bitter",
                        "field3": "lt. 3",
                        "field4": "",
                        "description": "campari bitter lt. 3",
                        "grammage": 3000,
                        "price": "44.90",
                        "price_label": "€ 44,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 42.91584920697005,
                            "left": 3.2565280181106258,
                            "width": 32.17101867874041,
                            "height": 33.419287131395016
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Campari_1458149699_1560783156.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_adWyedw_JOtKn0J.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619609,
                        "field1": "ramazzotti",
                        "field2": "amaro",
                        "field3": "cl. 70",
                        "field4": "",
                        "description": "ramazzotti amaro cl. 70",
                        "grammage": 700,
                        "price": "7.71",
                        "price_label": "€ 7,71",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 39.76210413469671,
                            "left": 35.36951264114586,
                            "width": 29.185867995472346,
                            "height": 18.63610710511381
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Amaro_Ramazzotti_70_cl_1530528151_1560783869.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_XRW4Dgf_sreoL9w.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619604,
                        "field1": "diesus",
                        "field2": "amaro del frate",
                        "field3": "",
                        "field4": "cl. 75",
                        "description": "diesus amaro del frate cl. 75",
                        "grammage": 750,
                        "price": "3.95",
                        "price_label": "€ 3,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 42.45592805059685,
                            "left": 64.85918302736977,
                            "width": 29.185867995472346,
                            "height": 15.876580166874646
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/DiesusAmaro75cl_1535619850_1560783254.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_EYc7i1v_576hM9T.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619610,
                        "field1": "bellini",
                        "field2": "grappa bianca veneta",
                        "field3": "lt. 2",
                        "field4": "",
                        "description": "bellini grappa bianca veneta lt. 2",
                        "grammage": 2000,
                        "price": "11.74",
                        "price_label": "€ 11,74",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 58.42176247898057,
                            "left": 35.36951264114586,
                            "width": 29.185867995472346,
                            "height": 17.781967814706448
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio256_bellini_grappa_bianca_veneta_lt2_1560866601_FxTJWnj.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_5K6Sy5u_gWBqcOJ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619605,
                        "field1": "Terra del Sole  ",
                        "field2": "Mirto rosso  28° ",
                        "field3": "lt. 2",
                        "field4": "",
                        "description": "Terra del Sole   Mirto rosso  28°  lt. 2",
                        "grammage": 2000,
                        "price": "12.99",
                        "price_label": "€ 12,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 58.42176247898057,
                            "left": 64.76872391575557,
                            "width": 29.185867995472346,
                            "height": 17.781967814706448
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/MIRTO_1561479744.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_9vUDMYo_9O9JtfZ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619606,
                        "field1": "gran marnier",
                        "field2": "liquore",
                        "field3": "cl. 70",
                        "field4": "",
                        "description": "gran marnier liquore cl. 70",
                        "grammage": 700,
                        "price": "15.44",
                        "price_label": "€ 15,44",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 76.29298455519609,
                            "left": 2.985150683268074,
                            "width": 32.2614777903546,
                            "height": 19.35884035084311
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/1117_Gran_Marnier_cl_70_1550249782_1560783496.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_onn24bv_3YBYATj.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619607,
                        "field1": "martini",
                        "field2": "dry",
                        "field3": "lt. 1",
                        "field4": "",
                        "description": "martini dry lt. 1",
                        "grammage": 0,
                        "price": "6.48",
                        "price_label": "€ 6,48",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 76.49009362221317,
                            "left": 35.36951264114586,
                            "width": 29.095408883858163,
                            "height": 19.35884035084311
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio253_MartiniDry1lt_1560855954.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_CA0DMQo_UwmnmL5.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619608,
                        "field1": "julia",
                        "field2": "grappa invecchiata",
                        "field3": "cl. 70",
                        "field4": "",
                        "description": "julia grappa invecchiata cl. 70",
                        "grammage": 700,
                        "price": "6.77",
                        "price_label": "€ 6,77",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Liquori",
                        "blueprint": {
                            "top": 76.42439059987413,
                            "left": 64.76872391575557,
                            "width": 29.4572453303149,
                            "height": 19.35884035084311
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/grappa_julia_invecchiata_1545324368_1560783798.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_zMR5njZ_VKxwzvD.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 11,
                "image_file": "/media/interactive_flyer_23/pages/page_11.jpg",
                "products": [
                    {
                        "item_id": 619619,
                        "field1": "jolly colombani",
                        "field2": "succo",
                        "field3": "vari gusti",
                        "field4": "lt. 1",
                        "description": "jolly colombani succo vari gusti lt. 1",
                        "grammage": 0,
                        "price": "0.62",
                        "price_label": "€ 0,62",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Succhi",
                        "blueprint": {
                            "top": 10.984180350202648,
                            "left": 33.37941218563382,
                            "width": 31.356886674212763,
                            "height": 28.820075567663086
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/jolly_pera_1_lt_1555346219_1560786334.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/jolly_ananas_1_lt_1555346220_1560786335.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_LZ48zWh_fmqm269.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619620,
                        "field1": "fuze",
                        "field2": "tea",
                        "field3": "limone/pesca",
                        "field4": "cl. 40",
                        "description": "fuze tea limone/pesca cl. 40",
                        "grammage": 400,
                        "price": "0.54",
                        "price_label": "€ 0,54",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 10.918477327863618,
                            "left": 62.77862346024354,
                            "width": 31.356886674212763,
                            "height": 26.848984897492254
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/fuzetea_040_1522416830_1560786425.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_fa3zV0Y_uP2HfHm.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619611,
                        "field1": "san bernardo",
                        "field2": "acqua",
                        "field3": "naturale/frizzante",
                        "field4": "cl. 50",
                        "description": "san bernardo acqua naturale/frizzante cl. 50",
                        "grammage": 500,
                        "price": "0.12",
                        "price_label": "€ 0,12",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Acqua",
                        "blueprint": {
                            "top": 42.587334095274926,
                            "left": 5.698924031693587,
                            "width": 29.36678621870072,
                            "height": 15.74517412219659
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/8003430110624_SBernardoFrizzante50cl_1560784252.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_gFfu9qF_cBFvEsK.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619613,
                        "field1": "san bernardo",
                        "field2": "acqua",
                        "field3": "naturale/frizzante",
                        "field4": "lt. 1,5",
                        "description": "san bernardo acqua naturale/frizzante lt. 1,5",
                        "grammage": 1500,
                        "price": "0.25",
                        "price_label": "€ 0,25",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Acqua",
                        "blueprint": {
                            "top": 42.587334095274926,
                            "left": 35.36951264114586,
                            "width": 29.18586799547235,
                            "height": 15.74517412219659
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/80067641_SanBernardoNaturale1_5_1560784730.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/80385103_SanBernardoAcquaNaturale1_5_1560784731.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_25AHEAe_6vAWQi7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619615,
                        "field1": "mia",
                        "field2": "acqua",
                        "field3": "naturale/frizzante",
                        "field4": "lt. 1,5",
                        "description": "mia acqua naturale/frizzante lt. 1,5",
                        "grammage": 1500,
                        "price": "0.14",
                        "price_label": "€ 0,14",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Acqua",
                        "blueprint": {
                            "top": 42.127412938901735,
                            "left": 64.6782648041414,
                            "width": 29.18586799547235,
                            "height": 16.270798300908808
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/frizzante-15_1560862987.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/naturale-15_1560862988.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_aO0xJNU_fltDhmt.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619614,
                        "field1": "valmora",
                        "field2": "acqua",
                        "field3": "naturale/frizzante",
                        "field4": "lt. 1,5",
                        "description": "valmora acqua naturale/frizzante lt. 1,5",
                        "grammage": 1500,
                        "price": "0.16",
                        "price_label": "€ 0,16",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Acqua",
                        "blueprint": {
                            "top": 58.487465501319605,
                            "left": 6.151219589764506,
                            "width": 29.18586799547235,
                            "height": 17.781967814706444
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/8010333000754_ValmoraFrizzante15_1560785020.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/8010333000778_ValmoraNaturale15lt_1560785021.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_uFzkrKP_LxCvO8O.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619616,
                        "field1": "valmora",
                        "field2": "acqua",
                        "field3": "naturale/frizzante",
                        "field4": "lt. 1",
                        "description": "valmora acqua naturale/frizzante lt. 1",
                        "grammage": 0,
                        "price": "0.16",
                        "price_label": "€ 0,16",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Acqua",
                        "blueprint": {
                            "top": 58.61887154599765,
                            "left": 35.36951264114586,
                            "width": 29.18586799547235,
                            "height": 17.781967814706444
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio262_valmora_acqua_frizzante_lt1_1560866647.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio262_valmora_acqua_naturale_lt1_1560866648.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_PKlYu2K_PUU7mfh.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619612,
                        "field1": "uliveto",
                        "field2": "acqua minerale effervescente naturale",
                        "field3": "lt. 1,5",
                        "field4": "",
                        "description": "uliveto acqua minerale effervescente naturale lt. 1,5",
                        "grammage": 1500,
                        "price": "0.34",
                        "price_label": "€ 0,34",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Acqua",
                        "blueprint": {
                            "top": 58.55316852365863,
                            "left": 64.85918302736977,
                            "width": 29.18586799547235,
                            "height": 17.781967814706444
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/1589_1528212189_1532356329_1560330777_1560784296.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_fQStSc6_hgvWJVC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619621,
                        "field1": "gatorade ",
                        "field2": "",
                        "field3": "arancia/limone",
                        "field4": "cl. 50",
                        "description": "gatorade  arancia/limone cl. 50",
                        "grammage": 500,
                        "price": "0.57",
                        "price_label": "€ 0,57",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 76.42439059987413,
                            "left": 5.970301366536138,
                            "width": 29.18586799547235,
                            "height": 19.29313732850408
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/8001160000888_Principale_1520607952_1560786491.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/8001160000949_Principale_1520607952_1535449165_1560786491.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_T7utPp4_flki3o2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619625,
                        "field1": "bravo",
                        "field2": "succhi",
                        "field3": "vari gusti",
                        "field4": "brik lt.1,5",
                        "description": "bravo succhi vari gusti brik lt.1,5",
                        "grammage": 1500,
                        "price": "1.19",
                        "price_label": "€ 1,19",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Succhi",
                        "blueprint": {
                            "top": 76.42439059987413,
                            "left": 35.45997175276005,
                            "width": 29.18586799547235,
                            "height": 19.29313732850408
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/90020193_BravoMultiVitamin15_1560787106.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_6Cskjq5_JJYJYM4.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619618,
                        "field1": "jolly colombani",
                        "field2": "succo di frutta",
                        "field3": "vari gusti",
                        "field4": "ml. 200 x 3",
                        "description": "jolly colombani succo di frutta vari gusti ml. 200 x 3",
                        "grammage": 600,
                        "price": "0.49",
                        "price_label": "€ 0,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Succhi",
                        "blueprint": {
                            "top": 76.35868757753511,
                            "left": 64.84383063799154,
                            "width": 29.18586799547235,
                            "height": 19.29313732850408
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/JOLLY_SUCCO_PERA_ML_200X3_1556268933_1560765123_1560786202.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/succo-albicocca-jolly-colombani-3-x-200-ml_1561103452.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_xtYsWSu_wUNUbK2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 12,
                "image_file": "/media/interactive_flyer_23/pages/page_12.jpg",
                "products": [
                    {
                        "item_id": 619623,
                        "field1": "Yoga",
                        "field2": "Succhi Tipo Bar",
                        "field3": "(escluso mirtillo)",
                        "field4": "cl. 20",
                        "description": "Yoga Succhi Tipo Bar (escluso mirtillo) cl. 20",
                        "grammage": 800,
                        "price": "0.00",
                        "price_label": "€ 0,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 9.144495724709873,
                            "left": 5.954948977157906,
                            "width": 26.110258200590103,
                            "height": 27.243203031526416
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/59806_yoga_magic_ananas_200_ml_1557757410.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_YTJldfM_4vgecxB.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619624,
                        "field1": "coca cola vari gusti/",
                        "field2": "fanta/sprite",
                        "field3": "",
                        "field4": "lattina cl. 33",
                        "description": "coca cola vari gusti/ fanta/sprite lattina cl. 33",
                        "grammage": 330,
                        "price": "0.38",
                        "price_label": "€ 0,38",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 10.524259193829453,
                            "left": 30.740745559444253,
                            "width": 31.899641343897866,
                            "height": 30.33124508146072
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/FANTA_ORANGE_SLEEK_CAN_CL_33_1487692508_1554211149_1557150_uam29pc.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/Coca-Cola_Regular_sleek_can_cl_33_1487692506_1554211172_15_Y3t1fpe.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/Coca-Cola_zero_sleek_can_cl_33_1487695986_1554211173_15571_Lmf0hGo.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_Uyg1Nwb_EDIbJLR.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619627,
                        "field1": "lemon-soda/",
                        "field2": "oran-soda",
                        "field3": "cl. 20",
                        "field4": "",
                        "description": "lemon-soda/ oran-soda cl. 20",
                        "grammage": 200,
                        "price": "0.31",
                        "price_label": "€ 0,31",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 45.47826707819214,
                            "left": 3.437446241338983,
                            "width": 31.899641343897866,
                            "height": 12.98564718395743
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Lemon_soda_20_cl_1560787355.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/s-l300_1561383853.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_4Xb7vjZ_3cHU45c.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619628,
                        "field1": "sanpellegrino",
                        "field2": "chinò/cocktail/aranciata amara/dolce/gassosa/cedrata",
                        "field3": "cl. 20",
                        "field4": "",
                        "description": "sanpellegrino chinò/cocktail/aranciata amara/dolce/gassosa/cedrata cl. 20",
                        "grammage": 200,
                        "price": "0.36",
                        "price_label": "€ 0,36",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Chinò",
                            "Cocktail",
                            "Aranciata amara",
                            "Gassosa",
                            "Cedrata",
                            "Arancia dolce"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 43.88961892247044,
                            "left": 35.341383647645316,
                            "width": 29.21829305138136,
                            "height": 14.520367936925101
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Chinotto_vetro_1512553304_1560787472.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/aranciata-amara-san-pellegrino-0.2-L-vap-640x660_1561383703.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_qlPkvvc_sowJbk8.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619631,
                        "field1": "beltè",
                        "field2": "thè",
                        "field3": "limone/pesca",
                        "field4": "lt. 1,5",
                        "description": "beltè thè limone/pesca lt. 1,5",
                        "grammage": 1500,
                        "price": "0.59",
                        "price_label": "€ 0,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Limone",
                            "Pesca"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 11.038107752956638,
                            "left": 63.926462917727385,
                            "width": 30.03242505590901,
                            "height": 31.340341655716166
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/belte_15_pesca_1559122526_1560787816_NNjAFAm.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/belte_15_limone_1559122526_1560787816_Rbueraa.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_pn7EiNN_Bj3icB8.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619622,
                        "field1": "Sanbitter ",
                        "field2": "Bianco / Rosso",
                        "field3": "",
                        "field4": "cl. 10 x 10",
                        "description": "Sanbitter  Bianco / Rosso cl. 10 x 10",
                        "grammage": 0,
                        "price": "3.92",
                        "price_label": "€ 3,92",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Rosso",
                            "Bianco"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 42.83837056504599,
                            "left": 64.72094647046835,
                            "width": 29.489670386223906,
                            "height": 15.571616294349544
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/san_pellegrino_sanbitter_dry_10_cl_x_10_1557757746.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/Sambitter_Rosso_1485445965_1504693464_1510056286_1558514388.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_nMds4n1_orblqMW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619629,
                        "field1": "schweppes",
                        "field2": "tonica",
                        "field3": "cl. 18",
                        "field4": "",
                        "description": "schweppes tonica cl. 18",
                        "grammage": 180,
                        "price": "0.38",
                        "price_label": "€ 0,38",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 58.40998685939554,
                            "left": 5.651146586406352,
                            "width": 29.489670386223906,
                            "height": 17.93692509855454
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/schweppes_tonica_vetro_18_cl_1451485505_1492855889_1517326_lx84DCf.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_BuikA8m_pvadud6.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619630,
                        "field1": "red bull",
                        "field2": "",
                        "field3": "ml. 250",
                        "field4": "",
                        "description": "red bull ml. 250",
                        "grammage": 250,
                        "price": "0.86",
                        "price_label": "€ 0,86",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 58.47568988173456,
                            "left": 35.50265341908699,
                            "width": 29.03737482815299,
                            "height": 17.93692509855454
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Red_Bull_regular_energy_drink_25_cl_1560787767.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_J6kNsk5_1Rv499q.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619626,
                        "field1": "bellini",
                        "field2": "sciroppo alla menta",
                        "field3": "",
                        "field4": "kg. 2",
                        "description": "bellini sciroppo alla menta kg. 2",
                        "grammage": 2000,
                        "price": "2.49",
                        "price_label": "€ 2,49",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 58.40998685939554,
                            "left": 64.9923238053109,
                            "width": 29.03737482815299,
                            "height": 17.93692509855454
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/giorgio_menta_1561457690.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_PAQMPbh_iUoEVWu.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619632,
                        "field1": "san benedetto",
                        "field2": "bibite",
                        "field3": "vari tipi",
                        "field4": "lt. 1,5",
                        "description": "san benedetto bibite vari tipi lt. 1,5",
                        "grammage": 1500,
                        "price": "0.48",
                        "price_label": "€ 0,48",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 6.193901256091455,
                            "width": 29.03737482815299,
                            "height": 19.18528252299606
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/San_benedetto_cedrata_Lt_15_1446114045_1516098976_1560787867.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/SB_Allegra_15lt_1526402109_1560787868.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_c28htcV_F0piBSA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619633,
                        "field1": "pepsi",
                        "field2": "cola",
                        "field3": "regular/twist limone",
                        "field4": "lt. 1,5",
                        "description": "pepsi cola regular/twist limone lt. 1,5",
                        "grammage": 1500,
                        "price": "0.75",
                        "price_label": "€ 0,75",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Regular",
                            "Twist Limone"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 35.50265341908699,
                            "width": 29.03737482815299,
                            "height": 19.18528252299606
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/pepsi_15_classica_1468309603_1470237957_1477399936_1495212_QMA3WMS.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/pepsi_twist_limone_15_1468309625_1470237976_1497606334_153_5tS3s3C.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_YEDkGaJ_1KYtShL.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619634,
                        "field1": "san pellegrino",
                        "field2": "bibite",
                        "field3": "vari gusti",
                        "field4": "lt. 1,25",
                        "description": "san pellegrino bibite vari gusti lt. 1,25",
                        "grammage": 1250,
                        "price": "0.79",
                        "price_label": "€ 0,79",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Bibite",
                        "blueprint": {
                            "top": 76.34691195795007,
                            "left": 64.81140558208253,
                            "width": 29.489670386223906,
                            "height": 19.18528252299606
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/San_Pellegrino_aranciata_lt_125_1446216586_1533033637_1560787994.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/San_Pellegrino_Cocktail_Lt_15_1443514577_1450461825_145347_b4SxofQ.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/San_Pellegrino_Chino_Lt_15_1443514571_1450461782_145347902_Ccl2BLI.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_9xdJSxC_ry8Mngt.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 13,
                "image_file": "/media/interactive_flyer_23/pages/page_13.jpg",
                "products": [
                    {
                        "item_id": 619635,
                        "field1": "moretti",
                        "field2": "birra",
                        "field3": "cl. 66",
                        "field4": "",
                        "description": "moretti birra cl. 66",
                        "grammage": 660,
                        "price": "0.71",
                        "price_label": "€ 0,71",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 11.103810775295663,
                            "left": 5.8320648096347085,
                            "width": 26.95681526102676,
                            "height": 30.551905387647835
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/MORETTI_BIRRA_CL_66_1435336589_1542732367_1560788051_35qV0SO.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_6q6u0G2_vXe9J3f.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619636,
                        "field1": "ceres",
                        "field2": "birra strong ale",
                        "field3": "cl. 33",
                        "field4": "",
                        "description": "ceres birra strong ale cl. 33",
                        "grammage": 330,
                        "price": "1.13",
                        "price_label": "€ 1,13",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 11.038107752956634,
                            "left": 32.24612540097637,
                            "width": 27.68048815394023,
                            "height": 29.106438896189232
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Ceres_Strong_Ale_birra_33cl_1512645900_1560788089.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_GYOSvs2_ccMNYTw.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619637,
                        "field1": "beck's ",
                        "field2": "birra",
                        "field3": "cl. 33",
                        "field4": "",
                        "description": "beck's  birra cl. 33",
                        "grammage": 330,
                        "price": "0.54",
                        "price_label": "€ 0,54",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 10.972404730617608,
                            "left": 59.29339977361731,
                            "width": 28.404161046853705,
                            "height": 24.770039421813408
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Becks_birra_33_cl_1457437162_1484839497_1509701822_1560788141.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_TDsLpKe_uddh3pN.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619638,
                        "field1": "ichnusa ",
                        "field2": "birra non filtrata",
                        "field3": "",
                        "field4": "cl. 50",
                        "description": "ichnusa  birra non filtrata cl. 50",
                        "grammage": 500,
                        "price": "0.99",
                        "price_label": "€ 0,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 42.70696452036794,
                            "left": 5.741605698020526,
                            "width": 21.07697300610482,
                            "height": 15.703022339027601
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/489092_ichnusaNonFiltrata50cl_1539255169_1554211360_156078_mev8jvM.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_7Es0IfS_Vo4Rrhp.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619639,
                        "field1": "heineken",
                        "field2": "birra ",
                        "field3": "",
                        "field4": "cl. 33",
                        "description": "heineken birra  cl. 33",
                        "grammage": 330,
                        "price": "0.59",
                        "price_label": "€ 0,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 41.590013140604476,
                            "left": 26.81857870412534,
                            "width": 22.162482345475027,
                            "height": 16.688567674113013
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Heineken_Cl_33_x_3_1560763931_1560788363_1560788417_9CZfRTh.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_SfFlIGk_GhsxylG.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619645,
                        "field1": "franziskaner",
                        "field2": "Weissbier hefe hell ",
                        "field3": "cl. 50",
                        "field4": "",
                        "description": "franziskaner Weissbier hefe hell  cl. 50",
                        "grammage": 500,
                        "price": "0.96",
                        "price_label": "€ 0,96",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 41.26149802890934,
                            "left": 49.07152016121455,
                            "width": 21.71018678740411,
                            "height": 17.082785808147182
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Franziskaner_hell_cl_50_1560789178.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_7OjVOPM_90UMi2Y.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619641,
                        "field1": "messina",
                        "field2": "birra",
                        "field3": "",
                        "field4": "cl. 33",
                        "description": "messina birra cl. 33",
                        "grammage": 330,
                        "price": "0.69",
                        "price_label": "€ 0,69",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 42.77266754270697,
                            "left": 71.14354339507538,
                            "width": 23.247991684845232,
                            "height": 15.571616294349544
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio285_BirraMessina33_1560855980.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_We0TZge_HKBWwox.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619642,
                        "field1": "messina",
                        "field2": "birra cristalli di sale ",
                        "field3": "cl. 50",
                        "field4": "",
                        "description": "messina birra cristalli di sale  cl. 50",
                        "grammage": 500,
                        "price": "1.28",
                        "price_label": "€ 1,28",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 58.40998685939554,
                            "left": 5.832064809634699,
                            "width": 20.896054782876455,
                            "height": 17.93692509855454
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Messina_birra_cristalli_di_sale_1554975264_1554996251_1558_jGfpWXr.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_4cZb0vF_A6lYpmE.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619644,
                        "field1": "blanche de namur ",
                        "field2": "birra ",
                        "field3": "cl. 75",
                        "field4": "",
                        "description": "blanche de namur  birra  cl. 75",
                        "grammage": 750,
                        "price": "2.98",
                        "price_label": "€ 2,98",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 58.40998685939554,
                            "left": 26.818578704125333,
                            "width": 22.16248234547502,
                            "height": 17.93692509855454
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/BLANCHE_DE_NAMUR_75_CL_NEW_1526480042_1560788992.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_VHgKNHI_igix7wS.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619646,
                        "field1": "baladin",
                        "field2": "birra open",
                        "field3": "bionda/ambrata",
                        "field4": "cl. 33",
                        "description": "baladin birra open bionda/ambrata cl. 33",
                        "grammage": 330,
                        "price": "1.89",
                        "price_label": "€ 1,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Bionda ",
                            "Ambrata"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 58.475689881734574,
                            "left": 49.161979272828724,
                            "width": 21.891105010632476,
                            "height": 17.93692509855454
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/BaladinOpenAmber_1560789576.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio290_BaladinOpenChiara33_1560855999.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_TSQX0KL_9zujGqo.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619647,
                        "field1": "baladin",
                        "field2": "birra open",
                        "field3": "ambrata/bionda/bianca",
                        "field4": "cl. 75",
                        "description": "baladin birra open ambrata/bionda/bianca cl. 75",
                        "grammage": 750,
                        "price": "4.89",
                        "price_label": "€ 4,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Ambrata",
                            "Bionda",
                            "Bianca"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 58.475689881734574,
                            "left": 71.05308428346119,
                            "width": 23.338450796459416,
                            "height": 17.805519053876484
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/BALADIN_05280006_OPEN_WHITE_5_075_l_BALADIN_1561457725.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_madtJTS_GRTahD5.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619643,
                        "field1": "ceres",
                        "field2": "birra top pilsner ",
                        "field3": "cl. 50",
                        "field4": "",
                        "description": "ceres birra top pilsner  cl. 50",
                        "grammage": 500,
                        "price": "0.63",
                        "price_label": "€ 0,63",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 76.34691195795007,
                            "left": 48.890601937986176,
                            "width": 22.072023233860843,
                            "height": 19.25098554533509
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/ceres_top_pilsner_lattina_1464108334_1528710495_1550831955_Rp5jBj3.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_zhqmdIp_81UFBw8.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619640,
                        "field1": "heineken ",
                        "field2": "birra",
                        "field3": "",
                        "field4": "lattina cl. 33",
                        "description": "heineken  birra lattina cl. 33",
                        "grammage": 330,
                        "price": "0.55",
                        "price_label": "€ 0,55",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Birre",
                        "blueprint": {
                            "top": 76.34691195795007,
                            "left": 71.14354339507538,
                            "width": 25.690387698428193,
                            "height": 19.25098554533509
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Heineken_birra_lattina_33_cl_1560788561.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_UEHSC0k_1etHx8O.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 14,
                "image_file": "/media/interactive_flyer_23/pages/page_14.jpg",
                "products": [
                    {
                        "item_id": 619653,
                        "field1": "roero arneis",
                        "field2": "fontanafredda",
                        "field3": "cl. 75",
                        "field4": "",
                        "description": "roero arneis fontanafredda cl. 75",
                        "grammage": 750,
                        "price": "3.45",
                        "price_label": "€ 3,45",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 10.578186596583443,
                            "left": 5.832064809634699,
                            "width": 30.303802390751567,
                            "height": 27.069645203679375
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/8000174000846_FontanaFreddaArneis_1560842297.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_4b9N4zM_bG92Hth.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619649,
                        "field1": "borgo lame",
                        "field2": "vino bonarda",
                        "field3": "cl. 75",
                        "field4": "",
                        "description": "borgo lame vino bonarda cl. 75",
                        "grammage": 750,
                        "price": "2.45",
                        "price_label": "€ 2,45",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 11.038107752956638,
                            "left": 36.58816275845717,
                            "width": 30.303802390751567,
                            "height": 29.30354796320631
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio292_borgo_lame_vino_bonarda_cl75_1560866710.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_deDqPpO_aOcrXqC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619664,
                        "field1": "zimor",
                        "field2": "prosecco di valdobbiadene",
                        "field3": "cl. 75",
                        "field4": "",
                        "description": "zimor prosecco di valdobbiadene cl. 75",
                        "grammage": 750,
                        "price": "4.89",
                        "price_label": "€ 4,89",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 11.103810775295665,
                            "left": 66.16829225629527,
                            "width": 27.95186548878279,
                            "height": 30.48620236530881
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/zimor_valdobbiadenebrut_1560863891.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_W4sSKQK_eoREdOx.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619657,
                        "field1": "Zonin",
                        "field2": "Vino",
                        "field3": "Bianco/Rosso/Rosato",
                        "field4": "lt. 1,5",
                        "description": "Zonin Vino Bianco/Rosso/Rosato lt. 1,5",
                        "grammage": 1500,
                        "price": "2.48",
                        "price_label": "€ 2,48",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Bianco",
                            "Rosso",
                            "Rosato"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 43.29829172141919,
                            "left": 3.1182914612091857,
                            "width": 32.11298462303524,
                            "height": 15.111695137976355
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/716004_zonin_1542014961_1560843106.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/716006_zonin_bianco_1542015148_1560843107.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/716007_zonin_rosso_1542015149_1560843107.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_hV612j1_uFfo8Mf.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619650,
                        "field1": "tavernello",
                        "field2": "vino ",
                        "field3": "bianco/rosso",
                        "field4": "brik lt. 1",
                        "description": "tavernello vino  bianco/rosso brik lt. 1",
                        "grammage": 0,
                        "price": "0.98",
                        "price_label": "€ 0,98",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Bianco",
                            "Rosso"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 43.36399474375822,
                            "left": 35.32173519585861,
                            "width": 29.308752162995546,
                            "height": 15.111695137976355
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/874-a16_1542904349_1560519252_1561451292.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/876-a16_1542904350_1560519252_1561451292.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/8008530008680_tavernello_rosso_1560519191_1561451293.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_OaZe0PL_Vck9mPR.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619656,
                        "field1": "Manfredi",
                        "field2": "Barbera Monferrato",
                        "field3": "cl. 75",
                        "field4": "",
                        "description": "Manfredi Barbera Monferrato cl. 75",
                        "grammage": 750,
                        "price": "1.99",
                        "price_label": "€ 1,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 42.31274638633377,
                            "left": 64.63048735885414,
                            "width": 29.308752162995546,
                            "height": 16.09724047306177
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/manfredi_barbera_monferrato_1561457798.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_dH94vkY.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619652,
                        "field1": "le filere",
                        "field2": "barbera d'alba",
                        "field3": "",
                        "field4": "cl. 75",
                        "description": "le filere barbera d'alba cl. 75",
                        "grammage": 750,
                        "price": "2.27",
                        "price_label": "€ 2,27",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 58.40998685939554,
                            "left": 3.3896687960517373,
                            "width": 31.93206639980687,
                            "height": 17.936925098554543
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio295__le_filere_barbera_dalba_cl75_1560866741.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_G60VMoy_4q8mDeM.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619654,
                        "field1": "huznar",
                        "field2": "gewurztraminer",
                        "field3": "cl. 75",
                        "field4": "",
                        "description": "huznar gewurztraminer cl. 75",
                        "grammage": 750,
                        "price": "2.59",
                        "price_label": "€ 2,59",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 58.40998685939554,
                            "left": 35.41219430747279,
                            "width": 29.21829305138136,
                            "height": 17.936925098554543
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/provvisorio297_gewurztraminer_huznar_1560856031.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_CAuHnfO_Vy5jrsA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619655,
                        "field1": "Manfredi",
                        "field2": "Dolcetto Langhe",
                        "field3": "cl. 75",
                        "field4": "",
                        "description": "Manfredi Dolcetto Langhe cl. 75",
                        "grammage": 750,
                        "price": "2.07",
                        "price_label": "€ 2,07",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 58.47568988173456,
                            "left": 64.81140558208251,
                            "width": 29.21829305138136,
                            "height": 17.936925098554543
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Dolcetto_langhe_1560863648.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_AcTWYem_MXJEOHi.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619658,
                        "field1": "Guidobono",
                        "field2": "Langhe Favorita",
                        "field3": "cl. 75 ",
                        "field4": "",
                        "description": "Guidobono Langhe Favorita cl. 75 ",
                        "grammage": 750,
                        "price": "4.99",
                        "price_label": "€ 4,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 3.3896687960517373,
                            "width": 31.841607288192684,
                            "height": 19.38239159001315
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/Favorita_Sfondo_bianco_1561457821.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_KJnziOq_4huNVcI.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619659,
                        "field1": "fantinel santa caterina",
                        "field2": "ribolla gialla",
                        "field3": "cl. 75",
                        "field4": "",
                        "description": "fantinel santa caterina ribolla gialla cl. 75",
                        "grammage": 750,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 76.4126149802891,
                            "left": 35.32173519585861,
                            "width": 29.308752162995535,
                            "height": 19.38239159001315
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/8009663905907_SantaCaterinaRibolla75_1560847888.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_aY7Js9u_81fpOml.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 619660,
                        "field1": "Fantinel Santa Caterina",
                        "field2": "Pinot Grigio",
                        "field3": "cl. 75",
                        "field4": "",
                        "description": "Fantinel Santa Caterina Pinot Grigio cl. 75",
                        "grammage": 750,
                        "price": "3.99",
                        "price_label": "€ 3,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Bevande",
                        "subcategory": "Vini e Spumanti",
                        "blueprint": {
                            "top": 76.34691195795007,
                            "left": 64.81140558208251,
                            "width": 29.308752162995535,
                            "height": 19.38239159001315
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_23/products/8009663102078_FantinelSantaCaterinaPinotGrigio75_1560848046.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_23/products/cropped_image_7H1uQTV_WLPxnEf.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 15,
                "image_file": "/media/interactive_flyer_23/pages/page_15.jpg",
                "products": []
            },
            {
                "number": 16,
                "image_file": "/media/interactive_flyer_23/pages/page_16.jpg",
                "products": []
            },
            {
                "number": 17,
                "image_file": "/media/interactive_flyer_23/pages/page_17.jpg",
                "products": []
            },
            {
                "number": 18,
                "image_file": "/media/interactive_flyer_23/pages/page_18.jpg",
                "products": []
            },
            {
                "number": 19,
                "image_file": "/media/interactive_flyer_23/pages/page_19.jpg",
                "products": []
            },
            {
                "number": 20,
                "image_file": "/media/interactive_flyer_23/pages/page_20.jpg",
                "products": []
            },
            {
                "number": 21,
                "image_file": "/media/interactive_flyer_23/pages/page_21.jpg",
                "products": []
            },
            {
                "number": 22,
                "image_file": "/media/interactive_flyer_23/pages/page_22.jpg",
                "products": []
            },
            {
                "number": 23,
                "image_file": "/media/interactive_flyer_23/pages/page_23.jpg",
                "products": []
            },
            {
                "number": 24,
                "image_file": "/media/interactive_flyer_23/pages/page_24.jpg",
                "products": []
            },
            {
                "number": 25,
                "image_file": "/media/interactive_flyer_23/pages/page_25.jpg",
                "products": []
            },
            {
                "number": 26,
                "image_file": "/media/interactive_flyer_23/pages/page_26.jpg",
                "products": []
            },
            {
                "number": 27,
                "image_file": "/media/interactive_flyer_23/pages/page_27.jpg",
                "products": []
            },
            {
                "number": 28,
                "image_file": "/media/interactive_flyer_23/pages/page_28.jpg",
                "products": []
            },
            {
                "number": 29,
                "image_file": "/media/interactive_flyer_23/pages/fine.png",
                "products": []
            }
        ]
    }
    },
    "ideabellezza": {
        "config": {
            "primary-color": "rgb(35, 31, 32)",  
            "secondary-color": "rgb(236, 19, 126)", 
            "tertiary-color": "rgb(255, 255, 255)", 
            "hover-color": "rgba(236, 19, 126, 0.3)", 
            "clientIcon": require("assets/images/clientLogoIdea.png"),
            "serverPath": "https://ideabellezza.volantinointerattivo.net",
            "pdfPath": "/media/interactive_flyer_27/volantino_idea_bellezza.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino Idea Bellezza!",
            "shareFlyerURL": "https://ideabellezza.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Idea Bellezza!",
            "ogTitleMeta": "Volantino Interattivo Idea Bellezza",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://ideabellezza.volantinointerattivo.net/media/interactive_flyer_27/images/clientLogo.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": ""
        },
        "leaflet": {
            "name": "Idea Bellezza",
            "code": "ideabellezza19",
            "id_campaign": "1298",
            "index": {
                "image_file": "/media/interactive_flyer_27/pages/indice.png",
                "links": [
                    {   
                        "page": 0,
                        "blueprint": {
                            "top": 28.15716791699325,
                            "left": 3.016181856599523,
                            "width": 94.20261656571348,
                            "height": 10.279550628895606
                        }
                    },
                    {   
                        "page": 2,
                        "blueprint": {
                            "top": 41.80345612660023,
                            "left": 3.016181856599523,
                            "width": 94.20261656571348,
                            "height": 10.170380323218751
                        }
                    },
                    {   
                        "page": 3,
                        "blueprint": {
                            "top": 55.34057403053037,
                            "left": 3.016181856599523,
                            "width": 94.20261656571348,
                            "height": 10.170380323218751
                        }
                    },    
                ]
            },
        "categories": [
            {
                "category_name": "Igiene Persona",
                "subcategories": [
                    "Capelli",
                    "Corpo"
                ]
            },
            {
                "category_name": "Profumi",
                "subcategories": [
                    "Uomo",
                    "Donna"
                ]
            },
            {
                "category_name": "Make Up",
                "subcategories": [
                    "Viso",
                    "Mani"
                ]
            },


        ],
        "pages": [
            {
                "number": 1,
                "image_file": "/media/interactive_flyer_27/pages/page_1.jpg",
                "products": [
                    {
                        "item_id": 446656,
                        "field1": "REVLON",
                        "field2": "Skinlights",
                        "field3": "Terra abbronzante",
                        "field4": "Varie Nuance",
                        "description": "REVLON Skinlights Terra abbronzante Varie Nuance",
                        "grammage": null,
                        "price": "9.95",
                        "price_label": "€ 9,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Make Up",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 1.8106297699445446,
                            "left": 35.27148637336062,
                            "width": 33.529322853688036,
                            "height": 21.314637896008346
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/revlon1.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_7UHrS1o.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [421593, 458148, 465631, 453400, 417735]
                    },
                    {
                        "item_id": 421593,
                        "field1": "REVLON",
                        "field2": "Photoready",
                        "field3": "Insta-Sculpt Duo",
                        "field4": "Correttore Illuminante in Stick",
                        "description": "REVLON Photoready Insta-Sculpt Duo Correttore/Illuminante in Stick",
                        "grammage": null,
                        "price": "10.95",
                        "price_label": "€ 10,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Correttore",
                            "Illuminante in Stick"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Make Up",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 1.938834898149673,
                            "left": 69.11678758565094,
                            "width": 28.009099928688805,
                            "height": 21.314637896008346
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/revlon2.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_pOkIKqO_LpjfOTn.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [446656, 458148, 465631, 453400, 417735]
                    },
                    {
                        "item_id": 458148,
                        "field1": "REVLON",
                        "field2": "Photoready",
                        "field3": "Instant Cheek Maker",
                        "field4": "Blush Illuminante",
                        "description": "REVLON Photoready Instant Cheek Maker Blush Illuminante",
                        "grammage": null,
                        "price": "11.95",
                        "price_label": "€ 11,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Make Up",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 23.669604128918905,
                            "left": 2.512130654512744,
                            "width": 38.50657303196602,
                            "height": 23.94284302421347
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/revlon_photoready.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_CVwUYmL_oTnhZMl.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [446656, 421593, 465631, 453400, 417735]
                    },
                    {
                        "item_id": 465631,
                        "field1": "REVLON",
                        "field2": "Colorstay",
                        "field3": "Brow Creator",
                        "field4": "Vari Colori",
                        "description": "REVLON Colorstay Brow Creator Vari Colori",
                        "grammage": null,
                        "price": "8.95",
                        "price_label": "€ 8,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Make Up",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 23.60550156481634,
                            "left": 41.33468204508107,
                            "width": 22.94135429262394,
                            "height": 24.071048152418598
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/revlon_colorstay.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_e0maCCJ_0ElJr7q.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [446656, 421593, 458148, 453400, 417735]
                    },
                    {
                        "item_id": 453400,
                        "field1": "PUPA VAMP!",
                        "field2": "Wet & Dry Ombretto",
                        "field3": "Vari Colori",
                        "field4": "",
                        "description": "PUPA VAMP! Wet & Dry Ombretto Vari Colori",
                        "grammage": null,
                        "price": "10.95",
                        "price_label": "€ 10,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Make Up",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 48.156783616098394,
                            "left": 2.0596533655783817,
                            "width": 30.90495457786873,
                            "height": 24.071048152418598
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/pupawamp.jpg",
                                "cropped": false
                            },
                            
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_SkYBSGd_hmih5tw.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [406284, 407181]
                    },
                    {
                        "item_id": 407181,
                        "field1": "PUPA VAMP!",
                        "field2": "Liner",
                        "field3": "Vari Tipi",
                        "field4": "",
                        "description": "PUPA VAMP! Liner Vari Tipi",
                        "grammage": null,
                        "price": "12.95",
                        "price_label": "€ 12,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Definition",
                            "Professional",
                            "Stylo"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Make Up",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 48.09268105199583,
                            "left": 33.0090999286888,
                            "width": 31.17644095122934,
                            "height": 24.071048152418598
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/pupawampliner1.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/pupawampliner2.png",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/pupawampliner3.png",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_qWen9PS_shbVYnK.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "VIDEO - Pupa Vamp!",
                                "data": "/media/interactive_flyer_27/video/pupavamp.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": 417735,
                        "field1": "REVLON",
                        "field2": "Photoready",
                        "field3": "Insta Sculpt Palette",
                        "field4": "",
                        "description": "REVLON Photoready Insta Sculpt Palette",
                        "grammage": null,
                        "price": "10.95",
                        "price_label": "€ 10,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "N.001",
                            "N.002"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Make Up",
                        "subcategory": "Viso",
                        "blueprint": {
                            "top": 24.18242464173942,
                            "left": 64.47977707500077,
                            "width": 32.986350106966796,
                            "height": 48.17361225498271
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/revloninstasculpt.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_fx4AudU_vMrAT1r.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [446656, 421593, 458148, 465631, 453400]
                    },
                    {
                        "item_id": 406284,
                        "field1": "PUPA",
                        "field2": "Smalto",
                        "field3": "Effetto Gel",
                        "field4": "Vari Colori",
                        "description": "PUPA Smalto Effetto Gel Vari Colori",
                        "grammage": null,
                        "price": "3.50",
                        "price_label": "€ 3,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Make Up",
                        "subcategory": "Mani",
                        "blueprint": {
                            "top": 72.59561851424806,
                            "left": 2.580883948779961,
                            "width": 29.638018168852508,
                            "height": 24.2633558447263
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/pupa_lasting.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_vp5jqHI_so0IHob.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "info",
                                "title": "CURIOSITA' - Pupa Smalto",
                                "data": {

                                    "titolo": "Pupa Smalto",
                                    "testo": "Lo smalto che ti regala l’effetto ultra lucido e plumping del gel senza lampada UV: è molto più pratico da applicare e…da togliere! Il colore è ultra pigmentato sin dalla prima applicazione, si stende in modo omogeneo e asciuga in tempi record. La texture è volumizzante effetto 3D per unghie dalla superficie liscia e piena. Il finish è super glossato. Il risultato? Unghie impeccabili, effetto plumping. Mai più senza!. Il maxi applicatore piatto, con 440 setole dal taglio arrotondato, garantisce un prelievo e un rilascio ottimale. Non contiene: toluene, formaldeide, DBP (dibutyl phthalate) e canfora. Ipoallergenico, Dermatologicamente testato, Paraben free, Prodotto in Italia. (Fonte: pupa.it)",
                                    "img": [
                                        "/media/interactive_flyer_27/images/pupa_lasting.jpg"
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 482066,
                        "field1": "LAYBA",
                        "field2": "Smalto",
                        "field3": "Semi permanente",
                        "field4": "Vari Colori",
                        "description": "LAYBA Smalto Semipermanente Vari Colori",
                        "grammage": null,
                        "price": "7.50",
                        "price_label": "€ 7,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Make Up",
                        "subcategory": "Mani",
                        "blueprint": {
                            "top": 72.65972107835063,
                            "left": 32.4443850184479,
                            "width": 34.07229560040926,
                            "height": 24.0710481524186
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/laybasmalto.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_yLqeYyD_gKvRUnT.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://laylacosmetics.it/azienda/"
                            }
                        ]
                    },
                    {
                        "item_id": 473909,
                        "field1": "SALLY HANSEN",
                        "field2": "Complete Salon Manicure",
                        "field3": "Smalto",
                        "field4": "Vari Colori",
                        "description": "SALLY HANSEN Complete Salon Manicure Smalto Vari Colori",
                        "grammage": null,
                        "price": "6.95",
                        "price_label": "€ 6,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Make Up",
                        "subcategory": "Mani",
                        "blueprint": {
                            "top": 72.46741338604295,
                            "left": 66.56117260409884,
                            "width": 30.90495457786873,
                            "height": 24.2633558447263
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/sallyhansen.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_aSwdVUH_K3onPha.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.sallyhansen.com/it/nail-color"
                            }
                        ]
                    }
                ]
            },
            {
                "number": 2,
                "image_file": "/media/interactive_flyer_27/pages/page_2.jpg",
                "products": [
                    {
                        "item_id": 447572,
                        "field1": "SUNSILK",
                        "field2": "Spray Termo Protettivo",
                        "field3": "Siero Modellante Ricci",
                        "field4": "125ml",
                        "description": "SUNSILK Spray Termo Protettivo Siero Modellante Ricci 125ml",
                        "grammage": null,
                        "price": "4.95",
                        "price_label": "€ 4,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Spray Termo Protettivo",
                            "Siero Modellante Ricci"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 1.8380462449076105,
                            "left": 36.34202531889637,
                            "width": 60.03838399653698,
                            "height": 21.598701701869714
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/sunsilk_spray.png",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/sunsilk_siero.png",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_fiUJyey_34kCq1J.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            
                        ]
                    },
                    {
                        "item_id": 417387,
                        "field1": "HEAD & SHOULDERS",
                        "field2": "Shampoo",
                        "field3": "Balsamo",
                        "field4": "",
                        "description": "HEAD & SHOULDERS Shampoo Balsamo",
                        "grammage": null,
                        "price": "2.35",
                        "price_label": "€ 2,35",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Shampoo",
                            "Balsamo"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 23.591085272418812,
                            "left": 2.8657244340912253,
                            "width": 33.890678710837825,
                            "height": 23.518087498414822
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/head_balsamo.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/head_shampoo.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_TbTtteG_zGvRbhJ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 457080,
                        "field1": "SUNSILK 1 MINUTE WOW!",
                        "field2": "Trattamento Intensivo Capelli",
                        "field3": "Vari tipi",
                        "field4": "180ml",
                        "description": "SUNSILK 1 MINUTE WOW! Trattamento Intensivo Capelli Vari tipi 180ml",
                        "grammage": null,
                        "price": "2.25",
                        "price_label": "€ 2,25",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 23.655064798970315,
                            "left": 37.06583722991918,
                            "width": 26.381130133976132,
                            "height": 23.518087498414822
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/sunsilkone.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_KetTaFQ_7nNlabA.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.sunsilk.it/1-minute-wow.html"
                            },
                            {
                                "type": "play",
                                "title": "VIDEO - Sunsilk Spot",
                                "data": "/media/interactive_flyer_27/video/sunsilkone.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": 477182,
                        "field1": "FRANCK PROVOST",
                        "field2": "Colorazione Capelli",
                        "field3": "Varie nuance",
                        "field4": "",
                        "description": "FRANCK PROVOST Colorazione Capelli Varie nuance",
                        "grammage": null,
                        "price": "3.45",
                        "price_label": "€ 3,45",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 23.974962431727835,
                            "left": 63.57544847112973,
                            "width": 32.80496084430361,
                            "height": 47.57438948178015
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/franck.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_Uhb0t4b_PoA42V0.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 458094,
                        "field1": "GARNIER FRUCTIS",
                        "field2": "Shampoo/Balsamo",
                        "field3": "Vari tipi",
                        "field4": "",
                        "description": "GARNIER FRUCTIS Shampoo/Balsamo Vari tipi",
                        "grammage": null,
                        "price": "1.85",
                        "price_label": "€ 1,85",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Shampoo 250ml",
                            "Balsamo 200ml"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 47.51942820268113,
                            "left": 2.9562009229690718,
                            "width": 33.709725733082124,
                            "height": 23.58206702496633
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/garnier_aloe_shampoo.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/garnier_aloe_balsamo.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_vMSHIcq_EUqchNP.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 496555,
                        "field1": " GARNIER FRUCTIS",
                        "field2": "Hair Food ",
                        "field3": "Maschera Capelli",
                        "field4": "Vari tipi - 390ml",
                        "description": " GARNIER FRUCTIS Hair Food  Maschera Capelli Vari tipi - 390ml",
                        "grammage": null,
                        "price": "5.45",
                        "price_label": "€ 5,45",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 47.58340772923262,
                            "left": 36.975360741041314,
                            "width": 26.471606622853983,
                            "height": 23.58206702496633
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/garnier_hair_food.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_vxdb2MA_BfSnFqT.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 461359,
                        "field1": "SCHULTZ",
                        "field2": "Shampoo/Balsamo",
                        "field3": "",
                        "field4": "",
                        "description": "SCHULTZ Shampoo/Balsamo",
                        "grammage": null,
                        "price": "2.50",
                        "price_label": "€ 2,50",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [
                            "Shampoo 250ml",
                            "Balsamo Ravvivante 200ml"
                        ],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 71.83164829225245,
                            "left": 2.86572443409122,
                            "width": 35.97163795502842,
                            "height": 26.333186666680984
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/schultz_shampoo.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/schultz_balsamo.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_5tSHF30_TxPih8H.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.ideabellezza.it/marca/360_schultz.html"
                            }
                        ]
                    },
                    {
                        "item_id": 450564,
                        "field1": "SCHULTZ",
                        "field2": "Maschera Ristrutturante",
                        "field3": "300ml",
                        "field4": "",
                        "description": "SCHULTZ Maschera Ristrutturante 300ml",
                        "grammage": null,
                        "price": "3.65",
                        "price_label": "€ 3,65",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Capelli",
                        "blueprint": {
                            "top": 71.83164829225245,
                            "left": 39.056319985231916,
                            "width": 32.17162542215865,
                            "height": 26.333186666680984
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/schultz_maschera.png",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_rMtfN65_mzEE7Vj.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }, 
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.ideabellezza.it/marca/360_schultz.html"
                            }
                        ]
                    }
                ]
            },
            {
                "number": 3,
                "image_file": "/media/interactive_flyer_27/pages/page_3.jpg",
                "products": [
                    {
                        "item_id": 403977,
                        "field1": "ICEBERG TWICE",
                        "field2": "Eau de Toilette",
                        "field3": "125ml",
                        "field4": "",
                        "description": "ICEBERG TWICE Eau de Toilette 125ml",
                        "grammage": null,
                        "price": "15.95",
                        "price_label": "€ 15,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Profumi",
                        "subcategory": "Uomo",
                        "blueprint": {
                            "top": 0.25591810620601413,
                            "left": 35.8896428745071,
                            "width": 61.84791377409401,
                            "height": 23.326148918760314
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/iceberg.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_uZjoTR7_xlldf4q.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 494951,
                        "field1": "GREY STONE",
                        "field2": "Eau de Toilette",
                        "field3": "100ml",
                        "field4": "",
                        "description": "GREY STONE Eau de Toilette 100ml",
                        "grammage": null,
                        "price": "4.95",
                        "price_label": "€ 4,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Profumi",
                        "subcategory": "Uomo",
                        "blueprint": {
                            "top": 23.672424824056304,
                            "left": 35.34678394123999,
                            "width": 30.452572133479467,
                            "height": 23.646046551517834
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/grey_stone.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_KeuaEGU_ofn3zNx.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 434714,
                        "field1": "PASSION DONNA",
                        "field2": "Eau de Parfum",
                        "field3": "100ml",
                        "field4": "",
                        "description": "PASSION DONNA Eau de Parfum 100ml",
                        "grammage": null,
                        "price": "4.95",
                        "price_label": "€ 4,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Profumi",
                        "subcategory": "Donna",
                        "blueprint": {
                            "top": 23.6084452975048,
                            "left": 66.10932128916217,
                            "width": 31.53829000001369,
                            "height": 23.646046551517834
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/passion_donna.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_p51j5w0_cEAu4fx.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 444714,
                        "field1": "SERGIO TACCHINI",
                        "field2": "With Style Uomo",
                        "field3": "Eau de Toilette",
                        "field4": "30ml",
                        "description": "SERGIO TACCHINI With Style Uomo Eau de Toilette 30ml",
                        "grammage": null,
                        "price": "3.95",
                        "price_label": "€ 3,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Profumi",
                        "subcategory": "Uomo",
                        "blueprint": {
                            "top": 47.536788227767126,
                            "left": 35.25683858181473,
                            "width": 37.057355821562645,
                            "height": 24.541759923238885
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/sergio_style.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_hHQ7Dvo_7ZZK582.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 495587,
                        "field1": "ICEBERG BURNING ICE",
                        "field2": "Shower Gel",
                        "field3": "200ml",
                        "field4": "",
                        "description": "ICEBERG BURNING ICE Shower Gel 200ml",
                        "grammage": null,
                        "price": "1.95",
                        "price_label": "€ 1,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Igiene Persona",
                        "subcategory": "Corpo",
                        "blueprint": {
                            "top": 47.47280870121563,
                            "left": 72.35219902173395,
                            "width": 25.114459289686216,
                            "height": 24.541759923238885
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/iceberg_gel.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_Z4EEIBc_eoag2IH.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 440304,
                        "field1": "LAURA BIAGIOTTI",
                        "field2": "Roma Uomo",
                        "field3": "After Shave Lotion",
                        "field4": "75ml",
                        "description": "LAURA BIAGIOTTI Roma Uomo After Shave Lotion 75ml",
                        "grammage": null,
                        "price": "19.95",
                        "price_label": "€ 19,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Profumi",
                        "subcategory": "Uomo",  
                        "blueprint": {
                            "top": 23.864363403710833,
                            "left": 1.9614906747653127,
                            "width": 33.16686679981502,
                            "height": 48.214184747295185
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/laura_roma.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_6lbjJdX_BQJXt9o.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.laurabiagiotti.it/profumi/"
                            }
                        ]
                    },
                    {
                        "item_id": 435623,
                        "field1": "PLAYBOY DONNA",
                        "field2": "Eau de Toilette",
                        "field3": "Varie Fragranze",
                        "field4": "60ml",
                        "description": "PLAYBOY DONNA Eau de Toilette Varie Fragranze 60ml",
                        "grammage": null,
                        "price": "5.95",
                        "price_label": "€ 5,95",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Vip", "Queen of the Game", "Play It Sexy", "Play It Wild"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Profumi",
                        "subcategory": "Donna",
                        "blueprint": {
                            "top": 72.4888035828535,
                            "left": 2.685302585788116,
                            "width": 95.05278519226559,
                            "height": 24.60573944979038
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_27/products/playboy1.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/playboy2.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/playboy3.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/playboy4.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_27/products/cropped_image_ES2Ea6J_HAoDY28.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 4,
                "image_file": "/media/interactive_flyer_27/pages/fine.png",
                "products": []
            }
        ]
    }
    
    },
    "procasa": {
        "config": {
            "primary-color": "rgb(46, 163, 80)",  
            "secondary-color": "rgb(133, 37, 54)", 
            "tertiary-color": "rgb(255, 255, 255)", 
            "hover-color": "rgba(133, 37, 54, 0.3)", 
            "clientIcon": require("assets/images/clientLogo_procasa.png"),
            "serverPath": "https://procasa.volantinointerattivo.net",
            "pdfPath": "/media/interactive_flyer_29/volantino_procasa.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino ProCasa!",
            "shareFlyerURL": "https://procasa.volantinointerattivo.net",
            "shareProductSentence": "Guarda questa fantastica offerta di ProCasa!",
            "ogTitleMeta": "Volantino Interattivo ProCasa",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://procasa.volantinointerattivo.net/media/interactive_flyer_29/images/clientLogo_procasa.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": ""
        },
        "leaflet": {
            "name": "ProCasa",
            "code": "procasa23",
            "id_campaign": "3532",
            "index": {
                "image_file": "/media/interactive_flyer_29/pages/indice_procasa.png",
                "links": [
                    {   //RISCALDAMENTO
                        "page": 0,
                        "blueprint": {
                            "top": 21.90840974650746,
                            "left": 3.1666443941672977,
                            "width": 93.60431454570315,
                            "height": 10.024409700326947
                        }
                    },
                    {   //INVERNO2019
                        "page": 2,
                        "blueprint": {
                            "top": 35.54477338287109,
                            "left": 3.2571199482863635,
                            "width": 93.60431454570315,
                            "height": 9.896368727215552
                        }
                    },
                    {   //ARREDO CASA
                        "page": 2,
                        "blueprint": {
                            "top": 48.797014099900544,
                            "left": 3.1666443941672977,
                            "width": 93.60431454570315,
                            "height": 10.024409700326947
                        }
                    },
                    {   //ELETTRODOMESTICI
                        "page": 4,
                        "blueprint": {
                            "top": 62.43337773626417,
                            "left": 3.2571199482863635,
                            "width": 93.60431454570315,
                            "height": 10.024409700326947
                        }
                    },

                ]
            },
        "categories": [
            {
                "category_name": "Casa",
                "subcategories": [
                    "Riscaldamento"
                ]
            },
            {
                "category_name": "Arredo",
                "subcategories": [
                    "Casa",
                    "Bagno"
                ]
            },
            {
                "category_name": "Elettrodomestici",
                "subcategories": [
                    "Cucina",
                    "Giardino"
                ]
            }
        ],
        "pages": [
            {
                "number": 1,
                "image_file": "/media/interactive_flyer_29/pages/page_1.jpg",
                "products": [
                    {
                        "item_id": 725875,
                        "field1": "CAMINETTO A BIOETANOLO",
                        "field2": "-Rivestimento in legno",
                        "field3": "-Potenza max 3,5 kW/h",
                        "field4": "-Dimensioni: cm 91x32x86h ",
                        "description": "CAMINETTO A BIOETANOLO -Rivestimento in legno -Potenza max 3,5 kW/h -Dimensioni: cm 91x32x86h ",
                        "grammage": null,
                        "price": "279.00",
                        "price_label": "€ 279,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 51.10115630890378,
                            "left": 34.54761755561074,
                            "width": 30.814288399900672,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/17338_1571674927.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_HTW45eY.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                        ]
                    },
                    {
                        "item_id": 725876,
                        "field1": "STUFA AL CARBONIO",
                        "field2": "-Potenza: 1000 W",
                        "field3": "-Oscillante",
                        "field4": "-Altezza: cm 65",
                        "description": "STUFA AL CARBONIO -Potenza: 1000 W -Oscillante -Altezza: cm 65",
                        "grammage": null,
                        "price": "28.90",
                        "price_label": "€ 28,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 51.165135835455274,
                            "left": 66.38094044488594,
                            "width": 30.995241377656367,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/93040_1571675062.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_YMVirJY_RU68I9Y.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725874,
                        "field1": "BIDONE",
                        "field2": "ASPIRACENERE",
                        "field3": "\"ASHLEY\"",
                        "field4": "800w",
                        "description": "BIDONE ASPIRACENERE \"ASHLEY\" 800w",
                        "grammage": null,
                        "price": "27.90",
                        "price_label": "€ 27,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 72.47031817710594,
                            "left": 34.44273987100428,
                            "width": 30.995241377656367,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/91879_1571674998.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_U6gs6iO_gpiuptQ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725877,
                        "field1": "STUFA ALOGENA \"MAXI\"",
                        "field2": "-Potenza: 1200 W",
                        "field3": "-Oscillante",
                        "field4": "-Altezza: cm 62",
                        "description": "STUFA ALOGENA \"MAXI\" -Potenza: 1200 W -Oscillante -Altezza: cm 62",
                        "grammage": null,
                        "price": "19.90",
                        "price_label": "€ 19,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 72.47031817710594,
                            "left": 66.47141693376379,
                            "width": 30.995241377656367,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/90813_1571675130.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_6Yz1K0F_yzamwq2.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725873,
                        "field1": "STUFA A LEGNA",
                        "field2": "\"KIR\"",
                        "field3": "",
                        "field4": "",
                        "description": "STUFA A LEGNA \"KIR\"",
                        "grammage": null,
                        "price": "229.90",
                        "price_label": "€ 229,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 51.10115630890376,
                            "left": 2.5333416885798488,
                            "width": 30.995241377656367,
                            "height": 41.9435607027299
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/93383_1571675435.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_mRUkFHz_CktcmYy.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "STUFA A LEGNA “KIR” (COD. 93383)",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_29/images/kir.jpg"
                                    ],
                                    "specifiche": "Struttura in acciaio ad alto spessore, Camera di combustione con base in ghisa (removibile) e superfici perimetrali in mattone refrattario, Piastra cottura in ghisa sul top della stufa, Cassetto cenere estraibile, Vetro ceramico resistente fino a 1000 °C, Superficie riscaldabile 220 mc",
                                    
                                }
                            },
                        ]
                    }
                ]
            },
            {
                "number": 2,
                "image_file": "/media/interactive_flyer_29/pages/page_2.jpg",
                "products": [
                    {
                        "item_id": 725883,
                        "field1": "TERMOVENTILATORE \"PLUGGY\"",
                        "field2": "CON TELECOMANDO ",
                        "field3": "-Potenza: 500 W",
                        "field4": "-Due velocità, timer 12 ore",
                        "description": "TERMOVENTILATORE \"PLUGGY\" CON TELECOMANDO  -Potenza: 500 W -Due velocità, timer 12 ore",
                        "grammage": null,
                        "price": "16.90",
                        "price_label": "€ 16,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 8.490791625602414,
                            "left": 2.5333416885798488,
                            "width": 30.995241377656367,
                            "height": 41.9435607027299
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/96225_1571675280.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_zurM0vh_liBpdtu.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725878,
                        "field1": "TERMOVENTILATORE",
                        "field2": "DE LONGHI",
                        "field3": "1000/2000 W",
                        "field4": "",
                        "description": "TERMOVENTILATORE DE LONGHI 1000/2000 W",
                        "grammage": null,
                        "price": "24.90",
                        "price_label": "€ 24,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 8.490791625602414,
                            "left": 34.47154226246151,
                            "width": 30.995241377656367,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/91835_1571675351.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_nuNIzYA_6y8zE8M.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725881,
                        "field1": "STUFA A GAS METANO",
                        "field2": "ECO 42T",
                        "field3": "",
                        "field4": "",
                        "description": "STUFA A GAS METANO ECO 42T",
                        "grammage": null,
                        "price": "129.00",
                        "price_label": "€ 129,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 29.795973967253083,
                            "left": 34.56201875133936,
                            "width": 30.814288399900672,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/33056_1571674850.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_LQbxenj_pOAue04.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "STUFA A GAS METANO ECO 42T (COD. 33056)",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_29/images/eco42t.jpg"
                                    ],
                                    "specifiche": "Installabile a parete o a pavimento, Con sensore esaurimento ossigeno ed interruttore di sicurezza antiribaltamento, Potenza 4200 watt, Superficie riscaldabile 110 mc, Dimensioni: cm 43x15x64h, Gas metano/ventola tangenziale",
                                    
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 725885,
                        "field1": "STUFA A GAS METANO \"ECO40\"",
                        "field2": "-Potenza: 4200 W",
                        "field3": "-Volume riscaldabile: 110 mc",
                        "field4": "-Installabile a parete o a pavimento ",
                        "description": "STUFA A GAS METANO \"ECO40\" -Potenza: 4200 W -Volume riscaldabile: 110 mc -Installabile a parete o a pavimento ",
                        "grammage": null,
                        "price": "97.00",
                        "price_label": "€ 97,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 8.490791625602414,
                            "left": 66.40974283634317,
                            "width": 30.995241377656367,
                            "height": 41.9435607027299
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/33055_1571674796.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_6yuos0q_X1QIkvH.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725884,
                        "field1": "STUFA AD INFRAROSSI",
                        "field2": "CAMPINGAZ",
                        "field3": "-Con sensore esaurimento ossigeno ",
                        "field4": "-Dimensioni: cm 45x35x78h",
                        "description": "STUFA AD INFRAROSSI CAMPINGAZ -Con sensore esaurimento ossigeno  -Dimensioni: cm 45x35x78h",
                        "grammage": null,
                        "price": "87.90",
                        "price_label": "€ 87,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 51.16513583545527,
                            "left": 66.47141693376379,
                            "width": 30.995241377656367,
                            "height": 41.9435607027299
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/17761_1571675197.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_WFBQnfS_vMOznZw.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725879,
                        "field1": "CUCINA A LEGNA",
                        "field2": "\"PRITY MIA\"",
                        "field3": "",
                        "field4": "",
                        "description": "CUCINA A LEGNA \"PRITY MIA\"",
                        "grammage": null,
                        "price": "359.00",
                        "price_label": "€ 359,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 51.10115630890376,
                            "left": 2.4428651997019966,
                            "width": 30.995241377656367,
                            "height": 41.9435607027299
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/98588_1571674628.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_wR8DR6a_esVgHZ0.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725882,
                        "field1": "STUFA A GAS",
                        "field2": "ECO 40 GPL",
                        "field3": "",
                        "field4": "",
                        "description": "STUFA A GAS  ECO 40 GPL",
                        "grammage": null,
                        "price": "97.00",
                        "price_label": "€ 97,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 51.16513583545527,
                            "left": 34.38106577358366,
                            "width": 30.995241377656367,
                            "height": 20.574398834527717
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/33070_1571674736.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_lF4jywH_bo1VcS9.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725880,
                        "field1": "STUFA A LEGNA",
                        "field2": "TEA",
                        "field3": "",
                        "field4": "",
                        "description": "STUFA A LEGNA TEA",
                        "grammage": null,
                        "price": "499.00",
                        "price_label": "€ 499,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Casa",
                        "subcategory": "Riscaldamento",
                        "blueprint": {
                            "top": 72.40633865055443,
                            "left": 34.38106577358366,
                            "width": 30.995241377656367,
                            "height": 20.766337414182228
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/93154_1571674515.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_dY9n8on_AaY57JI.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "STUFA A LEGNA TEA (COD. 93154)",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_29/images/tea.jpg"
                                    ],
                                    "specifiche": "Rivestimento esterno in acciaio porcellanato, Porta basamento e testata in ghisa, Cassetto cenere estraibile, Vetro ceramico resistente fino a 750 °C, Superficie riscaldabile 189 mc",
                                    
                                }
                            },
                        ]
                    }
                ]
            },
            {
                "number": 3,
                "image_file": "/media/interactive_flyer_29/pages/page_3.jpg",
                "products": [
                    {
                        "item_id": 725886,
                        "field1": "TAVOLO IN METALLO",
                        "field2": "CON VETRO TEMPERATO",
                        "field3": "-Dimensioni: cm120x120x75",
                        "field4": "",
                        "description": "TAVOLO IN METALLO CON VETRO TEMPERATO - Dimensioni: cm120x120x75",
                        "grammage": null,
                        "price": "149.00",
                        "price_label": "€ 149,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Arredo",
                        "subcategory": "Casa",
                        "blueprint": {
                            "top": 8.490791625602414,
                            "left": 2.5333416885798488,
                            "width": 30.995241377656367,
                            "height": 20.63837836107922
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/95840_1571741696.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_ihbVYKA_zlMtths.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725892,
                        "field1": "DIVANO PRONTOLETTO",
                        "field2": "\"FIRENZE\" CON CONTENITORE",
                        "field3": "-Dimensioni divano: cm225x86x88h",
                        "field4": "-Dimensioni letto: cm190x110",
                        "description": "DIVANO PRONTOLETTO \"FIRENZE\" CON CONTENITORE -Dimensioni divano: cm225x86x88h -Dimensioni letto: cm190x110",
                        "grammage": null,
                        "price": "289.00",
                        "price_label": "€ 289,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Arredo",
                        "subcategory": "Casa",
                        "blueprint": {
                            "top": 8.42681209905091,
                            "left": 34.47154226246151,
                            "width": 62.93344195153803,
                            "height": 20.63837836107922
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/92506_1571741783.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_nJf5Rw4_jZzakEW.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725894,
                        "field1": "KIT SCARPIERA",
                        "field2": "\"TWICE\" CILIEGIO",
                        "field3": "-Dimensioni:",
                        "field4": "cm 60x24x110h",
                        "description": "KIT SCARPIERA \"TWICE\" CILIEGIO -Dimensioni: cm 60x24x110h",
                        "grammage": null,
                        "price": "49.90",
                        "price_label": "€ 49,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Arredo",
                        "subcategory": "Casa",
                        "blueprint": {
                            "top": 29.795973967253083,
                            "left": 34.47154226246151,
                            "width": 30.995241377656367,
                            "height": 20.63837836107922
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/14554_1571741885.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_ZNIjUNh_y19CwlN.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725887,
                        "field1": "MOBILE BAGNO",
                        "field2": "\"CLAUDIA\"",
                        "field3": "",
                        "field4": "",
                        "description": "MOBILE BAGNO \"CLAUDIA\"",
                        "grammage": null,
                        "price": "210.00",
                        "price_label": "€ 210,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Arredo",
                        "subcategory": "Bagno",
                        "blueprint": {
                            "top": 29.85995349380459,
                            "left": 66.47141693376379,
                            "width": 30.995241377656367,
                            "height": 20.63837836107922
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/93826_1571742008.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_YZSomk6_MpDrIJL.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "MOBILE BAGNO “CLAUDIA” COMPLETO (COD. 93826)",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_29/images/claudia.jpg"
                                    ],
                                    "specifiche": "Mobile composto da: 2 ante/3 cassetti, In MDF ricoperto di PVC/finitura bianco portuna, Completo di specchio con illuminazione e lavabo integrale (rubinetto escluso), Dimensioni base: cm 82x35x84h, Dimensioni specchio: cm 68x80h",
                                    
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 725895,
                        "field1": "KIT INGRESSO \"NOON\"",
                        "field2": "-Mensola con cassetto (reversibile)",
                        "field3": "-Dimensioni: cm 95x26x19h",
                        "field4": "-Specchio con cornice cm 95x50h ",
                        "description": "KIT INGRESSO \"NOON\" -Mensola con cassetto (reversibile) -Dimensioni: cm 95x26x19h -Specchio con cornice cm 95x50h ",
                        "grammage": null,
                        "price": "78.00",
                        "price_label": "€ 78,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Arredo",
                        "subcategory": "Casa",
                        "blueprint": {
                            "top": 29.85995349380459,
                            "left": 2.4140628082447697,
                            "width": 30.995241377656367,
                            "height": 41.9435607027299
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/97244_1571741830.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_hHpWqGZ_ltGxNoV.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725889,
                        "field1": "MOBILE BAGNO",
                        "field2": "\"ANTONELLA\"",
                        "field3": "",
                        "field4": "",
                        "description": "MOBILE BAGNO \"ANTONELLA\"",
                        "grammage": null,
                        "price": "189.00",
                        "price_label": "€ 189,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Arredo",
                        "subcategory": "Bagno",
                        "blueprint": {
                            "top": 51.16513583545527,
                            "left": 34.533216359882125,
                            "width": 30.995241377656367,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/16891_1571741961.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_9jxXwQh_iccT3Pq.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "MOBILE BAGNO “ANTONELLA” COMPLETO (COD. 16891)",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_29/images/antonella.jpg"
                                    ],
                                    "specifiche": "Mobile composto da: 2 ante, In MDF ricoperto di PVC/finitura bianco portuna, Completo di specchio con illuminazione e lavabo integrale (rubinetto escluso), Dimensioni base: cm 82x35x84h, Dimensioni specchio: cm 68x80h",
                                    
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 725893,
                        "field1": "BOX DOCCIA BRIXO",
                        "field2": "-Dimensioni:",
                        "field3": "cm 70x90x185h",
                        "field4": "",
                        "description": "BOX DOCCIA BRIXO -Dimensioni: cm 70x90x185h",
                        "grammage": null,
                        "price": "129.90",
                        "price_label": "€ 129,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Arredo",
                        "subcategory": "Bagno",
                        "blueprint": {
                            "top": 51.16513583545527,
                            "left": 66.47141693376379,
                            "width": 30.995241377656367,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/443579_1571742057.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_gYLLPws_SfAY7vq.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725888,
                        "field1": "BILANCIA PESAPERSONE",
                        "field2": "BLACK+DECKER",
                        "field3": "",
                        "field4": "",
                        "description": "BILANCIA PESAPERSONE BLACK+DECKER",
                        "grammage": null,
                        "price": "16.90",
                        "price_label": "€ 16,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Arredo",
                        "subcategory": "Casa",
                        "blueprint": {
                            "top": 72.47031817710594,
                            "left": 2.504539297122622,
                            "width": 30.995241377656367,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/30719_1571742101.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_huNO2AA_QGTdVCC.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725890,
                        "field1": "PIASTRA STIRANTE BEAUTY ",
                        "field2": "CON TORMALINA",
                        "field3": "",
                        "field4": "",
                        "description": "PIASTRA STIRANTE BEAUTY  CON TORMALINA",
                        "grammage": null,
                        "price": "14.90",
                        "price_label": "€ 14,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Persona",
                        "blueprint": {
                            "top": 72.53429770365744,
                            "left": 34.44273987100428,
                            "width": 30.995241377656367,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/656_1571742134.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_T6Y3IRU_GBrTmmI.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725891,
                        "field1": "ASCIUGACAPELLI 2000 W",
                        "field2": "-2 velocità",
                        "field3": "-3 livelli di temperatura",
                        "field4": "",
                        "description": "ASCIUGACAPELLI 2000 W -2 velocità -3 livelli di temperatura",
                        "grammage": null,
                        "price": "17.90",
                        "price_label": "€ 17,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Persona",
                        "blueprint": {
                            "top": 72.53429770365744,
                            "left": 66.56189342264163,
                            "width": 30.995241377656367,
                            "height": 20.702357887630725
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/97278_1571742180.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_39dWWNR_eARu13q.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 4,
                "image_file": "/media/interactive_flyer_29/pages/page_4.jpg",
                "products": [
                    {
                        "item_id": 725896,
                        "field1": "IMPASTATORE FERRARI",
                        "field2": "1000 W",
                        "field3": "",
                        "field4": "",
                        "description": "IMPASTATORE FERRARI 1000 W",
                        "grammage": null,
                        "price": "129.90",
                        "price_label": "€ 129,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 8.554771152153917,
                            "left": 2.4140628082447595,
                            "width": 30.995241377656367,
                            "height": 20.51041930797621
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/g20076_1571826333.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_u85XEsJ_xK2ax29.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "IMPASTATORE FERRARI 1000 W (COD. 20076)",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_29/images/ferrari.jpg"
                                    ],
                                    "specifiche": "8 velocità di funzionamento, Potenza 1000-1200 W, Forza diretta: motore a presa diretta senza cinghie/per massima efficienza ed affidabilità, Contenitore 5.2 litri in acciaio inox con coperchio, Possibilità di lavorare impasti di max 2.5Kg ( a seconda degli alimenti da impastare), Piedini con ventosa per maggiore stabilità, Dispositivo di sicurezza che impedisce uso involontario, Corpo in metallo e design elegante, Accessori inclusi: 1 uncino impastatore / 1 mescolatore in alluminio / 1 frusta sbattitrice in acciaio inox / 1 coperchio in plastica trasparente",
                                    
                                }
                            },

                        ]
                    },
                    {
                        "item_id": 725904,
                        "field1": "FRIGGITRICE AD ARIA CALDA",
                        "field2": "-Potenza: 1400 W",
                        "field3": "-Capacità di cottura max: 1 Kg",
                        "field4": "-Timer da 30 min",
                        "description": "FRIGGITRICE AD ARIA CALDA -Potenza: 1400 W -Capacità di cottura max: 1 Kg -Timer da 30 min",
                        "grammage": null,
                        "price": "69.90",
                        "price_label": "€ 69,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 8.490791625602414,
                            "left": 34.533216359882125,
                            "width": 62.842965462660175,
                            "height": 20.51041930797621
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/ILAZ_1571823888.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_PnLgBSE_A60g7yR.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725900,
                        "field1": "CREPIERA 1200 W",
                        "field2": "-Dimensioni piastra:",
                        "field3": "diam. 30 cm ",
                        "field4": "",
                        "description": "CREPIERA 1200 W -Dimensioni piastra: diam. 30 cm ",
                        "grammage": null,
                        "price": "34.90",
                        "price_label": "€ 34,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 29.795973967253083,
                            "left": 34.442739871004264,
                            "width": 30.995241377656367,
                            "height": 20.51041930797621
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/g1009800_1571823924.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_ivt4CU6_2g7xBX7.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725897,
                        "field1": "TRITATUTTO ",
                        "field2": "MULTIFUNZIONE 500 W",
                        "field3": "",
                        "field4": "",
                        "description": "TRITATUTTO  MULTIFUNZIONE 500 W",
                        "grammage": null,
                        "price": "37.90",
                        "price_label": "€ 37,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 29.85995349380459,
                            "left": 66.38094044488591,
                            "width": 30.995241377656367,
                            "height": 20.51041930797621
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/g2008100_1571826239.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_DHSGqD2_EfoqBeQ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725905,
                        "field1": "ESTRATTORE DI SUCCO",
                        "field2": "-Potenza: 120 W",
                        "field3": "-Fino al 30% di succo estratto ",
                        "field4": "-Ricettario incluso",
                        "description": "ESTRATTORE DI SUCCO -Potenza: 120 W -Fino al 30% di succo estratto  -Ricettario incluso",
                        "grammage": null,
                        "price": "78.90",
                        "price_label": "€ 78,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 29.85995349380459,
                            "left": 2.4428651997019966,
                            "width": 30.995241377656367,
                            "height": 41.9435607027299
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/g2007200_1571826183.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_QaHjXAE_Re1foQQ.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725898,
                        "field1": "GRATTUGIA E",
                        "field2": "AFFETTAVERDURE",
                        "field3": "",
                        "field4": "",
                        "description": "GRATTUGIA E AFFETTAVERDURE",
                        "grammage": null,
                        "price": "39.90",
                        "price_label": "€ 39,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 51.16513583545527,
                            "left": 34.47154226246151,
                            "width": 30.995241377656367,
                            "height": 20.574398834527717
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/g20077_1571826277.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_kkrqh7x_27Am5ve.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725899,
                        "field1": "ARMADIO TERRY",
                        "field2": "cm 68x38x85h",
                        "field3": "",
                        "field4": "",
                        "description": "ARMADIO TERRY cm 68x38x85h",
                        "grammage": null,
                        "price": "29.90",
                        "price_label": "€ 29,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Arredo",
                        "subcategory": "Casa",
                        "blueprint": {
                            "top": 51.16513583545527,
                            "left": 66.50021932522102,
                            "width": 30.995241377656367,
                            "height": 20.574398834527717
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/93617_1571823824.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_is0tUOO_C03yrdD.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725901,
                        "field1": "CASSAPANCA",
                        "field2": "TERRY",
                        "field3": "cm 120x54x57h",
                        "field4": "",
                        "description": "CASSAPANCA TERRY cm 120x54x57h",
                        "grammage": null,
                        "price": "49.90",
                        "price_label": "€ 49,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Arredo",
                        "subcategory": "Casa",
                        "blueprint": {
                            "top": 72.47031817710594,
                            "left": 2.5333416885798585,
                            "width": 30.995241377656367,
                            "height": 20.574398834527717
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/7665_1571823783.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_IL4XBXL_5yBJtQL.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 725903,
                        "field1": "TOSAERBA ",
                        "field2": " MOD. M53-174WRE",
                        "field3": "",
                        "field4": "",
                        "description": "TOSAERBA   MOD. M53-174WRE",
                        "grammage": null,
                        "price": "399.00",
                        "price_label": "€ 399,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Giardino",
                        "blueprint": {
                            "top": 72.53429770365744,
                            "left": 34.47154226246151,
                            "width": 30.995241377656367,
                            "height": 20.574398834527717
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/94137_1571827287.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_xYDwjCo_lMg3Zbq.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "TOSAERBA MOD. M53-174WRE (COD. 94137)",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_29/images/tosaerba.jpg"
                                    ],
                                    "specifiche": "Cilindrata 174 CC, Motore McCulloch 174 quattro tempi e INSTART, Larghezza taglio 53 cm, Altezza taglio fino a 90 mm, Cesto raccoglierba in tela da 55 l, Per superfici fino a 2000 mq, Funzione Mulching, Sistema di taglio 3 in 1, Avviamento elettrico, Manico pieghevole",
                                    
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 725902,
                        "field1": "TOSAERBA ",
                        "field2": "MOD. M53-150AP",
                        "field3": "",
                        "field4": "",
                        "description": "TOSAERBA  MOD. M53-150AP",
                        "grammage": null,
                        "price": "549.00",
                        "price_label": "€ 549,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": 0,
                        "max_purchasable_pieces": 0,
                        "points": "0",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Giardino",
                        "blueprint": {
                            "top": 72.53429770365744,
                            "left": 66.50021932522102,
                            "width": 30.995241377656367,
                            "height": 20.574398834527717
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_29/products/94138_1571827329.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_29/products/cropped_image_aiWbaZS_4wy5hcS.png",
                                "cropped": true
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 5,
                "image_file": "/media/interactive_flyer_29/pages/fine_pagina_procasa.png",
                "products": []
            }
        ]
    }
    
    },
    "euronicsmpv": {
        "config": {
            "primary-color": "rgb(2, 64, 137)",  
            "secondary-color": "rgb(254, 201, 0)", 
            "tertiary-color": "rgb(255, 255, 255)", 
            "hover-color": "rgba(254, 201, 0, 0.5)", 
            "clientIcon": require("assets/images/clientLogo_euronics.png"),
            "serverPath": "https://euronicsmpv.volantinointerattivo.net",
            "pdfPath": "/media/interactive_flyer_31/volantino_euronics.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino Euronics!",
            "shareFlyerURL": "https://euronicsmpv.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Euronics!",
            "ogTitleMeta": "Volantino Interattivo Euronics",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://euronicsmpv.volantinointerattivo.net/media/interactive_flyer_31/images/clientLogo_euronics.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": ""
        },
        "leaflet": {
            "name": "Euronicsmpv",
            "code": "euronics325",
            "id_campaign": "12345",
            "index": {
                "image_file": "/media/interactive_flyer_31/pages/euronics_indice.png",
                "links": [
                    {   //MAGNIFICI10
                        "page": 2,
                        "blueprint": {
                            "top": 18.041242976323296,
                            "left": 3.662079542071665,
                            "width": 92.36423601945901,
                            "height": 11.46838066378143
                        }
                    },
                    {   //TV
                        "page": 2,
                        "blueprint": {
                            "top": 30.626020670370018,
                            "left": 3.662079542071665,
                            "width": 92.45454702942426,
                            "height": 11.242306812990172
                        }
                    },
                    {   //NOTEBOOK
                        "page": 4,
                        "blueprint": {
                            "top": 42.90936656336172,
                            "left": 3.662079542071665,
                            "width": 92.36423601945901,
                            "height": 11.317664763253925
                        }
                    },
                    {   //SMARTPHONE
                        "page": 4,
                        "blueprint": {
                            "top": 55.343428356880935,
                            "left": 3.7523905520368768,
                            "width": 92.36423601945901,
                            "height": 11.317664763253925
                        }
                    },
                    {   //ELETTRODOMESTICI
                        "page": 6,
                        "blueprint": {
                            "top": 67.70213220013639,
                            "left": 3.7523905520368768,
                            "width": 92.36423601945901,
                            "height": 11.317664763253925
                        }
                    },
                    {   //SEDI
                        "page": 8,
                        "blueprint": {
                            "top": 80.21155194391936,
                            "left": 3.7523905520368768,
                            "width": 92.36423601945901,
                            "height": 11.317664763253925
                        }
                    },
                    
    
                ]
            },
        "categories": [
            {
                "category_name": "Elettrodomestici",
                "subcategories": [
                    "Asciugatrici",
                    "Climatizzatori",
                    "Cucina",
                    "Cura Persona",
                    "Ferri da Stiro",
                    "Frigoriferi",
                    "Lavatrici",
                    "Pulizia Casa"
                ]
            },
            {
                "category_name": "Games",
                "subcategories": [
                    "Console"
                ]
            },
            {
                "category_name": "Informatica",
                "subcategories": [
                    "Notebook",
                    "Tablet"
                ]
            },
            {
                "category_name": "Smartphone",
                "subcategories": [
                    "Huawei",
                    "Samsung"
                ]
            },
            {
                "category_name": "Tv",
                "subcategories": [
                    "LG",
                    "Nordmende",
                    "Samsung",
                    "Sony"
                ]
            }
        ],
        "pages": [
            {
                "number": 1,
                "image_file": "/media/interactive_flyer_31/pages/page_1.jpg",
                "products": []
            },
            {
                "number": 2,
                "image_file": "/media/interactive_flyer_31/pages/page_2.jpg",
                "products": [
                    {
                        "item_id": 500069,
                        "field1": "TCL",
                        "field2": "Climatizzatore - TAC - 09CHSD82",
                        "field3": "",
                        "field4": "",
                        "description": "TCL Climatizzatore - TAC - 09CHSD82",
                        "grammage": null,
                        "price": "399.00",
                        "price_label": "€399,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Climatizzatori",
                        "blueprint": {
                            "top": 9.510792391916892,
                            "left": 7.095387028579232,
                            "width": 54.92450867350205,
                            "height": 14.010770370417536
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_gCMHgkx.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/tcl.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "promo",
                                "title": "OFFERTA 99EURO",
                                "data": {
                                    "titolo": "OFFERTA I MAGNIFICI 10",
                                    "testo": "I MAGNIFICI DIECI, PROMOZIONE VALIDA DAL 24/10 AL 6/11. TUO A 99€ CON L'ACQUISTO DI UN PRODOTTO DI ALMENO 599€ ( SALVO ESAURIMENTO SCORTE )",
                                    "img": [
                                        "/media/interactive_flyer_31/images/promo.jpg"
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 550873,
                        "field1": "SAMSUNG",
                        "field2": "Smartphone - Galaxy A10",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG Smartphone - Galaxy A10",
                        "grammage": null,
                        "price": "159.00",
                        "price_label": "€ 159,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 23.50627771923066,
                            "left": 7.095387028579232,
                            "width": 28.735713328155693,
                            "height": 19.578866683434843
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_EJn9CnP_wDFIdaI.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/a10.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "promo",
                                "title": "OFFERTA 99EURO",
                                "data": {
                                    "titolo": "OFFERTA I MAGNIFICI 10",
                                    "testo": "I MAGNIFICI DIECI, PROMOZIONE VALIDA DAL 24/10 AL 6/11. TUO A 99€ CON L'ACQUISTO DI UN PRODOTTO DI ALMENO 599€ ( SALVO ESAURIMENTO SCORTE )",
                                    "img": [
                                        "/media/interactive_flyer_31/images/promo.jpg"
                                    ]
                                }
                            },


                        ],
                        "related": [590863]
                    },
                    {
                        "item_id": 590863,
                        "field1": "SAMSUNG",
                        "field2": "Smartwatch - Galaxy Watch Active",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG Smartwatch - Galaxy Watch Active",
                        "grammage": null,
                        "price": "229.00",
                        "price_label": "€ 229,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 23.43103317446016,
                            "left": 36.35459286269033,
                            "width": 28.735713328155693,
                            "height": 19.578866683434843
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_XZWAy3I_M7QTAKB.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/galaxywatch.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "promo",
                                "title": "OFFERTA 99EURO",
                                "data": {
                                    "titolo": "OFFERTA I MAGNIFICI 10",
                                    "testo": "I MAGNIFICI DIECI, PROMOZIONE VALIDA DAL 24/10 AL 6/11. TUO A 99€ CON L'ACQUISTO DI UN PRODOTTO DI ALMENO 599€ ( SALVO ESAURIMENTO SCORTE )",
                                    "img": [
                                        "/media/interactive_flyer_31/images/promo.jpg"
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 555973,
                        "field1": "SMEG",
                        "field2": "Lavatrice - LBW508CTF",
                        "field3": "",
                        "field4": "",
                        "description": "SMEG Lavatrice - LBW508CTF",
                        "grammage": null,
                        "price": "239.00",
                        "price_label": "€ 239,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Lavatrici",
                        "blueprint": {
                            "top": 22.076631368591084,
                            "left": 66.06532965103153,
                            "width": 28.735713328155693,
                            "height": 20.78277939976291
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_eQBiNzz_XOz3Ia3.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/smeglavatrice.png",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "SMEG - LBW508CTF - Bianco",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_31/images/smeglavatrice.png"
                                    ],
                                    "specifiche": "Carica frontale, Profondità 400 mm, Capacità 5 Kg, Classe energetica A++, Max 800 Giri, Con display, Con giri centrifuga regolabili",
                                    
                                }
                            },
                            {
                                "type": "promo",
                                "title": "OFFERTA 99EURO",
                                "data": {
                                    "titolo": "OFFERTA I MAGNIFICI 10",
                                    "testo": "I MAGNIFICI DIECI, PROMOZIONE VALIDA DAL 24/10 AL 6/11. TUO A 99€ CON L'ACQUISTO DI UN PRODOTTO DI ALMENO 599€ ( SALVO ESAURIMENTO SCORTE )",
                                    "img": [
                                        "/media/interactive_flyer_31/images/promo.jpg"
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 504460,
                        "field1": "DE'LONGHI",
                        "field2": "Aspirapolvere Senza Filo",
                        "field3": "Multifunzione XLM21LE1.GYR",
                        "field4": "",
                        "description": "DE'LONGHI Aspirapolvere Senza Filo Multifunzione XLM21LE1.GYR",
                        "grammage": null,
                        "price": "239.90",
                        "price_label": "€ 239,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Pulizia Casa",
                        "blueprint": {
                            "top": 42.919370270020735,
                            "left": 7.095387028579232,
                            "width": 28.013263801387513,
                            "height": 20.78277939976291
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_QHgfDJo_p0YXsak.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/colombina.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.delonghi.com/it-it/prodotti/homecare/aspirapolveri/scope-elettriche-ricaricabili"
                            },
                            {
                                "type": "promo",
                                "title": "OFFERTA 99EURO",
                                "data": {
                                    "titolo": "OFFERTA I MAGNIFICI 10",
                                    "testo": "I MAGNIFICI DIECI, PROMOZIONE VALIDA DAL 24/10 AL 6/11. TUO A 99€ CON L'ACQUISTO DI UN PRODOTTO DI ALMENO 599€ ( SALVO ESAURIMENTO SCORTE )",
                                    "img": [
                                        "/media/interactive_flyer_31/images/promo.jpg"
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 544559,
                        "field1": "ROWENTA",
                        "field2": "Sistema Stirante - Compact Steam Pro",
                        "field3": "DG7644",
                        "field4": "",
                        "description": "ROWENTA Sistema Stirante - Compact Steam Pro DG7644",
                        "grammage": null,
                        "price": "239.90",
                        "price_label": "€ 239,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Ferri da Stiro",
                        "blueprint": {
                            "top": 42.84412572525023,
                            "left": 34.90969380915398,
                            "width": 31.083674290152263,
                            "height": 20.78277939976291
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_reEU07H_Eb71FkJ.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/rowenta.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "promo",
                                "title": "OFFERTA 99EURO",
                                "data": {
                                    "titolo": "OFFERTA I MAGNIFICI 10",
                                    "testo": "I MAGNIFICI DIECI, PROMOZIONE VALIDA DAL 24/10 AL 6/11. TUO A 99€ CON L'ACQUISTO DI UN PRODOTTO DI ALMENO 599€ ( SALVO ESAURIMENTO SCORTE )",
                                    "img": [
                                        "/media/interactive_flyer_31/images/promo.jpg"
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 568353,
                        "field1": "IROBOT",
                        "field2": "Robot Aspirapolvere",
                        "field3": "Roomba 604",
                        "field4": "",
                        "description": "IROBOT Robot Aspirapolvere Roomba 604",
                        "grammage": null,
                        "price": "299.90",
                        "price_label": "€ 299,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Pulizia Casa",
                        "blueprint": {
                            "top": 42.84412572525023,
                            "left": 65.79441107849347,
                            "width": 28.916325709847733,
                            "height": 20.78277939976291
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_hNWam1w_5VFcsWs.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/irobot.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "VIDEO - iRobot",
                                "data": "/media/interactive_flyer_31/video/irobot.mp4"
                            },
                            {
                                "type": "promo",
                                "title": "OFFERTA 99EURO",
                                "data": {
                                    "titolo": "OFFERTA I MAGNIFICI 10",
                                    "testo": "I MAGNIFICI DIECI, PROMOZIONE VALIDA DAL 24/10 AL 6/11. TUO A 99€ CON L'ACQUISTO DI UN PRODOTTO DI ALMENO 599€ ( SALVO ESAURIMENTO SCORTE )",
                                    "img": [
                                        "/media/interactive_flyer_31/images/promo.jpg"
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 568437,
                        "field1": "NORDMENDE",
                        "field2": "ND32S3300J",
                        "field3": "32\"",
                        "field4": "",
                        "description": "NORDMENDE ND32S3300J 32\"",
                        "grammage": null,
                        "price": "249.00",
                        "price_label": "€ 249,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Nordmende",
                        "blueprint": {
                            "top": 63.76210917145039,
                            "left": 7.1856932194252545,
                            "width": 28.013263801387513,
                            "height": 19.353133049123333
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_Iqr72Xc_nvBKSTS.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/nordmende.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "promo",
                                "title": "OFFERTA 99EURO",
                                "data": {
                                    "titolo": "OFFERTA I MAGNIFICI 10",
                                    "testo": "I MAGNIFICI DIECI, PROMOZIONE VALIDA DAL 24/10 AL 6/11. TUO A 99€ CON L'ACQUISTO DI UN PRODOTTO DI ALMENO 599€ ( SALVO ESAURIMENTO SCORTE )",
                                    "img": [
                                        "/media/interactive_flyer_31/images/promo.jpg"
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 568932,
                        "field1": "HP",
                        "field2": "Notebook - HP Stream 14",
                        "field3": "DS0013NL",
                        "field4": "",
                        "description": "HP Notebook - HP Stream 14 DS0013NL",
                        "grammage": null,
                        "price": "299.00",
                        "price_label": "€299,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 63.76210917145039,
                            "left": 35.451530954230115,
                            "width": 30.45153095423011,
                            "height": 19.353133049123333
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_15q7c4z_J0w3ThC.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/hpstream.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "promo",
                                "title": "OFFERTA 99EURO",
                                "data": {
                                    "titolo": "OFFERTA I MAGNIFICI 10",
                                    "testo": "I MAGNIFICI DIECI, PROMOZIONE VALIDA DAL 24/10 AL 6/11. TUO A 99€ CON L'ACQUISTO DI UN PRODOTTO DI ALMENO 599€ ( SALVO ESAURIMENTO SCORTE )",
                                    "img": [
                                        "/media/interactive_flyer_31/images/promo.jpg"
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 591460,
                        "field1": "SONY",
                        "field2": "PS4 500GB",
                        "field3": "F BLACK",
                        "field4": "",
                        "description": "SONY PS4 500GB F BLACK",
                        "grammage": null,
                        "price": "299.99",
                        "price_label": "€ 299,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Console",
                        "blueprint": {
                            "top": 63.61162008190937,
                            "left": 65.61379869680142,
                            "width": 29.00663190069376,
                            "height": 19.353133049123333
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_NUAjO9e_1QOTfEH.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/ps4.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "promo",
                                "title": "OFFERTA 99EURO",
                                "data": {
                                    "titolo": "OFFERTA I MAGNIFICI 10",
                                    "testo": "I MAGNIFICI DIECI, PROMOZIONE VALIDA DAL 24/10 AL 6/11. TUO A 99€ CON L'ACQUISTO DI UN PRODOTTO DI ALMENO 599€ ( SALVO ESAURIMENTO SCORTE )",
                                    "img": [
                                        "/media/interactive_flyer_31/images/promo.jpg"
                                    ]
                                }
                            },
                        ]
                    }
                ]
            },
            {
                "number": 3,
                "image_file": "/media/interactive_flyer_31/pages/page_3.jpg",
                "products": [
                    {
                        "item_id": 544260,
                        "field1": "SAMSUNG",
                        "field2": "Smart Tv Led - UE55RU7400UXZT",
                        "field3": "",
                        "field4": "",
                        "description": "",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 1.9110933700959736,
                            "left": 0.0,
                            "width": 33.792860015532916,
                            "height": 30.113102951305425
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_Mi917R9_wsUFlaQ.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/ue55ru7400uxzt.jpg",
                                "cropped": false
                            },
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""          
                            },
                            {
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_31/video/55RU7400.mp4"
                            },
                        ],
                        "related": [567133, 534353, 587643]
                    },
                    {
                        "item_id": 567133,
                        "field1": "SAMSUNG",
                        "field2": "Smart Tv Qled",
                        "field3": "QE55Q60RATXTZT",
                        "field4": "",
                        "description": "SAMSUNG Smart Tv Qled QE55Q60RATXTZT",
                        "grammage": null,
                        "price": "749.00",
                        "price_label": "€ 749,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 2.1368270044074857,
                            "left": 33.32298442218208,
                            "width": 32.79949191622668,
                            "height": 30.790303854239962
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_2rRFYYq_WPVtClW.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/qe55q60ratxtzt.jpg",
                                "cropped": false
                            }
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""  
                            },
                            {
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_31/video/55Q60RA.mp4"
                            },
                        ],
                        "related": [544260, 534353, 587643]
                    },
                    {
                        "item_id": 534353,
                        "field1": "SAMSUNG",
                        "field2": "Smart Tv Led ",
                        "field3": "UE50RU7170UXZT",
                        "field4": "",
                        "description": "SAMSUNG Smart Tv Led  UE50RU7170UXZT",
                        "grammage": null,
                        "price": "399.00",
                        "price_label": "€ 399,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 1.8358488253254694,
                            "left": 65.92351931759599,
                            "width": 32.79949191622668,
                            "height": 30.790303854239962
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_XakCoLc_aG6FVvb.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/ue50ru7170uxzt.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""      
                            },
                            {
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_31/video/50RU7170.mp4"
                            },
                        ],
                        "related": [544260, 567133, 587643]
                    },
                    {
                        "item_id": 598980,
                        "field1": "SONY",
                        "field2": "Android Tv Led",
                        "field3": "KD - 65XG81",
                        "field4": "",
                        "description": "SONY Android Tv Led KD - 65XG81",
                        "grammage": null,
                        "price": "899.00",
                        "price_label": "899,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Sony",
                        "blueprint": {
                            "top": 32.309889457379654,
                            "left": 1.1739804809982846,
                            "width": 32.79949191622668,
                            "height": 31.4675047571745
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_HOCgQE2_r3njyTD.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/sonyandroid.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [598318]
                    },
                    {
                        "item_id": 598318,
                        "field1": "SONY",
                        "field2": "Smart Tv Led",
                        "field3": "KD - 55XG7096BAEP",
                        "field4": "",
                        "description": "SONY Smart Tv Led KD - 55XG7096BAEP",
                        "grammage": null,
                        "price": "629.00",
                        "price_label": "€ 629,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Sony",
                        "blueprint": {
                            "top": 32.535623091691164,
                            "left": 33.4132906130281,
                            "width": 32.79949191622668,
                            "height": 30.564570219928445
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_ZiahHYq_UhwRUy2.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/sonykd55.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [598980]
                    },
                    {
                        "item_id": 587643,
                        "field1": "SAMSUNG",
                        "field2": "Smart Tv Led",
                        "field3": "UE43RU7170UXZT",
                        "field4": "",
                        "description": "SAMSUNG Smart Tv Led UE43RU7170UXZT",
                        "grammage": null,
                        "price": "379.00",
                        "price_label": "€ 379,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 32.68611218123217,
                            "left": 65.65260074505792,
                            "width": 32.79949191622668,
                            "height": 30.564570219928445
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_nZQbopx_b3sajih.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/ue43ru71.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""          
                            },
                            {
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_31/video/43RU7170.mp4"
                            },
                        ],
                        "related": [544260, 567133, 534353]
                    },
                    {
                        "item_id": 598130,
                        "field1": "LG OLED TV 2019",
                        "field2": "",
                        "field3": "",
                        "field4": "",
                        "description": "LG OLED TV 2019",
                        "grammage": null,
                        "price": "2699.00",
                        "price_label": "€ 2699,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "LG",
                        "blueprint": {
                            "top": 64.36406552961441,
                            "left": 2.7091857253806566,
                            "width": 30.0,
                            "height": 27.329054794796768
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_zUnMOIQ_VZM9QLg.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lg65c9.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [587089, 597647]
                    },
                    {
                        "item_id": 587089,
                        "field1": "LG OLED TV 2019",
                        "field2": "Mod. OLED65B9",
                        "field3": "",
                        "field4": "",
                        "description": "LG OLED TV 2019 Mod. OLED65B0",
                        "grammage": null,
                        "price": "2199.00",
                        "price_label": "€ 2199,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "LG",
                        "blueprint": {
                            "top": 64.36406552961441,
                            "left": 34.94849585741048,
                            "width": 30.0,
                            "height": 27.329054794796768
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_BDL7XBR_dXkFFEL.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lg65b9.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [598130, 597647]
                    },
                    {
                        "item_id": 597647,
                        "field1": "LG NANOCELL",
                        "field2": "Mod. 65SM8200PLA",
                        "field3": "",
                        "field4": "",
                        "description": "LG NANOCELL Mod. 65SM8200PLA",
                        "grammage": null,
                        "price": "899.00",
                        "price_label": "€ 899,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Tv",
                        "subcategory": "LG",
                        "blueprint": {
                            "top": 64.28882098484391,
                            "left": 67.1102018929273,
                            "width": 30.0,
                            "height": 27.329054794796768
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_JDqv027_i17NMRc.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lgnanocell.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [598130, 587089]
                    }
                ]
            },
            {
                "number": 4,
                "image_file": "/media/interactive_flyer_31/pages/page_4.jpg",
                "products": [
                    {
                        "item_id": 596893,
                        "field1": "ACER",
                        "field2": "Notebook Aspire 3",
                        "field3": "A315-54-32QP",
                        "field4": "",
                        "description": "ACER Notebook Aspire 3 A315-54-32QP",
                        "grammage": null,
                        "price": "399.99",
                        "price_label": "€ 399,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 13.724486899065123,
                            "left": 2.3606630563295843,
                            "width": 30.0,
                            "height": 24.99647390691114
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_VONS21S_mjPqxAv.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/acera315.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "ACER - A315-54-32QP - Nero",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_31/images/a315.jpg"
                                    ],
                                    "specifiche": "15,6 pollici, Processore Intel Core i3, RAM 8 Gb, SSD 256 GB, Scheda grafica Intel, Sistema operativo Windows 10, Peso 1,9 Kg",
                                    
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 595038,
                        "field1": "ACER",
                        "field2": "Notebook - Swift 3",
                        "field3": "SF314-41-R2XF",
                        "field4": "",
                        "description": "ACER Notebook - Swift 3 SF314-41-R2XF",
                        "grammage": null,
                        "price": "549.99",
                        "price_label": "€ 549,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 13.799731443835629,
                            "left": 34.87089176089747,
                            "width": 30.0,
                            "height": 24.99647390691114
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_GnIbNFD_hOQTMuS.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/acersf314.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "ACER - SF314-41-R2XF - Silver",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_31/images/sf314.jpg"
                                    ],
                                    "specifiche": "14 pollici, Processore AMD Ryzen 5, RAM 8 Gb, SSD 512 GB, Scheda grafica Intel, Sistema operativo Windows 10, Peso 1,45 Kg",
                                    
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 596761,
                        "field1": "ASUS",
                        "field2": "Notebook - Vivobook S15",
                        "field3": "S531FL-EJ131T",
                        "field4": "",
                        "description": "ASUS Notebook - Vivobook S15 S531FL-EJ131T",
                        "grammage": null,
                        "price": "899.99",
                        "price_label": "899,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 13.799731443835629,
                            "left": 67.29081427461934,
                            "width": 29.819387618307957,
                            "height": 24.845984817370134
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_VUdnAun_q53LVPR.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/vivobook_s15.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "ASUS - S531FL-EJ131T - Trasparent Silver",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_31/images/s15.jpg"
                                    ],
                                    "specifiche": "15,6 pollici, Processore Intel Core i7, RAM 8 Gb, SSD 256 GB, Scheda grafica nVidia, Sistema operativo Windows 10, Peso 1,8 Kg",
                                    
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 595644,
                        "field1": "HP",
                        "field2": "Notebook - Pavilion 15",
                        "field3": "CW1000NL",
                        "field4": "",
                        "description": "HP Notebook - Pavilion 15 CW1000NL",
                        "grammage": null,
                        "price": "499.99",
                        "price_label": "€ 499,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 42.2421693670862,
                            "left": 2.3606630563295843,
                            "width": 29.819387618307957,
                            "height": 24.99647390691114
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_I85sqIO_50HF5qG.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/pavilion.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "HP - PAVILION 15-CW1000NL - Mineral Silver",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_31/images/cw1000nl.jpg"
                                    ],
                                    "specifiche": "15,6 pollici, Processore AMD Ryzen 5, RAM 8 Gb, SSD 256 GB, Scheda grafica AMD, Sistema operativo Windows 10, Peso 1,85 Kg",
                                    
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 590106,
                        "field1": "LENOVO",
                        "field2": "Notebook ",
                        "field3": "IDEAPAD 330S-15IKB",
                        "field4": "",
                        "description": "LENOVO Notebook  IDEAPAD 330S-15IKB",
                        "grammage": null,
                        "price": "699.99",
                        "price_label": "€ 699,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 41.941191188004176,
                            "left": 34.96119795174349,
                            "width": 29.819387618307957,
                            "height": 24.99647390691114
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_dv9gUUS_tKTk0D9.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lenovoideapad.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "LENOVO - 330S-15IKB 81F500V2IX",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_31/images/330s.jpg"
                                    ],
                                    "specifiche": "15,6 pollici, Processore Intel Core i7, RAM 8 Gb, HD 512  GB, Scheda grafica ATI, Sistema operativo Windows 10, Peso 1,87 Kg",
                                    
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 589392,
                        "field1": "LENOVO",
                        "field2": "Notebook",
                        "field3": "Ideapad S145-15AST",
                        "field4": "",
                        "description": "LENOVO Notebook Ideapad S145-15AST",
                        "grammage": null,
                        "price": "349.99",
                        "price_label": "€ 349,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Notebook",
                        "blueprint": {
                            "top": 42.09168027754519,
                            "left": 67.2005080837733,
                            "width": 30.0,
                            "height": 24.99647390691114
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_8cyPZgy_zvA1TVR.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lenovos145.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "specs",
                                "title": "LENOVO - S145-15AST 81N3005NIX",
                                "data": {
                                    "img": [
                                        "/media/interactive_flyer_31/images/s145.jpg"
                                    ],
                                    "specifiche": "15,6 pollici, Processore AMD Vision A9, RAM 8 Gb, HD 256  GB,  Scheda grafica AMD, Sistema operativo Windows 10, Peso 1,85 Kg",
                                    
                                }
                            },
                        ]
                    },
                    {
                        "item_id": 586197,
                        "field1": "LENOVO",
                        "field2": "Web Book",
                        "field3": "TB-X505F",
                        "field4": "",
                        "description": "LENOVO Web Book TB-X505F",
                        "grammage": null,
                        "price": "129.99",
                        "price_label": "€ 129,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Tablet",
                        "blueprint": {
                            "top": 68.80349367107416,
                            "left": 0.0,
                            "width": 25.845915221082993,
                            "height": 27.028076615714753
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_EAe2VEY_dBqsDYR.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lenovox50sf.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 584433,
                        "field1": "LENOVO",
                        "field2": "Web Book",
                        "field3": "TB-X505L",
                        "field4": "",
                        "description": "LENOVO Web Book TB-X505L",
                        "grammage": null,
                        "price": "149.99",
                        "price_label": "€ 149,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Tablet",
                        "blueprint": {
                            "top": 68.95398276061516,
                            "left": 25.827570581962267,
                            "width": 24.40101616754664,
                            "height": 27.028076615714753
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_pefYyFa_hEUuXxH.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lenovox505l.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 582163,
                        "field1": "MEDIACOM",
                        "field2": "Tablet - Smartpad IYO7",
                        "field3": "M-SP7CY",
                        "field4": "",
                        "description": "MEDIACOM Tablet - Smartpad IYO7 M-SP7CY",
                        "grammage": null,
                        "price": "59.99",
                        "price_label": "€ 59,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Informatica",
                        "subcategory": "Tablet",
                        "blueprint": {
                            "top": 68.72824912630367,
                            "left": 49.66840496531205,
                            "width": 24.40101616754664,
                            "height": 27.028076615714753
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_yM33ff8_TpDHKGn.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/mediacom.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 580488,
                        "field1": "SONY",
                        "field2": "PS4 1TB F",
                        "field3": "+ Call Of Duty: Modern Warfare",
                        "field4": "",
                        "description": "SONY PS4 1TB F + Call Of Duty: Modern Warfare",
                        "grammage": null,
                        "price": "279.99",
                        "price_label": "€ 279,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Games",
                        "subcategory": "Console",
                        "blueprint": {
                            "top": 68.20153731291012,
                            "left": 73.59954553950786,
                            "width": 24.671934740084705,
                            "height": 27.554788429108285
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_si5l1Vi_wDgPjKQ.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/ps4cod.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 5,
                "image_file": "/media/interactive_flyer_31/pages/page_5.jpg",
                "products": [
                    {
                        "item_id": 579978,
                        "field1": "HUAWEI",
                        "field2": "Smartphone",
                        "field3": "Y5 2019",
                        "field4": "",
                        "description": "HUAWEI Smartphone Y5 2019",
                        "grammage": null,
                        "price": "119.00",
                        "price_label": "€ 119,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["black", "blue", "brown"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Huawei",
                        "blueprint": {
                            "top": 2.8892724521125275,
                            "left": 0.0,
                            "width": 33.97347239722497,
                            "height": 27.554788429108285
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_3UIeebd_1S75hc4.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/y52019.jpg",
                                "cropped": false
                            },
                        
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 579960,
                        "field1": "HUAWEI",
                        "field2": "P smart Z",
                        "field3": "",
                        "field4": "",
                        "description": "HUAWEI P smart Z",
                        "grammage": null,
                        "price": "199.00",
                        "price_label": "€ 199,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["black", "blue", "green"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Huawei",
                        "blueprint": {
                            "top": 1.384381556702445,
                            "left": 33.86482156725822,
                            "width": 32.16734858030453,
                            "height": 29.210168414059375
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_PTN5Ap5_USgUBBy.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/psmartz1.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/psmartz2.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 579455,
                        "field1": "HUAWEI",
                        "field2": "P30 PRO",
                        "field3": "",
                        "field4": "",
                        "description": "HUAWEI P30 PRO",
                        "grammage": null,
                        "price": "779.00",
                        "price_label": "€ 779,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Aurora", "Black", "Mystic Blue", "Breathing Crystal", "Misty Lavender"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Huawei",
                        "blueprint": {
                            "top": 2.136827004407486,
                            "left": 67.27811218028633,
                            "width": 30.0,
                            "height": 26.501364802321227
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_LJq9g7n_a7n1BBM.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/p30pro.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 578371,
                        "field1": "HUAWEI",
                        "field2": "Smartphone",
                        "field3": "PSMART 2019",
                        "field4": "",
                        "description": "HUAWEI Smartphone PSMART 2019",
                        "grammage": null,
                        "price": "179.00",
                        "price_label": "€ 179,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Blue", "Sapphire Blue", "Black"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Huawei",
                        "blueprint": {
                            "top": 31.256465830592592,
                            "left": 0.0,
                            "width": 33.97347239722497,
                            "height": 26.576609347091726
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_XF8U0CQ_NOKvMBl.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/psmart2019.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 577802,
                        "field1": "HUAWEI NOVA 5T",
                        "field2": "+ HUAWEI FREEBUDS LITE",
                        "field3": "",
                        "field4": "",
                        "description": "HUAWEI NOVA 5T + HUAWEI FREEBUDS LITE",
                        "grammage": null,
                        "price": "429.00",
                        "price_label": "€ 429,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Huawei",
                        "blueprint": {
                            "top": 31.55744400967461,
                            "left": 34.587271094026384,
                            "width": 30.18061238169204,
                            "height": 24.695495727829126
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_iRrBy2Z_ITyJjBO.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/nova5t1.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/nova5t2.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/freebuds.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 575529,
                        "field1": "SAMSUNG",
                        "field2": "Smarphone ",
                        "field3": "Galaxy A50",
                        "field4": "",
                        "description": "SAMSUNG Smarphone  Galaxy A50",
                        "grammage": null,
                        "price": "319.00",
                        "price_label": "€ 319,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Blue", "Coral", "White", "Black"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 31.256465830592592,
                            "left": 66.64596884436416,
                            "width": 31.53520524438237,
                            "height": 26.0498975336982
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_NEEWDS1_apqTkvI.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/a50.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [590863]
                    },
                    {
                        "item_id": 574356,
                        "field1": "SAMSUNG",
                        "field2": "Smartphone ",
                        "field3": "Galaxy S10",
                        "field4": "",
                        "description": "SAMSUNG Smartphone  Galaxy S10",
                        "grammage": null,
                        "price": "799.00",
                        "price_label": "€ 799,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Green", "Grey", "Black"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 57.96827922412157,
                            "left": 0.0,
                            "width": 33.88316620637894,
                            "height": 34.326797458453655
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_3byvpNy_q5hYXav.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/s10.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_31/video/s10.mp4"
                            },
                        ],
                        "related": [590863]
                    },
                    {
                        "item_id": 573364,
                        "field1": "SAMSUNG",
                        "field2": "Smarphone",
                        "field3": "Galaxy S10+",
                        "field4": "",
                        "description": "SAMSUNG Smarphone Galaxy S10+",
                        "grammage": null,
                        "price": "899.00",
                        "price_label": "€ 899,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Green", "Grey", "Black"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 58.19401285843308,
                            "left": 33.684209185566175,
                            "width": 32.618879534534635,
                            "height": 35.2297319956997
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_RIoFSqv_f3w2HXU.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/s10+.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_31/video/s10.mp4"
                            },
                        ],
                        "related": [590863]
                    },
                    {
                        "item_id": 573040,
                        "field1": "SAMSUNG",
                        "field2": "Smartphone",
                        "field3": "Galaxy S10E",
                        "field4": "",
                        "description": "SAMSUNG Smartphone Galaxy S10E",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": ["Yellow", "Green", "White", "Black"],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Smartphone",
                        "subcategory": "Samsung",
                        "blueprint": {
                            "top": 58.19401285843308,
                            "left": 65.5622945542119,
                            "width": 32.618879534534635,
                            "height": 33.95057473460113
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_eswcJEh_2wB5Ejy.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/s10e.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ],
                        "related": [590863]
                    }
                ]
            },
            {
                "number": 6,
                "image_file": "/media/interactive_flyer_31/pages/page_6.jpg",
                "products": [
                    {
                        "item_id": 571088,
                        "field1": "PHILIPS",
                        "field2": "Rasoio Serie 5000",
                        "field3": "S5130/06",
                        "field4": "",
                        "description": "PHILIPS Rasoio Serie 5000 S5130/06",
                        "grammage": null,
                        "price": "79.99",
                        "price_label": "€ 79,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 6.952477869719752,
                            "left": 0.0,
                            "width": 34.06377858807098,
                            "height": 29.88736931699391
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_PmSNcpg_fMJ1lBa.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/philips_rasoio.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 567394,
                        "field1": "BRAUN",
                        "field2": "Kit Multifunzione ",
                        "field3": "BT7020",
                        "field4": "",
                        "description": "BRAUN Kit Multifunzione  BT7020",
                        "grammage": null,
                        "price": "49.99",
                        "price_label": "€ 49,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 6.651499690637736,
                            "left": 33.86482156725822,
                            "width": 32.077042389458505,
                            "height": 30.188347496075927
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_qzhxnP6_fxXaWVG.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/braun.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 564714,
                        "field1": "SAMARITAN",
                        "field2": "Defibrillatore",
                        "field3": "PAD 350P",
                        "field4": "",
                        "description": "SAMARITAN Defibrillatore PAD 350P",
                        "grammage": null,
                        "price": "1300.00",
                        "price_label": "€ 1300,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cura Persona",
                        "blueprint": {
                            "top": 2.8892724521125275,
                            "left": 65.2010697908278,
                            "width": 34.798930209172184,
                            "height": 33.87533018983063
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_dJkLFVO_aosozXS.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/defibrillatore.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 562922,
                        "field1": "HOOVER",
                        "field2": "Scopa Elettrica Senza Fili",
                        "field3": "FD22RGE 011",
                        "field4": "",
                        "description": "HOOVER Scopa Elettrica Senza Fili FD22RGE 011",
                        "grammage": null,
                        "price": "149.99",
                        "price_label": "€ 149,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Pulizia Casa",
                        "blueprint": {
                            "top": 37.35127395700342,
                            "left": 0.0,
                            "width": 33.80556210986594,
                            "height": 27.103321160485258
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_0tI2vW2_jk2SK4t.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/hooverfd22.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 560293,
                        "field1": "HOOVER",
                        "field2": "Aspirapolvere a Traino",
                        "field3": "KS31PAR 011",
                        "field4": "",
                        "description": "HOOVER Aspirapolvere a Traino KS31PAR 011",
                        "grammage": null,
                        "price": "99.99",
                        "price_label": "€ 99,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Pulizia Casa",
                        "blueprint": {
                            "top": 37.35127395700342,
                            "left": 33.32298442218208,
                            "width": 32.90250020140573,
                            "height": 27.630032973878784
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_yMqh8jp_AZwraXK.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/hooverks31.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 560175,
                        "field1": "POLTI",
                        "field2": "Vaporetto",
                        "field3": "",
                        "field4": "",
                        "description": "POLTI Vaporetto",
                        "grammage": null,
                        "price": "369.00",
                        "price_label": "€ 369,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Pulizia Casa",
                        "blueprint": {
                            "top": 37.20078486746242,
                            "left": 66.013825508442,
                            "width": 33.98617449155798,
                            "height": 27.630032973878784
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_aTjdBPp_YnD7tUA.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/vaporetto.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 557893,
                        "field1": "ROWENTA",
                        "field2": "Scopa Elettrica Senza Fili",
                        "field3": "RH9286",
                        "field4": "",
                        "description": "ROWENTA Scopa Elettrica Senza Fili RH9286",
                        "grammage": null,
                        "price": "299.99",
                        "price_label": "€ 299,99",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Pulizia Casa",
                        "blueprint": {
                            "top": 64.89077734300794,
                            "left": 0.0,
                            "width": 33.98617449155798,
                            "height": 30.790303854239962
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_n0kp4T2_fd9qGL5.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/rowentarh9286.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 557179,
                        "field1": "DE LONGHI",
                        "field2": "ECAM359.53.B",
                        "field3": "Nero",
                        "field4": "",
                        "description": "DE LONGHI ECAM359.53.B",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 66.54615732795902,
                            "left": 34.767883475718435,
                            "width": 30.64484543025518,
                            "height": 28.532967511124838
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_jMzntA0_3HG0PlG.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/delonghi599.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_31/video/aroma.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": 552501,
                        "field1": "DE LONGHI",
                        "field2": "Macchina da Caffè",
                        "field3": "EDG210.B dolce gusto",
                        "field4": "",
                        "description": "DE LONGHI Macchina da Caffè EDG210.B dolce gusto",
                        "grammage": null,
                        "price": "59.90",
                        "price_label": "€ 59,90",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Cucina",
                        "blueprint": {
                            "top": 65.79371188025398,
                            "left": 66.3750502718261,
                            "width": 33.6249497281739,
                            "height": 30.037858406534923
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_puxpnNn_r2IU9l0.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/delonghi59.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 7,
                "image_file": "/media/interactive_flyer_31/pages/page_7.jpg",
                "products": [
                    {
                        "item_id": 552123,
                        "field1": "LG",
                        "field2": "Frigorifero Side by Side",
                        "field3": "GSB760PZXZ",
                        "field4": "",
                        "description": "LG Frigorifero Side by Side GSB760PZXZ",
                        "grammage": null,
                        "price": "999.00",
                        "price_label": "€ 999,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Frigoriferi",
                        "blueprint": {
                            "top": 1.7606042805549569,
                            "left": 0.0,
                            "width": 25.94892350626204,
                            "height": 35.2297319956997
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_d0Azkpp_OWLSEr3.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lgfrigo999.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lgfrigo9992.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "world",
                                "title": "LINK - Sito web",
                                "data": "https://www.lg.com/it/frigoriferi/lg-GSB760PZXZ"
                            }
                        ]
                    },
                    {
                        "item_id": 551264,
                        "field1": "LG",
                        "field2": "Frigorifero Combinato",
                        "field3": "GBP61DSPFN",
                        "field4": "",
                        "description": "LG Frigorifero Combinato GBP61DSPFN",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Frigoriferi",
                        "blueprint": {
                            "top": 1.3843815567024362,
                            "left": 26.00818296365431,
                            "width": 24.233105880187622,
                            "height": 35.2297319956997
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_tpqG963_IazME7s.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lg599.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lg5992.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 550701,
                        "field1": "SAMSUNG",
                        "field2": "Frigorifero Doppia Porta Serie 5000",
                        "field3": "RT38K5535S9",
                        "field4": "",
                        "description": "SAMSUNG Frigorifero Doppia Porta Serie 5000 RT38K5535S9",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Frigoriferi",
                        "blueprint": {
                            "top": 1.3843815567024362,
                            "left": 49.75871115615807,
                            "width": 24.86524921610977,
                            "height": 35.2297319956997
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_UFVjPOm_kfu78oH.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/samsungfrigo599.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 550047,
                        "field1": "SAMSUNG",
                        "field2": "Frigorifero Combinato da Incasso",
                        "field3": "BRB260030WW /EF",
                        "field4": "",
                        "description": "SAMSUNG Frigorifero Combinato da Incasso BRB260030WW/EF",
                        "grammage": null,
                        "price": "699.00",
                        "price_label": "€ 699,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Frigoriferi",
                        "blueprint": {
                            "top": 1.5348706462434447,
                            "left": 74.14138268458397,
                            "width": 25.858617315416016,
                            "height": 35.2297319956997
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_bVAPFFz_V6TpH2D.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/samsungfrigo699.jpg",
                                "cropped": false
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/samsungfrigo6992.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 550024,
                        "field1": "SAMSUNG",
                        "field2": "Lavatrice - WW90M6420PW",
                        "field3": "",
                        "field4": "",
                        "description": "SAMSUNG Lavatrice - WW90M6420PW",
                        "grammage": null,
                        "price": "699.00",
                        "price_label": "€ 699,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Lavatrici",
                        "blueprint": {
                            "top": 37.12554032269191,
                            "left": 0.0,
                            "width": 33.083112583097765,
                            "height": 26.501364802321227
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_1qrBKgh_JHMk7Q8.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/samsunglavatriceww90.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            },
                            {
                                "type": "play",
                                "title": "VIDEO",
                                "data": "/media/interactive_flyer_31/video/quick.mp4"
                            },
                        ]
                    },
                    {
                        "item_id": 548633,
                        "field1": "WHIRLPOOL",
                        "field2": "Lavatrice - Best Zen 8",
                        "field3": "",
                        "field4": "",
                        "description": "WHIRLPOOL Lavatrice - Best Zen 8",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Lavatrici",
                        "blueprint": {
                            "top": 37.12554032269191,
                            "left": 34.135740139796276,
                            "width": 31.638213529561423,
                            "height": 28.23198933204282
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_BVU1rYp_jWSeUuU.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/bestezen8.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 548286,
                        "field1": "LG",
                        "field2": "Lavatrice - F4WV509S0",
                        "field3": "",
                        "field4": "",
                        "description": "LG Lavatrice - F4WV509S0",
                        "grammage": null,
                        "price": "599.00",
                        "price_label": "€ 599,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Lavatrici",
                        "blueprint": {
                            "top": 37.050295777921406,
                            "left": 66.55566265351814,
                            "width": 31.638213529561423,
                            "height": 27.554788429108285
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_EoL9ahm_wa1Fe56.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/lglava.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 548110,
                        "field1": "AEG",
                        "field2": "Asciugatrice - T8DEC",
                        "field3": "",
                        "field4": "",
                        "description": "AEG Asciugatrice - T8DEC",
                        "grammage": null,
                        "price": "899.00",
                        "price_label": "€ 899,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Asciugatrici",
                        "blueprint": {
                            "top": 63.762109171450376,
                            "left": 0.8127557176141972,
                            "width": 32.72188781971368,
                            "height": 31.392260212403993
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_OQD0PeP_5HiyqyK.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/t8dec.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 547429,
                        "field1": "ELECTROLUX",
                        "field2": "Asciugatrice PerfectCare 700",
                        "field3": "EW7HL83B5",
                        "field4": "",
                        "description": "ELECTROLUX Asciugatrice PerfectCare 700 EW7HL83B5",
                        "grammage": null,
                        "price": "699.00",
                        "price_label": "€ 699,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Asciugatrici",
                        "blueprint": {
                            "top": 64.89077734300794,
                            "left": 66.28474408098009,
                            "width": 31.457601147869372,
                            "height": 27.780522063419795
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_kAMa3u0_kJXVcMv.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/electrolux.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    },
                    {
                        "item_id": 546013,
                        "field1": "MIELE",
                        "field2": "Asciugatrice Active",
                        "field3": "TDB - 230",
                        "field4": "",
                        "description": "MIELE Asciugatrice Active TDB - 230",
                        "grammage": null,
                        "price": "799.00",
                        "price_label": "€ 799,00",
                        "equivalence": 1,
                        "quantity_step": 1,
                        "grocery_label": "pz.",
                        "varieties": [],
                        "price_for_kg": null,
                        "available_pieces": null,
                        "max_purchasable_pieces": null,
                        "points": "",
                        "fidelity_product": false,
                        "focus": false,
                        "pam": false,
                        "three_for_two": false,
                        "one_and_one_gratis": false,
                        "underpriced_product": false,
                        "category": "Elettrodomestici",
                        "subcategory": "Asciugatrici",
                        "blueprint": {
                            "top": 65.11651097731946,
                            "left": 35.54183714507613,
                            "width": 30.0,
                            "height": 29.511146593141387
                        },
                        "images": [
                            {
                                "image_file": "/media/interactive_flyer_31/products/cropped_image_dNMr1Ll_3rjEaPg.png",
                                "cropped": true
                            },
                            {
                                "image_file": "/media/interactive_flyer_31/products/miele.jpg",
                                "cropped": false
                            },
                            
                        ],
                        "markers": [
                            {
                                "type": "plus",
                                "data": ""
                            }
                        ]
                    }
                ]
            },
            {
                "number": 8,
                "image_file": "/media/interactive_flyer_31/pages/page_8.jpg",
                "products": []
            },
            {
                "number": 9,
                "image_file": "/media/interactive_flyer_31/pages/euronics_fine.png",
                "products": []
            }
        ]
    }
    },
    "ottimo": {
        "config": {
            "primary-color": "rgb(29, 78, 157)",  
            "secondary-color": "rgb(136, 14, 16)", 
            "tertiary-color": "rgb(255, 255, 255)", 
            "hover-color": "rgba(136, 14, 16, 0.5)", 
            "clientIcon": require("assets/images/clientLogo_ottimo.png"),
            "serverPath": "https://ottimo.volantinointerattivo.net",
            "pdfPath": "/media/interactive_flyer_34/volantino_ottimo.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino Ottimo!",
            "shareFlyerURL": "https://ottimo.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Ottimo!",
            "ogTitleMeta": "Volantino Interattivo Ottimo",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://ottimo.volantinointerattivo.net/media/interactive_flyer_34/images/clientLogo_ottimo.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": ""
        },
        "leaflet": {
            "name": "ottimo",
            "code": "ottimo3325",
            "id_campaign": "5345",
            "index": {
                "image_file": "/media/interactive_flyer_34/pages/ottimo_indice.png",
                "links": [
                    {   //SOTTOCOSTO
                        "page": 0,
                        "blueprint": {
                            "top": 15.381315980227052,
                        "left": 2.207207207207207,
                        "width": 95.45045045045045,
                        "height": 8.013167432405181
                        }
                    },
                    {   //GASTRONOMIA
                        "page": 2,
                        "blueprint": {
                            "top": 24.745017249554454,
                        "left": 2.1171171171171173,
                        "width": 95.45045045045045,
                        "height": 8.013167432405181
                        }
                    },
                    {   //MACELLERIA
                        "page": 2,
                        "blueprint": {
                            "top": 34.19875410801001,
                        "left": 2.1171171171171173,
                        "width": 95.45045045045045,
                        "height": 8.013167432405181
                        }
                    },
                    {   //ORTOFRUTTA
                        "page": 4,
                        "blueprint": {
                            "top": 43.472419788209265,
                        "left": 2.207207207207207,
                        "width": 95.45045045045045,
                        "height": 8.013167432405181
                        }
                    },
                    {   //FRESCHI
                        "page": 4,
                        "blueprint": {
                            "top": 52.926156646664815,
                        "left": 2.207207207207207,
                        "width": 95.45045045045045,
                        "height": 8.013167432405181
                        }
                    },
                    {   //DISPENSA
                        "page": 6,
                        "blueprint": {
                            "top": 62.199822326864066,
                        "left": 2.207207207207207,
                        "width": 95.45045045045045,
                        "height": 8.013167432405181
                        }
                    },
                    {   //BEVANDE
                        "page": 10,
                        "blueprint": {
                            "top": 71.74359477444776,
                        "left": 2.207207207207207,
                        "width": 95.45045045045045,
                        "height": 8.013167432405181
                        }
                    },
                    {   //DETERGENZA
                        "page": 10,
                        "blueprint": {
                            "top": 80.92722486551887,
                        "left": 2.207207207207207,
                        "width": 95.45045045045045,
                        "height": 8.013167432405181
                        }
                    },
                    {   //PREMI
                        "page": 12,
                        "blueprint": {
                            "top": 90.11085495658998,
                        "left": 2.1171171171171173,
                        "width": 95.45045045045045,
                        "height": 8.013167432405181
                        }
                    },
                    
    
                ]
            },
    
            "categories": [
                {
                    "category_name": "Alimentari",
                    "subcategories": [
                        "Olio",
                        "Dispensa",
                        "Colazione",
                        "Pasta",
                        "Snack Dolci",
                        "Rossi"
                    ]
                },
                {
                    "category_name": "Gastronomia",
                    "subcategories": [
                        "Formaggi",
                        "Salumi"
                    ]
                },
                {
                    "category_name": "Bevande",
                    "subcategories": [
                        "Bibite",
                        "Vini e Spumanti",
                        "Acqua",
                        "Birre",
                        "Succhi",
                        "Liquori"
                    ]
                },
                {
                    "category_name": "Macelleria",
                    "subcategories": [
                        "Bovino",
                        "Suino",
                        "Avicoli",
                        "Altro"
                    ]
                },
                {
                    "category_name": "Ortofrutta",
                    "subcategories": [
                        "Frutta",
                        "Verdura"
                    ]
                },
                {
                    "category_name": "Surgelati",
                    "subcategories": [
                        "Ittico",
                        "Verdure",
                        "Preparati",
                        "Dolci",
                        "Gelati"
                    ]
                },
                {
                    "category_name": "Pet",
                    "subcategories": [
                        "Food",
                        "Care"
                    ]
                },
                {
                    "category_name": "Self Service",
                    "subcategories": [
                        "Pasta",
                        "Salumi",
                        "Latte",
                        "Formaggi",
                        "Snack",
                        "Yogurt"
                    ]
                },
                {
                    "category_name": "Igiene Casa",
                    "subcategories": [
                        "Bucato",
                        "Extra",
                        "Superfici",
                        "Pavimenti"
                    ]
                },
                {
                    "category_name": "Igiene Persona",
                    "subcategories": [
                        "Capelli",
                        "Corpo",
                        "Orale",
                        "Intima"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "/media/interactive_flyer_34/pages/page_1.jpg",
                    "products": [
                        {
                            "item_id": 722982,
                            "field1": "DESANTIS",
                            "field2": "olio extra vergine",
                            "field3": "di oliva classico",
                            "field4": "1 lt",
                            "description": "DESANTIS olio extra vergine di oliva classico 1 lt",
                            "grammage": null,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 46.26126126126126,
                                "left": 0.0,
                                "width": 24.774774774774773,
                                "height": 42.16216216216216
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/desantis_olio_extra_vergine_di_oliva_classico_1_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_zn44zrt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "http://www.oleariadesantisspa.it"
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA' - Classificazione degli Oli Ottenuti dalle Olive",
                                    "data": {
    
                                        "titolo": "Classificazione degli Oli Ottenuti dalle Olive",
                                        "testo": "L’olio di oliva viene classificato in base ai valori di 25 parametri di natura chimica, fisica ed organolettica. Il Regolamento CEE 2568/91 e le sue modifiche stabiliscono gli intervalli entro cui tali parametri possono variare. Dei 25 parametri l’acidità, ovvero la quantità di acido oleico non legato alla glicerina, è quello che viene valutato per primo. Non meno importante, comunque, è il valore assegnato all’olio dal panel test che definisce e quantifica le caratteristiche organolettiche. L’olio di oliva ottenuto dalle olive esclusivamente tramite operazioni meccaniche si classifica in: - olio extravergine di oliva, avente acidità minore o pari allo 0,8%; - olio di oliva lampante, avente acidità superiore a 2.0%. (Fonte: oleariadesantisspa.it)",
                                        "img": [
                                            "/media/interactive_flyer_34/images/desantis.jpg"
                                        ]
                                    }
                                },
                            ],
                            "related": [723096, 723113, 723184],
                        },
                        {
                            "item_id": 722989,
                            "field1": "GRANA PADANO",
                            "field2": "riserva oltre 20 mesi ",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "GRANA PADANO riserva oltre 20 mesi  l'etto",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 43.73873873873874,
                                "left": 22.52252252252252,
                                "width": 52.52252252252252,
                                "height": 27.2972972972973
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/grana_padano_mezza_forma_amb_1461144827_1487934590_1488366668.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_uz7TnXk_s4g5RHL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Grana Padano DOP: ecco come nasce",
                                    "data": "/media/interactive_flyer_34/video/grana.mp4"
                                },
    
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - BRUSCHETTE CON BRESAOLA, RICOTTA, FAVE, RUCOLA E GRANA PADANO RISERVA",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_34/images/grana_bruschette.jpg"
                                        ],
                                        "ingredients": "8 fette di pane in cassetta, 100 g di bresaola, 100 g di Grana Padano DOP, 100 g di fave fresche, 50 g di ricotta o formaggio spalmabile, 50 g di rucola, 3 cucchiai di olio extravergine di oliva, sale, pepe",
                                        "recipe": "Grigliate le fette di pane sulla bistecchiera o in forno. Spalmate la ricotta sulle fette, aggiungete le fave fresche, una o due fette di bresaola, qualche foglia di rucola e le scaglie di Grana Padano. Servite le bruschetta con un filo di olio extravergine di olive e un pizzico di sale e pepe. (Fonte: granapadano.it)"
                                    }
                                },
                            ],
                            
                            "related": [724409],
                        },
                        {
                            "item_id": 722981,
                            "field1": "PEPSI",
                            "field2": "cola",
                            "field3": "1,5 lt",
                            "field4": "• classica • twist • max zero",
                            "description": "PEPSI cola 1,5 lt • classica • twist • max zero",
                            "grammage": 1500,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classica",
                                "Twist",
                                "Max Zero"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 39.90990990990991,
                                "left": 74.50028153153153,
                                "width": 25.499718468468465,
                                "height": 48.648648648648646
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/pepsi_15_classica_1468309603_1470237957_1503481988.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/pepsi_max_zero_15_1_1468309612_1470237966_1554374700_1562859725.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/pepsi_twist_limone_15_1468309625_1470237976_1497606334_153_9CUBqtv.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_AQtcdCj_viGlGUu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://pepsimax.it/#products"
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO",
                                    "data": "/media/interactive_flyer_34/video/pepsi.mp4"
                                },
                            ],
                            "related": [723133,723134,723142,],
                        },
                        {
                            "item_id": 722980,
                            "field1": "KIMBO",
                            "field2": "caffè classico",
                            "field3": "4 x 250 gr",
                            "field4": "",
                            "description": "KIMBO caffè classico 4 x 250 gr",
                            "grammage": 0,
                            "price": "5.79",
                            "price_label": "€ 5,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 70.0,
                                "left": 25.851632882882885,
                                "width": 49.103322072072075,
                                "height": 18.378378378378375
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/kimbo_classico_x_4_1550253480.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_zyG8B1T_qvJTq5n.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.kimbo.it"
                                },
                                {
                                    "type": "info",
                                    "title": "CURIOSITA' - L’IMPORTANZA DELLE ORIGINI",
                                    "data": {
                                        "titolo": "L’IMPORTANZA DELLE ORIGINI",
                                        "testo": "MATERIE PRIME. Per fare un buon caffè bisogna partire da molto lontano perché la qualità dipende innanzi tutto dalla qualità delle materie prime. Per questo Kimbo S.p.A. dedica molta cura alla selezione dei caffè verdi direttamente nei Paesi d’origine. Tutte le fasi della coltivazione delle piante di caffè sono seguite con attenzione e sottoposte a molteplici controlli. Così la pianta può crescere sana e produre bacche rosse di ottima qualità. Quelle stesse bacche che nascondono il loro tesoro più prezioso: i chicchi verdi. Questi vengono essicati al sole, decorticati e finalmente spediti in Italia. (Fonte: kimbo.it)",
                                        "img": [
                                            "/media/interactive_flyer_34/images/kimbo.jpg"
                                        ]
                                    }
                                },
                            ]
                        },
                        
                    ]
                },
                {
                    "number": 2,
                    "image_file": "/media/interactive_flyer_34/pages/page_2.jpg",
                    "products": [
                        {
                            "item_id": 723000,
                            "field1": "BEPPINO OCCELLI",
                            "field2": "testun al barolo",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "BEPPINO OCCELLI testun al barolo l'etto",
                            "grammage": 100,
                            "price": "2.99",
                            "price_label": "€ 2,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 33.157376126126124,
                                "height": 33.24324324324325
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Occelli_al_Barolo_1550750302.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_QeKbFol_N8MaiHP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "hat-chef",
                                    "title": "RICETTA - Involtini di manzo ripieni di Occelli al Barolo con cipolle di Tropea caramellate e Burro Occelli",
                                    "data": {
                                        "img": [
                                            "/media/interactive_flyer_34/images/ocelli.jpg"
                                        ],
                                        "ingredients": "4 fette di Occelli® al Barolo, 8 scaloppine di manzo, 8 cipolle di Tropea, ½ litro di Barolo, 4 cucchiai di farina, Sale, 60 gr d’Olio Extra Vergine d'Oliva, 60 gr di Burro Occelli®, 2 cucchiai di zucchero",
                                        "recipe": "Tagliate le fette di formaggio in due parti e farcite le scaloppine. Chiudetele  bene e sigillate con gli stuzzicadenti per non far fuoriuscire il formaggio. Passate nella farina e fate dorare in padella con olio extra vergine d'oliva e Burro Occelli®. Nel frattempo  affettate le cipolle e fatele stufare in padella con Burro, olio e zucchero. Sfumate con il barolo e fate cuocere fino a quando non caramellano. Servite gli involtini ripieni di Occelli® al barolo con le cipolle caramellate. (Fonte: ocelli.it)"
                                    }
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO - Involtini di manzo ripieni di Occelli al Barolo",
                                    "data": "/media/interactive_flyer_34/video/ocelli.mp4"
                                },
                            ]
                        },
                        {
                            "item_id": 723001,
                            "field1": "VILLA DEGLI OLMI",
                            "field2": "vino novello  ",
                            "field3": "merlot e cabernet ",
                            "field4": "75 cl",
                            "description": "VILLA DEGLI OLMI vino novello   merlot e cabernet  75 cl",
                            "grammage": 750,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 0.0,
                                "left": 33.873873873873876,
                                "width": 35.139358108108105,
                                "height": 33.06306306306306
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/VILLA_DEGLI_OLMI_NOVELLO_2018_1541168239.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_3OQDTBv_qXYgI4b.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722985,
                            "field1": "gardani",
                            "field2": "prosciutto di parma",
                            "field3": "senza osso - 20 mesi",
                            "field4": "l'etto",
                            "description": "gardani prosciutto di parma senza osso - 20 mesi l'etto",
                            "grammage": 100,
                            "price": "1.99",
                            "price_label": "€ 1,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 0.0,
                                "left": 68.28406531531532,
                                "width": 30.995213963963966,
                                "height": 32.7027027027027
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/0087826_GARDANI_PROSCIUTTO_DI_PARMA_20_MESI_1547716268.jpg",
                                    "cropped": false
                                },
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_emoNxOs_M9kFpaU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722983,
                            "field1": "ROVAGNATI",
                            "field2": "prosciutto cotto",
                            "field3": "sapore delicato ",
                            "field4": "l'etto",
                            "description": "ROVAGNATI prosciutto cotto sapore delicato  l'etto",
                            "grammage": 100,
                            "price": "1.19",
                            "price_label": "€ 1,19 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 33.78378378378378,
                                "left": 0.0,
                                "width": 25.409628378378375,
                                "height": 15.405405405405405
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/prosciutto_cotto_ambientato_Di_Palo_1557497008.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_hSFM8f6_Ynwtuur.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722986,
                            "field1": "NEGRONI",
                            "field2": "salame napoli",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "NEGRONI salame napoli l'etto",
                            "grammage": 100,
                            "price": "1.19",
                            "price_label": "€ 1,19 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 33.78378378378378,
                                "left": 25.405405405405407,
                                "width": 24.508727477477475,
                                "height": 15.405405405405405
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/salame_napoli_1448374191_1497867457_1553679880.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_zPbir3M_QZ48UoW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722988,
                            "field1": "blu italia",
                            "field2": "bresaola",
                            "field3": "punta d’anca",
                            "field4": "l'etto",
                            "description": "blu italia bresaola punta d’anca l'etto",
                            "grammage": 100,
                            "price": "2.49",
                            "price_label": "€ 2,49 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 33.6036036036036,
                                "left": 50.18018018018018,
                                "width": 24.3285472972973,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/bresaola_tagliere_1551201881.jpg",
                                    "cropped": false
                                },
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_B0jSTzf_RlFv7Vp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722997,
                            "field1": "FURLOTTI",
                            "field2": "coppa di parma",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "FURLOTTI coppa di parma l'etto",
                            "grammage": 100,
                            "price": "1.49",
                            "price_label": "€ 1,49 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 33.6036036036036,
                                "left": 74.68046171171171,
                                "width": 25.31953828828829,
                                "height": 15.675675675675677
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/FURLOTTI_coppa_di_parma_intera_1490171690_1568393003.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_g3pLytZ_YPagtee.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722998,
                            "field1": "BERGADER",
                            "field2": "formaggio ",
                            "field3": "a pasta erborinata ",
                            "field4": "l'etto",
                            "description": "BERGADER formaggio  a pasta erborinata  l'etto",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 0.0,
                                "width": 33.4276463963964,
                                "height": 15.675675675675677
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/bergader_formaggio_1551201855.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_jkJyyFv_cOroxkp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722993,
                            "field1": "GALBANI",
                            "field2": "galbanone",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "GALBANI galbanone l'etto",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 49.549549549549546,
                                "left": 33.42342342342342,
                                "width": 33.06728603603603,
                                "height": 15.675675675675677
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/galbani_Galbanone_al_Kg_1551202074.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_LyL6pcl_2HkPJrR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "LINK - Sito web",
                                    "data": "https://www.galbani.it"
                                }
                            ]
                        },
                        {
                            "item_id": 722999,
                            "field1": "LEERDAMMER",
                            "field2": "original",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "LEERDAMMER original l'etto",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 66.66244369369369,
                                "width": 33.06728603603603,
                                "height": 15.675675675675677
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/leerdammer_Di_Palo_1552563327.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_XsK0ufN_PYfHsnl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722991,
                            "field1": "BLU ITALIA",
                            "field2": "asiago dop",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "BLU ITALIA asiago dop l'etto",
                            "grammage": 100,
                            "price": "0.79",
                            "price_label": "€ 0,79 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 0.0,
                                "width": 24.68890765765766,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Asiago_Blu_Italia_1547029783.jpg",
                                    "cropped": false
                                },
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_eSzcH8Q_1B7ERAU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722992,
                            "field1": "i maestri del latte",
                            "field2": "favarello",
                            "field3": "formaggio di pecora",
                            "field4": "l'etto",
                            "description": "i maestri del latte favarello formaggio di pecora l'etto",
                            "grammage": 100,
                            "price": "1.39",
                            "price_label": "€ 1,39 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 0.0,
                                "width": 24.68890765765766,
                                "height": 15.855855855855856
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/I_Maestri_del_Latte_Favarello_1561637944.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Bqa9XoO_TBFS1aB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724409,
                            "field1": "FIORUCCI",
                            "field2": "mortadella suprema",
                            "field3": "con pistacchio",
                            "field4": "l'etto",
                            "description": "FIORUCCI mortadella suprema con pistacchio l'etto",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 24.954954954954957,
                                "width": 33.78800675675675,
                                "height": 17.56756756756757
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Fiorucci_Suprema_Di_Palo_1554461067.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_y1qEJZA_oe75Ac7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "VIDEO",
                                    "data": "/media/interactive_flyer_34/video/fiorucci.mp4"
                                },

                            ]
                        },
                        {
                            "item_id": 722996,
                            "field1": "FIORUCCI",
                            "field2": "guanciale alla amatriciana",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "FIORUCCI guanciale alla amatriciana l'etto",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 83.78378378378379,
                                "left": 24.954954954954957,
                                "width": 34.86908783783783,
                                "height": 13.513513513513514
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/guanciale_lardo_1559287736.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_6SRS3Pw_G0Hra5N.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724407,
                            "field1": "FIORUCCI",
                            "field2": "rostello petto di tacchino arrosto",
                            "field3": "gran fetta",
                            "field4": "l'etto",
                            "description": "FIORUCCI rostello petto di tacchino arrosto gran fetta l'etto",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [
                                "Suino alle Erbe",
                                "Petto di Tacchino Arrosto"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 71.62162162162163,
                                "left": 60.62640765765765,
                                "width": 39.37359234234234,
                                "height": 25.315315315315317
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/fiorucci_rostello_petto_di_tacchino_1571756881.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_EYUWn88_99wiCoU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "/media/interactive_flyer_34/pages/page_3.jpg",
                    "products": [
                        {
                            "item_id": 722989,
                            "field1": "GRANA PADANO",
                            "field2": "riserva oltre 20 mesi ",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "GRANA PADANO riserva oltre 20 mesi  l'etto",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 33.157376126126124,
                                "height": 33.6036036036036
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/grana_padano_mezza_forma_amb_1461144827_1487934590_1488366_LWY59Jx.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_1H6QqOh_knO0qYF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ],
                            "related": [724409],
                        },
                        {
                            "item_id": 722995,
                            "field1": "GOLFERA",
                            "field2": "salame",
                            "field3": "il golfetta",
                            "field4": "l'etto",
                            "description": "GOLFERA salame il golfetta l'etto",
                            "grammage": 100,
                            "price": "1.69",
                            "price_label": "€ 1,69 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 33.78378378378378,
                                "left": 0.0,
                                "width": 32.87865990990991,
                                "height": 15.585585585585584
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Golfera_Logo_1487085709.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Golfera_salame_Golfetta_1551202103.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_2HT2twJ_EV9n5JZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722990,
                            "field1": "LATTERIA SORESINA",
                            "field2": "provolone piccante ",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "LATTERIA SORESINA provolone piccante  l'etto",
                            "grammage": 100,
                            "price": "0.99",
                            "price_label": "€ 0,99 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": "9.9000",
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 49.549549549549546,
                                "left": 0.0,
                                "width": 32.87865990990991,
                                "height": 15.585585585585584
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/0051912_Provolone_Piccante_Classico_11_kg_1536845258.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_sqYNi3d_T6THCJJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 722994,
                            "field1": "FAMIGLIA BUSTI",
                            "field2": "marzolino del chianti ",
                            "field3": "l'etto",
                            "field4": "",
                            "description": "FAMIGLIA BUSTI marzolino del chianti  l'etto",
                            "grammage": 100,
                            "price": "1.29",
                            "price_label": "€ 1,29 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 0.0,
                                "width": 32.87865990990991,
                                "height": 31.8018018018018
                            },
                            "images": [
                                
                                {
                                    "image_file": "/media/interactive_flyer_34/products/busti_marzolino_chianti_1551201929.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_hAoURqz_vnmOnJZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724460,
                            "field1": "hamburger classico",
                            "field2": "di bovino adulto",
                            "field3": "al kg",
                            "field4": "",
                            "description": "hamburger classico di bovino adulto al kg",
                            "grammage": null,
                            "price": "6.90",
                            "price_label": "€ 6,90 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Bovino",
                            "blueprint": {
                                "top": 8.91891891891892,
                                "left": 33.96396396396397,
                                "width": 32.338119369369366,
                                "height": 25.315315315315317
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/hamburger_euroesse_1430125239_1488194267_1522837794.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_wOmFJN7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724461,
                            "field1": "locena",
                            "field2": "di suino",
                            "field3": "al kg",
                            "field4": "",
                            "description": "locena di suino al kg",
                            "grammage": null,
                            "price": "5.50",
                            "price_label": "€ 5,50 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Suino",
                            "blueprint": {
                                "top": 7.207207207207206,
                                "left": 65.76998873873873,
                                "width": 34.23001126126126,
                                "height": 26.036036036036037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/suino_locena_Di_Palo_1551952611.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_saXSQJZ_Psa9w57.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724463,
                            "field1": "fusi",
                            "field2": "di pollo",
                            "field3": "al kg",
                            "field4": "",
                            "description": "fusi di pollo al kg",
                            "grammage": 0,
                            "price": "3.49",
                            "price_label": "€ 3,49 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 33.24324324324325,
                                "left": 33.96818693693694,
                                "width": 32.51829954954955,
                                "height": 15.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/0040_POLLO_FUSELLI_COSCE_1494334585_1508317221.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_8QoSZA9_W1NxYuE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724464,
                            "field1": "sovracosce",
                            "field2": "di pollo",
                            "field3": "al kg",
                            "field4": "",
                            "description": "sovracosce di pollo al kg",
                            "grammage": 0,
                            "price": "3.99",
                            "price_label": "€ 3,99 al Kg",
                            "equivalence": 10,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 32.97297297297297,
                                "left": 66.31052927927928,
                                "width": 33.68947072072072,
                                "height": 16.306306306306308
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/0052_pollo_sovracoscia_1494334534.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_A3OWIaq_1wQvYEf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724469,
                            "field1": "aia ",
                            "field2": "carne al fuoco ",
                            "field3": "stuzzichini di suino ",
                            "field4": "400 gr",
                            "description": "aia  carne al fuoco  stuzzichini di suino  400 gr",
                            "grammage": 400,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": "9.9700",
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Suino",
                            "blueprint": {
                                "top": 49.369369369369366,
                                "left": 33.878096846846844,
                                "width": 32.60838963963964,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/aia_carne_fuoco_stuzzichini_suino_1502271438.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_13wzwu9_Xd3sCzN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724467,
                            "field1": "AIA",
                            "field2": "nagghy",
                            "field3": "filetti di pollo panati",
                            "field4": "230 gr",
                            "description": "AIA nagghy filetti di pollo panati 230 gr",
                            "grammage": 230,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 33.697916666666664,
                                "width": 32.60838963963964,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/aia_nagghy_filetti_pollo_panati.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_3StmV5l_AA6Mnhm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724466,
                            "field1": "AIA",
                            "field2": "bon roll ",
                            "field3": "classico",
                            "field4": "750 gr",
                            "description": "AIA bon roll  classico 750 gr",
                            "grammage": 750,
                            "price": "5.49",
                            "price_label": "€ 5,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 33.78800675675675,
                                "width": 32.60838963963964,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Aia_bon_roll_classco_750_g_1522925102_1547465578.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_1GVklC5_UUeLsO7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724470,
                            "field1": "AIA",
                            "field2": "fusi di pollo",
                            "field3": "450 gr",
                            "field4": "",
                            "description": "AIA fusi di pollo 450 gr",
                            "grammage": 450,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 49.369369369369366,
                                "left": 66.58079954954954,
                                "width": 33.41920045045045,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/aia_il_pollo_fusi_1445331771.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_rpBx7l6_h3eCAM0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724468,
                            "field1": "AIA",
                            "field2": "spinacina ",
                            "field3": "classica",
                            "field4": "220 gr",
                            "description": "AIA spinacina  classica 220 gr",
                            "grammage": 220,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 66.58079954954954,
                                "width": 33.41920045045045,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Aia_Spinacine_pollo_classica_1445951469.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_wn6HqZa_EqKbUEh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724465,
                            "field1": "AIA ",
                            "field2": "hamburger fiorentino",
                            "field3": "di pollo",
                            "field4": "180 gr",
                            "description": "AIA  hamburger fiorentino di pollo 180 gr",
                            "grammage": 180,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Macelleria",
                            "subcategory": "Avicoli",
                            "blueprint": {
                                "top": 81.26126126126127,
                                "left": 66.58079954954954,
                                "width": 33.41920045045045,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Aia_fiorentino_180_gr_1446827889.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_2b4SCgv_5NaeT26.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "/media/interactive_flyer_34/pages/page_4.jpg",
                    "products": [
                        {
                            "item_id": 724471,
                            "field1": "Mandarino Miyagawa",
                            "field2": "al kg",
                            "field3": "",
                            "field4": "",
                            "description": "Mandarino Miyagawa al kg",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0,99 al Kg",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "kg.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 25.221002252252255,
                                "height": 17.65765765765766
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/MIYAGAWA_1571149268.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_fNEUiBE_tkl4aNC.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724473,
                            "field1": "PERE CONFERENCE",
                            "field2": "al kg",
                            "field3": "",
                            "field4": "",
                            "description": "PERE CONFERENCE al kg",
                            "grammage": 0,
                            "price": "1.29",
                            "price_label": "€ 1,29 al Kg",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "kg.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 17.83783783783784,
                                "left": 0.0,
                                "width": 25.221002252252255,
                                "height": 15.495495495495495
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/pere_conference_sfuse_1519385741.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_sjKD51S_vwKSkSN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724472,
                            "field1": "del monte",
                            "field2": "banane",
                            "field3": "al kg",
                            "field4": "",
                            "description": "del monte banane al kg",
                            "grammage": null,
                            "price": "1.29",
                            "price_label": "€ 1,29 al Kg",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "kg.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Frutta",
                            "blueprint": {
                                "top": 33.78378378378378,
                                "left": 0.0,
                                "width": 25.221002252252255,
                                "height": 15.495495495495495
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/del_monte_banane_1502189795.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_teX3PIp_os22Ci1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724475,
                            "field1": "ZUCCHINE",
                            "field2": "al kg",
                            "field3": "",
                            "field4": "",
                            "description": "ZUCCHINE al kg",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€ 0,99 al Kg",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "kg.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 49.63963963963964,
                                "left": 0.0,
                                "width": 25.221002252252255,
                                "height": 15.495495495495495
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/zucchine_scure_cesto_1453300558_1477469596.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_4CqCiPs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724474,
                            "field1": "LATTUGA TROCADERO",
                            "field2": "al pezzo",
                            "field3": "",
                            "field4": "",
                            "description": "LATTUGA TROCADERO al pezzo",
                            "grammage": 0,
                            "price": "0.39",
                            "price_label": "€ 0,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 0.0,
                                "width": 25.221002252252255,
                                "height": 15.495495495495495
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Lattuga_trocadero_1482923289_1495708147_1503939078.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_w9f2Ou0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724480,
                            "field1": "Friarielli ",
                            "field2": "a fasci",
                            "field3": "",
                            "field4": "",
                            "description": "Friarielli a fasci",
                            "grammage": 0,
                            "price": "0.333",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 3,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Ortofrutta",
                            "subcategory": "Verdura",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 0.0,
                                "width": 25.221002252252255,
                                "height": 16.036036036036037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/friarielli_2_1491992321.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_1jLoZR9_F1Q4xdd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723042,
                            "field1": "gamberetti e zucchine",
                            "field2": "surgelati",
                            "field3": "500 gr",
                            "field4": "",
                            "description": "gamberetti e zucchine surgelati 500 gr",
                            "grammage": 500,
                            "price": "3.49",
                            "price_label": "€ 3,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 5.945945945945946,
                                "left": 25.855855855855857,
                                "width": 24.139921171171167,
                                "height": 11.621621621621623
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Gamberi_e_Zucchine_Confezionati_1533571597.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_DCxBwDB_sG3kcV6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723043,
                            "field1": "polpo moscardino",
                            "field2": "indopacifico",
                            "field3": "confezione da",
                            "field4": "1 kg",
                            "description": "polpo moscardino indopacifico confezione da 1 kg",
                            "grammage": null,
                            "price": "6.99",
                            "price_label": "€ 6,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 5.135135135135135,
                                "left": 50.09009009009009,
                                "width": 24.04983108108108,
                                "height": 12.342342342342342
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/moscardini_confezione_1484648148.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/moscardini_2_1491040797_1539868352.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_VJGlsik_o0ykcrO.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723039,
                            "field1": "FINDUS",
                            "field2": "pisellini",
                            "field3": "primavera",
                            "field4": "300 gr",
                            "description": "FINDUS pisellini primavera 300 gr",
                            "grammage": 300,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Verdure",
                            "blueprint": {
                                "top": 17.83783783783784,
                                "left": 25.945945945945947,
                                "width": 24.04983108108108,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Pisellini_Primavera_300gr_CSR_1538064648_1539269196.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Qud9HES_AQkI101.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723036,
                            "field1": "orogel",
                            "field2": "broccoli a rosette",
                            "field3": "400 gr",
                            "field4": "",
                            "description": "orogel broccoli a rosette 400 gr",
                            "grammage": 400,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Verdure",
                            "blueprint": {
                                "top": 17.747747747747745,
                                "left": 50.09009009009009,
                                "width": 24.04983108108108,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/orogel_broccoli_rosette_400_g_1538040845.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_mCY4g8P_Qbp6Upj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723037,
                            "field1": "CAMEO",
                            "field2": "pizza ristorante ",
                            "field3": "",
                            "field4": "",
                            "description": "CAMEO pizza ristorante  • al salame 320 gr • ai quattro formaggi 340 gr • ai funghi 365 gr • bianca con prosciutto e patate 310 gr • tonno 355 gr • prosciutto 330 gr",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                " al salame 320 gr",
                                "ai quattro formaggi 340 gr",
                                "ai funghi 365 gr",
                                "bianca con prosciutto e patate 310 gr",
                                "tonno 355 gr",
                                "prosciutto 330 gr"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Preparati",
                            "blueprint": {
                                "top": 33.6036036036036,
                                "left": 25.855855855855857,
                                "width": 24.04983108108108,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Cameo_Ristorante_ai_4_formaggi_340_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Cameo_Ristorante_ai_funghi_365_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Pizza_Bianca_con_prosciutto_e_patate_1571481947.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_u4vR2Eu_c85K7th.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723046,
                            "field1": "CARTE D'OR",
                            "field2": "torta ",
                            "field3": "545 gr",
                            "field4": "• romantica • sofisticata",
                            "description": "CARTE D'OR torta  545 gr • romantica • sofisticata",
                            "grammage": 545,
                            "price": "5.99",
                            "price_label": "€ 5,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "romantica",
                                "sofisticata"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Dolci",
                            "blueprint": {
                                "top": 33.693693693693696,
                                "left": 50.09009009009009,
                                "width": 24.04983108108108,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/carte_dor_torta_romantica_1493712080_1503394851.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/ALGIDA_TORTA_SOFISTICATA_1493212410_1510852853.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_gBRdpb1_wtr1ZiH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723041,
                            "field1": "ALGIDA  ",
                            "field2": "magnum 4 stecchi",
                            "field3": "vari gusti",
                            "field4": "varie grammature",
                            "description": "ALGIDA   magnum 4 stecchi vari gusti varie grammature",
                            "grammage": 0,
                            "price": "2.89",
                            "price_label": "€ 2,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Gelati",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 25.855855855855857,
                                "width": 24.04983108108108,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/ALGIDA_MAGNUM_CLASSICO_1525246466.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/algida_magnum_almond_1530526268.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_UPYMD7O_BoFdCsh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723040,
                            "field1": "SOAVEGEL",
                            "field2": "soavelli",
                            "field3": "vari gusti",
                            "field4": "200 gr",
                            "description": "SOAVEGEL soavelli vari gusti 200 gr",
                            "grammage": 200,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Preparati",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 50.18018018018018,
                                "width": 24.04983108108108,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/42690031_SOAVEGEL_SOAVELLI_FORMAGGIO_AS_200_g_1434016278.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/42690032_SOAVEGEL_SOAVELLI_PROSFORM_AS_200_g_1434016285.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/42690035_SOAVEGEL_SOAVELLI_PIZZAIOLA_AS_200_g_1434016293.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_jJYVFnQ_c1DfxoY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723044,
                            "field1": "findus",
                            "field2": "pepite di salmone",
                            "field3": "180 gr",
                            "field4": "",
                            "description": "findus pepite di salmone 180 gr",
                            "grammage": 180,
                            "price": "0.00",
                            "price_label": "3 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Preparati",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 25.945945945945947,
                                "width": 24.04983108108108,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/40360401_FINDUS_PEPITE_DI_SALMONE_200_1567698948_1569425305.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_1aOAj9P_xPP5Eee.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723038,
                            "field1": "FINDUS",
                            "field2": "filetti d’alaska gratinati - 280/380 gr",
                            "field3": "",
                            "field4": "",
                            "description": "FINDUS filetti d’alaska gratinati - 280/380 gr • merluzzo patate e rosmarino • pomodoro e basilico • erbe mediterranee • funghi champignon • salmone melanzane e pomodori • zucchine e semi",
                            "grammage": 0,
                            "price": "0.00",
                            "price_label": "2 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "merluzzo patate e rosmarino",
                                "pomodoro e basilico",
                                " erbe mediterranee",
                                "funghi champignon",
                                "salmone melanzane e pomodori ",
                                "zucchine e semi"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 49.90990990990991,
                                "width": 24.04983108108108,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/40396069_Gratinati_Erbe_Mediterranee_1508850721_1508925981_ij1Zhh4.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/40360244_Gratinati_Salmone_1508850888_1509028316.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/40360397_FINDUS_GRATINATI_ZUCCHINE_SEMI_1567438880_1571482076.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_DaTcLLt_0sjjWFV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723047,
                            "field1": "AMADORI",
                            "field2": "bastoncini ",
                            "field3": "280 gr ",
                            "field4": "",
                            "description": "AMADORI bastoncini  280 gr ",
                            "grammage": 280,
                            "price": "0.00",
                            "price_label": "1 bollino",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 25.945945945945947,
                                "width": 24.04983108108108,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Amadori_Bastoncini_280gr_1454067151_1486543896_1505314454.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_lvchMmj_B7uGmwt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723045,
                            "field1": "pizzoli",
                            "field2": "patasnella",
                            "field3": "barchette",
                            "field4": "600 gr",
                            "description": "pizzoli patasnella barchette 600 gr",
                            "grammage": 600,
                            "price": "0.00",
                            "price_label": "2 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Verdure",
                            "blueprint": {
                                "top": 81.26126126126127,
                                "left": 49.90990990990991,
                                "width": 24.04983108108108,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Patasnella_Barchette_600g_1508433296.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_iynW3hY_gXtNQO0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723179,
                            "field1": "FRISKIES",
                            "field2": "gatto 375 gr",
                            "field3": "• crock & soft manzo, pollo e verdure",
                            "field4": "• sterilizzati tacchino",
                            "description": "FRISKIES gatto 375 gr • crock & soft manzo, pollo e verdure • sterilizzati tacchino",
                            "grammage": 375,
                            "price": "1.10",
                            "price_label": "€ 1,10",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "sterilizzati tacchino",
                                "crock & soft manzo pollo e verdure"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 17.83783783783784,
                                "left": 74.86908783783784,
                                "width": 25.130912162162158,
                                "height": 15.585585585585584
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/purina_friskies_gatti_sterilizzati_375_gr_1554109377.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/purina_friskies_crock_e_soft_375_gr_1554109377.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_EhOayaa_vDkYvBq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723176,
                            "field1": "purina FRISKIES",
                            "field2": "crocchette per cane ",
                            "field3": "1,5 kg",
                            "field4": "• junior • mini menù • nutri soft",
                            "description": "purina FRISKIES crocchette per cane  1,5 kg • junior • mini menù • nutri soft",
                            "grammage": 1500,
                            "price": "3.59",
                            "price_label": "€ 3,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                " junior ",
                                "mini menù",
                                "nutri soft"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 33.693693693693696,
                                "left": 74.86908783783784,
                                "width": 25.130912162162158,
                                "height": 15.585585585585584
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Junior_15kg_1503938898.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/friskiesNutri_Soft_Pollo_1503938975_1507537580.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/friskies_Mini_Menu_Pollo_1503938937_1507537580.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_PMA33TT_HLUwfsz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723177,
                            "field1": "PURINA FRISKIES",
                            "field2": "croccantini per gatto",
                            "field3": "2 kg",
                            "field4": "• manzo pollo e verdure • tonno e verdure",
                            "description": "PURINA FRISKIES croccantini per gatto 2 kg • manzo pollo e verdure • tonno e verdure",
                            "grammage": 2000,
                            "price": "3.69",
                            "price_label": "€ 3,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                " manzo pollo e verdure",
                                "tonno e verdure"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 74.86908783783784,
                                "width": 25.130912162162158,
                                "height": 15.585585585585584
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/friskies_manzo_2_kg_1553593362_1554806089.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/friskies_tonno_2_kg_1553593363_1554806090.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_FcunPmd_eis36Bl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723174,
                            "field1": "IL TAPPETINO",
                            "field2": "traversa",
                            "field3": "60 x 60 cm",
                            "field4": "",
                            "description": "IL TAPPETINO traversa 60 x 60 cm",
                            "grammage": null,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Care",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 74.86908783783784,
                                "width": 25.130912162162158,
                                "height": 15.585585585585584
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/il_tappetino_multiuso_60x60_1491321240.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_5ZNh7mN_ypYOrNS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723175,
                            "field1": "IL TAPPETINO",
                            "field2": "traversa",
                            "field3": "60 x 90 cm",
                            "field4": "",
                            "description": "IL TAPPETINO traversa 60 x 90 cm",
                            "grammage": null,
                            "price": "2.69",
                            "price_label": "€ 2,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Care",
                            "blueprint": {
                                "top": 81.26126126126127,
                                "left": 74.86908783783784,
                                "width": 25.130912162162158,
                                "height": 16.036036036036037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/il_tappetino_60x90_1500028119.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_IfVinD7_nnCxsi4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723178,
                            "field1": "GOURMET GOLD",
                            "field2": "per gatto 85 gr",
                            "field3": "",
                            "field4": "",
                            "description": "GOURMET GOLD per gatto 85 gr • mousse - manzo - pesce oceano - pollo delicato • dadini - fegatini e pollo - tacchino e anatra • patè coniglio ",
                            "grammage": 85,
                            "price": "0.49",
                            "price_label": "€ 0,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "mousse - manzo - pesce oceano - pollo delicato",
                                "dadini - fegatini e pollo - tacchino e anatra",
                                "patè coniglio "
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 5.990990990990991,
                                "left": 74.77477477477477,
                                "width": 25.225225225225227,
                                "height": 11.441441441441441
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/3275_purina_1542820909_1554365017_1554805884_DBh8kV6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/3276_1522753393_1554365018_1554805885_aNBjcQf.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/23644_1528377516_1554365019_1554805886_PBlp7Q5.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/gourmet_gold_tacchino_e_anatra_dadini_85gr_1554806024_gvKQzGP.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_mzCUZYX_REbnDYZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "/media/interactive_flyer_34/pages/page_5.jpg",
                    "products": [
                        {
                            "item_id": 724476,
                            "field1": "GRANAROLO ",
                            "field2": "latte uht",
                            "field3": "parzialmente scremato",
                            "field4": "brick 1 lt",
                            "description": "GRANAROLO  latte uht parzialmente scremato brick 1 lt",
                            "grammage": 0,
                            "price": "0.65",
                            "price_label": "€ 0,65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 24.139921171171167,
                                "height": 31.98198198198198
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/granarolo_latte_uht_ps_lt._1_1494420919_1495180235_1500652370.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_d5zEac0_L42ziN0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723011,
                            "field1": "BUITONI",
                            "field2": "basi",
                            "field3": "230 gr",
                            "field4": "• la sfoglia rotonda • la brisée • la frolla sottile",
                            "description": "BUITONI basi 230 gr • la sfoglia rotonda • la brisée • la frolla sottile",
                            "grammage": 230,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "la sfoglia rotonda",
                                "la brisée ",
                                "la frolla sottile"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 0.0,
                                "left": 23.783783783783782,
                                "width": 30.266047297297302,
                                "height": 31.98198198198198
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Buitoni_Sfoglia_rotonda_230_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Buitoni_la_Brisee_230_gr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Buitoni_Frolla_Sottile_230_gr_1513094228.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_FVAtsDH_yOaIbGA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724478,
                            "field1": "blu italia ",
                            "field2": "hamburger di cotto",
                            "field3": "150 gr",
                            "field4": "",
                            "description": "blu italia  hamburger di cotto 150 gr",
                            "grammage": 150,
                            "price": "0.95",
                            "price_label": "€ 0,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 0.0,
                                "left": 53.24324324324325,
                                "width": 22.878659909909906,
                                "height": 31.98198198198198
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/blu_italia_hamburger_cotto_150_gr_1519313422.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_TsxQbZl_UPHe5tT.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 724477,
                            "field1": "AIA",
                            "field2": "wudy classico snack",
                            "field3": "4 wurstel ",
                            "field4": "100 gr",
                            "description": "AIA wudy classico snack 4 wurstel  100 gr",
                            "grammage": 400,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 0.0,
                                "left": 76.2204391891892,
                                "width": 23.779560810810807,
                                "height": 31.98198198198198
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/wudy_aia_snack_mini_classico_100_gr_1490178660_1493018219__Z44UUQl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_88WNRqV_T15lR20.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723014,
                            "field1": "gran cucina",
                            "field2": "panna",
                            "field3": "vegetale",
                            "field4": "200 gr",
                            "description": "gran cucina panna vegetale 200 gr",
                            "grammage": 200,
                            "price": "0.59",
                            "price_label": "€ 0,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Latte",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 0.0,
                                "width": 20.62640765765766,
                                "height": 15.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Gran_Cucina_panna_vegetale_200ml_1494660484_1558969281.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_acgcjgc_Uu4CQJv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723033,
                            "field1": "parmalat ",
                            "field2": "zymil formaggio fresco",
                            "field3": "senza lattosio",
                            "field4": "150 gr",
                            "description": "parmalat  zymil formaggio fresco senza lattosio 150 gr",
                            "grammage": 150,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 20.54054054054054,
                                "width": 19.725506756756754,
                                "height": 15.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/zymil_spalmabile_150g_1530183563.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_lLkLJw1_GerBYpm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723020,
                            "field1": "GALBANI",
                            "field2": "santa lucia ",
                            "field3": "mascarpone",
                            "field4": "250 gr",
                            "description": "GALBANI santa lucia  mascarpone 250 gr",
                            "grammage": 250,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Latte",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 40.18018018018018,
                                "width": 19.545326576576574,
                                "height": 15.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Mascarpone_Santa_Lucia_250g_1478248910_1480587090.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_f6cth1T_mfFzPN1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723031,
                            "field1": "OSELLA",
                            "field2": "robiola ",
                            "field3": "100 gr",
                            "field4": "",
                            "description": "OSELLA robiola  100 gr",
                            "grammage": 100,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 33.333333333333336,
                                "left": 59.90990990990991,
                                "width": 19.545326576576574,
                                "height": 15.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/robiolo_osella_100_gr_1470318199.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_60fqvHn_zWm9sUe.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723029,
                            "field1": "coraya ",
                            "field2": "bastoncini di mare",
                            "field3": "180 gr",
                            "field4": "",
                            "description": "coraya  bastoncini di mare 180 gr",
                            "grammage": 180,
                            "price": "2.19",
                            "price_label": "€ 2,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 79.37359234234233,
                                "width": 20.62640765765766,
                                "height": 15.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Coraya_Bastoncini_di_Mare_gr_180_1564039415.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_HL4QiMH_0Yu2EQx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723026,
                            "field1": "exquisa",
                            "field2": "• formaggio spalmabile classico 300 gr",
                            "field3": "• fiocchi fitline 350 gr",
                            "field4": "",
                            "description": "exquisa • formaggio spalmabile classico 300 gr • fiocchi fitline 350 gr",
                            "grammage": 0,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                " formaggio spalmabile classico 300 gr",
                                "fiocchi fitline 350 gr"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 0.0,
                                "width": 40.08586711711712,
                                "height": 15.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Exquisa_formaggio_classico_300g_1531739989_1532007813_1540899829.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/16948_ExquisaFitLineFiocchiDiLatte350gr_1568729803_1571481693.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_XsEtfn4_UvovDOx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723035,
                            "field1": "PREALPI",
                            "field2": "burro ",
                            "field3": "metà metà",
                            "field4": "rotolo 250 gr",
                            "description": "PREALPI burro  metà metà rotolo 250 gr",
                            "grammage": 250,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 40.270270270270274,
                                "width": 19.455236486486484,
                                "height": 15.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/902_prealpi_meta_250_gr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_jicOcls_UIL8FXR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        
                        {
                            "item_id": 723030,
                            "field1": "BERETTA",
                            "field2": "spuntino zero 24 maxi",
                            "field3": "salame milano",
                            "field4": "66 gr",
                            "description": "BERETTA spuntino zero 24 maxi salame milano 66 gr",
                            "grammage": 66,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 49.27927927927928,
                                "left": 79.46368243243244,
                                "width": 20.53631756756757,
                                "height": 15.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/6691036_giu_2016_1467196795_1530717839.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_WasU7J4_QBbxZXE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723022,
                            "field1": "PARMAREGGIO",
                            "field2": "parmigiano reggiano  ",
                            "field3": "grattugiato 30 mesi ",
                            "field4": "60 gr",
                            "description": "PARMAREGGIO parmigiano reggiano   grattugiato 30 mesi  60 gr",
                            "grammage": 60,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 0.0,
                                "width": 20.53631756756757,
                                "height": 15.585585585585584
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Parmareggio_Grattugiato_30_Mesi_60gr_1571813651.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_BsiSLLF_ctiuUPZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723023,
                            "field1": "PARMAREGGIO",
                            "field2": "parmigiano reggiano porzionato ",
                            "field3": "30 mesi",
                            "field4": "250 gr",
                            "description": "PARMAREGGIO parmigiano reggiano porzionato  30 mesi 250 gr",
                            "grammage": 250,
                            "price": "5.49",
                            "price_label": "€ 5,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 65.4954954954955,
                                "left": 20.63063063063063,
                                "width": 19.545326576576574,
                                "height": 15.405405405405405
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Parmareggio_porzionato_1571814803.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_VKBL3Gu_g4M5VgR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723024,
                            "field1": "PARMAREGGIO",
                            "field2": "parmigiano reggiano stick  ",
                            "field3": "30 mesi",
                            "field4": "100 gr",
                            "description": "PARMAREGGIO parmigiano reggiano stick   30 mesi 100 gr",
                            "grammage": 100,
                            "price": "2.69",
                            "price_label": "€ 2,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 40.18018018018018,
                                "width": 19.545326576576574,
                                "height": 15.405405405405405
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/parmareggio_Parmiggiano_reggiano_stick.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_YZHr6j5_5GrZZrj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723028,
                            "field1": "BERETTA",
                            "field2": "zero 24 stick minimini ",
                            "field3": "60 gr",
                            "field4": "• classici • piccanti",
                            "description": "BERETTA zero 24 stick minimini  60 gr • classici • piccanti",
                            "grammage": 60,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classici",
                                "Piccanti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Snack",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 60.0,
                                "width": 19.545326576576574,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Beretta_stick_minimini_Classici_60_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Beretta_stick_minimini_Piccanti_60_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_kUpsUWv_CAbqu1l.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723013,
                            "field1": "CASA MODENA",
                            "field2": "i cubetti di pancetta ",
                            "field3": "110 gr",
                            "field4": "• dolce • affumicata",
                            "description": "CASA MODENA i cubetti di pancetta  110 gr • dolce • affumicata",
                            "grammage": 110,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Dolce",
                                "Affumicata"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Snack",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 79.45945945945945,
                                "width": 20.54054054054054,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/principale_8004225059883_1530775576.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/principale_8004225059876_1530775577.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_oxWGKeO_ADKoM57.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723015,
                            "field1": "beretta",
                            "field2": "fresca salumeria",
                            "field3": "prosciutto cotto ",
                            "field4": "120 gr",
                            "description": "beretta fresca salumeria prosciutto cotto  120 gr",
                            "grammage": 120,
                            "price": "2.19",
                            "price_label": "€ 2,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 0.0,
                                "width": 20.54054054054054,
                                "height": 16.306306306306308
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/BERETTA_Prosciutto-Cotto-brace_1504708277_1505289000_15059_B0mChXx.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_6PD1bK2_2LZAFF6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723016,
                            "field1": "BERETTA",
                            "field2": "fresca salumeria",
                            "field3": "prosciutto crudo  ",
                            "field4": "100 gr",
                            "description": "BERETTA fresca salumeria prosciutto crudo   100 gr",
                            "grammage": 100,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 20.72072072072072,
                                "width": 19.45945945945946,
                                "height": 16.306306306306308
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/189220_Fresca_Salumeria_PROSCIUTTO_CRUDO_f420_Maxi_1475659_S8biNcn.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_kPBMVHA_rj9V9wC.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723027,
                            "field1": "SENFTER",
                            "field2": "wurstel hot dog",
                            "field3": "250 gr",
                            "field4": "• classico • con speck",
                            "description": "SENFTER wurstel hot dog 250 gr • classico • con speck",
                            "grammage": 250,
                            "price": "1.65",
                            "price_label": "€ 1,65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Con Speck"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 40.270270270270274,
                                "width": 19.45945945945946,
                                "height": 15.855855855855856
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/senfter_hotdog_1497446823.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_xIB1CxD_bjEg2KZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723181,
                            "field1": "fage",
                            "field2": "yogurt trublend",
                            "field3": "150 gr",
                            "field4": "• lampone • fragola • mirtillo • ciliegia • pesca • vaniglia",
                            "description": "fage yogurt trublend 150 gr • lampone • fragola • mirtillo • ciliegia • pesca • vaniglia",
                            "grammage": 150,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Lampone",
                                "Fragola",
                                "Mirtillo",
                                "Ciliega",
                                "Pesca",
                                "Vaniglia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 59.90990990990991,
                                "width": 40.09009009009009,
                                "height": 15.855855855855856
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/32235540_FAGE_TRUBLEND_1.6_VANIGLIA_150_1568023100_1571069_qXxnANE.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/32235542_FAGE_TRUBLEND_1.6_LAMPONE_150_1568023102_15710691_KZMmYnt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/32235543_FAGE_TRUBLEND_16_FRAGOLA_150_1568023103_157106919_07o5G2i.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/32235545_FAGE_TRUBLEND_16_CILIEGIA_150_1568023099_15710691_6vO84Sl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/32235546_FAGE_TRUBLEND_16_MIRTILLI_150_1568023104_15710691_ZWXiop0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_WyFuvTv_QLXxt0M.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723025,
                            "field1": "GALBANI",
                            "field2": "santa lucia",
                            "field3": "burro",
                            "field4": "250 gr",
                            "description": "GALBANI santa lucia burro 250 gr",
                            "grammage": 250,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 49.414414414414416,
                                "left": 59.864864864864856,
                                "width": 19.36936936936937,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Burro_Santa_Lucia_250gr_new_1480435277.jpg",
                                    "cropped": true
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_F1qAtR1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        
                    ]
                },
                {
                    "number": 6,
                    "image_file": "/media/interactive_flyer_34/pages/page_6.jpg",
                    "products": [
                        {
                            "item_id": 723010,
                            "field1": "garofalo",
                            "field2": "pasta fresca ripiena 230 gr",
                            "field3": "",
                            "field4": "",
                            "description": "garofalo pasta fresca ripiena 230 gr • mezzelune - burrata e 'nduja - ricotta di bufala, porcini e tartufo • tortellini prosciutto crudo • ravioli - capresi - ricotta di bufala e spinaci • girasoli ricotta e scorzette di limoni di sicilia",
                            "grammage": 230,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "tortellini prosciutto crudo",
                                "mezzelune - burrata e 'nduja - ricotta di bufala porcini e tartufo",
                                "ravioli - capresi - ricotta di bufala e spinaci",
                                "girasoli ricotta e scorzette di limoni di sicilia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 0.0,
                                "left": 0.0,
                                "width": 30.900900900900904,
                                "height": 31.891891891891895
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Garofalo_mezzelune_burrata_1480073553_1541687542.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Garofalo_mezzelune_ricotta_di_bufala_1480073554_1541687544.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Garofalo_ravioli_capresi_1480073555_1541687545.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_y5EAfLi_r7rNblB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723018,
                            "field1": "PHILADELPHIA",
                            "field2": "original",
                            "field3": "250 gr",
                            "field4": "",
                            "description": "PHILADELPHIA original 250 gr",
                            "grammage": 250,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 0.0,
                                "left": 31.081081081081084,
                                "width": 26.756756756756754,
                                "height": 31.891891891891895
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Philadelphia_gr_250_1523263220_1536591314.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_5835Rlr_e8Jedl8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723017,
                            "field1": "granarolo ",
                            "field2": "ricotta",
                            "field3": "250 gr",
                            "field4": "",
                            "description": "granarolo  ricotta 250 gr",
                            "grammage": 250,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 0.0,
                                "left": 58.01801801801802,
                                "width": 21.53153153153153,
                                "height": 31.891891891891895
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/8005264_1525445679_1527257286.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_DUveG5y_HdrNuAq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723009,
                            "field1": "STUFFER",
                            "field2": "yogurt intero",
                            "field3": "vari gusti",
                            "field4": "2 x 125 gr",
                            "description": "STUFFER yogurt intero vari gusti 2 x 125 gr",
                            "grammage": 250,
                            "price": "0.50",
                            "price_label": "€ 0,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 0.0,
                                "left": 79.1891891891892,
                                "width": 20.81081081081081,
                                "height": 31.891891891891895
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Stuffer_yogurt_mela_e_banana.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_QFKjfPY_TsSNiEf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723095,
                            "field1": "valsoia",
                            "field2": "vitasoya",
                            "field3": "soyadrink",
                            "field4": "1 lt",
                            "description": "valsoia vitasoya soyadrink 1 lt",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 0.0,
                                "width": 20.63063063063063,
                                "height": 15.855855855855856
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/vitasoya_100_vegetale_1524129690.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_rrVzv2Q_12Xtp6j.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723003,
                            "field1": "fruttolo",
                            "field2": "dessert ",
                            "field3": "cioccolato e fiordilatte",
                            "field4": "4 x 62,5 gr",
                            "description": "fruttolo dessert  cioccolato e fiordilatte 4 x 62,5 gr",
                            "grammage": 250,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 20.63063063063063,
                                "width": 19.549549549549546,
                                "height": 15.855855855855856
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/C_NST000_FOOD_Fruttolo_Dessert_PACKSHOT_Ciocco_Nocc_IT_4x6_XkN8ZSa.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_HXX1tnm_g9wvGwA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723004,
                            "field1": "fruttolo",
                            "field2": "yogurt smarties",
                            "field3": "120 gr",
                            "field4": "• fragola • vaniglia",
                            "description": "fruttolo yogurt smarties 120 gr • fragola • vaniglia",
                            "grammage": 120,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fruttolo Smarties",
                                "Fragola - Vaniglia",
                                "Galak",
                                "Chocoballs"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 40.18018018018018,
                                "width": 19.36936936936937,
                                "height": 15.855855855855856
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/nestle_fruttolo_smarties_fragola_1440154177_1441009493.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/nestle_fruttolo_smarties_vaniglia_1440154191_1441009499.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_m54a5pf_kO5pIjy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723145,
                            "field1": "ADEZ",
                            "field2": "bevanda senza zuccheri aggiunti",
                            "field3": "800 ml",
                            "field4": "",
                            "description": "ADEZ bevanda senza zuccheri aggiunti 800 ml • mandorla • cocco",
                            "grammage": 800,
                            "price": "0.00",
                            "price_label": "1 bollino",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                " mandorla",
                                "cocco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 59.729729729729726,
                                "width": 40.270270270270274,
                                "height": 31.53153153153153
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/489363_AdeZ_Mandorla_1531302891_1562593027.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/489364_Adez_coco_1531302893_1562593028.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_BuX1kNI_DOgzJKL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723006,
                            "field1": "nestlè",
                            "field2": "fruttolo",
                            "field3": "difese immunitarie",
                            "field4": "fragola 6 x 90 gr",
                            "description": "nestlè fruttolo difese immunitarie fragola 6 x 90 gr",
                            "grammage": 540,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 49.369369369369366,
                                "left": 0.0,
                                "width": 20.63063063063063,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Fruttolo_Drink_6x90gr_1571230695.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_cxGsN9Y_5oxi1KN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723180,
                            "field1": "nestlè",
                            "field2": "galak",
                            "field3": "snack",
                            "field4": "4 x 26 gr",
                            "description": "nestlè galak snack 4 x 26 gr",
                            "grammage": 104,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 49.369369369369366,
                                "left": 20.63063063063063,
                                "width": 19.549549549549546,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Galak_snack_fresco_1571756685.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_DGMh2zo_IjRq4eC.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723007,
                            "field1": "perugina",
                            "field2": "fondente nero mousse con sottilissime sfoglie",
                            "field3": "228 gr",
                            "field4": "",
                            "description": "perugina fondente nero mousse con sottilissime sfoglie 228 gr",
                            "grammage": 228,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "cioccolato",
                                "cioccolato fondente"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 40.09009009009009,
                                "width": 19.549549549549546,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Perugina_Nero_sfoglie_cioccolato_1571153528.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Perugina_Nero_sfoglie_cioccolato_fondente_Fondente_1571153528.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_WewUIjz_PgR3QHz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723008,
                            "field1": "PARMALAT",
                            "field2": "yogurt",
                            "field3": "8 x 125 gr",
                            "field4": "• 0,1% di grassi • in pezzi • vellutato ",
                            "description": "PARMALAT yogurt 8 x 125 gr • 0,1% di grassi • in pezzi • vellutato ",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "in pezzi",
                                "vellutato"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 0.0,
                                "width": 20.72072072072072,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/parmalat_yogurt_vellutato_125_gr_x_8_1467104364.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/parmalat_yogurt_pezzi_magro_125_gr_x_8_1467104589.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/parmalat_yogurt_frutta_pezzi_125_gr_x_8_1467104596.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_hO9JPVq_WAu0zk4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723002,
                            "field1": "fruttolo",
                            "field2": "maxi duo ",
                            "field3": "4 x 100 gr",
                            "field4": "• fragola fiordilatte • fragola pesca • fragola vaniglia",
                            "description": "fruttolo maxi duo  4 x 100 gr • fragola fiordilatte • fragola pesca • fragola vaniglia",
                            "grammage": 400,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "fragola fiordilatte",
                                " fragola pesca ",
                                " fragola vaniglia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 20.63063063063063,
                                "width": 19.549549549549546,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Nestle_fruttolo_maxi_duo_fior_di_latte_e_fragola_151991864_718jXME.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Nestle_fruttolo_maxi_duo_pesca_e_fragola_1519918643_151999_sh8hSFg.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Nestle_fruttolo_maxi_duo_vaniglia_e_fragola_1519918644_151_EXMjocu.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_X0c9Blt_ciU9zd0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723032,
                            "field1": "VALLELATA",
                            "field2": "scamorza",
                            "field3": "180 gr",
                            "field4": "• fresca • affumicata",
                            "description": "VALLELATA scamorza 180 gr • fresca • affumicata",
                            "grammage": 180,
                            "price": "0.00",
                            "price_label": "2 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 40.270270270270274,
                                "width": 19.549549549549546,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Vallelata_scamorza_fresca_180_gr_1465484219.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/vallelata_scamorza_affumicata_180_gr_1465484231.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_AHOzhI4_M5JxXZB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723021,
                            "field1": "GALBANI",
                            "field2": "galbanino ",
                            "field3": "270 gr",
                            "field4": "",
                            "description": "GALBANI galbanino  270 gr",
                            "grammage": 270,
                            "price": "0.00",
                            "price_label": "1 bollino",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 59.63963963963963,
                                "width": 19.549549549549546,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Galbani_Galbanino_930g_1556293512.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_wL5csnr_qIRfW2c.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723034,
                            "field1": "sterilgarda",
                            "field2": "latte e cacao",
                            "field3": "375 ml",
                            "field4": "",
                            "description": "sterilgarda latte e cacao 375 ml",
                            "grammage": 375,
                            "price": "0.00",
                            "price_label": "3 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 79.36936936936937,
                                "width": 20.63063063063063,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/0091322_1552666850.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_wbhcWXd_g0Xiolp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723052,
                            "field1": "MELLIN",
                            "field2": "omogeneizzato di pesce",
                            "field3": "2 x 80 gr",
                            "field4": "• orata • platessa",
                            "description": "MELLIN omogeneizzato di pesce 2 x 80 gr • orata • platessa",
                            "grammage": 160,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Orata",
                                "Platessa"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.26126126126127,
                                "left": 0.0,
                                "width": 20.63063063063063,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/mellin_omo_pesce_x2_1541754751_1541777859.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_ae8L7KI_FwihPUn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723049,
                            "field1": "PLASMON",
                            "field2": "omogeneizzati alla carne ",
                            "field3": "2 x 80 gr",
                            "field4": "• agnello • manzo • pollo • prosciutto • vitello • cavallo",
                            "description": "PLASMON omogeneizzati alla carne  2 x 80 gr • agnello • manzo • pollo • prosciutto • vitello • cavallo",
                            "grammage": 160,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "agnello",
                                "manzo",
                                "pollo",
                                "prosciutto",
                                "vitello",
                                "cavallo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.35135135135135,
                                "left": 20.63063063063063,
                                "width": 19.549549549549546,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/plasmon_omogeneizzato_vitello_1496746408_1502112967.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_4UVujJB_avVL1Jd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723050,
                            "field1": "PLASMON",
                            "field2": "omogeneizzati alla frutta",
                            "field3": "2 x 104 gr",
                            "field4": "• banana/mela • mela • pera • prugna • 4 frutti",
                            "description": "PLASMON omogeneizzati alla frutta 2 x 104 gr • banana/mela • mela • pera • prugna • 4 frutti",
                            "grammage": 208,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "banana/mela",
                                "mela",
                                "pera",
                                "prugna",
                                "4frutti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 80.99099099099098,
                                "left": 40.270270270270274,
                                "width": 19.549549549549546,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/14024_1523862660_1524754377.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_jiFOwe0_AIlVcnB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723051,
                            "field1": "mellin",
                            "field2": "le pastine",
                            "field3": "320 gr",
                            "field4": "• cuoricini • semini • stelline • tempestina",
                            "description": "mellin le pastine 320 gr • cuoricini • semini • stelline • tempestina",
                            "grammage": 320,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "cuoricini",
                                "semini",
                                "stelline",
                                "tempestina"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.26126126126127,
                                "left": 60.0,
                                "width": 19.45945945945946,
                                "height": 15.765765765765765
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/mellin_pastina_stelline_1541754794_1549360934.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Mellin_Le_Pastine_Cuoricini_1549618996.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Mellin_Le_Pastine_Semini_1549618997.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_NlWqQAq_SmbyOOg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723048,
                            "field1": "plasmon",
                            "field2": "il biscotto dei bambini",
                            "field3": "2 x 600 gr",
                            "field4": "",
                            "description": "plasmon il biscotto dei bambini 2 x 600 gr",
                            "grammage": 1200,
                            "price": "6.99",
                            "price_label": "€ 6,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.26126126126127,
                                "left": 79.54954954954954,
                                "width": 20.450450450450454,
                                "height": 16.126126126126124
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/0072774_Plasmon_Biscotti_2x600_1529996741.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_M5mhkAa_8kpwgR1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "/media/interactive_flyer_34/pages/page_7.jpg",
                    "products": [
                        {
                            "item_id": 723085,
                            "field1": "KIMBO",
                            "field2": "caffè ",
                            "field3": "gold medal ",
                            "field4": "500 gr",
                            "description": "KIMBO caffè  gold medal  500 gr",
                            "grammage": 500,
                            "price": "5.49",
                            "price_label": "€ 5,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 8.64864864864865,
                                "left": 0.0,
                                "width": 24.954954954954957,
                                "height": 23.243243243243246
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/465_Kimbo_gold_medal_500_gr.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Q9WqDhN_6qNPK3v.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723189,
                            "field1": "LA GIULIA",
                            "field2": "caramelle vari gusti",
                            "field3": "200 gr",
                            "field4": "",
                            "description": "LA GIULIA caramelle vari gusti 200 gr",
                            "grammage": 200,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 8.73873873873874,
                                "left": 25.585585585585587,
                                "width": 23.873873873873876,
                                "height": 23.243243243243246
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/giulia_fruitland_ripiene_300_g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/FRUITLAND_GELEE_1561458764_1571483009.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_3wBWcs1_n5AfOgP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723057,
                            "field1": "SPERLARI",
                            "field2": "torroncini",
                            "field3": "vari gusti",
                            "field4": "130 gr",
                            "description": "SPERLARI torroncini vari gusti 130 gr",
                            "grammage": 130,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 10.36036036036036,
                                "left": 49.0990990990991,
                                "width": 23.243243243243246,
                                "height": 21.44144144144144
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/sperlari_torroncini_classici_130_gr_1509701534.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/sperlari_torroncini_teneri_mandorla_130_gr_1509701534.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/sperlari_zanzibar_cioccolato_gianduia_130_gr_1509701534.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Y5To8dM_S3EvxsP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723092,
                            "field1": "MULINO BIANCO",
                            "field2": "biscotti classici 350/400 gr",
                            "field3": "• galletti • macine • tarallucci • girotondi",
                            "field4": "",
                            "description": "MULINO BIANCO biscotti classici 350/400 gr • galletti • macine • tarallucci • girotondi",
                            "grammage": 0,
                            "price": "0.95",
                            "price_label": "€ 0,95",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "galletti",
                                "macine",
                                "tarallucci",
                                "girotondi"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 0.990990990990991,
                                "left": 72.07207207207206,
                                "width": 27.92792792792793,
                                "height": 30.81081081081081
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Galletti_350g_sx2_1506522795_1506603307_1509028965.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Macine_350g_sx2_1506522793_1506603308_1509028966.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Tarallucci_350g_sx2_1509004264_1509028967.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_9Uua7cZ_NbKtCJo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723069,
                            "field1": "LAVAZZA",
                            "field2": "caffè decaffeinato ",
                            "field3": "dek classico",
                            "field4": "250 gr",
                            "description": "LAVAZZA caffè decaffeinato  dek classico 250 gr",
                            "grammage": 250,
                            "price": "2.29",
                            "price_label": "€ 2,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.333333333333336,
                                "left": 0.0,
                                "width": 20.54054054054054,
                                "height": 16.036036036036037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Dec_gusto_classico_250_sx_1471355112.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_dO197S2_OQRV4RU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723070,
                            "field1": "nikol",
                            "field2": "caffè",
                            "field3": "50 cialde",
                            "field4": "universali monodose",
                            "description": "nikol caffè 50 cialde universali monodose",
                            "grammage": 0,
                            "price": "3.89",
                            "price_label": "€ 3,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 20.72072072072072,
                                "width": 19.27927927927928,
                                "height": 16.036036036036037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cialde_nikol_1558516700.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_2euAagV_MnkTRLX.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723083,
                            "field1": "BONOMELLI",
                            "field2": "tisana ",
                            "field3": "16 filtri",
                            "field4": "vari tipi ",
                            "description": "BONOMELLI tisana  16 filtri vari tipi ",
                            "grammage": 0,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.24324324324325,
                                "left": 40.18018018018018,
                                "width": 19.63963963963964,
                                "height": 16.036036036036037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/BONOMELLI_TISANA_VENTRE_SGONFIO_1548935960_1571385226_1571483240.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/xDepurativa-bonomelli_80_600.png.pagespeed.ic.leUPcGEsE5_1_uMqOuqK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_z6IaEnW_QoKumAo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723183,
                            "field1": "RIGONI DI ASIAGO",
                            "field2": "fiordifrutta confettura biologica",
                            "field3": "vari gusti",
                            "field4": "250 gr",
                            "description": "RIGONI DI ASIAGO fiordifrutta confettura biologica vari gusti 250 gr",
                            "grammage": 250,
                            "price": "2.69",
                            "price_label": "€ 2,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.333333333333336,
                                "left": 59.90990990990991,
                                "width": 19.45945945945946,
                                "height": 16.036036036036037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Rigoni_albicocche_fiordifrutta.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Rigoni_Asiago_confettura_arance_amare_1435339135_1474554172.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Rigoni_confettura_pere_g_250_1474554184.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Rigoni_Asiago_confettura_Frutti_Bosco_1435339145_1474554197.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Qmcpwvw_GtWX97w.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723091,
                            "field1": "kellogg's",
                            "field2": "special k",
                            "field3": "cereali 290 gr",
                            "field4": "• fondente • frutti rossi",
                            "description": "kellogg's special k cereali 290 gr • fondente • frutti rossi",
                            "grammage": 290,
                            "price": "2.79",
                            "price_label": "€ 2,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 33.333333333333336,
                                "left": 79.54954954954954,
                                "width": 20.450450450450454,
                                "height": 16.036036036036037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Kelloggs_special_k_frutti_rossi_1511430667_1523955875.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/kellogs_nutrimi_300_gr_1511196372_1523955876.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_gS5OSsK_KRZRvft.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723071,
                            "field1": "kellogg's",
                            "field2": "extra",
                            "field3": "375 gr",
                            "field4": "• cioccolato e nocciole • cioccolato al latte • classico • frutta • nocciole caramellate",
                            "description": "kellogg's extra 375 gr • cioccolato e nocciole • cioccolato al latte • classico • frutta • nocciole caramellate",
                            "grammage": 375,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "cioccolato e nocciole",
                                "cioccolato al latte",
                                "classico",
                                "frutta",
                                "nocciole caramellate"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 49.27927927927928,
                                "left": 0.0,
                                "width": 20.450450450450454,
                                "height": 16.036036036036037
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/kellogs_extra_milk_choccolate_375_gr_1520237686_1520257094.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/kellogs_extra_original_375_gr_1520237688_1520257095.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/kellogs_extra_caramelized_nuts_375_gr_1520237687_1520257096.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/488246_1517310381_1527157958.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/488250_1517310383_1527157960.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_gV2VF5A_03boUz7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723190,
                            "field1": "colussi",
                            "field2": "frollini",
                            "field3": "300 gr",
                            "field4": "• winx con gocce cioccolato • 44 gatti",
                            "description": "colussi frollini 300 gr • winx con gocce cioccolato • 44 gatti",
                            "grammage": 300,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                " winx con gocce cioccolato",
                                "44 gatti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 49.549549549549546,
                                "left": 20.72072072072072,
                                "width": 19.45945945945946,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/colussi_biscotti_winx_1568736915.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/colussi_biscotti_44_gatti_1568736916.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_baXNQKu_t0AeSDu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723072,
                            "field1": "grancereale",
                            "field2": "classico",
                            "field3": "500 gr",
                            "field4": "",
                            "description": "grancereale classico 500 gr",
                            "grammage": 500,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 49.549549549549546,
                                "left": 40.36036036036036,
                                "width": 19.45945945945946,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/gran_cereale_biscotto_classico_500_g_1527067982.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_OpZgofq_Vq2NkH4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723073,
                            "field1": "mulino bianco",
                            "field2": "baiocchi",
                            "field3": "tubo 168 gr",
                            "field4": "",
                            "description": "mulino bianco baiocchi tubo 168 gr",
                            "grammage": 168,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 49.369369369369366,
                                "left": 59.90990990990991,
                                "width": 19.45945945945946,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Baiocchi_tubo_sx_1536079684.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_eYQt1l0_ruhAhT5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723076,
                            "field1": "cabrioni ",
                            "field2": "signor wafer",
                            "field3": "45 gr x 5",
                            "field4": "• cacao • nocciola • vaniglia",
                            "description": "cabrioni  signor wafer 45 gr x 5 • cacao • nocciola • vaniglia",
                            "grammage": 225,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 79.45945945945945,
                                "width": 20.54054054054054,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Cabrioni_Biscotti_Wafer_Multipack_5x45g_Cacao_1516200240.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Cabrioni_Biscotti_Wafer_Multipack_5x45g_Nocciola_1516200240.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Cabrioni_Biscotti_Wafer_Multipack_5x45g_Vaniglia_1516200240.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_BxVCPmr_UTsZClc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723084,
                            "field1": "colussi",
                            "field2": "ciambellina",
                            "field3": "44 gatti",
                            "field4": "230 gr",
                            "description": "colussi ciambellina 44 gatti 230 gr",
                            "grammage": 230,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 0.0,
                                "width": 20.54054054054054,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/8002590062002_ColussiCiambelle44gatti230gr_1568372143.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_SZtZU9d_Iw3RURF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723080,
                            "field1": "BAULI",
                            "field2": "6 croissant ",
                            "field3": "classico",
                            "field4": "240 gr",
                            "description": "BAULI 6 croissant  classico 240 gr",
                            "grammage": 240,
                            "price": "1.09",
                            "price_label": "€ 1,09",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 20.63063063063063,
                                "width": 19.63963963963964,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Bauli_Croissant_Classico_6pz_1445526505_1452706510_1470303_xtAyUFC.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_ZsM3E48_nDU9rpy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723074,
                            "field1": "cabrioni",
                            "field2": "ciambelline ilaria",
                            "field3": "650 gr",
                            "field4": "• classiche • integrali • cioccolato",
                            "description": "cabrioni ciambelline ilaria 650 gr • classiche • integrali • cioccolato",
                            "grammage": 650,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "classiche",
                                "integrali",
                                "cioccolato"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 40.18018018018018,
                                "width": 19.63963963963964,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Ilaria_ciambelline_Cioccolato_1558947660.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Ilaria_ciambelline_Classiche_1558947661.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Ilaria_ciambelline_Integrali_1558947661.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_ox8q5BJ_XyebifG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723075,
                            "field1": "BALOCCO",
                            "field2": "frollini ",
                            "field3": "700 gr",
                            "field4": "• faccine • girandole • gocciolotti • saracene",
                            "description": "BALOCCO frollini  700 gr • faccine • girandole • gocciolotti • saracene",
                            "grammage": 700,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "faccine",
                                "girandole",
                                "gocciolotti",
                                "saracene"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 59.90990990990991,
                                "width": 19.36936936936937,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/balocco_faccine_g._700_1487180388_1495881281.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/balocco_girandole_g._700_1487180390_1495881282.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/balocco_gocciolotti_g._700_1487180391_1495881282.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_V5nNZVQ_yygsIqF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723081,
                            "field1": "MULINO BIANCO",
                            "field2": "6 crostatine ",
                            "field3": "240 gr",
                            "field4": "• cacao • albicocca",
                            "description": "MULINO BIANCO 6 crostatine  240 gr • cacao • albicocca",
                            "grammage": 240,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "cacao",
                                "albicocca"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 79.54954954954954,
                                "width": 20.450450450450454,
                                "height": 15.675675675675677
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Crostatina_albicocca_6_pz_sx_1534934832_1535471922_1535539_ny8ZSM9.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Crostatina_cacao_6pz_sx_1540388696.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_5T5RvZW_IdOlm1c.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723079,
                            "field1": "BALCONI",
                            "field2": "trancetto 10 snack",
                            "field3": "280 gr",
                            "field4": "• cacao • albicocca",
                            "description": "BALCONI trancetto 10 snack 280 gr • cacao • albicocca",
                            "grammage": 280,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "cacao",
                                "albicocca"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 0.0,
                                "width": 20.450450450450454,
                                "height": 16.216216216216214
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/trancetto_albicocca_1552316209.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/trancetto_cacao_1552316210.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_FF59CZw_cbdgKME.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723086,
                            "field1": "mulino bianco",
                            "field2": "plumcake integrali",
                            "field3": "330 gr ",
                            "field4": "",
                            "description": "mulino bianco plumcake integrali 330 gr ",
                            "grammage": 330,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 80.99099099099098,
                                "left": 20.63063063063063,
                                "width": 19.549549549549546,
                                "height": 16.216216216216214
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Plumcake_integrale_10pz_sx_1524059575_1525079245_152545213_loC7FNp.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_9FKz4Y4_ynyihg9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723089,
                            "field1": "kinder",
                            "field2": "panecioc ",
                            "field3": "290 gr",
                            "field4": "",
                            "description": "kinder panecioc  290 gr",
                            "grammage": 290,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 80.9009009009009,
                                "left": 40.18018018018018,
                                "width": 19.549549549549546,
                                "height": 16.216216216216214
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/KINDER_PANECIOC__T10_Restyling_1489052876_1506326458.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Md67Ncz_5XX329h.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723090,
                            "field1": "MOTTA",
                            "field2": "buondì farciti 258/276 gr",
                            "field3": "• albicocca • ciliegia • cacao ",
                            "field4": "• ricoperto cioccolato",
                            "description": "MOTTA buondì farciti 258/276 gr • albicocca • ciliegia • cacao  • ricoperto cioccolato",
                            "grammage": 0,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "albicocca",
                                "ciliegia",
                                "cacao"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Colazione",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 59.729729729729726,
                                "width": 19.549549549549546,
                                "height": 16.216216216216214
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/buondi_albicocca_1460709324_1494237443.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/buondi_cacao_1460709333_1494237443.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/buondi_ciliegia_1460709343_1494237443.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/buondi_cioccolato_1460709351_1494237444.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_NLnuL9g_t7lvPvW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723088,
                            "field1": "NUTELLA",
                            "field2": "b-ready ",
                            "field3": "x 6",
                            "field4": "132 gr",
                            "description": "NUTELLA b-ready  x 6 132 gr",
                            "grammage": 132,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 79.54954954954954,
                                "width": 20.450450450450454,
                                "height": 16.216216216216214
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/nutella_b_ready_1504084757.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_4DgjxLO_HsioX6u.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 8,
                    "image_file": "/media/interactive_flyer_34/pages/page_8.jpg",
                    "products": [
                        {
                            "item_id": 723064,
                            "field1": "NOVI",
                            "field2": "tavoletta di cioccolato ",
                            "field3": "le specialità",
                            "field4": "vari gusti 100 gr ",
                            "description": "NOVI tavoletta di cioccolato  le specialità vari gusti 100 gr ",
                            "grammage": 200,
                            "price": "1.00",
                            "price_label": "€ 2,00",
                            "equivalence": 1,
                            "quantity_step": 2,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 8.693693693693692,
                                "left": 0.0,
                                "width": 24.234234234234233,
                                "height": 23.243243243243246
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/novi_tavolette_noisette_con_nocciole__1483022447_1527068858.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/novi_le_specialita_gianduia_100_gr_1459245292_1539269909.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Novi_Specialita_Bianco_100_g_1459245301_1539269909.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_4rwwMtu_7dwYMZ0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723061,
                            "field1": "nestlè",
                            "field2": "galak tavoletta di cioccolato bianco",
                            "field3": "• classica 100 gr",
                            "field4": "• poprì con cereali 90 gr",
                            "description": "nestlè galak tavoletta di cioccolato bianco • classica 100 gr • poprì con cereali 90 gr",
                            "grammage": 0,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                " classica 100 gr",
                                " poprì con cereali 90 gr"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 0.0,
                                "left": 24.324324324324323,
                                "width": 25.855855855855857,
                                "height": 32.02702702702703
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Galak_Tavoletta_100g_1482146065_a028PyF.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_T38JjXI_bzrNWRK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723056,
                            "field1": "sapori",
                            "field2": "capricci golosi",
                            "field3": "78 gr",
                            "field4": "",
                            "description": "sapori capricci golosi 78 gr",
                            "grammage": 78,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "cioccolato e nocciole",
                                "cioccolato e rhum",
                                "cioccolato e arancia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 0.0,
                                "left": 50.0,
                                "width": 26.666666666666668,
                                "height": 32.02702702702703
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Capricci-Golosi-nocciola_Cantucci_2_1542299511.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Capricci-Golosi-nocciola_Sapori_1542299511.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_gAYgrwt_B8w0Cjc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723105,
                            "field1": "MULINO BIANCO",
                            "field2": "pan carrè ",
                            "field3": "24 fette ",
                            "field4": "430 gr",
                            "description": "MULINO BIANCO pan carrè  24 fette  430 gr",
                            "grammage": 430,
                            "price": "0.60",
                            "price_label": "€ 0,60",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 0.0,
                                "left": 77.65765765765765,
                                "width": 22.34234234234234,
                                "height": 32.02702702702703
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/mulino_bianco_Pancarre_24_fette_1531132700.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_orlkzjf_RmAJemo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723065,
                            "field1": "LINDT",
                            "field2": "tavoletta di cioccolato",
                            "field3": "100 gr",
                            "field4": "",
                            "description": "LINDT tavoletta di cioccolato 100 gr",
                            "grammage": 200,
                            "price": "1.00",
                            "price_label": "€ 2,00",
                            "equivalence": 1,
                            "quantity_step": 2,
                            "grocery_label": "pz.",
                            "varieties": [
                                "latte",
                                "fondente"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 0.0,
                                "width": 20.450450450450447,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/lindt_latte_gamma_blue_1535625333.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/lindt_fondente__gamma_blue_1535625568.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_fC5mFHZ_J03GGZs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723060,
                            "field1": "condorelli",
                            "field2": "torrone morbido mandorle e pistacchi",
                            "field3": "100 gr",
                            "field4": "",
                            "description": "condorelli torrone morbido mandorle e pistacchi 100 gr",
                            "grammage": 100,
                            "price": "2.39",
                            "price_label": "€ 2,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                " ricoperto al cioccolato fondente",
                                "ricoperto alla vaniglia",
                                "morbido non ricoperto"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 20.63063063063063,
                                "width": 19.459459459459456,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Condorelli_torrone_ricoperto_di_cioccolato_100_gr_1539616675.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Condorelli_torrone_ricoperto_alla_vaniglia_100_gr_1539616675.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Condorelli_torrone_non_ricoperto_100_gr_1539616675.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_e571fns_5CcGCK6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723066,
                            "field1": "LINDT",
                            "field2": "gold tavoletta di cioccolato",
                            "field3": "300 gr",
                            "field4": "",
                            "description": "LINDT gold tavoletta di cioccolato 300 gr",
                            "grammage": 300,
                            "price": "3.39",
                            "price_label": "€ 3,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "al latte",
                                "fondente"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 40.36036036036036,
                                "width": 19.459459459459456,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Lindt_tavoletta_Gold_Latte_300_g_1448901445.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_UtIpWDj_1woYR1t.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723063,
                            "field1": "KIT KAT",
                            "field2": "family pack",
                            "field3": "8 x 20,7 gr",
                            "field4": "",
                            "description": "KIT KAT family pack 8 x 20,7 gr",
                            "grammage": 166,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 59.90990990990991,
                                "width": 19.459459459459456,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/KitKat_family_8_x_20_g_1441287855_1472637451.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_0jTHW9m_sgieOWa.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723059,
                            "field1": "la luisa",
                            "field2": "torrone morbido",
                            "field3": "150 gr",
                            "field4": "",
                            "description": "la luisa torrone morbido 150 gr",
                            "grammage": 300,
                            "price": "1.00",
                            "price_label": "€ 3,00",
                            "equivalence": 1,
                            "quantity_step": 3,
                            "grocery_label": "pz.",
                            "varieties": [
                                "al limoncello",
                                "alle mandorle e nocciole",
                                "mandorle e pistacchio",
                                "con cioccolato e nocciole"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 79.45945945945945,
                                "width": 20.540540540540533,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Luisa_torrone_limoncello_1539268695.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Luisa_torrone_mandorla_e_nocciole_1539268695.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Luisa_torrone_cioccolato_e_nocciole_1539268695.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_K7DmIWX_8UPONOe.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723067,
                            "field1": "MOTTA",
                            "field2": "tartufone",
                            "field3": "150 gr ",
                            "field4": "",
                            "description": "MOTTA tartufone 150 gr ",
                            "grammage": 150,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "ciocco",
                                "torrone",
                                "noir",
                                "nocciola",
                                "stracciatella"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 49.369369369369366,
                                "left": 0.0,
                                "width": 20.540540540540533,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/8000300120707__BARRA_CIOCCONOIR_150G_H_1571482635.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/8034097875090__BARRA_CIOCCOSTRACCIATELLA_150G_H_1571482650.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/8000300208214__BARRA_CIOCCOTORRONE_150G_H_1571482673.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_tw2eOCl_59obWse.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723182,
                            "field1": "motta",
                            "field2": "tartufone praline",
                            "field3": "100 gr",
                            "field4": "- ciocco • nocciola • noir • stracciatella",
                            "description": "motta tartufone praline 100 gr - ciocco • nocciola • noir • stracciatella",
                            "grammage": 100,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "ciocco",
                                "nocciola",
                                "noir",
                                "stracciatella"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Snack Dolci",
                            "blueprint": {
                                "top": 49.369369369369366,
                                "left": 20.72072072072072,
                                "width": 19.459459459459456,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/8034097875113__PRALINE_CIOCCONOIR_ASTUCCIO_100G_H_1571480712.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/8034097875120__PRALINE_CIOCCONOCCIOLA_ASTUCCIO_100G_H_1571480713.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/8034097875106__PRALINE_CIOCCOSTRACCIATELLA_ASTUCCIO_100G_H_S7Kd3oL.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_2Zc5jVR_PzaHhts.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723053,
                            "field1": "motta",
                            "field2": "tartufone",
                            "field3": "mini",
                            "field4": "140 gr",
                            "description": "motta tartufone mini 140 gr",
                            "grammage": 140,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 49.369369369369366,
                                "left": 40.270270270270274,
                                "width": 19.459459459459456,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Mini_Tartufone_1539617019.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_liLCJi8_uCMFjXR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723054,
                            "field1": "MOTTA",
                            "field2": "tartufone torta ",
                            "field3": "450 gr",
                            "field4": "",
                            "description": "MOTTA tartufone torta  450 gr",
                            "grammage": 450,
                            "price": "2.49",
                            "price_label": "€ 2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "gianduia",
                                "tiramisù"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 49.369369369369366,
                                "left": 60.0,
                                "width": 19.459459459459456,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Torta_Gianduia_1539617045.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Torta_Tiramisu_1539617046.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_1yuK0Gy_hXPEthd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723055,
                            "field1": "BALOCCO",
                            "field2": "torte in festa",
                            "field3": "400 gr",
                            "field4": "",
                            "description": "BALOCCO torte in festa 400 gr",
                            "grammage": 400,
                            "price": "1.89",
                            "price_label": "€ 1,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "cioccolato",
                                "yogurt e cioccolato",
                                "limone",
                                "panna e cioccolato"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 49.369369369369366,
                                "left": 79.45945945945945,
                                "width": 20.540540540540533,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Balocco_Voglia_di_Yogurt_e_Cioccolato_400gr_1571295691.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Balocco_Voglia_di_Cioccolato_400g_1571295692.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Balocco_Voglia_di_Limone_400g_1571295692.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Balocco_Voglia_di_Panna_e_Cioccolato_400gr_1571295692.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_sSByGSb_X5HLKm0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723062,
                            "field1": "nestlè ",
                            "field2": "multipack 114/126/135 gr ",
                            "field3": "",
                            "field4": "",
                            "description": "nestlè  multipack 114/126/135 gr ",
                            "grammage": 0,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "kit kat",
                                "lion",
                                "smarties"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 0.0,
                                "width": 20.540540540540533,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/lion_Trio_1449134634_1481881314.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/smarties_Trio_1481881315.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/KitKat_Trio_3x41g_1472827140_1513596790_1513608921.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/KitKat_White_trio_3x41g_1472827150_1513608921.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/temp_BLOB469641646_1512488757_2_1513770032.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_cAmnFQ7_sw0p1eH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723104,
                            "field1": "ROBERTO",
                            "field2": "pane",
                            "field3": "400 gr",
                            "field4": "• bianco • integrali",
                            "description": "ROBERTO pane 400 gr • bianco • integrali",
                            "grammage": 400,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 20.72072072072072,
                                "width": 19.369369369369362,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/ROBERTO_Pane_bianco_400_g_1516967448.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/59729_roberto_pane_integrale_1490723466_1571483538.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_RbMOPt0_NASQ2uk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723087,
                            "field1": "ferrero rocher",
                            "field2": "16 pezzi",
                            "field3": "200 gr",
                            "field4": "",
                            "description": "ferrero rocher 16 pezzi 200 gr",
                            "grammage": 200,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 40.36036036036036,
                                "width": 19.369369369369362,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Ferrero_Rocher_16_pezzi_1448983793.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_3iJj5D2_JLBQQfF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723112,
                            "field1": "doria",
                            "field2": "doriano crackers ",
                            "field3": "700 gr",
                            "field4": "",
                            "description": "doria doriano crackers  700 gr",
                            "grammage": 700,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "con sale iodato",
                                "a ridotto contenuto di sale",
                                " integrale"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 59.81981981981983,
                                "width": 19.369369369369362,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Doriano_a_ridotto_contenuto_di_sale_700g_1467624888_147643_Wdmf4KU.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Doriano_classico_con_sale_iodato_700g_1467624879_147643923_pfGZn2G.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Doriano_integrale_700g_1467624898_1476439247_1501000053_1523951453.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_lqEOgrW_cQUc4Rg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723103,
                            "field1": "PANEALBA",
                            "field2": "crostini 80/100 gr ",
                            "field3": "",
                            "field4": "",
                            "description": "PANEALBA crostini 80/100 gr ",
                            "grammage": 0,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "origano",
                                "paprika peperoncino ",
                                "pomodoro basilico",
                                "mediterraneo",
                                " integrali"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 79.54954954954954,
                                "width": 20.450450450450447,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/AD103_Crostino_Mediterraneo_100g_1548417268.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/AD102_Crostino_Paprika_e_Peperoncino_100g_1548417269.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/render-Crostini-pomodoro-basilico_1548417270.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_nl37zio_9jQ5imt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723107,
                            "field1": "PRINGLES",
                            "field2": "patatine 165 gr",
                            "field3": "",
                            "field4": "",
                            "description": "PRINGLES patatine 165 gr",
                            "grammage": 165,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "original",
                                "hot&spicy",
                                "ketchup ",
                                " sour cream&onion",
                                "paprika"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 0.0,
                                "width": 20.450450450450447,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Pringles_Paprika_1553070486.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Pringles_Hot__Spice_1553070486.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Pringles_Original_1553070487.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_urAfMR8_R1EC7r0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723093,
                            "field1": "CAMEO",
                            "field2": "snack friends",
                            "field3": "gran festa ",
                            "field4": "400 gr",
                            "description": "CAMEO snack friends gran festa  400 gr",
                            "grammage": 400,
                            "price": "1.99",
                            "price_label": "€ 1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 20.72072072072072,
                                "width": 19.549549549549546,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Cameo_Gran_Festa_400g.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_fF34qhi_HZrMyx7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723082,
                            "field1": "ERIDANIA",
                            "field2": "zucchero zefiro",
                            "field3": "1 kg",
                            "field4": "",
                            "description": "ERIDANIA zucchero zefiro 1 kg",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "2 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 80.99099099099098,
                                "left": 40.36036036036036,
                                "width": 19.549549549549546,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Eridania_Zefiro_zucchero_Kg_1.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_r0jIxYy_RP3VQDd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723078,
                            "field1": "nutella",
                            "field2": "200 gr",
                            "field3": "",
                            "field4": "",
                            "description": "nutella 200 gr",
                            "grammage": 200,
                            "price": "0.00",
                            "price_label": "2 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 59.90990990990991,
                                "width": 19.549549549549546,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Nutella_200_gr_1454603106.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_A0354XL_6DEGts2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723077,
                            "field1": "VICENZI",
                            "field2": "vicenzovo",
                            "field3": "savoiardi ",
                            "field4": "300 gr",
                            "description": "VICENZI vicenzovo savoiardi  300 gr",
                            "grammage": 300,
                            "price": "0.00",
                            "price_label": "3 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 79.45945945945945,
                                "width": 20.540540540540533,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/WEB_3020_vicenzovo_farina_macinata_pietra_300_SCATTO_B.jpg_tyybAh0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_m3wIy7d_lBxuD5a.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 9,
                    "image_file": "/media/interactive_flyer_34/pages/page_9.jpg",
                    "products": [
                        {
                            "item_id": 723114,
                            "field1": "LA TORRENTE",
                            "field2": "passata tipo birra ",
                            "field3": "tappo richiudibile",
                            "field4": "700 gr",
                            "description": "LA TORRENTE passata tipo birra  tappo richiudibile 700 gr",
                            "grammage": 690,
                            "price": "0.55",
                            "price_label": "€ 0,55",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 8.91891891891892,
                                "left": 0.0,
                                "width": 21.081081081081077,
                                "height": 22.83783783783784
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/BIRRA_LT_new_1438158975.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Rh9V5kN_XmHmDzj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723102,
                            "field1": "AS DO MAR",
                            "field2": "filetti di tonno ",
                            "field3": "150 gr",
                            "field4": "",
                            "description": "AS DO MAR filetti di tonno  150 gr",
                            "grammage": 150,
                            "price": "2.99",
                            "price_label": "€ 2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "al naturale",
                                "all’olio di oliva "
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 10.990990990990992,
                                "left": 21.53153153153153,
                                "width": 22.162162162162158,
                                "height": 20.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/As_do_mar_filetti_tonno_olio_gr_150_1497350962.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Asdomar_filetti_naturale_gr_150_1465407208_1500974172_1510678652.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_gC8NmKV_0bx4zcu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723100,
                            "field1": "as do mar",
                            "field2": "tonno pescato a canna",
                            "field3": "all’olio di oliva",
                            "field4": "6 x 80 gr",
                            "description": "as do mar tonno pescato a canna all’olio di oliva 6 x 80 gr",
                            "grammage": 480,
                            "price": "3.69",
                            "price_label": "€ 3,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 10.9009009009009,
                                "left": 43.42342342342342,
                                "width": 32.52252252252252,
                                "height": 20.945945945945947
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/As_do_Mar_Tonno_olio_oliva_6_x_80_g_1561743201.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_VX3lQgk_hMTd6am.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723121,
                            "field1": "GAROFALO",
                            "field2": "pasta di semola di Gragnano ",
                            "field3": "formati speciali 500 gr",
                            "field4": "",
                            "description": "GAROFALO pasta di semola di Gragnano  formati speciali 500 gr",
                            "grammage": 500,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "mafalde",
                                " fusilli bucati lunghi ",
                                "pappardelle",
                                "calamarata",
                                "fusillone",
                                "orecchiette",
                                "gigantoni",
                                "schiaffoni",
                                " tagliatelle",
                                "spaghetti alla chitarra"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 0.0,
                                "left": 76.21621621621621,
                                "width": 23.783783783783775,
                                "height": 31.75675675675675
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/garofalo_Calamarata_IGP_500_gr_1554733819.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/garofalo_orecchiette_500_gr_1554733819.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_G4EpNJj_sYcEdPz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723096,
                            "field1": "VALSOIA",
                            "field2": "olio di soia ",
                            "field3": "1 lt",
                            "field4": "",
                            "description": "VALSOIA olio di soia  1 lt",
                            "grammage": null,
                            "price": "2.59",
                            "price_label": "€ 2,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 0.0,
                                "width": 20.450450450450447,
                                "height": 15.810810810810812
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/valsoia_olio_soia_1_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_fUxuUaH_hQfBaH8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723113,
                            "field1": "DESANTIS",
                            "field2": "olio di semi  ",
                            "field3": "di mais",
                            "field4": "1 lt",
                            "description": "DESANTIS olio di semi   di mais 1 lt",
                            "grammage": null,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 33.333333333333336,
                                "left": 20.72072072072072,
                                "width": 19.369369369369362,
                                "height": 15.810810810810812
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Desantis_olio_di_semi_di_mais_pet_1_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_4n6RaBG_s7QfSKG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723119,
                            "field1": "BARILLA",
                            "field2": "sughi ricchi  ",
                            "field3": "ragù alla carne 400 gr",
                            "field4": "",
                            "description": "BARILLA sughi ricchi   ragù alla carne 400 gr",
                            "grammage": 400,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "amatriciana",
                                "bolognese",
                                "contadina ",
                                "montanara"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Rossi",
                            "blueprint": {
                                "top": 33.333333333333336,
                                "left": 40.270270270270274,
                                "width": 19.369369369369362,
                                "height": 15.810810810810812
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Ragu_bolognese_400g_it_1548170229_1549467485.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Amatriciana_400g_it_1543919652_1549467501.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Ragu_contadino_400g_it_1550071186.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_rSWhfcJ_v8BMFpY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723111,
                            "field1": "calvè",
                            "field2": "top down",
                            "field3": "maionese",
                            "field4": "2 x 250 gr",
                            "description": "calvè top down maionese 2 x 250 gr",
                            "grammage": 500,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 59.81981981981983,
                                "width": 19.369369369369362,
                                "height": 15.810810810810812
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Classica_Top_Down_250ml_bipacco_1560441455_1562670536.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_oXWL6KR_H7rxtff.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723101,
                            "field1": "ZAROTTI",
                            "field2": "filetti di alici",
                            "field3": "all'olio di girasole",
                            "field4": "65 gr",
                            "description": "ZAROTTI filetti di alici all'olio di girasole 65 gr",
                            "grammage": 65,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 79.45945945945945,
                                "width": 20.540540540540533,
                                "height": 15.810810810810812
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/zarotti_alici_1496930392.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_jmMXe3J_WorINCQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723097,
                            "field1": "SIMMENTHAL",
                            "field2": "carne magra",
                            "field3": "in gelatina",
                            "field4": "3 x 70 gr",
                            "description": "SIMMENTHAL carne magra in gelatina 3 x 70 gr",
                            "grammage": 210,
                            "price": "2.39",
                            "price_label": "€ 2,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 49.549549549549546,
                                "left": 0.0,
                                "width": 20.540540540540533,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/simmenthal_3_x_70_g_1461862302.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_4So4uH0_zOT6yvD.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723099,
                            "field1": "DELICIUS",
                            "field2": "filetti di sgombro ",
                            "field3": "125 gr",
                            "field4": "",
                            "description": "DELICIUS filetti di sgombro  125 gr",
                            "grammage": 125,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "all’olio di oliva",
                                "al naturale"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 20.72072072072072,
                                "width": 19.279279279279272,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/delicius_sgombro_filetti_al_naturale_1510222063_1511171918_KdzdO13.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/delicius_sgombro_filetti_all_olio_di_oliva_1510222063_1511_4TIYfpd.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_2v0CxnU_tDkOqU0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723115,
                            "field1": "KNORR",
                            "field2": "10 dadi ",
                            "field3": "100/110 gr",
                            "field4": "",
                            "description": "KNORR 10 dadi  100/110 gr",
                            "grammage": 0,
                            "price": "0.666",
                            "price_label": "€ 2,00",
                            "equivalence": 1,
                            "quantity_step": 3,
                            "grocery_label": "pz.",
                            "varieties": [
                                "classico",
                                "vegetale",
                                "funghi porcini"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 40.270270270270274,
                                "width": 19.279279279279272,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/DADO_10_CUBI__funghi_1551263402.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/DADO_10_CUBI__vegetale_1551263402.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/DADO_10_CUBI_CLASSICO_1551263403.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Ftyl71r_pqDwLI8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723110,
                            "field1": "polenta valsugana",
                            "field2": "la classica  375 gr",
                            "field3": "",
                            "field4": "",
                            "description": "polenta valsugana la classica  375 gr",
                            "grammage": 375,
                            "price": "1.59",
                            "price_label": "€ 1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 59.90990990990991,
                                "width": 19.279279279279272,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/polenta_valsugana_classica_1480587915_1508148347.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Sb0Wjr6_LgD52ta.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723108,
                            "field1": "BARILLA",
                            "field2": "farina tipo “00” ",
                            "field3": "1 kg",
                            "field4": "",
                            "description": "BARILLA farina tipo “00”  1 kg",
                            "grammage": 0,
                            "price": "0.55",
                            "price_label": "€ 0,55",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 79.63963963963963,
                                "width": 20.270270270270267,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Barilla_Farina_00_1_kg_1456215333.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_E417L8y_KzFAnd3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723109,
                            "field1": "CURTIRISO",
                            "field2": "riso integrale ",
                            "field3": "10 minuti",
                            "field4": "1 kg",
                            "description": "CURTIRISO riso integrale  10 minuti 1 kg",
                            "grammage": 0,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 0.0,
                                "width": 20.270270270270267,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Curti_riso_integrale_1465492059.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_HGUi5jN_NF3qwJs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723116,
                            "field1": "KNORR",
                            "field2": "risotteria ",
                            "field3": "varie ricette ",
                            "field4": "175 gr",
                            "description": "KNORR risotteria  varie ricette  175 gr",
                            "grammage": 175,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 20.63063063063063,
                                "width": 19.369369369369362,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/asparagi_1551263119.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/zucca_1551263132.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_MGAPUuX_HNn4KPt.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723117,
                            "field1": "LA MOLISANA",
                            "field2": "pasta di semola ",
                            "field3": "formati normali varie trafile",
                            "field4": "500 gr",
                            "description": "LA MOLISANA pasta di semola  formati normali varie trafile 500 gr",
                            "grammage": 500,
                            "price": "0.59",
                            "price_label": "€ 0,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 40.45045045045045,
                                "width": 19.369369369369362,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/la_molisana_PENNE_RIGATE_g._500_1500298857_1506085277.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/la_molisana_SPAGHETTO_QUADRATO_g._500_1500298858_1506085277.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_wkG0DQ6_cZ3Qpb1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723120,
                            "field1": "BARILLA",
                            "field2": "pasta integrale",
                            "field3": "varie trafile",
                            "field4": "500 gr",
                            "description": "BARILLA pasta integrale varie trafile 500 gr",
                            "grammage": 500,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 59.90990990990991,
                                "width": 19.369369369369362,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/barilla_Spaghetti_integrali_1469518192_1483370364.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/barilla_Tortiglioni_integrali_500_g_1469518212_1483370365.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_VO0sOFV_mwFgKc9.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723106,
                            "field1": "mulino bianco  ",
                            "field2": "3 piadelle sfogliate ",
                            "field3": "330 gr",
                            "field4": "",
                            "description": "mulino bianco   3 piadelle sfogliate  330 gr",
                            "grammage": 330,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 79.63963963963963,
                                "width": 20.360360360360357,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/mulino_bianco_Piadelle_sfogliate_3pz_1531132247.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_l7EM0rJ_swOYaEJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723184,
                            "field1": "olitalia",
                            "field2": "fridor",
                            "field3": "olio di semi di girasole",
                            "field4": "2 lt",
                            "description": "olitalia fridor olio di semi di girasole 2 lt",
                            "grammage": 2000,
                            "price": "0.00",
                            "price_label": "3 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Olio",
                            "blueprint": {
                                "top": 81.26126126126127,
                                "left": 0.0,
                                "width": 20.360360360360357,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Fridor_Fritto_perfetto_1571756728.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_OSxQ0wr_euIc8iY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723191,
                            "field1": "star ",
                            "field2": "il mio gran ragù ",
                            "field3": "classico",
                            "field4": "3 x 100 gr",
                            "description": "star  il mio gran ragù  classico 3 x 100 gr",
                            "grammage": 300,
                            "price": "0.00",
                            "price_label": "3 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 20.81081081081081,
                                "width": 19.189189189189186,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Star_gran_ragu_classico_x_3_1443429693_1458560936.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_7Dq78zw_ZdwQ2Yg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723098,
                            "field1": "rio mare",
                            "field2": "filetti di tonno grigliati",
                            "field3": "all'olio di oliva 120 gr",
                            "field4": "",
                            "description": "rio mare filetti di tonno grigliati all'olio di oliva 120 gr",
                            "grammage": 120,
                            "price": "0.00",
                            "price_label": "2 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "classici",
                                "con peperoncino"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 40.45045045045045,
                                "width": 19.189189189189186,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/RIOMARE__FILETTI_GRIGLIATI_PEPERONCI._SC_GR_0120_1559742385.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/RIOMARE__FILETTI_GRIGLIATI_OOLIVA____SC_GR_0120_1559742386.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_qoDU00A_J3w0pl3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723118,
                            "field1": "valfrutta",
                            "field2": "fagioli cannellini ",
                            "field3": "580 gr",
                            "field4": "",
                            "description": "valfrutta fagioli cannellini  580 gr",
                            "grammage": 580,
                            "price": "0.00",
                            "price_label": "2 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 59.90990990990991,
                                "width": 19.189189189189186,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/valfrutta_Cannellini_g_580_1503412303.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_pQOdOSi_MrPB3nV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723185,
                            "field1": "molino spadoni",
                            "field2": "farina tipo \"1\"",
                            "field3": "macinata a pietra",
                            "field4": "1 kg",
                            "description": "molino spadoni farina tipo \"1\" macinata a pietra 1 kg",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "2 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 81.26126126126127,
                                "left": 79.45945945945945,
                                "width": 20.540540540540533,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/molino_spadoni_tipo_1_a_pietra_1_kg_1571480900.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_lvvIHNj_89fS2Ze.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 10,
                    "image_file": "/media/interactive_flyer_34/pages/page_10.jpg",
                    "products": [
                        {
                            "item_id": 723125,
                            "field1": "buongiorno italia",
                            "field2": "vino",
                            "field3": "1 lt",
                            "field4": "• bianco • rosso",
                            "description": "buongiorno italia vino 1 lt • bianco • rosso",
                            "grammage": 0,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "bianco",
                                "rosso"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 9.009009009009008,
                                "left": 0.0,
                                "width": 23.513513513513512,
                                "height": 22.65765765765766
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Buongiorno_Italia_Vino_Bianco_1557322147.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Buongiorno_Italia_Vino_Rosso_1557322148.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_21EpoKF_dk7yU1z.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723137,
                            "field1": "ULIVETO",
                            "field2": "acqua ",
                            "field3": "minerale naturale ",
                            "field4": "1,5 lt x 6 bottiglie",
                            "description": "ULIVETO acqua  minerale naturale  1,5 lt x 6 bottiglie",
                            "grammage": 9000,
                            "price": "2.35",
                            "price_label": "€ 2,35",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Acqua",
                            "blueprint": {
                                "top": 0.0,
                                "left": 23.873873873873876,
                                "width": 24.95495495495495,
                                "height": 31.846846846846844
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Uliveto_15L__Cancella_e_Vinci_fardello_1549280535.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_dy3GyNV_P79xqWi.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723144,
                            "field1": "bibite",
                            "field2": "",
                            "field3": "",
                            "field4": "33 cl",
                            "description": "bibite 33 cl",
                            "grammage": 1320,
                            "price": "0.50",
                            "price_label": "€ 2,00",
                            "equivalence": 1,
                            "quantity_step": 4,
                            "grocery_label": "pz.",
                            "varieties": [
                                "coca cola",
                                "sprite",
                                "fanta"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 0.09009009009009009,
                                "left": 49.009009009009006,
                                "width": 29.459459459459453,
                                "height": 31.846846846846844
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Coca-Cola_light_sleek_can_cl_33_1487695986_1494354101_1534157003.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Coca-Cola_Regular_sleek_can_cl_33_1487692506_1497606235_15_kZ8CnS1.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Coca-Cola_zero_sleek_can_cl_33_1487695986_1497606235_15197_e9sXOkU.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/FANTA_ORANGE_SLEEK_CAN_CL_33_1487692508_1494354102_1534157005.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/sprite_33cl_1520527008_1520532938_1534157005.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_3BB3Q5j_XTsjT4n.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723141,
                            "field1": "PERONI",
                            "field2": "birra",
                            "field3": "33 cl x 3 bottiglie",
                            "field4": "",
                            "description": "PERONI birra 33 cl x 3 bottiglie",
                            "grammage": 990,
                            "price": "1.45",
                            "price_label": "€ 1,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 0.09009009009009009,
                                "left": 78.82882882882883,
                                "width": 21.171171171171164,
                                "height": 31.846846846846844
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Peroni_3x33_1466441081_1470317158_1526463107.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_NSv6nQx_cyOJJZV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723149,
                            "field1": "SAN BENEDETTO",
                            "field2": "acqua",
                            "field3": "50 cl x 6 bottiglie",
                            "field4": "",
                            "description": "SAN BENEDETTO acqua 50 cl x 6 bottiglie",
                            "grammage": 3000,
                            "price": "0.85",
                            "price_label": "€ 0,85",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "naturale",
                                "frizzante"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Acqua",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 0.0,
                                "width": 20.450450450450447,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/san_benedetto_acqua_naturale_50_cl_1460444975.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/san_benedetto_acqua_frizzante_50_cl_1460445026.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/28641san_benedetto_naturale_6x05_1476094185_1498635173.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/san-benedetto-acqua-minerale-naturale-oligominerale-frizza_UOIrTci.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_4Y48nBw_uXKQhnh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723136,
                            "field1": "SAN BENEDETTO",
                            "field2": "succoso zero",
                            "field3": " 40 cl",
                            "field4": "",
                            "description": "SAN BENEDETTO succoso zero  40 cl",
                            "grammage": 400,
                            "price": "0.49",
                            "price_label": "€ 0,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "frutti rossi",
                                "frutta mix",
                                "arancia e arancia rossa"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 20.63063063063063,
                                "width": 19.459459459459456,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/san_benedetto_succoso_frutta_mix_1468946380.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/san_benedetto_succoso_frutti_rossi_1468946415.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_NWbIX8W.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723147,
                            "field1": "STERILGARDA",
                            "field2": "succo ",
                            "field3": "vari gusti  ",
                            "field4": "1,5 lt",
                            "description": "STERILGARDA succo  vari gusti   1,5 lt",
                            "grammage": 1500,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 40.36036036036036,
                                "width": 19.459459459459456,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/sterilgarda_succo_polpa_ananas_15_lt.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/sterilgarda_succo_ace_15_lt_1441032405.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_sgK7433_Cjwd8lM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723146,
                            "field1": "valfrutta",
                            "field2": "triangolini",
                            "field3": "8 x 10 cl",
                            "field4": "",
                            "description": "valfrutta triangolini 8 x 10 cl",
                            "grammage": 800,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "albicocca",
                                "pera",
                                "pesca"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Succhi",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 59.90990990990991,
                                "width": 19.459459459459456,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Valfrutta_Triangolini_Albicocca_100x8_1558713687_156570972_4Y5mTux.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Valfrutta_Triangolini_Pesca_100x8_1558713688_1565709730_15_M4zkmUC.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_690dj1q_Ev7bNTG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723143,
                            "field1": "miller",
                            "field2": "birra",
                            "field3": "genuine draft",
                            "field4": "33 cl",
                            "description": "miller birra genuine draft 33 cl",
                            "grammage": 330,
                            "price": "0.00",
                            "price_label": "1 bollino",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 79.54954954954954,
                                "width": 20.450450450450447,
                                "height": 15.720720720720719
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/56565_1514970020_1559742787.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_dHhfZlg_WCepQIF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723133,
                            "field1": "SCHWEPPES",
                            "field2": "1 lt ",
                            "field3": "",
                            "field4": "",
                            "description": "SCHWEPPES 1 lt ",
                            "grammage": null,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "agrumi",
                                "limone",
                                "pompelmo rosa"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 49.549549549549546,
                                "left": 0.0,
                                "width": 17.38738738738738,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/schweppes_limone_pet_1_lt_1429282575_1437065366.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/schweppes_pompelmo_rosa_pet_1_lt_1449657371_1485859059.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_TdBj4Wc_KOqcYcl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723134,
                            "field1": "SAN BENEDETTO",
                            "field2": "zero",
                            "field3": "75 cl",
                            "field4": "",
                            "description": "SAN BENEDETTO zero 75 cl",
                            "grammage": 750,
                            "price": "0.45",
                            "price_label": "€ 0,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "aranciata",
                                "gassosa",
                                "limone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 49.549549549549546,
                                "left": 17.65765765765766,
                                "width": 15.855855855855852,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/san_benedetto_zero_gassosa_75_cl_1454582530_1470057852.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/san_benedetto_limone_zero_75_cl_1470057860.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/san_benedetto_zero_aranciata_75_cl_1470057869.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_13PZqBU_kpYn4m1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723135,
                            "field1": "GATORADE",
                            "field2": "pet 1 lt",
                            "field3": "",
                            "field4": "",
                            "description": "GATORADE pet 1 lt",
                            "grammage": 0,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "arancia",
                                "arancia rossa",
                                "limone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 33.78378378378378,
                                "width": 16.03603603603603,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Gatorade_arancia_1lt_1463567418.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Gatorade_limone_1LT_1463567428.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_KFqNoCl_sXQHOQR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723150,
                            "field1": "MONSTER",
                            "field2": "energy drink",
                            "field3": "50 cl",
                            "field4": "",
                            "description": "MONSTER energy drink 50 cl",
                            "grammage": 500,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "green classica",
                                "ultra white ",
                                "vr46 the doctor",
                                "hamilton",
                                "ultra red zero",
                                " mango loco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 50.27027027027027,
                                "width": 16.03603603603603,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Monster_energy_Drink_lattina_1494945369.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/monster_energy_the_doctor_valentino_rossi_vr46_edition_145_1Y90kGM.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/monster_ultra-zero_1491324983_1494945370.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_PImqn1Y_nDHaJFU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723142,
                            "field1": "ARNONE",
                            "field2": "bitter ",
                            "field3": "10 cl x 6 bottiglie",
                            "field4": "",
                            "description": "ARNONE bitter  10 cl x 6 bottiglie",
                            "grammage": 600,
                            "price": "1.00",
                            "price_label": "€ 1,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "bianco",
                                "brio",
                                "rosso"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Bibite",
                            "blueprint": {
                                "top": 49.549549549549546,
                                "left": 66.3963963963964,
                                "width": 16.03603603603603,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cluster_arbit_bitter_rosso_1523694619.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cluster_arbit_bitter_bianco_1523694621.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_gMAnVMf_IwsBOpJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723139,
                            "field1": "HEINEKEN ",
                            "field2": "birra",
                            "field3": "66 cl",
                            "field4": "",
                            "description": "HEINEKEN  birra 66 cl",
                            "grammage": 660,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 82.70270270270271,
                                "width": 17.297297297297295,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/heineken_birra_66_cl_1487067293.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_sL99bmA_kvLU0Gf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723148,
                            "field1": "TOURTEL",
                            "field2": "birra",
                            "field3": "analcolica",
                            "field4": "66 cl",
                            "description": "TOURTEL birra analcolica 66 cl",
                            "grammage": 660,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 0.0,
                                "width": 17.297297297297295,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/tourtel_birra_puro_malto_cl_66_1529401245.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_9nqjnor_1NMr8um.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723140,
                            "field1": "ICHNUSA",
                            "field2": "birra",
                            "field3": "non filtrata",
                            "field4": "50 cl",
                            "description": "ICHNUSA birra non filtrata 50 cl",
                            "grammage": 500,
                            "price": "1.09",
                            "price_label": "€ 1,09",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Birre",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 17.65765765765766,
                                "width": 15.94594594594594,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/ichnusaNonFiltrata50cl_1507631847.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_qtupD8g_UdxfiwH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723124,
                            "field1": "SAN MATTEO",
                            "field2": "vino mosso ",
                            "field3": "pet 75 cl",
                            "field4": "",
                            "description": "SAN MATTEO vino mosso  pet 75 cl",
                            "grammage": 750,
                            "price": "0.99",
                            "price_label": "€ 0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "rosso",
                                "bianco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 33.96396396396397,
                                "width": 15.94594594594594,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/663382_San_Matteo_vino_rosso_pet_75_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/663383_San_Matteo_vino_bianco_pet_75_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_rwcnn9R_K4vtmWI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723126,
                            "field1": "la cacciatora",
                            "field2": "vino rubicone igt",
                            "field3": "75 cl",
                            "field4": "",
                            "description": "la cacciatora vino rubicone igt 75 cl",
                            "grammage": 750,
                            "price": "1.49",
                            "price_label": "€ 1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "sangiovese",
                                "trebbiano"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 50.36036036036036,
                                "width": 15.94594594594594,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/trebbiano_1474643875.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cacciatora_1474643883.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_AJwKfmd_S66dG0E.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723122,
                            "field1": "FEUDI DI SAN GREGORIO",
                            "field2": "vino",
                            "field3": "75 cl",
                            "field4": "• trigaio rosso • albente bianco",
                            "description": "FEUDI DI SAN GREGORIO vino 75 cl • trigaio rosso • albente bianco",
                            "grammage": 750,
                            "price": "5.29",
                            "price_label": "€ 5,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "trigaio rosso",
                                "albente bianco"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 65.4954954954955,
                                "left": 66.4864864864865,
                                "width": 15.94594594594594,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Feudi_di_San_Gregorio_albente_75_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Feudi_di_San_Gregorio_trigato_75_cl_1571483835.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_fi0h8OY_GFD92qe.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723123,
                            "field1": "serristori",
                            "field2": "vino chianti docg ",
                            "field3": "75 cl",
                            "field4": "",
                            "description": "serristori vino chianti docg  75 cl",
                            "grammage": 750,
                            "price": "3.29",
                            "price_label": "€ 3,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 82.70270270270271,
                                "width": 17.297297297297295,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Serristori_chianti_75_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_US23Vcx_BcvFN1E.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723132,
                            "field1": "MIONETTO",
                            "field2": "prosecco doc ",
                            "field3": "treviso",
                            "field4": "75 cl",
                            "description": "MIONETTO prosecco doc  treviso 75 cl",
                            "grammage": 750,
                            "price": "7.49",
                            "price_label": "€ 7,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 0.0,
                                "width": 17.297297297297295,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Prosecco_DOC_Treviso_frizzante_legatura_Mionetto_NEW_2017__D9li4qm.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_3Kyminh_xCutCe0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723131,
                            "field1": "BOTTEGA",
                            "field2": "brut millesimato ",
                            "field3": "75 cl",
                            "field4": "",
                            "description": "BOTTEGA brut millesimato  75 cl",
                            "grammage": 750,
                            "price": "3.59",
                            "price_label": "€ 3,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Vini e Spumanti",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 17.747747747747745,
                                "width": 16.03603603603603,
                                "height": 15.540540540540542
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/664434_bottega_brut.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_BvqtsDK_6gDWCxm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723128,
                            "field1": "STOCK",
                            "field2": "crema di limoncè ",
                            "field3": "50 cl",
                            "field4": "",
                            "description": "STOCK crema di limoncè  50 cl",
                            "grammage": 500,
                            "price": "5.99",
                            "price_label": "€ 5,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 33.78378378378378,
                                "width": 16.03603603603603,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/stock_Crema_Limonce_50_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_2VPrLPN_bpGR2qn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723129,
                            "field1": "JAGERMEISTER",
                            "field2": "amaro ",
                            "field3": "70 cl",
                            "field4": "",
                            "description": "JAGERMEISTER amaro  70 cl",
                            "grammage": 700,
                            "price": "12.99",
                            "price_label": "€ 12,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 50.18018018018018,
                                "width": 16.03603603603603,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/58178_Jagermaister_70_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_HYc6oAw_DWzMSst.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723127,
                            "field1": "LONG JOHN",
                            "field2": "scotch whisky ",
                            "field3": "70 cl",
                            "field4": "",
                            "description": "LONG JOHN scotch whisky  70 cl",
                            "grammage": 700,
                            "price": "7.99",
                            "price_label": "€ 7,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 66.57657657657658,
                                "width": 16.03603603603603,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/long_john_scotch_whisky_70_cl_1559895868.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_XXVjBJr_jYWbgZ4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723130,
                            "field1": "CASTAGNER",
                            "field2": "grappa morbida",
                            "field3": "50 cl",
                            "field4": "",
                            "description": "CASTAGNER grappa morbida 50 cl",
                            "grammage": 500,
                            "price": "6.99",
                            "price_label": "€ 6,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Bevande",
                            "subcategory": "Liquori",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 82.70270270270271,
                                "width": 17.297297297297295,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/664274_castagner_grappa_morbida_astuccio_50_cl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_VjrSkxt_gk5FWqz.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 11,
                    "image_file": "/media/interactive_flyer_34/pages/page_11.jpg",
                    "products": [
                        {
                            "item_id": 723159,
                            "field1": "dash",
                            "field2": "detersivo ",
                            "field3": "in polvere",
                            "field4": "92 misurini",
                            "description": "dash detersivo  in polvere 92 misurini",
                            "grammage": null,
                            "price": "11.49",
                            "price_label": "€ 11,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 8.468468468468469,
                                "left": 0.0,
                                "width": 28.01801801801801,
                                "height": 23.28828828828829
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/DASH_CLASSICO_92_misurini_1543945463.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_w9ZZe1z_8Hze9jF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723186,
                            "field1": "general",
                            "field2": "detersivo liquido lavatrice",
                            "field3": "27 lavaggi",
                            "field4": "• universale • color",
                            "description": "general detersivo liquido lavatrice 27 lavaggi • universale • color",
                            "grammage": 0,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 8.558558558558559,
                                "left": 35.04504504504504,
                                "width": 28.01801801801801,
                                "height": 23.28828828828829
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/general_total_color_27_lavaggi_1571763467.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/general_total_univesale_27_lavaggi_1571763468.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_yzjLY6r_ypvM3Jk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723154,
                            "field1": "vernel",
                            "field2": "mini ammorbidente concentrato ",
                            "field3": "vari tipi ",
                            "field4": "750 + 250 ml",
                            "description": "vernel mini ammorbidente concentrato  vari tipi  750 + 250 ml",
                            "grammage": null,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 0.0,
                                "left": 63.333333333333336,
                                "width": 15.765765765765762,
                                "height": 31.846846846846844
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Ver_Nel_ammorbidente_blu_40_lavaggi_1427395840.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Ver_Nel_ammorbidente_lavanda_40_lavaggi_1427395847_1561372157.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_cizhXu4_oqOht5H.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723171,
                            "field1": "vileda",
                            "field2": "turbo smart",
                            "field3": "sistema",
                            "field4": "",
                            "description": "vileda turbo smart sistema",
                            "grammage": 0,
                            "price": "17.90",
                            "price_label": "€ 17,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 0.0,
                                "left": 78.10810810810811,
                                "width": 21.891891891891884,
                                "height": 31.846846846846844
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/vileda_turbo_smart_1552465762.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_s6M1qJS_2g6DVau.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723162,
                            "field1": "DIMENSION",
                            "field2": "shampoo & balsamo 2 in 1 250 ml",
                            "field3": "",
                            "field4": "",
                            "description": "DIMENSION shampoo & balsamo 2 in 1 250 ml",
                            "grammage": 250,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "capelli grassi",
                                "capelli normali ",
                                "capelli lisci",
                                "capelli secchi"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 33.42342342342342,
                                "left": 0.0,
                                "width": 20.630630630630627,
                                "height": 15.9009009009009
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Dimension_shampoo__balsamo_2in1_lisci_normali.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Dimension_shampoo__balsamo_2in1_secchi_grassi_1493229239.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_ywF9Vc8_dgNSdt5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723166,
                            "field1": "neutromed",
                            "field2": "bagno",
                            "field3": "750 ml",
                            "field4": "",
                            "description": "neutromed bagno 750 ml",
                            "grammage": 750,
                            "price": "1.45",
                            "price_label": "€ 1,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                " idratante",
                                "miele e vaniglia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 33.51351351351351,
                                "left": 20.72072072072072,
                                "width": 19.459459459459456,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Neutromed_Bagno_Idratante_1537434216.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/neutro_med_miele_750_ml_1531468654_1559320612.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_XF7Ajnd_5AoXMyF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723163,
                            "field1": "COLGATE ",
                            "field2": "dentifricio ",
                            "field3": "fresh gel",
                            "field4": "100 ml",
                            "description": "COLGATE  dentifricio  fresh gel 100 ml",
                            "grammage": 100,
                            "price": "0.79",
                            "price_label": "€ 0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 33.6036036036036,
                                "left": 40.270270270270274,
                                "width": 19.459459459459456,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/colgate_dentifricio_fresh_gel_ml_100_1548320084.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_mkzAoG3_m5TRORv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723164,
                            "field1": "NEUTROMED",
                            "field2": "igiene intima",
                            "field3": "200 ml",
                            "field4": "",
                            "description": "NEUTROMED igiene intima 200 ml",
                            "grammage": 200,
                            "price": "0.89",
                            "price_label": "€ 0,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "delicatezza",
                                "freschezza",
                                "lenitivo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 33.693693693693696,
                                "left": 59.90990990990991,
                                "width": 19.459459459459456,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/neutromed_lenitivo_1527521064.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/neutromed_freschezza_1527521164.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_SrrSH4Z_h0fH8vh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723165,
                            "field1": "lines",
                            "field2": "lady assorbente anatomico",
                            "field3": "9 pezzi",
                            "field4": "",
                            "description": "lines lady assorbente anatomico 9 pezzi",
                            "grammage": 0,
                            "price": "0.45",
                            "price_label": "€ 0,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 33.6036036036036,
                                "left": 79.45945945945945,
                                "width": 20.540540540540533,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Lines_Lady_9_Anatomico_1549539997.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_K7U365V_wofry28.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723169,
                            "field1": "tena ",
                            "field2": "lady",
                            "field3": "assorbenti super",
                            "field4": "x 15",
                            "description": "tena  lady assorbenti super x 15",
                            "grammage": null,
                            "price": "4.69",
                            "price_label": "€ 4,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 0.0,
                                "width": 20.540540540540533,
                                "height": 15.63063063063063
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/tena_lady_super_1527606763.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_BcuWeYE_0dYaOpY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723173,
                            "field1": "PAMPERS",
                            "field2": "baby dry ",
                            "field3": "pannolini",
                            "field4": "varie misure",
                            "description": "PAMPERS baby dry  pannolini varie misure",
                            "grammage": 0,
                            "price": "4.99",
                            "price_label": "€ 4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 49.549549549549546,
                                "left": 20.72072072072072,
                                "width": 19.279279279279272,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/pampers_baby_dry_extralarge_flash_759.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Pampers_Baby_dry_maxi_x_19.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_P3pccJW_aNcJBhg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723153,
                            "field1": "REGINA",
                            "field2": "di cuori",
                            "field3": "asciugatutto 3 veli",
                            "field4": "3 rotoli",
                            "description": "REGINA di cuori asciugatutto 3 veli 3 rotoli",
                            "grammage": 0,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 49.549549549549546,
                                "left": 40.45045045045045,
                                "width": 19.279279279279272,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/regina_cuori_asciugatutto_3_rotoli.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_ubCgfXw_1AhTDiW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723167,
                            "field1": "maxi ",
                            "field2": "carta igienica",
                            "field3": "4 rotoli",
                            "field4": "",
                            "description": "maxi  carta igienica 4 rotoli",
                            "grammage": 0,
                            "price": "0.75",
                            "price_label": "€ 0,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                " argan",
                                "camomilla"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 59.90990990990991,
                                "width": 19.279279279279272,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/maxi_carta_igienica_camomilla_1530718083.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/maxi_carta_igienica_fleur_argan_1530718083.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_6S3R64Z_OcKVQsr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723157,
                            "field1": "FAIRY",
                            "field2": "lavastoviglie caps",
                            "field3": "platinum x 16",
                            "field4": "",
                            "description": "FAIRY lavastoviglie caps platinum x 16",
                            "grammage": 0,
                            "price": "3.69",
                            "price_label": "€ 3,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "lemon",
                                "regolar"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 49.45945945945946,
                                "left": 79.45945945945945,
                                "width": 20.540540540540533,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Fairy_16_caps_limone_1537171244_1541405959.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/FAIRY_PLATINUM_Caps_16_1537171245_1541405960.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_JFMLT7m_vscomdm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723152,
                            "field1": "LIMONELLO",
                            "field2": "detersivo piatti ",
                            "field3": "5 lt",
                            "field4": "",
                            "description": "LIMONELLO detersivo piatti  5 lt",
                            "grammage": 5000,
                            "price": "2.45",
                            "price_label": "€ 2,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 0.0,
                                "width": 20.540540540540533,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/limonello_detersivo_piatti_5_Lt_1534952740.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_e8RFuv7_MaxvZ4k.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723155,
                            "field1": "smac",
                            "field2": "express pavimenti",
                            "field3": "1 lt",
                            "field4": "",
                            "description": "smac express pavimenti 1 lt",
                            "grammage": null,
                            "price": "1.29",
                            "price_label": "€ 1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "agrumi",
                                "intensa"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Pavimenti",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 20.72072072072072,
                                "width": 19.279279279279272,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/smac_express_blu_1556802759.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/smac_express_giallo_1556802761.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Tkx6oZ6_JDLIGET.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723172,
                            "field1": "VILEDA",
                            "field2": "glitzi crystal",
                            "field3": "fibra verde",
                            "field4": "2+1 gratis",
                            "description": "VILEDA glitzi crystal fibra verde 2+1 gratis",
                            "grammage": 0,
                            "price": "0.69",
                            "price_label": "€ 0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 65.4054054054054,
                                "left": 40.45045045045045,
                                "width": 19.279279279279272,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/vileda_glitzi_2__1_1458560416.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_VqjF02p_nXA7oUU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723151,
                            "field1": "VILEDA",
                            "field2": "igiene super panno ",
                            "field3": "pavimenti",
                            "field4": "3 pezzi",
                            "description": "VILEDA igiene super panno  pavimenti 3 pezzi",
                            "grammage": null,
                            "price": "1.79",
                            "price_label": "€ 1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Pavimenti",
                            "blueprint": {
                                "top": 65.22522522522523,
                                "left": 60.0,
                                "width": 19.279279279279272,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/501773_vileda_pavimenti_igiene_super_1482136392.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_zE3TYix_rLJjZO5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723170,
                            "field1": "MARIGOLD ",
                            "field2": "guanti il resistente",
                            "field3": "",
                            "field4": "",
                            "description": "MARIGOLD  guanti il resistente",
                            "grammage": 0,
                            "price": "1.39",
                            "price_label": "€ 1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "piccola ",
                                "media",
                                "grande"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 65.31531531531532,
                                "left": 79.45945945945945,
                                "width": 20.540540540540533,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/marigold_il_resistente_guanti_small.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Marigold_Il_Resistente_Medium_2013_1458560437.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_Psn4AJU_IshWLgE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723158,
                            "field1": "ACE",
                            "field2": "candeggina classica",
                            "field3": "5 lt",
                            "field4": "",
                            "description": "ACE candeggina classica 5 lt",
                            "grammage": 5000,
                            "price": "2.59",
                            "price_label": "€ 2,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 81.26126126126127,
                                "left": 0.0,
                                "width": 20.540540540540533,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/ace_Candeggina_classica_5_lt_1432637077_1435163129.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_VJhjsCx_X5JyfZ2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723156,
                            "field1": "caress",
                            "field2": "tovaglioli",
                            "field3": "monovelo",
                            "field4": "maxi scorta",
                            "description": "caress tovaglioli monovelo maxi scorta",
                            "grammage": 0,
                            "price": "0.00",
                            "price_label": "1 bollino",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 81.08108108108108,
                                "left": 20.81081081081081,
                                "width": 25.76576576576576,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/caress_tovaglioli_maxi_1543831529.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_zRs6oJz_HbXeWoo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723160,
                            "field1": "mr muscle",
                            "field2": "idraulico gel",
                            "field3": "1 lt",
                            "field4": "",
                            "description": "mr muscle idraulico gel 1 lt",
                            "grammage": 0,
                            "price": "0.00",
                            "price_label": "3 bollini",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 81.17117117117117,
                                "left": 46.75675675675675,
                                "width": 25.76576576576576,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/2895_1505811219_1511282170.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_dUbY9EC_GiYnJPP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": 723161,
                            "field1": "pronto ",
                            "field2": "5 in 1",
                            "field3": "legno pulito",
                            "field4": "750 ml",
                            "description": "pronto  5 in 1 legno pulito 750 ml",
                            "grammage": 750,
                            "price": "0.00",
                            "price_label": "1 bollino",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 81.26126126126127,
                                "left": 72.97297297297297,
                                "width": 27.027027027027025,
                                "height": 15.450450450450452
                            },
                            "images": [
                                {
                                    "image_file": "/media/interactive_flyer_34/products/Pronto_Legno_pulito_5_in_1_750_ml_1531495176.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "/media/interactive_flyer_34/products/cropped_image_7bawFRD_Aqfi5lG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 12,
                    "image_file": "/media/interactive_flyer_34/pages/page_12.jpg",
                    "products": [ ]
                },
                {
                    "number": 13,
                    "image_file": "/media/interactive_flyer_34/pages/ottimo_fine.png",
                    "products": [ ]
                },

            ]
    }
    }
    
     
}

const lefletSwitch = () => {
    if (process.env.NODE_ENV === 'production') {
        var hostName = window.location.hostname.split(".");
        return leaflets[hostName[0]];
    } else {
        return leaflets.ottimo;
    }

}

export const serverResponse = lefletSwitch();


